import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const vacationsAdapter = createEntityAdapter();

const initialState = vacationsAdapter.getInitialState();

export const vacationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVacations: builder.query({
      query: () => ({
        url: "/vacations",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedVacations = responseData.map((vacation) => {
          vacation.id = vacation._id;
          return vacation;
        });

        return vacationsAdapter.setAll(initialState, loadedVacations);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Vacation", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Vacation", id })),
          ];
        } else return [{ type: "Vacation", id: "LIST" }];
      },
    }),
    getCopVacations: builder.query({
      query: (id) => ({
        url: `/cops/${id}/vacations`,
      }),
      transformResponse: (responseData) => {
        const loadedVacations = responseData.map((vacation) => {
          vacation.id = vacation._id;
          return vacation;
        });

        return vacationsAdapter.setAll(initialState, loadedVacations);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Vacation", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Vacation", id })),
          ];
        } else return [{ type: "Vacation", id: "LIST" }];
      },
    }),
    addVacation: builder.mutation({
      query: (vacation) => ({
        url: "/vacations",
        method: "POST",
        body: { ...vacation },
      }),
      invalidatesTags: [{ type: "Vacation", id: "LIST" }],
    }),
    updateVacation: builder.mutation({
      query: (vacation) => ({
        url: "/vacations",
        method: "PATCH",
        body: { ...vacation },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Vacation", id: arg.id },
      ],
    }),
    deleteVacation: builder.mutation({
      query: (id) => ({
        url: "/vacations",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Vacation", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetVacationsQuery,
  useGetCopVacationsQuery,
  useAddVacationMutation,
  useUpdateVacationMutation,
  useDeleteVacationMutation,
} = vacationsApiSlice;

export const selectVacationsResult =
  vacationsApiSlice.endpoints.getVacations.select();

const selectVacationsData = createSelector(
  selectVacationsResult,
  (vacationsResult) => vacationsResult.data
);

export const { selectAll: selectAllVacations, selectIds: selectVacationsIds } =
  vacationsAdapter.getSelectors(
    (state) => selectVacationsData(state) ?? initialState
  );
