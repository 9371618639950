import { Grid, InputLabel, Typography } from "@mui/material";
import dayjs from "dayjs";

const MetaDataInfo = ({ steamid, createdAt, updatedAt, changedBy }) => {
  return (
    <Grid container direction="row" rowSpacing={1}>
      <Grid item xs={4}>
        <InputLabel>Steam ID</InputLabel>
      </Grid>
      <Grid item xs={8}>
        <Typography textAlign="left">{steamid}</Typography>
      </Grid>
      <Grid item xs={4}>
        <InputLabel>Erstellt am</InputLabel>
      </Grid>
      <Grid item xs={8}>
        <Typography textAlign="left">
          {dayjs(createdAt).format("DD.MM.YYYY HH:mm")}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <InputLabel>Geändert am </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <Typography textAlign="left">
          {dayjs(updatedAt).format("DD.MM.YYYY HH:mm")}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <InputLabel>Geändert von </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <Typography textAlign="left">{changedBy}</Typography>
      </Grid>
    </Grid>
  );
};
export default MetaDataInfo;
