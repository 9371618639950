import { Box, Grid, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import CustomCard from "components/CustomCard";
import Header from "components/Header";
import MyApplicationsDataGrid from "features/applications/MyApplicationsDataGrid";
import CopActivityChart from "features/cops/CopActivityChart";
import CopCareerChart from "features/cops/CopCareerChart";
import CopEvents from "features/cops/CopEvents";
import CopLearningModules from "features/cops/CopLearningModules";
import CopMainData from "features/cops/CopMainData";
import CopRatings from "features/cops/CopRatings";
import CopVacations from "features/cops/CopVacations";
import { useGetUserRatingsQuery } from "features/ratings/ratingsApiSlice";
import useAuth from "hooks/useAuth";
import { useCallback, useEffect, useState } from "react";
import { hasPermission } from "util/permissionHelper";

const scrollTo = (id) => {
  if (id === "main") {
    window.scrollTo({ top: 0, behavior: "smooth" });
  } else {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
};

const Profile = () => {
  useEffect(() => {
    document.title = `Mein Profil | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  const theme = useTheme();
  const xlBreakpoint = useMediaQuery("(min-width: 1536px)");
  const [tab, setTab] = useState("main");
  const { copData: cop, roles } = useAuth();

  const ratingsQuery = useGetUserRatingsQuery("myRatings", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const sections = document.querySelectorAll("section");
    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const top = section.offsetTop;
        const height = section.clientHeight;

        if (window.scrollY >= top - height / 3) {
          setTab(section.getAttribute("id"));
        }
      });
    });
  }, []);

  const handleChange = useCallback((_, value) => {
    setTab(value);
  }, []);

  const canEditCop = hasPermission({ roles, permissions: [] });

  return (
    <Box>
      <Header title="MEIN PROFIL" subtitle="" sx={{ mb: "-8px" }} />
      {!!cop ? (
        <>
          <Box
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: theme.palette.background.default,
              zIndex: 999,
              mb: 1,
            }}
          >
            <Tabs
              value={tab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label="Allgemeines"
                value="main"
                onClick={() => scrollTo("main")}
              />
              <Tab
                label="Aktivität"
                value="activity"
                onClick={() => scrollTo("activity")}
              />
              <Tab
                label="Karriere"
                value="career"
                onClick={() => scrollTo("career")}
              />
              <Tab
                label="Urlaub"
                value="vacation"
                onClick={() => scrollTo("vacation")}
              />
              <Tab
                label="Absolvierte Module"
                value="modules"
                onClick={() => scrollTo("modules")}
              />
              <Tab
                label="Absolvierte Veranstaltungen"
                value="events"
                onClick={() => scrollTo("events")}
              />
              <Tab
                label="Meine Bewerbungen"
                value="applications"
                onClick={() => scrollTo("applications")}
              />
              <Tab
                label="Bewertungen"
                value="ratings"
                onClick={() => scrollTo("ratings")}
              />
            </Tabs>
          </Box>

          <Grid container spacing={2} direction="row">
            <Grid item xs={12} xl={3}>
              <section
                id="main"
                style={{
                  scrollMarginTop: "20rem",
                  height: "100%",
                }}
              >
                <CopMainData cop={cop} canEditCop={canEditCop} />
              </section>
            </Grid>

            <Grid item xs={12} xl={9}>
              <Box
                display="grid"
                gap={2}
                gridTemplateColumns="repeat(12, 1fr)"
                gridTemplateRows="1fr auto"
                height="100%"
              >
                <Box gridColumn="span 12">
                  <section
                    id="activity"
                    style={{ scrollMarginTop: "3rem", height: "100%" }}
                  >
                    <CopActivityChart playtimes={cop?.playtimes} />
                  </section>
                </Box>

                <Box gridColumn={xlBreakpoint ? "span 5" : "span 12"}>
                  <section id="career" style={{ scrollMarginTop: "3rem" }}>
                    <CopCareerChart career={cop?.career} copId={cop?._id} />
                  </section>
                </Box>
                <Box gridColumn={xlBreakpoint ? "span 7" : "span 12"}>
                  <section id="vacation" style={{ scrollMarginTop: "3rem" }}>
                    <CopVacations
                      vacationsQuery={{
                        data: {
                          ids:
                            cop?.vacations?.map((vacation) => vacation._id) ||
                            [],
                          entities: cop?.vacations?.reduce(
                            (entities, vaction) => {
                              return {
                                ...entities,
                                [vaction._id]: {
                                  ...vaction,
                                  id: vaction._id,
                                },
                              };
                            },
                            {}
                          ),
                        },
                        isSuccess: true,
                        isLoading: false,
                      }}
                    />
                  </section>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} xl={4}>
              <section id="modules" style={{ scrollMarginTop: "3rem" }}>
                <CopLearningModules
                  copId={cop?._id}
                  learningModules={cop?.learningModules}
                  canEditCop={canEditCop}
                />
              </section>
            </Grid>

            <Grid item xs={12} xl={5}>
              <section id="events" style={{ scrollMarginTop: "3rem" }}>
                <CopEvents
                  eventsQuery={{
                    data: {
                      ids: cop?.events?.map((event) => event._id) || [],
                      entities: cop?.events?.reduce((entities, event) => {
                        return {
                          ...entities,
                          [event._id]: {
                            ...event,
                            id: event._id,
                          },
                        };
                      }, {}),
                    },
                    isSuccess: true,
                    isLoading: false,
                  }}
                />
              </section>
            </Grid>

            <Grid item xs={12} xl={3}>
              <section id="applications" style={{ scrollMarginTop: "3rem" }}>
                <CustomCard title="MEINE BEWERBUNGEN">
                  <MyApplicationsDataGrid />
                </CustomCard>
              </section>
            </Grid>

            <Grid item xs={12}>
              <section id="ratings" style={{ scrollMarginTop: "3rem" }}>
                <CopRatings
                  ratingsQuery={ratingsQuery}
                  copId={cop?._id}
                  canEditCop={canEditCop}
                  perspective="user"
                />
              </section>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6}>
            <CustomCard title="MEINE BEWERBUNGEN">
              <MyApplicationsDataGrid />
            </CustomCard>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Profile;
