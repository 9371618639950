import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Header from "components/Header";
import {
  useDeleteFAQMutation,
  useGetFAQsQuery,
} from "features/faq/faqApiSlice";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RequirePermissionForRender from "features/auth/RequirePermissionForRender";
import { useCallback, useEffect, useState } from "react";
import FAQDialog from "features/faq/FAQDialog";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import { useSnackbar } from "contexts/Snackbar.context";
import dayjs from "dayjs";
import useExtensions from "hooks/useExtensions";
import { generateHTML } from "@tiptap/core";
import CustomSearch from "components/CustomSearch";
import QuestionsDataGrid from "features/faq/QuestionsDataGrid";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useAddSubmittedQuestionMutation } from "features/faq/questionsApiSlice";
import SubmitQuestionsBadge from "features/faq/SubmittedQuestionsBadge";

const RotatedExpandIcon = styled((props) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(360deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const FAQAccordion = ({ faq }) => {
  const showSnackbar = useSnackbar();

  const [deleteFAQ] = useDeleteFAQMutation();

  const [dialogOpen, setDialogOpen] = useState(false);

  const showConfirmDialog = useConfirmDialog();

  const extensions = useExtensions({ placeholder: "" });

  const handleDelete = useCallback(async () => {
    try {
      await deleteFAQ(faq.id).unwrap();
      showSnackbar("FAQ gelöscht");
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const onDelete = () => {
    showConfirmDialog({
      title: "FAQ löschen?",
      message: "Soll die Frage gelöscht werden?",
      onConfirm: () => handleDelete(),
    });
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {faq.title}
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Box
            sx={{
              a: {
                color: "info.main",
                textDecoration: "none",
              },
              whiteSpace: "pre-line",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: generateHTML(faq.content, extensions),
              }}
            />
          </Box>
        </AccordionDetails>
        <AccordionActions
          sx={{
            justifyContent: "space-between",
            pl: "16px",
          }}
        >
          <Typography color={"text.secondary"} variant="subtitle2">
            {`Zuletzt aktualisiert von ${faq.updatedBy?.name || "?"} am ${dayjs(
              faq.updatedAt
            ).format("DD.MM.YYYY, [um] HH:mm [Uhr]")}`}
          </Typography>
          <RequirePermissionForRender permissions={["editFAQ"]}>
            <Box>
              <IconButton onClick={() => setDialogOpen(true)}>
                <EditIcon />
              </IconButton>
              <IconButton color="error" onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </RequirePermissionForRender>
        </AccordionActions>
      </Accordion>
      <FAQDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        initialValues={faq}
      />
    </>
  );
};

const SubmitQuestionDialog = ({ open, onClose }) => {
  const [addSubmittedQuestion] = useAddSubmittedQuestionMutation();
  const [question, setQuestion] = useState("");
  const [error, setError] = useState("");
  const showSnackbar = useSnackbar();

  const submitQuestion = async () => {
    if (!question) {
      setError("Pflichtfeld");
      return;
    }

    await addSubmittedQuestion({ question })
      .unwrap()
      .then(() => {
        showSnackbar("Frage eingereicht");
        onClose();
        setQuestion("");
      })
      .catch((error) => showSnackbar(error.data?.message || "Fehler", "error"));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Frage einreichen</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          value={question}
          onChange={(event) => setQuestion(event.target.value)}
          label="Frage"
          error={!!!question && !!error}
          helperText={!!!question && error}
          inputProps={{
            maxLength: 300,
          }}
          sx={{ mt: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          endIcon={<SendOutlinedIcon />}
          onClick={submitQuestion}
        >
          Absenden
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FAQ = () => {
  useEffect(() => {
    document.title = `FAQ | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  const { data: faqs, isSuccess } = useGetFAQsQuery("faqList", {
    refetchOnMountOrArgChange: true,
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitQuestionDialogOpen, setSubmitQuestionDialogOpen] =
    useState(false);
  const [questionsExpanded, setQuestionsExpanded] = useState(false);
  const [initialTitle, setInitialTitle] = useState("");
  const [questionId, setQuestionId] = useState("");

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      const rows = faqs.ids.map((id) => faqs.entities[id]);
      setRows(rows);
      setFilteredRows(rows);
    }
  }, [faqs, isSuccess]);

  const handleSearchChanged = (event) => {
    const searchString = event.target?.value;

    setFilteredRows(
      rows.filter((faq) =>
        faq.title?.toLowerCase().includes(searchString.toLowerCase())
      )
    );
  };

  return (
    <Box>
      <Header title="FAQ" subtitle="Frequently Asked Questions" />

      <Box display="flex">
        <Box>
          <CustomSearch onChange={handleSearchChanged} />
        </Box>
        <Button
          startIcon={<QuestionAnswerOutlinedIcon />}
          variant="contained"
          onClick={() => setSubmitQuestionDialogOpen(true)}
          sx={{
            ml: 1,
          }}
        >
          Frage einreichen
        </Button>
        <RequirePermissionForRender permissions={["editFAQ"]}>
          <Button
            variant="contained"
            startIcon={<AddOutlinedIcon />}
            onClick={() => setDialogOpen(true)}
            sx={{
              ml: 1,
            }}
          >
            FAQ hinzufügen
          </Button>

          <SubmitQuestionsBadge>
            <Button
              variant="contained"
              onClick={() => setQuestionsExpanded((value) => !value)}
              endIcon={<RotatedExpandIcon expand={questionsExpanded} />}
              sx={{
                ml: 1,
              }}
            >
              Eingereichte Fragen
            </Button>
          </SubmitQuestionsBadge>
        </RequirePermissionForRender>
      </Box>
      <Collapse in={questionsExpanded} timeout="auto" unmountOnExit={false}>
        <Box mb={1} mt={1}>
          <QuestionsDataGrid
            onCreateFAQ={(question, id) => {
              setInitialTitle(question);
              setQuestionId(id);
              setDialogOpen(true);
            }}
          />
        </Box>
      </Collapse>
      <Box mt={1}>
        {filteredRows.map((faq) => (
          <FAQAccordion key={faq.id} faq={faq} defaultEditable={!faq.title} />
        ))}
      </Box>
      <FAQDialog
        open={dialogOpen}
        onClose={() => {
          setInitialTitle("");
          setQuestionId("");
          setDialogOpen(false);
        }}
        initialValues={{
          title: initialTitle,
          content: { type: "doc", content: [{ type: "paragraph" }] },
        }}
        questionId={questionId}
      />
      <SubmitQuestionDialog
        open={submitQuestionDialogOpen}
        onClose={() => setSubmitQuestionDialogOpen(false)}
      />
    </Box>
  );
};
export default FAQ;
