import useAuth from "hooks/useAuth";
import { Link } from "react-router-dom";
import { hasPermission } from "util/permissionHelper";

const permissionsForUserEdit = [
  "lockUser",
  "editUserName",
  "editUserRoles",
  "setUserApplicationLock",
];

const UserLink = ({ children, id, ...props }) => {
  const { user } = useAuth();

  return hasPermission({
    roles: user.roles,
    permissions: permissionsForUserEdit,
  }) ? (
    <Link {...props} to={`/users/${id}`}>
      {children}
    </Link>
  ) : (
    children
  );
};
export default UserLink;
