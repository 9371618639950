import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Header from "components/Header";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CustomCard from "components/CustomCard";
import { useEffect, useState } from "react";
import RequestRatingForm from "features/requestedRatings/RequestRatingForm";
import ReceivedRequestedRatingsDataGrid from "features/requestedRatings/ReceivedRequestedRatingsDataGrid";
import SentRequestedRatingsDataGrid from "features/requestedRatings/SentRequestedRatingsDataGrid";

const RequestedRatings = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.title = `Angeforderte Bewertungen | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box>
      <Header
        title="ANGEFORDERTE BEWERTUNGEN"
        subtitle="Gesendete / Erhaltene Bewertungsanforderungen"
      />
      <Button
        startIcon={<AddOutlinedIcon />}
        variant="contained"
        sx={{ mb: "16px" }}
        onClick={() => setOpen(true)}
      >
        Bewertung anfordern
      </Button>
      <Box mb="16px">
        <CustomCard title="GESENDETE ANFORDERUNGEN" expandable>
          <SentRequestedRatingsDataGrid />
        </CustomCard>
      </Box>
      <CustomCard title="ERHALTENE ANFORDERUNGEN">
        <ReceivedRequestedRatingsDataGrid />
      </CustomCard>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Bewertung anfordern</DialogTitle>
        <DialogContent>
          <RequestRatingForm postSubmit={() => setOpen(false)} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default RequestedRatings;
