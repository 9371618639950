import { Box, Grid, InputLabel, Slider, useTheme } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import CustomCard from "components/CustomCard";
import dayjs from "dayjs";
import { useState } from "react";
import {
  useGetActivityStatisticsQuery,
  useGetControlcentersStatisticsQuery,
  useGetOperationsStatisticsQuery,
} from "./copStatisticsApiSlice";
import { DataGrid } from "@mui/x-data-grid";
import { dataGridSx } from "styles/dataGridSx";
import CustomGridPagination from "components/CustomGridPagination";
import { Link } from "react-router-dom";

const copNameField = {
  field: "copName",
  headerName: "Name",
  valueGetter: ({ row }) => row.cop?.user?.name,
  renderCell: ({ value, row }) => (
    <Link to={`/cops/${row.cop?._id}`} style={{ textDecoration: "none" }}>
      <Box color="info.main">{value}</Box>
    </Link>
  ),
  flex: 1,
  maxWidth: 200,
};

const sumField = {
  field: "sum",
  headerName: "Anzahl",
  type: "number",
  flex: 1,
};

const avgInvolvedCopsField = {
  field: "avgInvolvedCops",
  headerName: "⌀ Beteiligte Beamte",
  type: "number",
  flex: 1,
  valueGetter: ({ row }) =>
    Math.round((row.sumInvolvedCops / row.sum) * 100) / 100,
};

const activityStatisticsColumns = [
  copNameField,
  {
    field: "playtimeHours",
    headerName: "Spielstunden",
    type: "number",
    flex: 1,
  },
];

const controlcenterStatisticsColumns = [
  copNameField,
  sumField,
  avgInvolvedCopsField,
];

const operationStatisticsColumns = [
  copNameField,
  sumField,
  avgInvolvedCopsField,
];

const CopStatistics = () => {
  const theme = useTheme();
  const [from, setFrom] = useState(dayjs().subtract(1, "month").startOf("day"));
  const [until, setUntil] = useState(dayjs());
  const [numberCopsSelected, setNumberCopsSelected] = useState(5);
  const [_numberCopsSelected, _setNumberCopsSelected] = useState(5);

  const {
    data: activityStatistics,
    isLoading: getActivityStatisticsIsLoading,
  } = useGetActivityStatisticsQuery(
    {
      from: from?.valueOf(),
      until: until?.valueOf(),
      numberCopsSelected,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: controlcenterStatistics,
    isLoading: getControlcenterStatisticsIsLoading,
  } = useGetControlcentersStatisticsQuery(
    {
      from: from?.valueOf(),
      until: until?.valueOf(),
      numberCopsSelected,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: operationStatistics,
    isLoading: getOperationStatisticsIsLoading,
  } = useGetOperationsStatisticsQuery(
    {
      from: from?.valueOf(),
      until: until?.valueOf(),
      numberCopsSelected,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item>
            <DateTimePicker
              label="Von"
              value={from}
              onAccept={(value) => setFrom(value)}
              slotProps={{
                actionBar: {
                  actions: ["cancel", "clear", "accept"],
                },
              }}
            />
          </Grid>

          <Grid item>
            <DateTimePicker
              label="Bis"
              value={until}
              onAccept={(value) => setUntil(value)}
              slotProps={{
                actionBar: {
                  actions: ["cancel", "clear", "accept"],
                },
              }}
            />
          </Grid>

          <Grid item>
            <Box width="150px">
              <InputLabel>Anzahl: {_numberCopsSelected}</InputLabel>
              <Slider
                value={_numberCopsSelected}
                min={1}
                max={20}
                step={1}
                onChangeCommitted={(_, value) => setNumberCopsSelected(value)}
                onChange={(_, value) => _setNumberCopsSelected(value)}
                valueLabelDisplay="off"
                getAriaValueText={(value) => value}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CustomCard title="AKTIVITÄT">
            <Box height="300px" sx={dataGridSx(theme)}>
              <DataGrid
                getRowId={(row) => row.cop?._id}
                columns={activityStatisticsColumns}
                rows={activityStatistics || []}
                loading={getActivityStatisticsIsLoading}
                rowSelection={false}
                pageSizeOptions={[5, 10, 20]}
                density="compact"
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 5 },
                  },
                }}
                slots={{
                  pagination: CustomGridPagination,
                }}
              />
            </Box>
          </CustomCard>
        </Grid>

        <Grid item xs={12} md={4}>
          <CustomCard title="LEITSTELLEN">
            <Box height="300px" sx={dataGridSx(theme)}>
              <DataGrid
                getRowId={(row) => row.cop?._id}
                columns={controlcenterStatisticsColumns}
                rows={controlcenterStatistics || []}
                loading={getControlcenterStatisticsIsLoading}
                rowSelection={false}
                pageSizeOptions={[5, 10, 20]}
                density="compact"
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 5 },
                  },
                }}
                slots={{
                  pagination: CustomGridPagination,
                }}
              />
            </Box>
          </CustomCard>
        </Grid>

        <Grid item xs={12} md={4}>
          <CustomCard title="EINSATZLEITUNGEN">
            <Box height="300px" sx={dataGridSx(theme)}>
              <DataGrid
                getRowId={(row) => row.cop?._id}
                columns={operationStatisticsColumns}
                rows={operationStatistics || []}
                loading={getOperationStatisticsIsLoading}
                rowSelection={false}
                pageSizeOptions={[5, 10, 20]}
                density="compact"
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 5 },
                  },
                }}
                slots={{
                  pagination: CustomGridPagination,
                }}
              />
            </Box>
          </CustomCard>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CopStatistics;
