import { Box, Checkbox, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useDeleteEventMutation,
  useGetEventsQuery,
  useUpdateEventMutation,
} from "./eventsApiSlice";
import { dataGridSx } from "styles/dataGridSx";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import { DataGrid } from "@mui/x-data-grid";
import EventDialog from "./EventDialog";
import { useSnackbar } from "contexts/Snackbar.context";
import dayjs from "dayjs";
import CustomGridPagination from "components/CustomGridPagination";
import { calcGridHeightSubtraction } from "util/calculations";

const EventDataGrid = ({ eventType }) => {
  const theme = useTheme();
  const showSnackbar = useSnackbar();

  const {
    data: events,
    isSuccess: getEventsIsSuccess,
    isLoading: getEventsIsLoading,
  } = useGetEventsQuery("eventsList", {
    refetchOnMountOrArgChange: true,
  });

  const [updateEvent] = useUpdateEventMutation();
  const [deleteEvent] = useDeleteEventMutation();

  const [rows, setRows] = useState();
  const [eventData, setEventData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (getEventsIsSuccess) {
      setRows(
        events.ids.reduce((filtered, id) => {
          const event = events.entities[id];

          if (event.type === eventType) {
            filtered.push(event);
          }

          return filtered;
        }, [])
      );
    }
  }, [events, getEventsIsSuccess, eventType]);

  const renderCheckbox = (params) => (
    <Checkbox checked={params.value} disableRipple />
  );

  const columns = [
    {
      field: "title",
      headerName: "Titel",
      flex: 1,
    },
    {
      field: "start",
      headerName: "Start",
      ...dateTimeColumnType,
      flex: 1,
    },
    {
      field: "end",
      headerName: "Ende",
      ...dateTimeColumnType,
      flex: 1,
    },
    {
      field: "allDay",
      headerName: "Ganztages Veranstaltung",
      renderCell: renderCheckbox,
      type: "boolean",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Typ",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Geändert am",
      ...dateTimeColumnType,
      flex: 1,
    },
    {
      field: "updatedBy",
      headerName: "Geändert von",
      valueGetter: ({ value }) => value?.name,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      ...dateTimeColumnType,
      flex: 1,
    },
    {
      field: "createdBy",
      headerName: "Erstellt von",
      valueGetter: ({ value }) => value?.name,
      flex: 1,
    },
  ];

  if (eventType === "Modul") {
    columns.splice(5, 0, {
      field: "learningModule",
      headerName: "Modul",
      flex: 1,
    });
  }

  const handleRowClick = (params) => {
    setEventData({
      ...params.row,
      start: dayjs(params.row?.start),
      end: dayjs(params.row?.end),
      learningModule: params.row?.learningModule?._id || "",
      organizedBy: params.row?.organizedBy.map((organizer) => organizer._id),
      processEvent: false,
    });
    setDialogOpen(true);
  };

  const onSaveEvent = async (values, { resetForm }) => {
    await updateEvent(values)
      .unwrap()
      .then(() => {
        showSnackbar("Veranstaltung geändert");
        setDialogOpen(false);
        resetForm();
      })
      .catch((error) =>
        showSnackbar(error?.data?.message || "Fehler", "error")
      );
  };

  const onDeleteEvent = async (id) => {
    await deleteEvent(id)
      .unwrap()
      .then(() => {
        showSnackbar("Veranstaltung gelöscht");
        setDialogOpen(false);
      })
      .catch((error) =>
        showSnackbar(error?.data?.message || "Fehler", "error")
      );
  };

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        ".MuiDataGrid-row": {
          ":hover": {
            cursor: "pointer !important",
          },
        },
        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        rows={getEventsIsSuccess && !!rows ? rows : []}
        loading={getEventsIsLoading}
        onRowClick={handleRowClick}
        rowSelection={false}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        slots={{
          pagination: CustomGridPagination,
        }}
      />
      <EventDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onDelete={onDeleteEvent}
        onSubmit={onSaveEvent}
        initialValues={eventData}
        readOnly={false}
      />
    </Box>
  );
};

export default EventDataGrid;
