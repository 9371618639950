import { Box, Button, Chip, useTheme } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useSnackbar } from "contexts/Snackbar.context";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { dataGridSx } from "styles/dataGridSx";
import { useGetCopsQuery } from "features/cops/copsApiSlice";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import CustomGridPagination from "components/CustomGridPagination";
import {
  useAddInfoMutation,
  useDeleteInfoMutation,
  useGetInfosQuery,
  useUpdateInfoMutation,
} from "./infosApiSlice";
import CopsAutocomplete from "components/CopsAutocomplete";
import Textarea from "components/Textarea";
import { Link } from "react-router-dom";
import { calcGridHeightSubtraction } from "util/calculations";

const InfosDataGrid = ({ infoType, rankFilter = [] }) => {
  const theme = useTheme();

  const { data: cops, isSuccess: getCopsIsSuccess } = useGetCopsQuery(
    "copsList",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: infos,
    isSuccess: getInfosIsSuccess,
    isLoading: getInfosIsLoading,
  } = useGetInfosQuery("infosList", {
    refetchOnMountOrArgChange: true,
  });

  const [addInfo] = useAddInfoMutation();
  const [updateInfo] = useUpdateInfoMutation();
  const [deleteInfo] = useDeleteInfoMutation();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const showSnackbar = useSnackbar();

  useEffect(() => {
    if (getInfosIsSuccess) {
      setRows(
        infos.ids.reduce((filtered, id) => {
          const info = infos.entities[id];

          if (info.type === infoType) {
            const addedInfo = {
              id,
              affectedCops:
                rankFilter.length > 0
                  ? info.affectedCops
                      .filter((cop) => rankFilter.includes(cop.rank))
                      .map((cop) => cop._id)
                  : info.affectedCops.map((cop) => cop._id),
              type: info.type,
              text: info.text,
              updatedAt: info.updatedAt,
              updatedBy: info.updatedBy,
              createdAt: info.createdAt,
              createdBy: info.createdBy,
            };

            if (addedInfo.affectedCops?.length > 0) filtered.push(addedInfo);
          }

          return filtered;
        }, [])
      );
    }
    // eslint-disable-next-line
  }, [infos, getInfosIsSuccess]);

  const EditToolbar = () => {
    const handleAdd = async () => {
      const id = `$${Date.now()}`;
      setRows((oldRows) => [
        {
          id,
          affectedCops: [],
          type: infoType,
          text: "",
          updatedAt: null,
          updatedBy: "",
          createdAt: new Date(),
          createdBy: "",
          isNew: true,
        },
        ...oldRows,
      ]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "affectedCops" },
      }));
    };

    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <Button
          variant="text"
          onClick={handleAdd}
          startIcon={<AddOutlinedIcon />}
        >
          {infoType === "note" ? "Notiz" : "Sanktion"} hinzufügen
        </Button>
      </GridToolbarContainer>
    );
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.enterKeyDown
    ) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const showConfirmDialog = useConfirmDialog();

  const handleDelete = useCallback(async (id) => {
    try {
      await deleteInfo(id).unwrap();
      showSnackbar(`${infoType === "note" ? "Notiz" : "Sanktion"} gelöscht`);
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = (id) => () => {
    showConfirmDialog({
      title: `${infoType === "note" ? "Notiz" : "Sanktion"} löschen?`,
      message: `Soll die ${
        infoType === "note" ? "Notiz" : "Sanktion"
      } gelöscht werden`,
      onConfirm: () => handleDelete(id),
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    if (newRow.isNew) {
      await addInfo(newRow).unwrap();
      newRow.isNew = false;
      showSnackbar(`${infoType === "note" ? "Notiz" : "Sanktion"} angelegt`);
    } else {
      await updateInfo(newRow).unwrap();
      showSnackbar(`${infoType === "note" ? "Notiz" : "Sanktion"} gespeichert`);
    }

    return newRow;
  };

  const handleProcessRowUpdateError = (error) => {
    showSnackbar(error.data?.message || "Fehler", "error");
  };

  const EditCopsInput = (params) => {
    const { id, field } = params;

    const apiRef = useGridApiContext();

    const handleChange = (_, value) => {
      apiRef.current.setEditCellValue({ id, field, value });
    };

    return getCopsIsSuccess ? (
      <CopsAutocomplete
        cops={cops}
        filterCop={
          rankFilter.length > 0 && ((cop) => rankFilter.includes(cop.rank))
        }
        autocompleteProps={{
          readOnly: false,
          value: params.value,
          limitTags: 3,
          onChange: handleChange,
          renderTags: (value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={cops.entities[option].user?.name}
                clickable
                component={Link}
                to={`/cops/${cops.entities[option]._id}`}
              />
            )),
        }}
        inputProps={{
          sx: {
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
          },
        }}
      />
    ) : (
      <></>
    );
  };

  const renderCops = (params) =>
    getCopsIsSuccess ? (
      <CopsAutocomplete
        cops={cops}
        filterCop={
          rankFilter.length > 0 && ((cop) => rankFilter.includes(cop.rank))
        }
        autocompleteProps={{
          readOnly: true,
          forcePopupIcon: false,
          value: params.value,
          limitTags: 3,
          renderTags: (value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={cops.entities[option]?.user?.name}
                clickable
                component={Link}
                to={`/cops/${cops.entities[option]?._id}`}
                onDelete={undefined}
              />
            )),
        }}
        inputProps={{
          sx: {
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
          },
        }}
      />
    ) : (
      <></>
    );

  const renderEditCops = (params) => <EditCopsInput {...params} />;

  const columns = [
    {
      field: "affectedCops",
      headerName: "Betroffene Polizisten",
      renderCell: renderCops,
      renderEditCell: renderEditCops,
      editable: true,
      flex: 1,
    },
    {
      field: "text",
      headerName: "Sachverhalt",
      renderEditCell: (params) => <Textarea {...params} />,
      editable: true,
      flex: 2,
    },
    {
      field: "updatedAt",
      headerName: "Geändert am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "updatedBy",
      headerName: "Geändert von",
      valueGetter: ({ value }) => value?.name,
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "createdBy",
      headerName: "Erstellt von",
      valueGetter: ({ value }) => value?.name,
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "actions",
      headerName: "Aktionen",
      type: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Speichern"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Bearbeiten"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Löschen"
            onClick={handleDeleteClick(id)}
            color="error"
          />,
        ];
      },
    },
  ];

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        columns={columns}
        rows={getInfosIsSuccess ? rows : []}
        loading={getInfosIsLoading}
        editMode="row"
        disableRowSelectionOnClick
        slots={{ toolbar: EditToolbar, pagination: CustomGridPagination }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        getRowHeight={() => "auto"}
        sx={{
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
      />
    </Box>
  );
};
export default memo(InfosDataGrid);
