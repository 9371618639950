import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const armorysAdapter = createEntityAdapter();

const initialState = armorysAdapter.getInitialState();

export const armorysApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getArmorys: builder.query({
      query: () => ({
        url: "/armories",
      }),
      transformResponse: (responseData) => {
        const loadedArmorys = responseData
          .map((armory) => {
            armory.id = armory._id;
            return armory;
          })
          .sort((a, b) => a.armoryId - b.armoryId);

        loadedArmorys.forEach((armory) => {
          armory.changes.sort(
            (a, b) =>
              new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
          );

          armory.gear.sort((a, b) => b.count - a.count);
          armory.inventory.sort((a, b) => b.count - a.count);
        });

        return armorysAdapter.setAll(initialState, loadedArmorys);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Armory", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Armory", id })),
          ];
        } else return [{ type: "Armory", id: "LIST" }];
      },
    }),
  }),
});

export const { useGetArmorysQuery } = armorysApiSlice;

export const selectArmorysResult =
  armorysApiSlice.endpoints.getArmorys.select();

const selectArmorysData = createSelector(
  selectArmorysResult,
  (armorysResult) => armorysResult.data
);

export const { selectAll: selectAllArmorys, selectIds: selectArmoryIds } =
  armorysAdapter.getSelectors(
    (state) => selectArmorysData(state) ?? initialState
  );
