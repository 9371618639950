const DesertSvg = ({ ...props }) => {
  return (
    <svg
      height="800px"
      width="800px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 431 431"
      {...props}
    >
      <g>
        <path
          d="M301.5,123c22.883,0,41.5-18.617,41.5-41.5S324.383,40,301.5,40S260,58.617,260,81.5S278.617,123,301.5,123z M301.5,55
     c14.612,0,26.5,11.888,26.5,26.5S316.112,108,301.5,108S275,96.112,275,81.5S286.888,55,301.5,55z"
        />
        <path
          d="M423.5,258h-64.674v-35h13.81c4.143,0,7.5-3.358,7.5-7.5v-28.087c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V208h-6.31
     v-25.109c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V208H339v-15.63c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v23.13
     c0,4.142,3.357,7.5,7.5,7.5h12.326v35h-104.46c-11.434,0-21.977-4.736-28.926-12.994C203.483,236.741,193.3,232,182.5,232
     c-10.799,0-20.983,4.741-27.941,13.007C147.609,253.264,137.067,258,125.634,258H63v-35h14.5c4.143,0,7.5-3.358,7.5-7.5v-34
     c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V208h-7v-42.5c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v8.5h-9v-20.5
     c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v28c0,4.142,3.357,7.5,7.5,7.5H48v69H7.5c-4.143,0-7.5,3.358-7.5,7.5
     s3.357,7.5,7.5,7.5h118.134c15.87,0,30.596-6.683,40.4-18.334c4.102-4.872,10.103-7.666,16.466-7.666s12.364,2.794,16.464,7.666
     C208.771,266.317,223.496,273,239.366,273H423.5c4.143,0,7.5-3.358,7.5-7.5S427.643,258,423.5,258z"
        />
        <path
          d="M109,327.224c0-4.142-3.357-7.5-7.5-7.5h-9.501c2.787-9.919,0.285-21.025-7.504-28.815
     c-11.475-11.474-30.144-11.474-41.62,0c-7.789,7.79-10.291,18.896-7.504,28.815H21.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5
     h80C105.643,334.724,109,331.366,109,327.224z M53.481,301.515c5.626-5.626,14.78-5.626,20.406,0
     c4.942,4.942,5.539,12.605,1.799,18.209H51.684C47.943,314.12,48.54,306.457,53.481,301.515z"
        />
        <path
          d="M390.055,350h-16.076c1.587-4.143,2.417-8.585,2.417-13.154c0-9.806-3.818-19.024-10.752-25.958
     c-6.934-6.934-16.152-10.752-25.958-10.752s-19.024,3.818-25.957,10.752c-6.935,6.933-10.753,16.152-10.753,25.958
     c0,4.569,0.83,9.012,2.417,13.154h-21.899c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h106.562c4.143,0,7.5-3.358,7.5-7.5
     S394.197,350,390.055,350z M324.335,321.495c4.1-4.101,9.552-6.358,15.351-6.358s11.251,2.258,15.352,6.358
     c4.1,4.101,6.358,9.552,6.358,15.351c0,4.821-1.582,9.39-4.465,13.154h-34.49c-2.883-3.764-4.465-8.333-4.465-13.154
     C317.976,331.047,320.234,325.595,324.335,321.495z"
        />
        <path
          d="M211,321.5c0-4.142-3.357-7.5-7.5-7.5h-28c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h28
     C207.643,329,211,325.642,211,321.5z"
        />
        <path
          d="M132.625,354h-40.25c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h40.25c4.143,0,7.5-3.358,7.5-7.5
     S136.768,354,132.625,354z"
        />
        <path
          d="M287,303.5c0-4.142-3.357-7.5-7.5-7.5h-16c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h16
     C283.643,311,287,307.642,287,303.5z"
        />
        <path
          d="M237.5,376h-47.875c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5H237.5c4.143,0,7.5-3.358,7.5-7.5S241.643,376,237.5,376z
     "
        />
      </g>
    </svg>
  );
};
export default DesertSvg;
