import { Box } from "@mui/material";
import Header from "components/Header";
import JobProtocolDataGrid from "features/jobProtocols/JobProtocolDataGrid";
import { useEffect } from "react";

const Jobs = () => {
  useEffect(() => {
    document.title = `Job Protokoll | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="JOB PROTOKOLL" subtitle="Protokolle eingeplanter Jobs" />
      <JobProtocolDataGrid />
    </Box>
  );
};
export default Jobs;
