import { Box, Button, useTheme } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import {
  useAddNewsMutation,
  useDeleteNewsMutation,
  useGetNewsQuery,
  useUpdateNewsMutation,
} from "./newsApiSlice";
import { dataGridSx } from "styles/dataGridSx";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "contexts/Snackbar.context";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import CustomGridPagination from "components/CustomGridPagination";
import { Link } from "react-router-dom";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import dayjs from "dayjs";
import { calcGridHeightSubtraction } from "util/calculations";

const NewsDataGrid = () => {
  const theme = useTheme();
  const {
    data: news,
    isSuccess: getNewsIsSuccess,
    isLoading: getNewsIsLoading,
  } = useGetNewsQuery("newsList", {
    refetchOnMountOrArgChange: true,
  });

  const [addNews] = useAddNewsMutation();
  const [updateNews] = useUpdateNewsMutation();
  const [deleteNews] = useDeleteNewsMutation();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const showSnackbar = useSnackbar();

  useEffect(() => {
    if (getNewsIsSuccess)
      setRows(
        news?.ids.map((id) => ({
          id,
          from: news.entities[id].from,
          until: news.entities[id].until,
          audience: news.entities[id].audience,
          message: news.entities[id].message,
          url: news.entities[id].url,
        }))
      );
  }, [news, getNewsIsSuccess]);

  const EditToolbar = () => {
    const handleAddNews = async () => {
      const id = `$${Date.now()}`;
      setRows((oldRows) => [
        {
          id,
          from: dayjs(),
          until: dayjs().add(1, "week"),
          audience: "",
          message: "",
          url: "",
          isNew: true,
        },
        ...oldRows,
      ]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "from" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          variant="text"
          onClick={handleAddNews}
          startIcon={<AddOutlinedIcon />}
        >
          News hinzufügen
        </Button>
      </GridToolbarContainer>
    );
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const showConfirmDialog = useConfirmDialog();

  const handleDeleteNews = useCallback(async (id) => {
    try {
      await deleteNews(id).unwrap();
      showSnackbar("Eintrag gelöscht");
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = (id) => () => {
    showConfirmDialog({
      title: "Eintrag löschen?",
      message: "Soll der Eintrag gelöscht werden?",
      onConfirm: () => handleDeleteNews(id),
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    if (newRow.isNew) {
      await addNews(newRow).unwrap();
      newRow.isNew = false;
      showSnackbar("News angelegt");
    } else {
      await updateNews(newRow).unwrap();
      showSnackbar("News gespeichert");
    }

    return newRow;
  };

  const handleProcessRowUpdateError = (error) => {
    showSnackbar(error.data?.message, "error");
  };

  const renderUrl = ({ value }) => (
    <Link
      to={value || "#"}
      target="_blank"
      style={{ color: theme.palette.info.main, textDecoration: "none" }}
    >
      {value}
    </Link>
  );

  const columns = [
    {
      field: "active",
      headerName: "Aktiv",
      type: "boolean",
      valueGetter: ({ row }) =>
        dayjs().isBetween(dayjs(row.from), dayjs(row.until)),
    },
    {
      field: "from",
      ...dateTimeColumnType,
      headerName: "Von",
      flex: 1,
      maxWidth: 150,
      editable: true,
    },
    {
      field: "until",
      ...dateTimeColumnType,
      headerName: "Bis",
      flex: 1,
      maxWidth: 150,
      editable: true,
    },
    {
      field: "audience",
      type: "singleSelect",
      valueOptions: ["Alle", "Polizisten", "Zivilisten"],
      headerName: "Zielgruppe",
      editable: true,
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "message",
      headerName: "Nachricht",
      editable: true,
      flex: 1,
    },
    {
      field: "url",
      headerName: "URL",
      renderCell: renderUrl,
      flex: 2,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Aktionen",
      type: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Speichern"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Bearbeiten"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Löschen"
            onClick={handleDeleteClick(id)}
            color="error"
          />,
        ];
      },
    },
  ];

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        loading={getNewsIsLoading}
        editMode="row"
        rows={getNewsIsSuccess ? rows : []}
        slots={{ toolbar: EditToolbar, pagination: CustomGridPagination }}
        initialState={{
          sorting: { sortModel: [{ field: "active", sort: "desc" }] },
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
    </Box>
  );
};
export default NewsDataGrid;
