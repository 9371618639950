import { Box, useTheme } from "@mui/material";
import { useGetPlaytimesQuery } from "./playtimesApiSlice";
import { dataGridSx } from "styles/dataGridSx";
import { memo, useCallback, useEffect, useState } from "react";
import PlayerSideChip from "components/PlayerSideChip";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import CustomGridPagination from "components/CustomGridPagination";
import PlaytimeDialog from "./PlaytimeDialog";
import { DateTimePicker } from "@mui/x-date-pickers";
import { calcGridHeightSubtraction } from "util/calculations";

dayjs.extend(duration);

const PlaytimeDataGrid = () => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [playtime, setPlaytime] = useState({});
  const [from, setFrom] = useState(dayjs().subtract(3, "day").startOf("day"));
  const [until, setUntil] = useState(dayjs());
  const [rows, setRows] = useState([]);

  const {
    data: playtimes,
    isSuccess,
    isLoading,
  } = useGetPlaytimesQuery(
    {
      from: from?.valueOf() || undefined,
      until: until?.valueOf() || undefined,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (isSuccess) setRows(playtimes.ids.map((id) => playtimes.entities[id]));
  }, [playtimes, isSuccess]);

  const CustomGridToolbar = useCallback(
    () => (
      <GridToolbarContainer sx={{ mb: 1 }}>
        <Box display="flex" height="100%" alignItems="flex-end">
          <GridToolbarQuickFilter sx={{ maxWidth: "200px" }} />
        </Box>
        <DateTimePicker
          label="Von"
          value={from}
          onAccept={(value) => setFrom(value)}
          slotProps={{
            actionBar: {
              actions: ["cancel", "clear", "accept"],
            },
          }}
        />
        <DateTimePicker
          label="Bis"
          value={until}
          onAccept={(value) => setUntil(value)}
          slotProps={{
            actionBar: {
              actions: ["cancel", "clear", "accept"],
            },
          }}
        />
      </GridToolbarContainer>
    ),
    // eslint-disable-next-line
    []
  );

  const columns = [
    {
      field: "server",
      headerName: "Server",
      flex: 1,
      maxWidth: 80,
    },
    {
      field: "side",
      type: "singleSelect",
      valueOptions: ["cop", "civ"],
      headerName: "Seite",
      renderCell: ({ value }) => <PlayerSideChip side={value} />,
      flex: 1,
      maxWidth: 140,
    },
    {
      field: "cop",
      headerName: "Name",
      valueGetter: ({ value }) => value?.user?.name,
      flex: 1,
      maxWidth: 140,
    },
    {
      field: "name",
      headerName: "Name auf dem Server",
      flex: 1,
      maxWidth: 250,
    },
    {
      field: "start",
      headerName: "Von",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 140,
    },
    {
      field: "end",
      headerName: "Bis",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 140,
    },
    {
      field: "duration",
      headerName: "Spieldauer",
      valueGetter: ({ row }) =>
        dayjs
          .duration(dayjs(row.end).diff(dayjs(row.start)))
          .format("HH[h] mm[m]"),
      flex: 1,
    },
  ];

  const handleRowClick = ({ row }) => {
    setPlaytime(row);
    setDialogOpen(true);
  };

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),

        ".MuiDataGrid-row": {
          ":hover": {
            cursor: "pointer !important",
          },
        },
        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        rows={isSuccess ? rows : []}
        loading={isLoading}
        rowSelection={false}
        disableRowSelectionOnClick
        onRowClick={handleRowClick}
        initialState={{
          sorting: {
            sortModel: [{ field: "start", sort: "desc" }],
          },
        }}
        slots={{
          pagination: CustomGridPagination,
          toolbar: CustomGridToolbar,
        }}
      />
      <PlaytimeDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        playtime={playtime}
      />
    </Box>
  );
};
export default memo(PlaytimeDataGrid);
