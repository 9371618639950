import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const expCalculationsAdapter = createEntityAdapter();

const initialState = expCalculationsAdapter.getInitialState();

export const expCalculationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExpCalculations: builder.query({
      query: (params) => {
        const query = {
          url: "/exp-calculations",
        };

        if (params) query.params = { ...params };

        return query;
      },
      transformResponse: (responseData) => {
        const loadedExpCalculations = responseData.map((expCalculation) => {
          expCalculation.id = expCalculation._id;
          return expCalculation;
        });

        return expCalculationsAdapter.setAll(
          initialState,
          loadedExpCalculations
        );
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "ExpCalculation", id: "LIST" },
            ...result.ids.map((id) => ({ type: "ExpCalculation", id })),
          ];
        } else return [{ type: "ExpCalculation", id: "LIST" }];
      },
    }),
  }),
});

export const { useGetExpCalculationsQuery } = expCalculationsApiSlice;

export const selectExpCalculationsResult =
  expCalculationsApiSlice.endpoints.getExpCalculations.select();

const selectExpCalculationsData = createSelector(
  selectExpCalculationsResult,
  (expCalculationsResult) => expCalculationsResult.data
);

export const {
  selectAll: selectAllExpCalculations,
  selectIds: selectExpCalculationIds,
} = expCalculationsAdapter.getSelectors(
  (state) => selectExpCalculationsData(state) ?? initialState
);
