export const hasPermission = ({ roles, permissions }) => {
  if (!roles) return false;

  return roles.some((role) =>
    role.permissions.some(
      (permission) =>
        permissions.includes(permission.name) || permission.name === "admin"
    )
  );
};

/**
 *
 * @param {roles} roles
 * @returns Höchstes Level der Rollen, die die Berechtigungen zur Zuordnung von Benutzerrollen haben
 */
export const getMaxRoleEditLevel = ({ roles }) => {
  return Math.max(
    ...roles
      .filter((role) =>
        role.permissions.some(
          (permission) =>
            permission.name === "editUserRoles" || permission.name === "admin"
        )
      )
      .map((role) => role.level)
  );
};
