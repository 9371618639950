import { Box } from "@mui/material";
import Header from "components/Header";
import InfosDataGrid from "features/infos/InfosDataGrid";
import { useEffect } from "react";

const Notes = () => {
  useEffect(() => {
    document.title = `Notizen | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="NOTIZEN" subtitle="Notizen zu Polizisten" />
      <InfosDataGrid infoType="note" />
    </Box>
  );
};

export default Notes;
