import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const newsAdapter = createEntityAdapter();

const initialState = newsAdapter.getInitialState();

export const newsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query({
      query: () => ({
        url: "/news",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedNews = responseData.map((news) => {
          news.id = news._id;
          return news;
        });

        return newsAdapter.setAll(initialState, loadedNews);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "News", id: "LIST" },
            ...result.ids.map((id) => ({ type: "News", id })),
          ];
        } else return [{ type: "News", id: "LIST" }];
      },
    }),
    addNews: builder.mutation({
      query: (news) => ({
        url: "/news",
        method: "POST",
        body: { ...news },
      }),
      invalidatesTags: [{ type: "News", id: "LIST" }],
    }),
    updateNews: builder.mutation({
      query: (news) => ({
        url: "/news",
        method: "PATCH",
        body: { ...news },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "News", id: arg.id }],
    }),
    deleteNews: builder.mutation({
      query: (id) => ({
        url: "/news",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "News", id: arg.id }],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useAddNewsMutation,
  useUpdateNewsMutation,
  useDeleteNewsMutation,
} = newsApiSlice;

export const selectNewsResult = newsApiSlice.endpoints.getNews.select();

const selectNewsData = createSelector(
  selectNewsResult,
  (newsResult) => newsResult.data
);

export const { selectAll: selectAllNews, selectIds: selectNewsIds } =
  newsAdapter.getSelectors((state) => selectNewsData(state) ?? initialState);
