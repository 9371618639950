import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const playerCountsAdapter = createEntityAdapter();

const initialState = playerCountsAdapter.getInitialState();

export const playerCountsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPlayerCounts: builder.query({
      query: (params) => {
        const query = {
          url: "/player-counts",
        };

        if (params) query.params = { ...params };

        return query;
      },
      transformResponse: (responseData) => {
        const loadedPlayerCounts = responseData.map((playerCount) => {
          playerCount.id = playerCount._id;
          return playerCount;
        });

        return playerCountsAdapter.setAll(initialState, loadedPlayerCounts);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "PlayerCount", id: "LIST" },
            ...result.ids.map((id) => ({ type: "PlayerCount", id })),
          ];
        } else return [{ type: "PlayerCount", id: "LIST" }];
      },
    }),
  }),
});

export const { useGetPlayerCountsQuery } = playerCountsApiSlice;

export const selectPlayerCountsResult =
  playerCountsApiSlice.endpoints.getPlayerCounts.select();

const selectPlayerCountsData = createSelector(
  selectPlayerCountsResult,
  (playerCountsResult) => playerCountsResult.data
);

export const {
  selectAll: selectAllPlayerCounts,
  selectIds: selectPlayerCountIds,
} = playerCountsAdapter.getSelectors(
  (state) => selectPlayerCountsData(state) ?? initialState
);
