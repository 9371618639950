import { Box, Button, Grid, ListItem } from "@mui/material";
import CustomCard from "components/CustomCard";
import Header from "components/Header";
import { useGetLinksQuery } from "features/links/linksApiSlice";
import { useEffect, useState } from "react";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import CustomSearch from "components/CustomSearch";
import { Link } from "react-router-dom";

const LinkItem = ({ link }) => (
  <ListItem disablePadding>
    <Link to={link.url} target="_blank">
      <Button
        color="info"
        startIcon={<LinkOutlinedIcon />}
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {link.text}
      </Button>
    </Link>
  </ListItem>
);

const UsefulLinks = () => {
  useEffect(() => {
    document.title = `Linkliste | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  const { data: links, isSuccess } = useGetLinksQuery("linksList", {
    refetchOnMountOrArgChange: true,
  });

  const [rows, setRows] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      const rows = links.ids.map((id) => links.entities[id]);
      setRows(rows);
      setFilteredRows(rows);
    }
  }, [links, isSuccess]);

  useEffect(() => {
    setCategories([...new Set(filteredRows.map((link) => link.category))]);
  }, [filteredRows]);

  const handleSearchChanged = (event) => {
    const searchString = event.target?.value;

    setFilteredRows(
      rows.filter((link) =>
        link.text?.toLowerCase().includes(searchString.toLowerCase())
      )
    );
  };

  return (
    <Box>
      <Header title="LINKLISTE" subtitle="Nützliche Links" />
      <Box display="flex" mb={1}>
        <CustomSearch onChange={handleSearchChanged} />
      </Box>
      <Grid container spacing={2}>
        {categories.map((category) => (
          <Grid key={category} item xs>
            <CustomCard title={category} expandable defaultExpanded>
              {filteredRows.reduce((filtered, link) => {
                if (link.category === category)
                  filtered.push(<LinkItem key={link.id} link={link} />);

                return filtered;
              }, [])}
            </CustomCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default UsefulLinks;
