import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const jobProtocolsAdapter = createEntityAdapter();

const initialState = jobProtocolsAdapter.getInitialState();

export const jobProtocolsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJobProtocols: builder.query({
      query: (params) => {
        const query = {
          url: "/job-protocols",
        };

        if (params) query.params = { ...params };

        return query;
      },
      transformResponse: (responseData) => {
        const loadedJobProtocols = responseData.map((jobProtocol) => {
          jobProtocol.id = jobProtocol._id;
          return jobProtocol;
        });

        return jobProtocolsAdapter.setAll(initialState, loadedJobProtocols);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "JobProtocol", id: "LIST" },
            ...result.ids.map((id) => ({ type: "JobProtocol", id })),
          ];
        } else return [{ type: "JobProtocol", id: "LIST" }];
      },
    }),
  }),
});

export const { useGetJobProtocolsQuery } = jobProtocolsApiSlice;

export const selectJobProtocolsResult =
  jobProtocolsApiSlice.endpoints.getJobProtocols.select();

const selectJobProtocolsData = createSelector(
  selectJobProtocolsResult,
  (jobProtocolsResult) => jobProtocolsResult.data
);

export const {
  selectAll: selectAllJobProtocols,
  selectIds: selectJobProtocolIds,
} = jobProtocolsAdapter.getSelectors(
  (state) => selectJobProtocolsData(state) ?? initialState
);
