import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import deLocale from "@fullcalendar/core/locales/de";
import { Box, alpha, useTheme } from "@mui/material";
import Header from "components/Header";
import { useEffect, useState } from "react";
import {
  useAddEventMutation,
  useDeleteEventMutation,
  useGetEventsQuery,
  useUpdateEventMutation,
} from "features/events/eventsApiSlice";

import dayjs from "dayjs";

import { useSnackbar } from "contexts/Snackbar.context";
import useAuth from "hooks/useAuth";

import { hasPermission } from "util/permissionHelper";
import EventDialog from "features/events/EventDialog";

const Calendar = () => {
  useEffect(() => {
    document.title = `Kalender | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  const theme = useTheme();
  const showSnackbar = useSnackbar();
  const { user } = useAuth();

  const [calendarEvents, setCalendarEvents] = useState([]);
  const [addEventDialogOpen, setAddEventDialogOpen] = useState(false);
  const [editEventDialogOpen, setEditEventDialogOpen] = useState(false);
  const [eventData, setEventData] = useState({});
  const [newEventData, setNewEventData] = useState({});
  const [updateEvent] = useUpdateEventMutation();
  const [addEvent] = useAddEventMutation();
  const [deleteEvent] = useDeleteEventMutation();

  const { data: events, isSuccess: getEventsIsSuccess } = useGetEventsQuery(
    "eventsList",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const isEventEditable = (event) => {
    switch (event.type) {
      case "Modul":
        if (event.learningModule?.requiredForRank === "C1") {
          return hasPermission({
            roles: user.roles,
            permissions: ["postEventsCA"],
          });
        } else {
          return hasPermission({
            roles: user.roles,
            permissions: ["postEventsC1+"],
          });
        }

      case "Einsatztraining":
        return hasPermission({
          roles: user.roles,
          permissions: ["postEventsETR"],
        });

      case "Fachbereichstraining":
        return hasPermission({
          roles: user.roles,
          permissions: ["postEventsFBTR"],
        });

      case "Sonstiges":
        return hasPermission({
          roles: user.roles,
          permissions: ["postEvents"],
        });

      default:
        return false;
    }
  };

  useEffect(() => {
    if (getEventsIsSuccess)
      setCalendarEvents(
        events.ids.map((id) => ({
          id: id,
          title: events.entities[id].title,
          start: events.entities[id].start,
          end: events.entities[id].end,
          allDay: events.entities[id].allDay,
          editable: isEventEditable(events.entities[id]),
        }))
      );
    // eslint-disable-next-line
  }, [getEventsIsSuccess, events]);

  const handleDateClicked = (selected) => {
    if (
      !hasPermission({
        roles: user.roles,
        permissions: [
          "postEventsCA",
          "postEventsC1+",
          "postEventsETR",
          "postEventsFBTR",
          "postEvents",
        ],
      })
    ) {
      return;
    }

    setNewEventData({
      start: selected.start,
      end: selected.end,
      allDay: selected.allDay,
    });
    setAddEventDialogOpen(true);
  };

  const handleEventClicked = (selected) => {
    setEventData({
      ...events?.entities[selected.event.id],
      start: dayjs(events?.entities[selected.event.id]?.start),
      end: dayjs(events?.entities[selected.event.id]?.end),
      learningModule:
        events?.entities[selected.event.id]?.learningModule?._id || "",
      organizedBy: events?.entities[selected.event.id]?.organizedBy.map(
        (organizer) => organizer._id
      ),
      editable: selected.event.startEditable && selected.event.durationEditable,
      processEvent: false,
    });
    setEditEventDialogOpen(true);
  };

  const handleEventChanged = async (selected) => {
    await updateEvent({
      id: selected.event.id,
      start: selected.event.start,
      end: selected.event.end,
      allDay: selected.event.allDay,
    })
      .unwrap()
      .catch((error) => showSnackbar(error.data?.message || "Fehler", "error"));
  };

  const onAddEvent = async (values, { resetForm }) => {
    await addEvent(values)
      .unwrap()
      .then(() => {
        showSnackbar("Veranstaltung erstellt");
        setAddEventDialogOpen(false);
        resetForm();
      })
      .catch((error) =>
        showSnackbar(error?.data?.message || "Fehler", "error")
      );
  };

  const onSaveEvent = async (values, { resetForm }) => {
    await updateEvent(values)
      .unwrap()
      .then(() => {
        showSnackbar("Veranstaltung geändert");
        setEditEventDialogOpen(false);
        resetForm();
      })
      .catch((error) =>
        showSnackbar(error?.data?.message || "Fehler", "error")
      );
  };

  const onDeleteEvent = async (id) => {
    await deleteEvent(id)
      .unwrap()
      .then(() => {
        showSnackbar("Veranstaltung gelöscht");
        setEditEventDialogOpen(false);
      })
      .catch((error) =>
        showSnackbar(error?.data?.message || "Fehler", "error")
      );
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="KALENDER" subtitle="Aktuelle Veranstaltungen" />
      <Box
        flex="1 1 100%"
        sx={{
          "--fc-button-bg-color": theme.palette.primary.main,
          "--fc-button-text-color": theme.palette.primary.contrastText,
          "--fc-button-border-color": "transparent",
          "--fc-button-hover-bg-color": theme.palette.primary.dark,
          "--fc-button-hover-border-color": "transparent",
          "--fc-button-active-bg-color": theme.palette.primary.dark,
          "--fc-button-active-border-color": "transparent",
          "--fc-border-color": alpha(theme.palette.text.secondary, 0.3),
          "--fc-neutral-bg-color": theme.palette.background.default,
          "--fc-list-event-hover-bg-color": alpha(
            theme.palette.primary.main,
            0.5
          ),

          "& .fc .fc-button:not(:disabled)": {
            boxShadow: "none",
          },

          "& .fc-button-primary:not(:disabled).fc-button-active": {
            border: "none",
          },

          "& .fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus":
            {
              boxShadow: "none",
            },

          "& .fc-button-primary": { border: "none" },

          "& .fc-list-event:hover": {
            cursor: "pointer",
          },

          "& .fc-event": {
            cursor: "pointer",
          },
        }}
      >
        <FullCalendar
          height="100%"
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
          ]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
          }}
          initialView="dayGridMonth"
          selectable
          selectMirror
          dayMaxEvents
          select={handleDateClicked}
          eventClick={handleEventClicked}
          locale={deLocale}
          events={calendarEvents}
          eventChange={handleEventChanged}
        />
      </Box>
      <EventDialog
        open={addEventDialogOpen}
        onClose={() => setAddEventDialogOpen(false)}
        onSubmit={onAddEvent}
        initialValues={{
          title: "",
          start: dayjs(newEventData.start),
          end: dayjs(newEventData.end),
          allDay: newEventData.allDay,
          type: "",
          learningModule: "",
          note: "",
          affectedFunctions: [],
          affectedRanks: [],
          affectionCondition: "and",
          organizedBy: user.copData?._id ? [user.copData._id] : [],
          processEvent: false,
        }}
      />
      <EventDialog
        open={editEventDialogOpen}
        onClose={() => setEditEventDialogOpen(false)}
        onDelete={onDeleteEvent}
        onSubmit={onSaveEvent}
        initialValues={eventData}
        readOnly={!eventData.editable}
      />
    </Box>
  );
};

export default Calendar;
