import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";

const ConfirmDialogContext = React.createContext();

export const useConfirmDialog = () => useContext(ConfirmDialogContext);

const ConfirmDialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [onConfirm, setOnConfirm] = useState(() => {});

  const showConfirmDialog = ({ title, message, onConfirm }) => {
    setTitle(title);
    setMessage(message);
    setOnConfirm(() => onConfirm);
    setOpen(true);
  };

  return (
    <ConfirmDialogContext.Provider value={showConfirmDialog}>
      {children}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Abbrechen</Button>
          <Button
            onClick={() => {
              if (onConfirm) onConfirm();
              setOpen(false);
            }}
          >
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmDialogContext.Provider>
  );
};

export default ConfirmDialogProvider;
