import { Box, Typography } from "@mui/material";
import CustomCard from "components/CustomCard";
import { useGetConsoleLogsQuery } from "./rconApiSlice";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

const LogEntry = ({ log }) => (
  <Box m="4px 0" display="flex" alignItems="center">
    <Typography color="secondary.main">{`[${dayjs(log?.createdAt).format(
      "DD.MM.YYYY HH:mm:ss"
    )}]`}</Typography>
    <Typography fontWeight="bold" sx={{ ml: 1 }}>
      {log?.user?.name}:
    </Typography>
    <Typography fontStyle="italic" sx={{ ml: 1 }}>
      {log.message}
    </Typography>
  </Box>
);

const RConLog = () => {
  const { data, isSuccess } = useGetConsoleLogsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [logs, setLogs] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setLogs(data.ids.map((id) => data.entities[id]));
    }
  }, [isSuccess, data]);

  return (
    <CustomCard title="KONSOLE" expandable defaultExpanded>
      <Box
        display="flex"
        flexDirection="column-reverse"
        height="300px"
        p={1}
        sx={{
          backgroundColor: "background.default",
          borderRadius: 2,
          overflow: "auto",
        }}
      >
        <Box>
          {logs.map((log) => (
            <LogEntry key={log.id} log={log} />
          ))}
        </Box>
      </Box>
    </CustomCard>
  );
};
export default RConLog;
