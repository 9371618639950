import { apiSlice } from "app/api/apiSlice";
import { logout, setCredentials } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendLogout: builder.mutation({
      query: () => ({
        url: "/auth/logout",
        method: "POST",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log(data);
          dispatch(logout());
          setTimeout(() => {
            dispatch(apiSlice.util.resetApiState());
          }, 1000);
        } catch (error) {
          console.log(error);
        }
      },
    }),
    refresh: builder.mutation({
      query: () => ({ url: "/auth/refresh", method: "GET" }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { accessToken, user } = data;
          dispatch(setCredentials({ accessToken, user }));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    authenticate: builder.mutation({
      query: (arg) => ({
        url: "auth/steam/authenticate",
        method: "GET",
        params: { ...arg },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { accessToken, user } = data;
          dispatch(setCredentials({ accessToken, user }));
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const {
  useSendLogoutMutation,
  useRefreshMutation,
  useAuthenticateMutation,
} = authApiSlice;
