import { useParams } from "react-router-dom";
import { useGetUsersQuery, useUpdateUserMutation } from "./usersApiSlice";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import CustomCard from "components/CustomCard";
import LockIcon from "@mui/icons-material/Lock";
import { useGetRolesQuery } from "features/roles/rolesApiSlice";
import { useSnackbar } from "contexts/Snackbar.context";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { memo, useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { getMaxRoleEditLevel, hasPermission } from "util/permissionHelper";
import useAuth from "hooks/useAuth";
import SupportPanelButton from "components/SupportPanelButton";
import { DateTimePicker } from "@mui/x-date-pickers";
import SteamProfileLinkButton from "components/SteamProfileLinkButton";
import MetaDataInfo from "components/MetaDataInfo";
import { FaDiscord } from "react-icons/fa";
import { useRemoveUserConnectionMutation } from "features/discord/discordApiSlice";

const User = (props) => {
  const theme = useTheme();

  const { id } = useParams();

  const showSnackbar = useSnackbar();

  const { user } = useGetUsersQuery("usersList", {
    selectFromResult: ({ data }) => ({
      user: data?.entities[props.id || id],
    }),
  });

  const [removeDiscord] = useRemoveUserConnectionMutation();

  const { roles: currentUserRoles, user: currentUser } = useAuth();

  const [userRoles, setUserRoles] = useState([]);
  const [userName, setUserName] = useState("");
  const [applicationLockUntil, setApplicationLockUntil] = useState(null);
  const [canEditUserName, setCanEditUserName] = useState(false);
  const [canEditUserLockStatus, setCanEditUserLockStatus] = useState(false);
  const [canEditUserRoles, setCanEditUserRoles] = useState(false);
  const [canEditUserApplicationLock, setCanEditUserApplicationLock] =
    useState(false);
  const [canRemoveDiscord, setCanRemoveDiscord] = useState(false);
  const [maxRoleEditLevel, setMaxRoleEditLevel] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);

  const [updateUser] = useUpdateUserMutation();

  const { data: roles, isSuccess: getRolesIsSuccess } =
    useGetRolesQuery("rolesList");

  useEffect(() => {
    if (user) {
      setUserName(user.name);
      setUserRoles(user.roles);
      setApplicationLockUntil(user.applicationLockUntil);

      document.title = `${user.name} | Polizei ${process.env.REACT_APP_MAP}`;
    }

    if (
      user &&
      (user._id !== currentUser._id ||
        hasPermission({ roles: currentUserRoles, permissions: [] }))
    ) {
      setCanEditUserName(
        hasPermission({
          roles: currentUserRoles,
          permissions: ["editUserName"],
        })
      );

      setCanEditUserLockStatus(
        hasPermission({
          roles: currentUserRoles,
          permissions: ["lockUser"],
        })
      );

      setCanEditUserRoles(
        hasPermission({
          roles: currentUserRoles,
          permissions: ["editUserRoles"],
        })
      );

      setCanEditUserApplicationLock(
        hasPermission({
          roles: currentUserRoles,
          permissions: ["setUserApplicationLock"],
        })
      );

      setCanRemoveDiscord(
        hasPermission({
          roles: currentUserRoles,
          permissions: ["removeDiscordConnection"],
        })
      );
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    setMaxRoleEditLevel(getMaxRoleEditLevel({ roles: currentUserRoles }));
    setIsAdmin(hasPermission({ roles: currentUserRoles, permissions: [] }));
  }, [currentUserRoles]);

  const handleUserIsLockedToggle = async (event) => {
    await updateUser({ id: user.id, isLocked: event.target.checked });

    showSnackbar(
      event.target.checked ? "Benutzer gesperrt" : "Benutzer entsperrt"
    );
  };

  const [editName, setEditName] = useState(false);
  const [editRoles, setEditRoles] = useState(false);
  const [editUserApplicationLock, setEditUserApplicationLock] = useState(false);

  const handleUserRolesSaved = async () => {
    setEditRoles(false);
    await updateUser({ id: user.id, roles: userRoles });
    showSnackbar("Rollen gespeichert");
  };

  const handleUserNameSaved = async () => {
    setEditName(false);
    await updateUser({ id: user.id, name: userName });
    showSnackbar("Name gespeichert");
  };

  const handleUserApplicationLockSaved = async () => {
    setEditUserApplicationLock(false);
    await updateUser({ id: user.id, applicationLockUntil });
    showSnackbar("Bewerbungssperre gespeichert");
  };

  if (!user)
    return (
      <Box m="16px">
        <CircularProgress />
      </Box>
    );

  return (
    <Grid
      container
      spacing={theme.spacing(2)}
      direction="column"
      maxWidth="400px"
    >
      <Grid item>
        <CustomCard>
          <Box
            m="8px 16px 0"
            flexDirection="column"
            display="flex"
            alignItems="center"
          >
            <Box>
              <img
                alt="steam-avatar"
                src={user.steamData?.avatarfull}
                style={{ borderRadius: "50%" }}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt="16px"
            >
              <Box display="flex" alignItems="center">
                <TextField
                  inputProps={{
                    readOnly: !canEditUserName,
                    maxLength: 30,
                    style: {
                      textAlign: "center",
                    },
                  }}
                  label="Name"
                  value={userName}
                  onChange={(event) => {
                    setUserName(event.target.value);
                    setEditName(!(event.target.value === user.name));
                  }}
                />
                {editName && (
                  <IconButton onClick={handleUserNameSaved}>
                    <SaveIcon color="primary" />
                  </IconButton>
                )}
              </Box>
              <Box display="flex" height="100%" alignItems="center" mt="16px">
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  {user.steamData?.personaname}
                </Typography>
                <SteamProfileLinkButton
                  profileurl={user.steamData?.profileurl}
                />
              </Box>
              <SupportPanelButton steamid={user.steamid} sx={{ mt: "24px" }} />
            </Box>
          </Box>

          <Box mt="48px">
            <Divider sx={{ mb: "16px" }} />
            <MetaDataInfo
              steamid={user.steamid}
              createdAt={user.createdAt}
              updatedAt={user.updatedAt}
              changedBy={user.changedBy?.name}
            />
          </Box>

          <Box mt={4} display="flex" alignItems="center">
            <TextField
              value={user.discord?.username}
              disabled
              size="small"
              label="Discord Benutzername"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FaDiscord
                      size="1.25rem"
                      color={theme.palette.primary.main}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              color="error"
              size="small"
              disabled={!canRemoveDiscord || !!!user.discord}
              onClick={async () => {
                removeDiscord(user._id)
                  .unwrap()
                  .then(() => showSnackbar("Verknüpfung entfernt"))
                  .catch((error) =>
                    showSnackbar(error?.data?.message || "Fehler", "error")
                  );
              }}
            >
              Entfernen
            </Button>
          </Box>
        </CustomCard>
      </Grid>
      <Grid item>
        <CustomCard title="BERECHTIGUNGEN">
          <Box>
            <FormControlLabel
              control={
                <Switch
                  color="error"
                  disabled={!canEditUserLockStatus}
                  checked={user.isLocked}
                  checkedIcon={<LockIcon />}
                  onChange={handleUserIsLockedToggle}
                />
              }
              label="Benutzer gesperrt"
              style={{
                color: theme.palette.text.secondary,
              }}
            />
          </Box>
          <Box mt="24px" display="flex">
            <Autocomplete
              fullWidth
              disableCloseOnSelect
              multiple
              readOnly={!canEditUserRoles}
              value={userRoles}
              onChange={(_, value) => {
                setUserRoles(value);
                setEditRoles(!(value === user.roles));
              }}
              noOptionsText="Keine Optionen"
              forcePopupIcon={canEditUserRoles}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionDisabled={(option) =>
                option.level >= maxRoleEditLevel && !isAdmin
              } // Nur die Rollen anzeigen, deren Level < dem höchsten Rollen Level des aktuellen Benutzer entspricht oder wenn Gesamt Berechtigung
              options={
                getRolesIsSuccess
                  ? roles.ids.map((id) => roles.entities[id])
                  : []
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(props) => <TextField {...props} label="Rollen" />}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.name}
                    {...getTagProps({ index })}
                    disabled={option.level > maxRoleEditLevel}
                  />
                ))
              }
            />
            <Box display="flex" alignItems="center">
              {editRoles && (
                <IconButton onClick={handleUserRolesSaved}>
                  <SaveIcon color="primary" />
                </IconButton>
              )}
            </Box>
          </Box>

          <Box mt="36px">
            <Box display="flex" alignItems="center">
              <DateTimePicker
                label="Bewerbungssperre bis zum"
                readOnly={!canEditUserApplicationLock}
                value={
                  !!applicationLockUntil ? dayjs(applicationLockUntil) : null
                }
                onChange={(value) => {
                  setEditUserApplicationLock(true);
                  setApplicationLockUntil(value);
                }}
                slotProps={{
                  actionBar: {
                    actions: ["cancel", "clear", "accept"],
                  },
                  textField: {
                    fullWidth: true,
                    error: null,
                  },
                }}
              />
              {editUserApplicationLock && (
                <IconButton onClick={handleUserApplicationLockSaved}>
                  <SaveIcon color="primary" />
                </IconButton>
              )}
            </Box>
            <Box mt="4px" ml="4px">
              {dayjs().isBefore(dayjs(user.applicationLockUntil)) && (
                <Box display="flex" alignItems="center">
                  <LockIcon color="error" fontSize="small" />
                  <Typography color="error" sx={{ ml: "8px" }}>
                    Bewerbungssperre aktiv
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </CustomCard>
      </Grid>
    </Grid>
  );
};
export default memo(User);
