import { Box } from "@mui/material";
import Header from "components/Header";
import DocumentChangesDataGrid from "features/documentChanges/DocumentChangesDataGrid";

import { useEffect } from "react";

const DatabaseChanges = () => {
  useEffect(() => {
    document.title = `Datenbank | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Header title="DATENBANK" subtitle="Änderungsprotokolle" />
      <DocumentChangesDataGrid />
    </Box>
  );
};
export default DatabaseChanges;
