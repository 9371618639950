import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const linksAdapter = createEntityAdapter();

const initialState = linksAdapter.getInitialState();

export const linksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLinks: builder.query({
      query: () => ({
        url: "/links",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedLinks = responseData.map((link) => {
          link.id = link._id;
          return link;
        });

        return linksAdapter.setAll(initialState, loadedLinks);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Link", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Link", id })),
          ];
        } else return [{ type: "Link", id: "LIST" }];
      },
    }),
    addLink: builder.mutation({
      query: (link) => ({
        url: "/links",
        method: "POST",
        body: { ...link },
      }),
      invalidatesTags: [{ type: "Link", id: "LIST" }],
    }),
    updateLink: builder.mutation({
      query: (link) => ({
        url: "/links",
        method: "PATCH",
        body: { ...link },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Link", id: arg.id }],
    }),
    deleteLink: builder.mutation({
      query: (id) => ({
        url: "/links",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Link", id: arg.id }],
    }),
  }),
});

export const {
  useGetLinksQuery,
  useAddLinkMutation,
  useUpdateLinkMutation,
  useDeleteLinkMutation,
} = linksApiSlice;

export const selectLinksResult = linksApiSlice.endpoints.getLinks.select();

const selectLinksData = createSelector(
  selectLinksResult,
  (linksResult) => linksResult.data
);

export const { selectAll: selectAllLinks, selectIds: selectLinkIds } =
  linksAdapter.getSelectors((state) => selectLinksData(state) ?? initialState);
