import { Avatar, Box, Tooltip, useTheme } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useGetUsersQuery } from "features/users/usersApiSlice";
import { useEffect, useState } from "react";
import { dataGridSx } from "styles/dataGridSx";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSteam } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import RequirePermissionForRender from "features/auth/RequirePermissionForRender";
import CustomGridPagination from "components/CustomGridPagination";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import { calcGridHeightSubtraction } from "util/calculations";

const GridToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarQuickFilter />
  </GridToolbarContainer>
);

const UserDataGrid = () => {
  const theme = useTheme();
  const {
    data: users,
    isSuccess,
    isLoading,
  } = useGetUsersQuery("usersList", {
    refetchOnMountOrArgChange: true,
  });

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (isSuccess)
      setRows(
        users?.ids.map((id) => {
          const user = users.entities[id];

          return {
            id: id,
            avatar: user.steamData?.avatarmedium,
            name: user.name,
            steamid: user.steamid,
            profileurl: user.steamData?.profileurl,
            lastAccessAt: user.lastAccessAt,
            lastAuthenticationAt: user.lastAuthenticationAt,
            createdAt: user.createdAt,
            isLocked: user.isLocked,
          };
        })
      );
  }, [isSuccess, users]);

  const renderIsLocked = (params) =>
    params.value ? (
      <LockOutlinedIcon color="error" />
    ) : (
      <LockOpenOutlinedIcon color="success" />
    );

  const renderName = (params) => (
    <Box display="flex" alignItems="center">
      <Avatar
        alt="steam-avatar"
        src={params.row.avatar}
        sx={{
          height: "35px",
          width: "35px",
          mr: "12px",
        }}
      />
      {params.value}
    </Box>
  );

  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: renderName,
      flex: 1,
    },
    {
      field: "steamid",
      headerName: "Steam ID",
      flex: 1,
    },
    {
      field: "lastAccessAt",
      headerName: "Letzter Zugriff am",
      ...dateTimeColumnType,
      flex: 1,
    },
    {
      field: "lastAuthenticationAt",
      headerName: "Letzte Authentifizierung am",
      ...dateTimeColumnType,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      ...dateTimeColumnType,
      flex: 1,
    },
    {
      field: "isLocked",
      headerName: "Gesperrt",
      align: "center",
      headerAlign: "center",
      renderCell: renderIsLocked,
      width: 60,
    },
    {
      field: "actions",
      headerName: "Aktionen",
      type: "actions",
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Steam Account"
          sx={{
            color: "info.main",
          }}
          icon={
            <Tooltip title="Steam Profil">
              <FontAwesomeIcon size="xl" icon={faSteam} />
            </Tooltip>
          }
          onClick={() => window.open(row.profileurl, "_blank")}
        />,
        <RequirePermissionForRender permissions={["supportPanel"]}>
          <GridActionsCellItem
            label="Support Panel"
            sx={{
              color: "info.main",
            }}
            icon={
              <Tooltip title="Support Panel">
                <SecurityOutlinedIcon
                  style={{
                    fontSize: "24px",
                  }}
                />
              </Tooltip>
            }
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_SUPPORT_TOOL_BASE_URL}/${row.steamid}`,
                "_blank"
              )
            }
          />
        </RequirePermissionForRender>,
      ],
    },
  ];

  const navigate = useNavigate();

  const handleRowClick = (params) => {
    navigate(`/users/${params.row.id}`);
  };

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        ".MuiDataGrid-row": {
          ":hover": {
            cursor: "pointer !important",
          },
        },
        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        rows={isSuccess ? rows : []}
        loading={isLoading}
        onRowClick={handleRowClick}
        rowSelection={false}
        slots={{
          pagination: CustomGridPagination,
          toolbar: GridToolbar,
        }}
      />
    </Box>
  );
};
export default UserDataGrid;
