import { apiSlice } from "app/api/apiSlice";
import { setDiscord } from "features/auth/authSlice";

export const discordApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    authenticateDiscord: builder.mutation({
      query: (arg) => ({
        url: "/discord/authenticate",
        method: "GET",
        params: { ...arg },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { discord } = data;
          dispatch(setDiscord(discord));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    removeUserConnection: builder.mutation({
      query: (id) => ({
        url: "/discord/remove-user",
        method: "PATCH",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
    }),
    removeOwnConnection: builder.mutation({
      query: () => ({
        url: "/discord/remove",
        method: "PATCH",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const query = await queryFulfilled;
          if (query.meta.response.ok) {
            dispatch(setDiscord(undefined));
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const {
  useAuthenticateDiscordMutation,
  useRemoveOwnConnectionMutation,
  useRemoveUserConnectionMutation,
} = discordApiSlice;
