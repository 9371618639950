import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import CustomCard from "components/CustomCard";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { memo, useEffect, useState } from "react";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import { DataGrid } from "@mui/x-data-grid";
import { dataGridSx } from "styles/dataGridSx";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import CustomGridPagination from "components/CustomGridPagination";

dayjs.extend(weekOfYear);

const minActictyHours = 2.5;

const columns = [
  {
    field: "week",
    headerName: "Woche",
    ...dateTimeColumnType,
    valueFormatter: ({ value }) => {
      const date = dayjs(value);

      return `KW ${date.week()} ${date.year()} (${date
        .startOf("week")
        .format("DD.MM.")} - ${date.format("DD.MM.")})`;
    },
    flex: 1,
    maxWidth: 250,
  },
  {
    field: "playtimeCop",
    headerName: "Spielzeit Polizist (h)",
    flex: 1,
  },
  {
    field: "playtimeCiv",
    headerName: "Spielzeit Zivilist (h)",
    flex: 1,
  },
];

const data2rows = (data) => {
  const rows = [];

  // Zivilist Spielwochen
  data[1]?.data?.forEach((playWeek) => {
    const index = rows.findIndex(
      (row) => row.week.valueOf() === playWeek.x?.valueOf()
    );

    if (index >= 0) {
      rows[index].playtimeCiv += playWeek.y;
    } else {
      rows.push({
        week: dayjs(playWeek.x?.valueOf()),
        playtimeCiv: playWeek.y,
        playtimeCop: 0,
      });
    }
  });

  // Polizist Spielwochen
  data[2]?.data?.forEach((playWeek) => {
    const index = rows.findIndex(
      (row) => row.week.valueOf() === playWeek.x?.valueOf()
    );

    if (index >= 0) {
      rows[index].playtimeCop += playWeek.y;
    } else {
      rows.push({
        week: dayjs(playWeek.x?.valueOf()),
        playtimeCiv: 0,
        playtimeCop: playWeek.y,
      });
    }
  });

  return rows;
};

const CopActivityChart = ({ playtimes }) => {
  const theme = useTheme();

  const [data, setData] = useState([]);

  const [showChart, setShowChart] = useState(true);

  useEffect(() => {
    if (playtimes) {
      const sortedPlaytimes = [...playtimes];
      sortedPlaytimes.sort(
        (a, b) => dayjs(a.end).valueOf() - dayjs(b.end).valueOf()
      );

      setData([
        {
          id: "Mindestaktivität",
          data:
            sortedPlaytimes.length > 0
              ? [
                  {
                    x: new Date(
                      dayjs(sortedPlaytimes[0].end).endOf("week").valueOf()
                    ),
                    y: minActictyHours,
                  },
                  {
                    x: new Date(
                      dayjs(sortedPlaytimes[sortedPlaytimes.length - 1].end)
                        .endOf("week")
                        .valueOf()
                    ),
                    y: minActictyHours,
                  },
                ]
              : [],
        },

        {
          id: "Zivilist",
          data: sortedPlaytimes?.reduce((filtered, playtime) => {
            if (playtime.side === "civ") {
              const durationHours =
                Math.round((playtime.durationMs / 1000 / 60 / 60) * 100) / 100; // Zeit in Stunden

              const calendarWeek = dayjs(playtime.start).endOf("week");

              const index = filtered.findIndex(
                (data) => data.x?.valueOf() === calendarWeek.valueOf()
              );

              if (index >= 0) {
                filtered[index].y =
                  Math.round((filtered[index].y + durationHours) * 100) / 100;
              } else {
                filtered.push({
                  x: new Date(calendarWeek.valueOf()),
                  y: durationHours,
                });
              }
            }

            return filtered;
          }, []),
        },
        {
          id: "Polizist",
          data: sortedPlaytimes?.reduce((filtered, playtime) => {
            if (playtime.side === "cop") {
              const durationHours =
                Math.round((playtime.durationMs / 1000 / 60 / 60) * 100) / 100; // Zeit in Stunden

              const calendarWeek = dayjs(playtime.start).endOf("week");

              const index = filtered.findIndex(
                (data) => data.x?.valueOf() === calendarWeek.valueOf()
              );

              if (index >= 0) {
                filtered[index].y =
                  Math.round((filtered[index].y + durationHours) * 100) / 100;
              } else {
                filtered.push({
                  x: new Date(calendarWeek.valueOf()),
                  y: durationHours,
                });
              }
            }

            return filtered;
          }, []),
        },
      ]);
    }
  }, [playtimes]);

  const [height, setHeight] = useState();

  useEffect(() => {
    setHeight(document.getElementById("activity")?.offsetHeight || 0);
  }, []);

  return (
    <>
      <Tooltip
        arrow
        title={
          <Typography>Wechseln zu {showChart ? "Tabelle" : "Graph"}</Typography>
        }
      >
        <IconButton
          onClick={() => setShowChart((oldValue) => !oldValue)}
          color="primary"
          sx={{
            position: "relative",
            zIndex: 10,
            mb: "-100px",
            ml: "90px",
            mt: "8px",
            float: "left",
          }}
        >
          {showChart ? <TimelineOutlinedIcon /> : <TableChartOutlinedIcon />}
        </IconButton>
      </Tooltip>
      <CustomCard title="AKTIVITÄT" fullHeight id="activity-container">
        <Box height={`${height}px`} overflow="visible">
          {showChart ? (
            <ResponsiveLine
              data={data}
              colors={[
                alpha(theme.palette.error.main, 0.3),
                theme.palette.civ.main,
                theme.palette.cop.main,
              ]}
              theme={{
                grid: {
                  line: {
                    stroke: alpha(theme.palette.text.secondary, 0.15),
                  },
                },
                axis: {
                  legend: {
                    text: {
                      fill: theme.palette.text.secondary,
                    },
                  },
                  ticks: {
                    text: {
                      fill: theme.palette.text.secondary,
                    },
                    line: {
                      stroke: alpha(theme.palette.text.secondary, 0.15),
                    },
                  },
                },
                tooltip: {
                  container: {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.background.default,
                  },
                },
                legends: {
                  text: {
                    fill: theme.palette.text.secondary,
                  },
                },
              }}
              margin={{ top: 10, right: 30, bottom: 60, left: 60 }}
              xScale={{
                type: "time",
                precision: "day",
                max: "auto",
                min: "auto",
              }}
              xFormat={(value) =>
                `KW ${dayjs(value).week()} ${dayjs(value).year()}`
              }
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
              }}
              yFormat={(value) => `${value} Stunden`}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 10,
                tickValues: 5,
                tickPadding: 10,
                tickRotation: 0,
                legend: "",
                legendOffset: 0,
                legendPosition: "middle",
                truncateTickAt: 0,
                format: (value) =>
                  `KW ${dayjs(value).week()} ${dayjs(value).year()}`,
              }}
              axisLeft={{
                tickSize: 0,
                tickValues: 6,
                tickPadding: 10,
                tickRotation: 0,
                legend: "Spielstunden",
                legendOffset: -40,
                legendPosition: "middle",
                truncateTickAt: 0,
              }}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateY: 60,
                  itemsSpacing: 30,
                  itemDirection: "left-to-right",
                  itemWidth: 50,
                  itemHeight: 20,
                  itemOpacity: 1,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "transparent",
                },
              ]}
              pointSize={10}
              pointColor={theme.palette.background.paper}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              enableCrosshair={false}
              useMesh={true}
              curve="catmullRom"
              //areaBaselineValue={5}
            />
          ) : (
            <Box height="100%" sx={dataGridSx(theme)}>
              <DataGrid
                rows={!!data ? data2rows(data) : []}
                columns={columns}
                getRowId={(row) => row.week.valueOf()}
                density="compact"
                initialState={{
                  sorting: {
                    sortModel: [{ field: "week", sort: "desc" }],
                  },
                  pagination: {
                    paginationModel: { pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10, 25]}
                slots={{
                  pagination: CustomGridPagination,
                }}
              />
            </Box>
          )}
        </Box>
      </CustomCard>
    </>
  );
};
export default memo(CopActivityChart);
