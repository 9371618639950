import { Box } from "@mui/material";
import Header from "components/Header";
import RatingDataGrid from "features/ratings/RatingDataGrid";
import { useGetRatingsQuery } from "features/ratings/ratingsApiSlice";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { hasPermission } from "util/permissionHelper";

const Ratings = () => {
  const { roles } = useAuth();
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    document.title = `Bewertungen | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  useEffect(() => {
    if (!!roles)
      setReadOnly(!hasPermission({ roles, permissions: ["editRatings"] }));
  }, [roles]);

  const {
    data: ratings,
    isSuccess,
    isLoading,
  } = useGetRatingsQuery("ratingsList", {
    refetchOnMountOrArgChange: true,
  });

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header
        title="BEWERTUNGEN"
        subtitle="Abgegebene Bewertungen zu Polizisten"
      />
      <RatingDataGrid
        ratings={isSuccess ? ratings.ids.map((id) => ratings.entities[id]) : []}
        isLoading={isLoading}
        readOnly={readOnly}
      />
    </Box>
  );
};

export default Ratings;
