import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CopFunctions from "./CopFunctions";
import CopRankBadge from "../../components/CopRankBadge";
import CustomCard from "../../components/CustomCard";
import { activityStatus, ranks, status as statusOptions } from "util/copEnums";
import {
  useGetCopsQuery,
  useUpdateCopMutation,
} from "features/cops/copsApiSlice";
import { memo, useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { useSnackbar } from "contexts/Snackbar.context";
import MetaDataInfo from "components/MetaDataInfo";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import dayjs from "dayjs";
import SupportPanelButton from "components/SupportPanelButton";
import { getCopStatusColor } from "util/muiColorGetter";
import IsOnlineBadge from "components/IsOnlineBadge";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const CopMainData = ({ cop, canEditCop }) => {
  const theme = useTheme();

  const { user } = cop;

  const { data: cops, isSuccess: getCopsIsSuccess } =
    useGetCopsQuery("copsList");

  const [copExp, setCopExp] = useState("");
  const [editedCopExp, setEditedCopExp] = useState(false);

  const [copNumber, setCopNumber] = useState("");
  const [editedCopNumber, setEditedCopNumber] = useState(false);

  const [copRank, setCopRank] = useState("");
  const [editedCopRank, setEditedCopRank] = useState(false);

  const [copStatus, setCopStatus] = useState("");
  const [editedCopStatus, setEditedCopStatus] = useState(false);

  const [copFunctions, setCopFunctions] = useState([]);
  const [editedCopFunctions, setEditedCopFunctions] = useState(false);

  const showSnackbar = useSnackbar();
  const [updateCop, { isLoading: updateCopIsLoading }] = useUpdateCopMutation();

  useEffect(() => {
    if (cop) {
      setCopExp(
        !!cop.exp
          ? Math.round(cop.exp * 100) / 100
          : cop.exp === 0
          ? cop.exp
          : ""
      );
      setCopNumber(cop.number || "");
      setCopRank(cop.rank);
      setCopStatus(cop.status || "");
      setCopFunctions(cop.functions);
    }
    // eslint-disable-next-line
  }, [cop]);

  const handleExpSaved = async () => {
    await updateCop({ id: cop._id, exp: copExp })
      .unwrap()
      .then(() => {
        showSnackbar("Erfahrungspunkte gespeichert");
        setEditedCopExp(false);
      })
      .catch((error) => showSnackbar(error.data || "Fehler", "error"));
  };

  const handleNumberSaved = async () => {
    await updateCop({ id: cop._id, number: copNumber })
      .unwrap()
      .then(() => {
        showSnackbar("Dienstnummer gespeichert");
        setEditedCopNumber(false);
      })
      .catch((error) => showSnackbar(error.data || "Fehler", "error"));
  };

  const handleRankSaved = async () => {
    await updateCop({ id: cop._id, rank: copRank })
      .unwrap()
      .then(() => {
        showSnackbar("Dienstgrad gespeichert");
        setEditedCopRank(false);
      })
      .catch((error) => showSnackbar(error.data || "Fehler", "error"));
  };

  const handleStatusSaved = async () => {
    await updateCop({ id: cop._id, status: copStatus })
      .unwrap()
      .then(() => {
        showSnackbar("Status gespeichert");
        setEditedCopStatus(false);
      })
      .catch((error) => showSnackbar(error.data || "Fehler", "error"));
  };

  const handleFunctionsSaved = async () => {
    await updateCop({ id: cop._id, functions: copFunctions })
      .unwrap()
      .then(() => {
        showSnackbar("Funktionen gespeichert");
        setEditedCopFunctions(false);
      })
      .catch((error) => showSnackbar(error.data || "Fehler", "error"));
  };

  const handleExpChange = (event) => {
    if (event.target.value || event.target.value === 0) {
      setCopExp(event.target.value);
    } else {
      setCopExp("");
    }
    setEditedCopExp(event.target.value !== cop.exp);
  };

  const handleCopIsInvisToggle = async (event) => {
    await updateCop({ id: cop._id, isInvisible: event.target.checked })
      .unwrap()
      .then(() => {
        showSnackbar(
          !event.target.checked ? "Polizist unsichtbar" : "Polizist sichtbar"
        );
      })
      .catch((error) => showSnackbar(error.data || "Fehler", "error"));
  };

  const handleNumberChange = (event) => {
    setCopNumber(event.target.value);
    setEditedCopNumber(event.target.value !== cop.number);
  };

  const handleRankChange = (event) => {
    setCopRank(event.target.value);
    setEditedCopRank(event.target.value !== cop.rank);
  };

  const handleStatusChange = (event) => {
    setCopStatus(event.target.value);
    setEditedCopStatus(event.target.value !== cop.status);
  };

  const handleFunctionChange = (_, value) => {
    setCopFunctions(value);
    setEditedCopFunctions(value !== cop.functions);
  };

  return (
    <CustomCard title="ALLGEMEINES" id="cop-main-data" fullHeight>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <IsOnlineBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              badgeContent={!!cop.online ? 1 : 0}
              color={cop.online?.side === "civ" ? "warning" : undefined}
            >
              <Avatar
                alt="steam-avatar"
                src={user?.steamData?.avatarfull}
                sx={{ height: "60px", width: "60px" }}
              />
            </IsOnlineBadge>

            <Typography
              sx={{
                ml: "16px",
              }}
              variant="h5"
              fontWeight="bold"
            >
              {user?.name}
            </Typography>
          </Box>
          <Box ml="24px">
            <SupportPanelButton steamid={user?.steamid} />
          </Box>
        </Box>
        {!!cop.online && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="16px"
            borderRadius="100px"
            p="4px 8px"
            sx={{
              color:
                cop.online.side === "cop"
                  ? "info.contrastText"
                  : "warning.contrastText",
              backgroundColor:
                cop.online.side === "cop" ? "info.main" : "warning.main",

              textAlign: "center",
            }}
          >
            <InfoOutlinedIcon />
            <Typography sx={{ ml: 1 }}>
              {`Spielt aktuell als ${
                cop.online.side === "cop" ? "Polizist" : "Zivilist"
              } unter dem Namen ${cop.online.name} auf Server ${
                cop.online.server
              }`}
            </Typography>
            <div />
          </Box>
        )}
        <Box mt="32px" display="flex">
          <TextField
            type="number"
            value={copExp}
            label="Erfahrungspunkte"
            fullWidth
            onChange={handleExpChange}
            error={copExp < 0}
            helperText={
              copExp < 0 && "Anzahl Erfahrungspunkte muss positiv sein"
            }
            inputProps={{
              readOnly: !canEditCop,
              min: 0,
              step: 0.01,
            }}
            sx={{
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
            }}
          />
          <Box display="flex" alignItems="center">
            {editedCopExp && (
              <IconButton
                onClick={handleExpSaved}
                disabled={updateCopIsLoading || copExp < 0}
                color="primary"
              >
                {updateCopIsLoading ? (
                  <CircularProgress size="1em" />
                ) : (
                  <SaveIcon />
                )}
              </IconButton>
            )}
          </Box>
        </Box>
        <Box mt="16px" display="flex">
          <TextField
            select
            value={copNumber}
            label="Dienstnummer"
            fullWidth
            onChange={handleNumberChange}
            inputProps={{
              readOnly: !canEditCop,
            }}
            sx={{
              ".MuiSvgIcon-root": {
                display: !canEditCop && "none",
              },
            }}
          >
            {[...Array(120).keys()].map((number) => (
              <MenuItem
                key={number + 1}
                value={number + 1}
                disabled={
                  number + 1 !== cop.number &&
                  getCopsIsSuccess &&
                  cops.ids
                    .map((id) => cops.entities[id].number)
                    .includes(number + 1)
                }
              >
                {number + 1}
              </MenuItem>
            ))}
          </TextField>
          <Box display="flex" alignItems="center">
            {editedCopNumber && (
              <IconButton
                onClick={handleNumberSaved}
                disabled={updateCopIsLoading}
                color="primary"
              >
                {updateCopIsLoading ? (
                  <CircularProgress size="1em" />
                ) : (
                  <SaveIcon />
                )}
              </IconButton>
            )}
          </Box>
        </Box>
        <Box mt="16px" display="flex">
          <TextField
            select
            value={copRank}
            label="Dienstgrad"
            fullWidth
            onChange={handleRankChange}
            inputProps={{
              readOnly: !canEditCop,
            }}
            sx={{
              ".MuiSvgIcon-root": {
                display: !canEditCop && "none",
              },
            }}
          >
            {Object.keys(ranks)
              .filter((rank) => rank !== "AD")
              .map((rank) => (
                <MenuItem key={rank} value={rank}>
                  <CopRankBadge rank={rank} />
                </MenuItem>
              ))}
          </TextField>
          <Box display="flex" alignItems="center">
            {editedCopRank && (
              <IconButton
                onClick={handleRankSaved}
                disabled={updateCopIsLoading}
                color="primary"
              >
                {updateCopIsLoading ? (
                  <CircularProgress size="1em" />
                ) : (
                  <SaveIcon />
                )}
              </IconButton>
            )}
          </Box>
        </Box>

        <Box mt="16px" display="flex">
          <TextField
            select
            value={copStatus}
            label="Status"
            fullWidth
            onChange={handleStatusChange}
            inputProps={{
              readOnly: !canEditCop,
            }}
            sx={{
              ".MuiSvgIcon-root": {
                display: !canEditCop && "none",
              },
            }}
          >
            {statusOptions.map((status) => (
              <MenuItem key={status} value={status}>
                <Chip label={status} color={getCopStatusColor(status)} />
              </MenuItem>
            ))}
          </TextField>
          <Box display="flex" alignItems="center">
            {editedCopStatus && (
              <IconButton
                onClick={handleStatusSaved}
                disabled={updateCopIsLoading}
                color="primary"
              >
                {updateCopIsLoading ? (
                  <CircularProgress size="1em" />
                ) : (
                  <SaveIcon />
                )}
              </IconButton>
            )}
          </Box>
        </Box>

        <Box mt="16px" display="flex">
          <TextField
            select
            defaultValue={cop?.activityStatus || ""}
            label="Aktivitätsstatus"
            fullWidth
            inputProps={{
              readOnly: true,
            }}
            sx={{
              ".MuiSvgIcon-root": {
                display: "none",
              },
            }}
          >
            {activityStatus.map((status) => (
              <MenuItem key={status} value={status}>
                <Chip label={status} color={getCopStatusColor(status)} />
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box mt="16px" display="flex">
          <CopFunctions
            functions={copFunctions}
            onChange={handleFunctionChange}
            readOnly={!canEditCop}
            hideInputOnReadOnly={false}
          />
          <Box display="flex" alignItems="center">
            {editedCopFunctions && (
              <IconButton
                onClick={handleFunctionsSaved}
                disabled={updateCopIsLoading}
                color="primary"
              >
                {updateCopIsLoading ? (
                  <CircularProgress size="1em" color="primary" />
                ) : (
                  <SaveIcon />
                )}
              </IconButton>
            )}
          </Box>
        </Box>
        <Box mt="16px">
          <FormControlLabel
            control={
              <Switch
                color="warning"
                disabled={!canEditCop}
                checked={cop.isInvisible}
                checkedIcon={<VisibilityOffIcon />}
                onChange={handleCopIsInvisToggle}
              />
            }
            label="Unsichtbar"
            style={{
              color: theme.palette.text.secondary,
            }}
          />
        </Box>
        <Box mt="16px">
          <Grid container direction="row" rowSpacing={1}>
            <Grid item xs={4}>
              <InputLabel>Eingestellt am</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <Typography textAlign="left">
                {!!cop?.career &&
                  dayjs(
                    Math.min(
                      ...cop?.career?.map((careerItem) =>
                        dayjs(careerItem.date).valueOf()
                      ),
                      dayjs(cop?.createdAt).valueOf()
                    )
                  ).format("DD.MM.YYYY HH:mm")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Gekündigt am</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <Typography textAlign="left">
                {cop?.latestDismissal &&
                  dayjs(cop.latestDismissal).format("DD.MM.YYYY HH:mm")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mt="32px">
          <Divider sx={{ mb: "16px" }} />
          <MetaDataInfo
            steamid={cop?.user?.steamid}
            createdAt={cop?.createdAt}
            updatedAt={cop?.updatedAt}
            changedBy={cop?.changedBy?.name}
          />
        </Box>
      </Box>
    </CustomCard>
  );
};
export default memo(CopMainData);
