import * as React from "react";
import {
  useGridApiContext,
  GRID_DATE_COL_DEF,
  GRID_DATETIME_COL_DEF,
} from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import "dayjs/locale/de";
import dayjs from "dayjs";

function buildApplyDateFilterFn(filterItem, compareFn, showTime = false) {
  if (!filterItem.value) {
    return null;
  }

  // Make a copy of the date to not reset the hours in the original object
  const filterValueCopy = dayjs(filterItem.value);
  filterValueCopy.hour(0).minute(0).second(0).millisecond(0);

  const filterValueMs = filterValueCopy.valueOf();
  return ({ value }) => {
    if (!value) {
      return false;
    }

    // Make a copy of the date to not reset the hours in the original object
    const dateCopy = dayjs(value);
    dateCopy.hour(0).minute(0).second(0).millisecond(0);

    const cellValueMs = dateCopy.valueOf();

    return compareFn(cellValueMs, filterValueMs);
  };
}

function getDateFilterOperators(showTime = false) {
  return [
    {
      value: "is",
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 === value2,
          showTime
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: "not",
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 !== value2,
          showTime
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: "after",
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 > value2,
          showTime
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: "onOrAfter",
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 >= value2,
          showTime
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: "before",
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 < value2,
          showTime
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: "onOrBefore",
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(
          filterItem,
          (value1, value2) => value1 <= value2,
          showTime
        );
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime },
    },
    {
      value: "isEmpty",
      getApplyFilterFn: () => {
        return ({ value }) => {
          return value == null;
        };
      },
      requiresFilterValue: false,
    },
    {
      value: "isNotEmpty",
      getApplyFilterFn: () => {
        return ({ value }) => {
          return value != null;
        };
      },
      requiresFilterValue: false,
    },
  ];
}

const dateAdapter = new AdapterDayjs({ locale: "de" });

/**
 * `date` column
 */

export const dateColumnType = {
  ...GRID_DATE_COL_DEF,
  resizable: false,
  renderEditCell: (params) => {
    return <GridEditDateCell {...params} />;
  },
  filterOperators: getDateFilterOperators(),
  valueFormatter: (params) => dateAdapter.format(params.value, "keyboardDate"),
  valueGetter: (params) => dayjs(params.value),
};

const GridEditDateInput = styled(InputBase)({
  fontSize: "inherit",
  padding: "0 9px",
});

function WrappedGridEditDateInput(props) {
  let { InputProps, ...other } = props;

  delete other.focused; // fix warning where focused prop was passed on DOM element

  return <GridEditDateInput fullWidth {...InputProps} {...other} />;
}

function GridEditDateCell({ id, field, value, colDef }) {
  const apiRef = useGridApiContext();

  const Component = colDef.type === "dateTime" ? DateTimePicker : DatePicker;

  const handleChange = (newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <Component
      value={value}
      onChange={handleChange}
      slots={{ textField: WrappedGridEditDateInput }}
    />
  );
}

function GridFilterDateInput(props) {
  const { item, showTime, applyValue, apiRef } = props;

  const Component = showTime ? DateTimePicker : DatePicker;

  const handleFilterChange = (newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <Component
      value={item.value || null}
      label={apiRef.current.getLocaleText("filterPanelInputLabel")}
      slotProps={{
        textField: {
          variant: "standard",
        },
        inputAdornment: {
          sx: {
            "& .MuiButtonBase-root": {
              marginRight: -1,
            },
          },
        },
      }}
      onChange={handleFilterChange}
    />
  );
}

/**
 * `dateTime` column
 */

export const dateTimeColumnType = {
  ...GRID_DATETIME_COL_DEF,
  resizable: false,
  renderEditCell: (params) => {
    return <GridEditDateCell {...params} />;
  },
  filterOperators: getDateFilterOperators(true),
  valueGetter: (params) => (params.value ? dayjs(params.value) : ""),
  valueFormatter: (params) =>
    params.value ? dateAdapter.format(params.value, "keyboardDateTime") : "",
};
