import { useSelector } from "react-redux";
import { selectCurrentToken, selectCurrentUser } from "features/auth/authSlice";

const useAuth = () => {
  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);

  if (token && user)
    return {
      token,
      user,
      roles: user.roles,
      steamUser: user.steamData,
      copData: user.copData,
    };

  return { token: "", user: {}, roles: [], steamUser: {}, copData: {} };
};

export default useAuth;
