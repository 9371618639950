import { apiSlice } from "app/api/apiSlice";

export const onlineCopsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOnlineCops: builder.query({
      query: () => ({
        url: "/panthor-api/online-cops",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
    }),
  }),
});

export const { useGetOnlineCopsQuery } = onlineCopsApiSlice;
