import { useTheme } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";

const PieChartBase = ({ data, colors, ...props }) => {
  const theme = useTheme();

  return (
    <ResponsivePie
      data={data}
      colors={colors}
      theme={{
        tooltip: {
          container: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
          },
        },
      }}
      margin={{ top: 10, right: 0, bottom: 65, left: 0 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      enableArcLinkLabels={false}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 3]],
      }}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 40,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: theme.palette.text.primary,
          itemDirection: "top-to-bottom",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
        },
      ]}
      {...props}
    />
  );
};
export default PieChartBase;
