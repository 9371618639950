import { useSnackbar } from "contexts/Snackbar.context";
import { Formik } from "formik";
import * as yup from "yup";
import { useAddRequestedRatingMutation } from "./requestedRatingsApiSlice";
import { useGetCopsQuery } from "features/cops/copsApiSlice";
import dayjs from "dayjs";
import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { ratingFunctions, serverPeriods } from "util/copEnums";
import { DatePicker } from "@mui/x-date-pickers";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CopsAutocomplete from "components/CopsAutocomplete";
import { memo } from "react";

yup.addMethod(yup.object, "dayjs", function method(message) {
  return this.test("dayjs", message, function validate(value) {
    if (!value) {
      return true;
    }

    if (dayjs.isDayjs(value) && !!value.isValid) return value.isValid();

    return false;
  });
});

const RequestRatingForm = ({ postSubmit }) => {
  const showSnackbar = useSnackbar();
  const [addRequestedRating, { isLoading: addRequestedRatingIsLoading }] =
    useAddRequestedRatingMutation();
  const { data: cops } = useGetCopsQuery("copsList");

  const initialValues = {
    requestedFrom: [],
    forFunction: "",
    date: dayjs(),
    period: "",
    reason: "",
  };

  const requestedRatingSchema = yup.object().shape({
    requestedFrom: yup.array().min(1, "Pflichtfeld").of(yup.string()),
    forFunction: yup.string().required("Pflichtfeld"),
    date: yup
      .object()
      .nullable()
      .required("Pflichtfeld")
      .dayjs("Ungültiges Datum"),
    period: yup.string().required("Pflichtfeld"),
    reason: yup.string().required("Pflichtfeld"),
  });

  const onSubmit = async (values, { resetForm }) => {
    await addRequestedRating(values)
      .unwrap()
      .then(() => {
        showSnackbar(
          values.requestedFrom.length > 1
            ? "Bewertungen angefordert"
            : "Bewertung angefordert"
        );
        resetForm();
        if (postSubmit) postSubmit();
      })
      .catch((error) =>
        showSnackbar(error?.data?.message || "Fehler", "error")
      );
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={requestedRatingSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={2} columns={2} sx={{ mt: "0px" }}>
            <Grid item xs={2}>
              <CopsAutocomplete
                cops={cops}
                autocompleteProps={{
                  id: "requestedFrom",
                  limitTags: 5,
                  value: values.requestedFrom,
                  onChange: (_, value) => setFieldValue("requestedFrom", value),
                  onBlur: handleBlur,
                }}
                inputProps={{
                  error: touched.requestedFrom && !!errors.requestedFrom,
                  helperText: touched.requestedFrom && errors.requestedFrom,
                  label: "Von",
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                label="Für Funktion"
                name="forFunction"
                value={values.forFunction}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.forFunction && !!errors.forFunction}
                helperText={touched.forFunction && errors.forFunction}
              >
                {ratingFunctions.map((ratingFunction) => (
                  <MenuItem key={ratingFunction} value={ratingFunction}>
                    {ratingFunction}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2} sm={1}>
              <DatePicker
                value={dayjs(values.date)}
                name="date"
                label="Datum"
                onChange={(value) => setFieldValue("date", value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    onBlur: handleBlur,
                    error: !!touched.date && !!errors.date,
                    helperText: touched.date && errors.date,
                  },
                }}
              />
            </Grid>
            <Grid item xs={2} sm={1}>
              <TextField
                select
                fullWidth
                label="Server Periode"
                name="period"
                value={values.period}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.period && !!errors.period}
                helperText={touched.period && errors.period}
              >
                {serverPeriods.map((period) => (
                  <MenuItem key={period} value={period}>
                    {period}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                multiline
                label="Grund"
                name="reason"
                value={values.reason}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.reason && !!errors.reason}
                helperText={touched.reason && errors.reason}
                inputProps={{
                  maxLength: 500,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                endIcon={<SendOutlinedIcon />}
                variant="contained"
                type="submit"
                disabled={addRequestedRatingIsLoading}
              >
                Absenden
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};
export default memo(RequestRatingForm);
