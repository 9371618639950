import {
  Box,
  Button,
  Collapse,
  Tab,
  Tabs,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import CustomCard from "components/CustomCard";
import RatingDataGrid from "features/ratings/RatingDataGrid";
import { memo, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CopRatingsChart from "./CopRatingsChart";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box mt="24px">{children}</Box>}
    </div>
  );
};

const RotatedExpandIcon = styled((props) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(360deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const a11yProps = (index) => ({
  id: `tab-${index}`,
  "aria-controls": `tabpanel-${index}`,
});

const CopRatings = ({ copId, ratingsQuery, canEditCop, perspective }) => {
  const theme = useTheme();
  const [tab, setTab] = useState(0);
  const [graphExpanded, setGraphExpanded] = useState(false);

  const { data: ratings, isLoading, isSuccess } = ratingsQuery;

  const [receivedRatings, setReceivedRatings] = useState([]);
  const [sentRatings, setSentRatings] = useState([]);

  useEffect(() => {
    if (tab === 0 && receivedRatings.length === 0) {
      setGraphExpanded(false);
    }

    if (tab === 1 && sentRatings.length === 0) {
      setGraphExpanded(false);
    }
  }, [tab, receivedRatings, sentRatings]);

  useEffect(() => {
    if (isSuccess) {
      setReceivedRatings(
        ratings?.ids.reduce((filtered, id) => {
          const rating = ratings.entities[id];

          if (rating.rated?.cop?._id === copId) {
            filtered.push(rating);
          }

          return filtered;
        }, [])
      );

      setSentRatings(
        ratings?.ids.reduce((filtered, id) => {
          const rating = ratings.entities[id];

          if (rating.evaluator?.cop?._id === copId) {
            filtered.push(rating);
          }

          return filtered;
        }, [])
      );
    }
  }, [ratings, isSuccess, copId]);

  const handleTabChange = (_, newValue) => {
    setTab(newValue);
  };

  return (
    <CustomCard>
      <Box display="flex" alignItems="center" mb="16px">
        <Tabs
          value={tab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
          }}
          sx={{
            mt: "-12px",
            ml: "-16px",
            "& .MuiTabs-indicator": {
              display: "flex",
              justifyContent: "center",
              backgroundColor: "transparent",
            },
            "& .MuiTabs-indicatorSpan": {
              maxWidth: 120,
              width: "100%",
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          <Tab
            label={
              <Typography variant="subtitle1" letterSpacing={1}>
                Erhaltene Bewertungen
              </Typography>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Typography variant="subtitle1" letterSpacing={1}>
                Geschriebene Bewertungen
              </Typography>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <Box>
        <CustomTabPanel value={tab} index={0}>
          <Box>
            <Button
              onClick={() => setGraphExpanded((value) => !value)}
              variant="contained"
              sx={{ mb: 1 }}
              disabled={receivedRatings.length === 0}
              startIcon={"Ø"}
            >
              <Box mr="8px">Diagramm</Box>
              <RotatedExpandIcon expand={graphExpanded} />
            </Button>
            <Collapse in={graphExpanded} timeout={700} unmountOnExit={false}>
              <Box mb={2}>
                <CopRatingsChart ratings={receivedRatings} />
              </Box>
            </Collapse>
          </Box>
          <Box>
            <RatingDataGrid
              ratings={receivedRatings}
              isLoading={isLoading}
              readOnly={!canEditCop}
              perspective={!!perspective && `${perspective}Received`}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <Box>
            <Button
              onClick={() => setGraphExpanded((value) => !value)}
              variant="contained"
              sx={{ mb: 1 }}
              disabled={sentRatings.length === 0}
              startIcon={"Ø"}
            >
              <Box mr="8px">Diagramm</Box>
              <RotatedExpandIcon expand={graphExpanded} />
            </Button>
            <Collapse in={graphExpanded} timeout={700} unmountOnExit={false}>
              <Box mb={2}>
                <CopRatingsChart ratings={sentRatings} />
              </Box>
            </Collapse>
          </Box>
          <Box>
            <RatingDataGrid
              ratings={sentRatings}
              isLoading={isLoading}
              readOnly={!canEditCop}
              perspective={!!perspective && `${perspective}Sent`}
            />
          </Box>
        </CustomTabPanel>
      </Box>
    </CustomCard>
  );
};
export default memo(CopRatings);
