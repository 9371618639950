import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { memo } from "react";

const CopsAutocomplete = ({
  cops = {},
  filterCop,
  autocompleteProps,
  inputProps,
}) => {
  return (
    <Autocomplete
      fullWidth
      disableCloseOnSelect
      multiple
      options={
        (filterCop
          ? cops.ids?.filter((id) => filterCop(cops.entities[id]))
          : cops?.ids) || []
      }
      getOptionLabel={(option) =>
        !!cops?.entities ? cops?.entities[option]?.user?.name : ""
      }
      noOptionsText="Keine Optionen"
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {cops?.entities[option]?.user?.name}
        </li>
      )}
      renderInput={(props) => <TextField {...props} {...inputProps} />}
      {...autocompleteProps}
    />
  );
};
export default memo(CopsAutocomplete);
