import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const documentChangesAdapter = createEntityAdapter();

const initialState = documentChangesAdapter.getInitialState();

export const documentChangesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentChanges: builder.query({
      query: (params) => {
        const query = {
          url: "/document-changes",
        };

        if (params) query.params = { ...params };

        return query;
      },
      transformResponse: (responseData) => {
        const loadedDocumentChanges = responseData.map((documentChange) => {
          documentChange.id = documentChange._id;
          return documentChange;
        });

        return documentChangesAdapter.setAll(
          initialState,
          loadedDocumentChanges
        );
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "DocumentChange", id: "LIST" },
            ...result.ids.map((id) => ({ type: "DocumentChange", id })),
          ];
        } else return [{ type: "DocumentChange", id: "LIST" }];
      },
    }),
    getModelNames: builder.query({
      query: (params) => {
        const query = {
          url: "/document-changes/model-names",
        };

        if (params) query.params = { ...params };

        return query;
      },
    }),
  }),
});

export const { useGetDocumentChangesQuery, useGetModelNamesQuery } =
  documentChangesApiSlice;

export const selectDocumentChangesResult =
  documentChangesApiSlice.endpoints.getDocumentChanges.select();

const selectDocumentChangesData = createSelector(
  selectDocumentChangesResult,
  (documentChangesResult) => documentChangesResult.data
);

export const {
  selectAll: selectAllDocumentChanges,
  selectIds: selectDocumentChangeIds,
} = documentChangesAdapter.getSelectors(
  (state) => selectDocumentChangesData(state) ?? initialState
);
