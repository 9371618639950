import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "contexts/Snackbar.context";
import {
  useDeleteRequestedRatingMutation,
  useGetRequestedRatingsQuery,
} from "./requestedRatingsApiSlice";
import { memo, useCallback, useEffect, useState } from "react";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import { dateColumnType, dateTimeColumnType } from "util/dateTimeColumnType";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { dataGridSx } from "styles/dataGridSx";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import CustomGridPagination from "components/CustomGridPagination";
import { calcGridHeightSubtraction } from "util/calculations";

const RequestedRatingsDataGrid = () => {
  const theme = useTheme();
  const showSnackbar = useSnackbar();

  const [showFullfilled, setShowFullfilled] = useState(false);
  const [rows, setRows] = useState([]);

  const {
    data: requestedRatings,
    isSuccess,
    isLoading,
  } = useGetRequestedRatingsQuery("requestedRatingsList", {
    refetchOnMountOrArgChange: true,
  });

  const [deleteRequestedRating] = useDeleteRequestedRatingMutation();

  useEffect(() => {
    if (isSuccess) {
      setRows(
        !showFullfilled
          ? requestedRatings.ids.reduce((filtered, id) => {
              const requestedRating = requestedRatings.entities[id];

              if (
                requestedRating.requestedFrom?.some(
                  (requestedCop) => !requestedCop?.fullfilled
                )
              ) {
                filtered.push(requestedRating);
              }

              return filtered;
            }, [])
          : requestedRatings.ids.map((id) => requestedRatings.entities[id])
      );
    }
  }, [isSuccess, requestedRatings, showFullfilled]);

  const showConfirmDialog = useConfirmDialog();

  const handleDeleteRequestedRating = useCallback(async (id) => {
    try {
      await deleteRequestedRating(id).unwrap();
      showSnackbar("Bewertungsanforderung gelöscht");
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = (id) => () => {
    showConfirmDialog({
      title: "Bewertungsanforderung löschen?",
      message: "Soll die Bewertungsanforderung gelöscht werden?",
      onConfirm: () => handleDeleteRequestedRating(id),
    });
  };

  const EditToolbar = () => {
    return (
      <GridToolbarContainer>
        <FormControlLabel
          control={
            <Switch
              checked={showFullfilled}
              onChange={(_, checked) => setShowFullfilled(checked)}
            />
          }
          label="Erfüllte Anforderungen anzeigen"
          sx={{
            userSelect: "none",
            color: theme.palette.text.secondary,
          }}
        />
      </GridToolbarContainer>
    );
  };

  const renderRequestedFrom = (params) => (
    <Autocomplete
      multiple
      fullWidth
      limitTags={7}
      noOptionsText="Keine Einträge"
      disableCloseOnSelect
      disableClearable
      value={params.value}
      options={params.value}
      getOptionLabel={(option) => option.cop?.user?.name}
      isOptionEqualToValue={(option, value) =>
        option.cop?._id === value.cop?._id
      }
      renderOption={(props, option, { selected }) => (
        <Box
          key={props.key}
          sx={{
            "& .MuiAutocomplete-option": {
              cursor: "default !important",
            },
          }}
        >
          <li {...props}>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              {option.cop?.user?.name}
              <Box display="flex" alignItems="center">
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: "-4px" }}
                        checked={option.fullfilled}
                        disableRipple
                        sx={{
                          cursor: "default",
                        }}
                      />
                    }
                    label="Bewertung abgegeben"
                    sx={{
                      cursor: "default",
                      mr: "16px",
                    }}
                  />
                </Box>
                <Box visibility={!!option.fullfilledAt ? undefined : "hidden"}>
                  <Tooltip arrow title={<Typography>Abgegeben am</Typography>}>
                    <Box display="flex" alignItems="center">
                      <TodayOutlinedIcon sx={{ mr: "4px" }} color="primary" />

                      {dayjs(option.fullfilledAt).format("DD.MM.YYYY HH:mm")}
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </li>
        </Box>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={option.cop?.user?.name}
            color={option.fullfilled ? "success" : "default"}
            clickable
            component={Link}
            to={`/cops/${option.cop?._id}`}
            onDelete={undefined}
          />
        ))
      }
      renderInput={(props) => (
        <TextField
          {...props}
          sx={{
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
            ".MuiInputBase-input": {
              display: "none",
            },
            ".MuiInputBase-root": {
              cursor: "pointer",
            },
          }}
        />
      )}
    />
  );

  const renderCopName = ({ value, row }) => (
    <Link
      to={`/cops/${row.requestor?._id}`}
      style={{ color: theme.palette.info.main, textDecoration: "none" }}
    >
      {value}
    </Link>
  );

  const columns = [
    {
      field: "requestor",
      headerName: "Anforderer",
      valueGetter: ({ value }) => value?.user?.name,
      renderCell: renderCopName,
      flex: 1,
      maxWidth: 300,
    },
    {
      field: "forFunction",
      headerName: "Für Funktion",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "date",
      headerName: "Datum",
      ...dateColumnType,
      flex: 1,
      maxWidth: 110,
    },
    {
      field: "period",
      headerName: "Server Periode",
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "reason",
      headerName: "Grund",
      flex: 1,
      maxWidth: 300,
    },
    {
      field: "requestedFrom",
      headerName: "Angefordert von",
      renderCell: renderRequestedFrom,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },

    {
      field: "actions",
      headerName: "Aktionen",
      type: "actions",
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Löschen"
          onClick={handleDeleteClick(id)}
          color="error"
        />,
      ],
    },
  ];

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows?.length > 0 ? rows : []}
        getRowHeight={() => "auto"}
        slots={{
          toolbar: EditToolbar,
          pagination: CustomGridPagination,
        }}
        disableRowSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        sx={{
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
      />
    </Box>
  );
};
export default memo(RequestedRatingsDataGrid);
