import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useGetCopsQuery, useUpdateCopMutation } from "./copsApiSlice";
import dayjs from "dayjs";
import { getCopStatusColor } from "util/muiColorGetter";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { dataGridSx } from "styles/dataGridSx";
import { useNavigate } from "react-router-dom";
import { useGetLearningModulesQuery } from "features/learningModules/learningModulesApiSlice";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useSnackbar } from "contexts/Snackbar.context";
import { activityStatus, status } from "util/copEnums";
import useAuth from "hooks/useAuth";
import { hasPermission } from "util/permissionHelper";
import CustomGridPagination from "components/CustomGridPagination";
import { calcGridHeightSubtraction } from "util/calculations";

const RecruitDataGrid = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();
  const { roles } = useAuth();
  const [authorizeForTestPerm, setAuthorizeForTestPerm] = useState(false);

  const {
    data: cops,
    isSuccess,
    isLoading,
  } = useGetCopsQuery("copsList", { refetchOnMountOrArgChange: true });

  const { data: modules, isSuccess: getLearningModulesIsSuccess } =
    useGetLearningModulesQuery("learningModulesList", {
      refetchOnMountOrArgChange: true,
    });

  const [updateCop] = useUpdateCopMutation();

  const [rows, setRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      const newRows = cops?.ids
        .filter(
          (id) =>
            cops.entities[id].rank === "CA" &&
            cops.entities[id].status !== "Gekündigt"
        )
        .map((id) => ({
          id,
          user: cops.entities[id]?.user?.name,
          number: cops.entities[id].number,
          status: cops.entities[id].status,
          activityStatus: cops.entities[id].activityStatus,
          hiredAt: dayjs(
            Math.min(
              ...cops.entities[id]?.career?.map((careerItem) =>
                dayjs(careerItem.date).valueOf()
              ),
              dayjs(cops.entities[id]?.createdAt).valueOf()
            )
          ),
          isSecondaryFaction: !!cops.entities[id]?.functions?.some(
            (copFunction) => copFunction === "Zweitfraktion"
          ),
          isAuthorizedForTest: cops.entities[id].isAuthorizedForTest,
          learningModules: cops.entities[id].learningModules,
          functions: cops.entities[id].functions,
        }));

      setRows(newRows);
    }
  }, [isSuccess, cops]);

  useEffect(() => {
    setAuthorizeForTestPerm(
      hasPermission({ roles, permissions: ["authorizeRecruitsForTest"] })
    );
  }, [roles]);

  const renderStatus = (params) => (
    <Chip label={params.value} color={getCopStatusColor(params.value)} />
  );

  const renderCheckbox = (params) => (
    <Checkbox checked={params.value} disableRipple />
  );

  const modules2option = ({ modules, completedModules }) =>
    modules.reduce((filtered, learningModule) => {
      if (learningModule.requiredForRank === "C1") {
        const completedModule = completedModules.find(
          (item) => item._id === learningModule._id
        );

        const moduleData = { ...learningModule };

        if (completedModule) {
          moduleData.completedAt = completedModule.completedAt;
          moduleData.enteredBy = completedModule.enteredBy;
        }

        filtered.push(moduleData);
      }
      return filtered;
    }, []);

  // const allModulesCompleted = ({ modules, completedModules }) =>
  //   modules
  //     .filter((learningModule) => learningModule.requiredForRank === "C1")
  //     ?.every((learningModule) => {
  //       return completedModules?.some(
  //         (completedModule) => completedModule._id === learningModule._id
  //       );
  //     });

  const renderModules = (params) => (
    <Autocomplete
      fullWidth
      multiple
      limitTags={10}
      value={params.value}
      disableCloseOnSelect
      disableClearable
      options={
        getLearningModulesIsSuccess
          ? modules2option({
              modules: modules.ids.map((id) => modules.entities[id]),
              completedModules: params.value,
            })
          : []
      }
      getOptionLabel={(option) => option.shortcut}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      noOptionsText="Keine Module absolviert"
      renderOption={(props, option, { selected }) => (
        <Box
          key={props.key}
          sx={{
            "& .MuiAutocomplete-option": {
              cursor: "default !important",
            },
          }}
        >
          <li {...props}>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: "8px" }}
                  checked={selected}
                  disableRipple
                  sx={{
                    cursor: "default",
                  }}
                />
                <Box mr="8px">{option.shortcut}</Box>
                <Box mr="8px">{option.name}</Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Tooltip arrow title={<Typography>Absolviert am</Typography>}>
                  <Box
                    display={!!option.completedAt ? "flex" : "none"}
                    alignItems="center"
                  >
                    <TodayOutlinedIcon sx={{ mr: "4px" }} color="primary" />

                    {dayjs(option.completedAt).format("DD.MM.YYYY HH:mm")}
                  </Box>
                </Tooltip>
                <Tooltip
                  sx={{ ml: "16px" }}
                  arrow
                  title={<Typography>Eingetragen von</Typography>}
                >
                  <Box
                    display={!!option.enteredBy?.name ? "flex" : "none"}
                    alignItems="center"
                  >
                    <SchoolOutlinedIcon sx={{ mr: "4px" }} color="primary" />

                    {option.enteredBy?.name}
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </li>
        </Box>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={option.shortcut}
            onDelete={undefined}
          />
        ))
      }
      renderInput={(props) => (
        <TextField
          {...props}
          sx={{
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
            ".MuiInputBase-input": {
              display: "none",
            },
            ".MuiInputBase-root": {
              cursor: "pointer",
            },
          }}
        />
      )}
    />
  );

  const columns = [
    {
      field: "user",
      headerName: "Name",
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "number",
      type: "string",
      headerName: "Dienstnr.",
      flex: 1,
      maxWidth: 70,
    },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      valueOptions: status,
      renderCell: renderStatus,
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "activityStatus",
      headerName: "Aktivitätsstatus",
      type: "singleSelect",
      valueOptions: activityStatus,
      renderCell: renderStatus,
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "hiredAt",
      headerName: "Eingestellt am",
      valueFormatter: (params) =>
        dayjs(params.value).format("DD.MM.YYYY HH:mm"),
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "isAuthorizedForTest",
      headerName: "Prüfungszulassung",
      type: "boolean",
      renderCell: renderCheckbox,
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "learningModules",
      headerName: "Absolvierte Anwärter Module",
      flex: 1,
      renderCell: renderModules,
    },
    {
      field: "isSecondaryFaction",
      headerName: "Zweitfraktionist",
      type: "boolean",
      renderCell: renderCheckbox,
    },
  ];

  const handleCellClick = useCallback(
    (params) => {
      if (params.field === "learningModules" || params.field === "__check__")
        return;
      navigate(`/cops/${params.row.id}`);
    },
    // eslint-disable-next-line
    []
  );

  const EditToolbar = useCallback(() => {
    const authorizeForTest = ({ authorized }) => {
      rowSelectionModel.forEach(async (id) => {
        await updateCop({ id, isAuthorizedForTest: authorized });
      });

      showSnackbar(
        authorized ? "Zur Prüfung zugelassen" : "Prüfungszulassung entzogen"
      );
    };

    return (
      <GridToolbarContainer>
        <Button
          variant="text"
          color="success"
          onClick={() => authorizeForTest({ authorized: true })}
          startIcon={<CheckOutlinedIcon />}
          disabled={rowSelectionModel.length === 0}
        >
          Zur Prüfung zulassen
        </Button>
        <Button
          variant="text"
          onClick={() => authorizeForTest({ authorized: false })}
          startIcon={<CloseOutlinedIcon />}
          disabled={rowSelectionModel.length === 0}
        >
          Prüfungszulassung entziehen
        </Button>
      </GridToolbarContainer>
    );
    // eslint-disable-next-line
  }, [rowSelectionModel]);

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),

        "& .row-style--pointer": {
          ":hover": {
            cursor: "pointer !important",
          },
        },
        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        rows={isSuccess ? rows : []}
        loading={isLoading}
        getRowHeight={() => "auto"}
        onCellClick={handleCellClick}
        disableRowSelectionOnClick
        getRowClassName={() => "row-style--pointer"}
        initialState={{
          sorting: {
            sortModel: [{ field: "user", sort: "asc" }],
          },
        }}
        checkboxSelection={authorizeForTestPerm}
        onRowSelectionModelChange={(model) => setRowSelectionModel(model)}
        rowSelectionModel={rowSelectionModel}
        slots={
          authorizeForTestPerm
            ? { toolbar: EditToolbar, pagination: CustomGridPagination }
            : { pagination: CustomGridPagination }
        }
      />
    </Box>
  );
};
export default RecruitDataGrid;
