import { Box, Button, IconButton, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { FaDiscord } from "react-icons/fa";
import LanguageIcon from "@mui/icons-material/Language";
import Starfield from "react-starfield";
import { hex2rgb } from "util/colorConverter";
import { useEffect } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const handleLogin = () => {
  window.location.href = `${process.env.REACT_APP_SERVER}/auth/steam`;
};

const Login = () => {
  useEffect(() => {
    document.title = `Polizei Verwaltung ${process.env.REACT_APP_MAP}`;
  }, []);

  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
    >
      <Box
        display="flex"
        alignItems="center"
        zIndex={1}
        maxWidth="650px"
        m="0 100px"
        p="16px"
        sx={{
          backgroundColor: "info.main",
          color: "info.contrastText",
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <InfoOutlinedIcon fontSize="large" sx={{ mr: 2 }} />
        <Typography>
          Diese Website dient der Verwaltung einer fiktiven Polizeibehörde in
          einem online Rollenspiel und steht in keiner Verbindung zu einer
          staatlichen Behörde
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p="25px 100px"
        m="25px 100px"
        boxShadow="15"
        backgroundColor={theme.palette.background.paper}
        borderRadius={theme.shape.borderRadius}
        zIndex={1}
        sx={{
          backgroundImage: "url(./assets/img/polizei_mono.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Typography align="center" variant="h3" mb={theme.spacing(2)}>
          Polizei {process.env.REACT_APP_MAP}
        </Typography>

        <Typography variant="h6" mb={theme.spacing(4)} textAlign="center">
          Du musst dich über Steam anmelden, um das Intranet nutzen zu können.
        </Typography>
        <Button
          sx={{
            backgroundImage: "url(/assets/img/steamlogin.png)",
            backgroundPosition: "center",
            backgroundSize: "fit",
            backgroundRepeat: "no-repeat",
            width: "109px",
            height: "66px",
            "&:hover": {
              boxShadow: theme.shadows[12],
            },
          }}
          onClick={handleLogin}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <IconButton
            disableRipple
            disableTouchRipple
            href="https://discord.gg/jbHyyASESd"
            target="_blank"
            sx={{
              fontSize: 20,
              color: theme.palette.text.primary,
              "&.MuiButtonBase-root:hover": {
                color: theme.palette.primary.main,
              },
            }}
          >
            <FaDiscord />
            <Typography ml="5px">Discord</Typography>
          </IconButton>
          <IconButton
            disableRipple
            disableTouchRipple
            href="https://forum.panthor.de/forum/board/24-polizei/"
            target="_blank"
            sx={{
              fontSize: 20,
              color: theme.palette.text.primary,
              "&.MuiButtonBase-root:hover": {
                color: theme.palette.primary.main,
              },
            }}
          >
            <LanguageIcon fontSize="inhert" />
            <Typography ml="5px">Forum</Typography>
          </IconButton>
        </Box>
      </Box>
      <Box zIndex={0}>
        <Starfield
          backgroundColor={theme.palette.background.default}
          starCount={2000}
          starColor={[
            hex2rgb(theme.palette.text.primary).r,
            hex2rgb(theme.palette.text.primary).g,
            hex2rgb(theme.palette.text.primary).b,
          ]}
        />
      </Box>
    </Box>
  );
};

export default Login;
