import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { useGetCopsQuery } from "features/cops/copsApiSlice";
import { Formik } from "formik";
import * as yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ratingFunctions, serverPeriods } from "util/copEnums";
import { DatePicker } from "@mui/x-date-pickers";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useAddRatingMutation } from "./ratingsApiSlice";
import { useSnackbar } from "contexts/Snackbar.context";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { memo } from "react";
import ColoredRating from "components/ColoredRating";

yup.addMethod(yup.object, "dayjs", function method(message) {
  return this.test("dayjs", message, function validate(value) {
    if (!value) {
      return true;
    }

    if (dayjs.isDayjs(value) && !!value.isValid) return value.isValid();

    return false;
  });
});

const RatingForm = ({ initialValues, postSubmit }) => {
  const theme = useTheme();
  const { data: cops, isSuccess: getCopsIsSuccess } = useGetCopsQuery(
    "copsList",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const isRecruit = (rank) => rank === "CA" || rank === "C6";

  const isNegativeRating = (ratings) =>
    Object.values(ratings).some((rating) => rating > 0 && rating <= 2);

  const isPositiveRating = (ratings) =>
    Object.values(ratings).some((rating) => rating > 4);

  const ratingSchema = yup.object().shape({
    evaluator: yup.object().shape({
      function: yup.string().required("Pflichtfeld"),
    }),
    rated: yup.object().shape({
      cop: yup.string().required("Pflichtfeld"),
      function: yup.string().required("Pflichtfeld"),
    }),
    date: yup.object().nullable().dayjs("Ungültiges Datum"),
    period: yup.string().required("Pflichtfeld"),
    ratings: yup.object().shape({
      roleplay: yup.number(),
      performance: yup.number(),
      tactics: yup.number(),
      radio: yup.number(),
      knowledge: yup.number(),
    }),
    comment: yup
      .string()
      .when("rated.cop", {
        is: (value) => isRecruit(cops?.entities[value]?.rank),
        then: (schema) => schema.required("Pflichtfeld bei Anwärtern"),
      })
      .when("ratings", {
        is: (value) => isNegativeRating(value) || isPositiveRating(value),
        then: (schema) => schema.required("Pflichtfeld aufgrund der Bewertung"),
      }),
  });

  const [addRating, { isLoading: addRatingIsLoading }] = useAddRatingMutation();
  const showSnackbar = useSnackbar();

  const onSubmit = async (values, { resetForm }) => {
    await addRating(values)
      .unwrap()
      .then(() => {
        resetForm();
        showSnackbar("Bewertung abgegeben");
        if (postSubmit) postSubmit();
      })
      .catch((error) =>
        showSnackbar(error?.data?.message || "Fehler", "error")
      );
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={ratingSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container columns={2} rowSpacing={4} columnSpacing={4}>
            <Grid item xs={2} lg={1}>
              <Grid container columns={2} spacing={2}>
                <Grid item xs={2}>
                  <Autocomplete
                    id="rated.cop"
                    fullWidth
                    value={values.rated?.cop || null}
                    options={getCopsIsSuccess ? cops?.ids : []}
                    onChange={(_, value) => setFieldValue("rated.cop", value)}
                    onBlur={handleBlur}
                    noOptionsText="Keine Optionen"
                    getOptionLabel={(option) =>
                      cops?.entities[option]?.user?.name || ""
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {cops?.entities[option]?.user?.name}
                      </li>
                    )}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        error={touched.rated?.cop && !!errors.rated?.cop}
                        helperText={touched.rated?.cop && errors.rated?.cop}
                        label="Bewertung für"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    select
                    fullWidth
                    label={
                      !!cops?.entities[values.rated.cop]?.user?.name
                        ? `${
                            cops?.entities[values.rated.cop]?.user?.name
                          }'s Funktion`
                        : "Funktion des Bewerteten"
                    }
                    name="rated.function"
                    value={values.rated?.function}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      !!touched.rated?.function && !!errors.rated?.function
                    }
                    helperText={
                      touched.rated?.function && errors.rated?.function
                    }
                  >
                    {ratingFunctions.map((ratingFunction) => (
                      <MenuItem key={ratingFunction} value={ratingFunction}>
                        {ratingFunction}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    select
                    fullWidth
                    label="Meine Funktion"
                    name="evaluator.function"
                    value={values.evaluator?.function}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      !!touched.evaluator?.function &&
                      !!errors.evaluator?.function
                    }
                    helperText={
                      touched.evaluator?.function && errors.evaluator?.function
                    }
                  >
                    {ratingFunctions.map((ratingFunction) => (
                      <MenuItem key={ratingFunction} value={ratingFunction}>
                        {ratingFunction}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={2} sm={1}>
                  <DatePicker
                    value={dayjs(values.date)}
                    name="date"
                    label="Datum"
                    onChange={(value) => setFieldValue("date", value)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        onBlur: handleBlur,
                        error: !!touched.date && !!errors.date,
                        helperText: touched.date && errors.date,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={1}>
                  <TextField
                    select
                    fullWidth
                    label="Server Periode"
                    name="period"
                    value={values.period}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!touched.period && !!errors.period}
                    helperText={touched.period && errors.period}
                  >
                    {serverPeriods.map((period) => (
                      <MenuItem key={period} value={period}>
                        {period}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    multiline
                    label="Anmerkung"
                    name="comment"
                    value={values.comment}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!touched.comment && !!errors.comment}
                    helperText={touched.comment && errors.comment}
                    inputProps={{
                      maxLength: 2000,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} lg={1}>
              <Grid container rowSpacing={3} columns={2} sx={{ mb: "24px" }}>
                <Grid item xs={1}>
                  <Box display="flex" alignItems="center" height="100%">
                    <InputLabel>Roleplay</InputLabel>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <ColoredRating
                    size="large"
                    value={values.ratings?.roleplay || 0}
                    name="ratings.roleplay"
                    onChange={(_, value) => {
                      setFieldValue("ratings.roleplay", value || 0);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" alignItems="center" height="100%">
                    <InputLabel>Leistung unter Stress</InputLabel>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <ColoredRating
                    size="large"
                    value={values.ratings?.performance || 0}
                    name="ratings.performance"
                    onChange={(_, value) =>
                      setFieldValue("ratings.performance", value || 0)
                    }
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" alignItems="center" height="100%">
                    <InputLabel>Taktisches Verständnis</InputLabel>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <ColoredRating
                    size="large"
                    value={values.ratings?.tactics || 0}
                    name="ratings.tactics"
                    onChange={(_, value) =>
                      setFieldValue("ratings.tactics", value || 0)
                    }
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" alignItems="center" height="100%">
                    <InputLabel>Funkdisziplin</InputLabel>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <ColoredRating
                    size="large"
                    value={values.ratings?.radio || 0}
                    name="ratings.radio"
                    onChange={(_, value) =>
                      setFieldValue("ratings.radio", value || 0)
                    }
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" alignItems="center" height="100%">
                    <InputLabel>Fachwissen</InputLabel>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <ColoredRating
                    size="large"
                    value={values.ratings?.knowledge || 0}
                    name="ratings.knowledge"
                    onChange={(_, value) =>
                      setFieldValue("ratings.knowledge", value || 0)
                    }
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Accordion
                  sx={{
                    backgroundColor:
                      theme.palette.mode === "light" &&
                      theme.palette.background.default,
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Bewertungsskala
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      display="flex"
                      alignItems="center"
                      height="100%"
                      sx={{ mb: "8px" }}
                    >
                      <ColoredRating
                        readOnly
                        defaultValue={0}
                        sx={{ mr: "8px" }}
                      />
                      <InputLabel>Keine Bewertung</InputLabel>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      height="100%"
                      sx={{ mb: "8px" }}
                    >
                      <ColoredRating
                        readOnly
                        defaultValue={1}
                        sx={{ mr: "8px" }}
                      />
                      <InputLabel>Sehr schlecht</InputLabel>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      height="100%"
                      sx={{ mb: "8px" }}
                    >
                      <ColoredRating
                        readOnly
                        defaultValue={2}
                        sx={{ mr: "8px" }}
                      />
                      <InputLabel>Schlecht</InputLabel>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      height="100%"
                      sx={{ mb: "8px" }}
                    >
                      <ColoredRating
                        readOnly
                        defaultValue={3}
                        sx={{ mr: "8px" }}
                      />
                      <InputLabel>Erwartungsgemäß</InputLabel>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      height="100%"
                      sx={{ mb: "8px" }}
                    >
                      <ColoredRating
                        readOnly
                        defaultValue={4}
                        sx={{ mr: "8px" }}
                      />
                      <InputLabel>Gut</InputLabel>
                    </Box>
                    <Box display="flex" alignItems="center" height="100%">
                      <ColoredRating
                        readOnly
                        defaultValue={5}
                        sx={{ mr: "8px" }}
                      />
                      <InputLabel>Sehr gut</InputLabel>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            <Grid item xs>
              <Button
                fullWidth
                endIcon={<SendOutlinedIcon />}
                variant="contained"
                type="submit"
                disabled={addRatingIsLoading}
              >
                Absenden
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};
export default memo(RatingForm);
