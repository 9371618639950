import {
  Box,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import CustomCard from "components/CustomCard";
import Header from "components/Header";
import dayjs from "dayjs";
import { useGetArmorysQuery } from "features/armories/armoriesApiSlice";
import { useEffect, useState } from "react";
import ArmoryChangeDialog from "features/armories/ArmoryChangeDialog";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

const getArmoryNameFromId = (id) =>
  ({
    "-4": "Tresor Links",
    "-3": "Tresor Rechts",
    "-2": "Tresor Polizei HQ",
  }[id] ?? "Unbekannt");

const ArmoryCard = ({ armory }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [change, setChange] = useState([]);

  return (
    <>
      <CustomCard title={getArmoryNameFromId(armory.armoryId).toUpperCase()}>
        <Grid container spacing={2} columns={3}>
          <Grid item xs={3} md={1}>
            <List subheader={<ListSubheader>Ausrüstung</ListSubheader>}>
              <Box maxHeight="300px" overflow="auto">
                {armory.gear.map((item, index) => (
                  <Box key={item._id}>
                    <ListItem>
                      <ListItemText primary={item.name} />
                      {item.count}
                    </ListItem>
                    {index < armory.gear.length - 1 && (
                      <Divider component="li" sx={{ m: "0 8px" }} />
                    )}
                  </Box>
                ))}
              </Box>
            </List>
          </Grid>
          <Grid item xs={3} md={1}>
            <List
              subheader={
                <ListSubheader>
                  {"Virtuelle Gegenstände (T-Inventar)"}
                </ListSubheader>
              }
            >
              <Box maxHeight="300px" overflow="auto">
                {armory.inventory.map((item, index) => (
                  <Box key={item._id}>
                    <ListItem>
                      <ListItemText primary={item.name} />
                      {item.count}
                    </ListItem>
                    {index < armory.inventory.length - 1 && (
                      <Divider
                        variant="middle"
                        component="li"
                        sx={{ mr: "0 8px" }}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            </List>
          </Grid>
          <Grid item xs={3} md={1}>
            <List subheader={<ListSubheader>Änderungen</ListSubheader>}>
              <Box maxHeight="300px" overflow="auto">
                {armory.changes.map((change) => {
                  const adds =
                    change.gearChanges.filter(
                      (gearChange) => gearChange.oldCount < gearChange.newCount
                    ).length +
                    change.inventoryChanges.filter(
                      (inventoryChange) =>
                        inventoryChange.oldCount < inventoryChange.newCount
                    ).length;

                  const removes =
                    change.gearChanges.filter(
                      (gearChange) => gearChange.oldCount > gearChange.newCount
                    ).length +
                    change.inventoryChanges.filter(
                      (inventoryChange) =>
                        inventoryChange.oldCount > inventoryChange.newCount
                    ).length;

                  return (
                    <ListItem disablePadding key={change._id}>
                      <ListItemButton
                        onClick={() => {
                          setChange(change);
                          setDialogOpen(true);
                        }}
                      >
                        <ListItemText
                          primary={dayjs(change.createdAt).format(
                            "DD.MM.YYYY HH:mm"
                          )}
                        />
                        {adds > 0 && (
                          <Chip
                            label={adds}
                            icon={<AddCircleOutlineOutlinedIcon />}
                            color="success"
                            size="small"
                            sx={{
                              ml: 1,
                            }}
                          />
                        )}
                        {removes > 0 && (
                          <Chip
                            label={removes}
                            icon={<RemoveCircleOutlineOutlinedIcon />}
                            color="error"
                            size="small"
                            sx={{
                              ml: 1,
                            }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </Box>
            </List>
          </Grid>
        </Grid>
      </CustomCard>
      <ArmoryChangeDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        change={change}
      />
    </>
  );
};

const Armory = () => {
  useEffect(() => {
    document.title = `Waffenkammer | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  const { data: armories, isSuccess } = useGetArmorysQuery("armoriesList", {
    refetchOnMountOrArgChange: true,
  });

  return (
    <Box>
      <Header title="WAFFENKAMMER" subtitle="Inhalt & Änderungsprotokoll" />
      {isSuccess && (
        <Grid
          container
          spacing={2}
          columns={armories.ids.length}
          justifyContent="center"
        >
          {armories.ids.map((id) => (
            <Grid
              key={id}
              item
              xs={armories.ids.length}
              xl={armories.ids.length / 2}
            >
              <ArmoryCard armory={armories.entities[id]} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
export default Armory;
