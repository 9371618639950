import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Slider,
  TextField,
  alpha,
  useTheme,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import Header from "components/Header";
import { useGetPlaytimesQuery } from "features/playtimes/playtimesApiSlice";
import GSTC from "gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js";
import "gantt-schedule-timeline-calendar/dist/style.css";
import { memo, useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import deDE from "dayjs/locale/de";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import PlaytimeDialog from "features/playtimes/PlaytimeDialog";

// eslint-disable-next-line
let gstc, state;

function itemSlot(vido, props) {
  const { html, onChange, update } = vido;

  let description = "";
  onChange((newProps) => {
    props = newProps;
    if (!props || !props.item) return;
    description = props.item.description;
    update();
  });

  return (content) =>
    html`<div class="item-text">
      <div class="item-label">${content}</div>
      <div
        class="item-description"
        style="font-size:11px;margin-top:1px;line-height:1em;"
      >
        ${description}
      </div>
    </div>`;
}

function initializeGSTC(element) {
  /**
   * @type { import("gantt-schedule-timeline-calendar").Config }
   */
  const config = {
    licenseKey: process.env.REACT_APP_GANTT_SCHEDULE_LICENCE_KEY,
    autoInnerHeight: true,
    plugins: [],
    locale: deDE,
    actions: {
      "chart-timeline-items-row-item": [],
    },
    slots: {
      "chart-timeline-items-row-item": { content: [itemSlot] },
    },
    scroll: {
      horizontal: {
        width: 8,
        minInnerSize: 40,
        multiplier: 1,
        precise: true,
        byPixels: true,
      },
      vertical: {
        width: 8,
        minInnerSize: 40,
        multiplier: 1,
        precise: true,
        byPixels: true,
      },
    },
    list: {
      columns: {
        data: {
          [GSTC.api.GSTCID("label")]: {
            id: GSTC.api.GSTCID("label"),
            width: 200,
            data: "label",
            sortable: "label",
            header: {
              content: "Name",
            },
          },
        },
      },
      rows: {},
      row: {
        height: 50,
      },
      toggle: {
        display: false,
      },
      sort: {
        icons: {
          up: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up"><line x1="12" y1="19" x2="12" y2="5"></line><polyline points="5 12 12 5 19 12"></polyline></svg>',
          down: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-down"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>',
        },
      },
    },
    chart: {
      items: {},
      item: {
        height: 50,
      },
      time: {
        zoom: 16,
      },
    },
  };

  state = GSTC.api.stateFromConfig(config);

  gstc = GSTC({
    element,
    state,
  });
}

const PlaytimeTracker = () => {
  const scheduleRef = useCallback((element) => {
    if (element) initializeGSTC(element);
  }, []);

  const theme = useTheme();
  const [from, setFrom] = useState(dayjs().subtract(3, "day").startOf("day"));
  const [until, setUntil] = useState(dayjs());
  const [rawPlaytimes, setRawPlaytimes] = useState([]);
  const [filteredPlaytimes, setFilteredPlaytimes] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPlaytime, setSelectedPlaytime] = useState({});
  const [searchOptions, setSearchOptions] = useState([]);

  const { data: playtimes, isSuccess } = useGetPlaytimesQuery(
    {
      from: from?.valueOf() || undefined,
      until: until?.valueOf() || undefined,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    document.title = `Spielzeiten | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  useEffect(() => {
    if (from) {
      state.update("config.chart.time", (time) => {
        time.from = from.valueOf();
        return time;
      });
    }
  }, [from]);

  useEffect(() => {
    if (until) {
      state.update("config.chart.time", (time) => {
        time.to = until.valueOf();
        return time;
      });
    }
  }, [until]);

  useEffect(() => {
    if (isSuccess) {
      const data = playtimes.ids.map((id) => playtimes.entities[id]);
      setRawPlaytimes(data);
      setFilteredPlaytimes(data);

      setSearchOptions([
        ...new Set(data.map((playtime) => playtime?.cop?.user?.name)),
      ]);
    }
  }, [isSuccess, playtimes]);

  const clickAction = useCallback(
    (element, data) => {
      const onClick = () => {
        setSelectedPlaytime(playtimes.entities[data.item.rawId]);
        setDialogOpen(true);
      };

      element.addEventListener("click", onClick);

      return {
        update(element, newData) {
          data = newData;
        },

        destroy(element, data) {
          element.removeListener("click", onClick);
        },
      };
    },
    [playtimes]
  );

  useEffect(() => {
    state.update("config.actions.chart-timeline-items-row-item", () => [
      clickAction,
    ]);
  }, [clickAction]);

  useEffect(() => {
    state.update("config.chart.items", () => ({}));
    state.update("config.list.rows", () => ({}));

    state.update("config.list.rows", () =>
      filteredPlaytimes.reduce((rows, playtime) => {
        if (!rows[GSTC.api.GSTCID(playtime.cop?._id)]) {
          rows = {
            ...rows,
            [GSTC.api.GSTCID(playtime.cop?._id)]: {
              id: GSTC.api.GSTCID(playtime.cop?._id),
              label: playtime.cop?.user.name,
            },
          };
        }

        return rows;
      }, {})
    );

    state.update("config.chart.items", () =>
      filteredPlaytimes.reduce((items, playtime) => {
        const id = GSTC.api.GSTCID(playtime._id);
        const start = dayjs(playtime.start);
        const end = dayjs(playtime.end);
        items = {
          ...items,
          [id]: {
            id,
            label: `${start.format("HH:mm")} - ${end.format("HH:mm")}`,
            description: `${playtime.name} - Server ${playtime.server}`,
            rawId: playtime._id,
            rowId: GSTC.api.GSTCID(playtime.cop?._id),
            style: {
              backgroundColor:
                playtime.side === "cop"
                  ? theme.palette.cop.main
                  : theme.palette.civ.main,
            },
            time: {
              start: start.valueOf(),
              end: end.valueOf(),
            },
          },
        };

        return items;
      }, {})
    );
    // eslint-disable-next-line
  }, [filteredPlaytimes]);

  const handleSearchChanges = useCallback(
    (_, value) => {
      setFilteredPlaytimes(
        value.length > 0
          ? rawPlaytimes.filter((playtime) =>
              value.some((searchValue) =>
                playtime.cop?.user.name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())
              )
            )
          : rawPlaytimes
      );
    },
    [rawPlaytimes]
  );

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Header title="SPIELZEITEN" subtitle="Polizisten-Spielzeiten Protokoll" />

      <Box mb={1}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Autocomplete
              freeSolo
              multiple
              onChange={handleSearchChanges}
              options={searchOptions}
              renderInput={(params) => (
                <TextField {...params} placeholder="Suche..." />
              )}
              sx={{
                minWidth: "300px",
              }}
            />
          </Grid>
          <Grid item>
            <DateTimePicker
              label="Von"
              value={from}
              onAccept={(value) => setFrom(value)}
              slotProps={{
                actionBar: {
                  actions: ["cancel", "clear", "accept"],
                },
              }}
            />
          </Grid>
          <Grid item>
            <DateTimePicker
              label="Bis"
              value={until}
              onAccept={(value) => setUntil(value)}
              slotProps={{
                actionBar: {
                  actions: ["cancel", "clear", "accept"],
                },
              }}
            />
          </Grid>
          <Grid item>
            <Box width="200px" m="0 8px">
              <Slider
                aria-labelledby="zoom-slider-label"
                min={10}
                max={30}
                defaultValue={16}
                step={1}
                marks={[
                  {
                    value: 16,
                    label: "Stunden",
                  },
                  {
                    value: 20,
                    label: "Tage",
                  },
                  {
                    value: 26,
                    label: "Monat",
                  },
                ]}
                onChange={(_, value) => {
                  state.update("config.chart.time", (time) => {
                    time.from = from.valueOf();
                    time.to = until.valueOf();
                    time.zoom = value;

                    return time;
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Button
              startIcon={<TodayOutlinedIcon />}
              variant="contained"
              onClick={() => gstc.api.scrollToTime(dayjs().valueOf())}
            >
              Heute
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "100%",
          "& .gstc": {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius / 3,
          },
          "& .gstc__list-column-header": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "& .gstc__chart-calendar": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "& .gstc__chart-calendar-dates": {
            color: theme.palette.primary.contrastText,
          },
          "& .gstc__list-column-header-resizer-container--gstcid-label": {
            pl: 2,
          },
          "& .gstc__list-column-rows": {
            color: theme.palette.text.primary,
          },
          "& .gstc__list-column-row-content": {
            pl: 2,
          },
          "& .gstc__scroll-bar": {
            backgroundColor: theme.palette.background.paper,
            cursor: "default",
          },

          "& .gstc__scroll-bar-inner": {
            backgroundColor: theme.palette.grey[600],
            borderRadius: 0,
            ":hover": {
              backgroundColor: alpha(theme.palette.grey[600], 0.5),
            },
          },
          "& .gstc__scroll-bar-inner--active": {
            backgroundColor: alpha(theme.palette.grey[600], 0.5),
            cursor: "default",
          },
          "& .gstc__list-column-row": {
            borderColor: alpha(theme.palette.text.secondary, 0.2),
          },
          "& .gstc__chart-timeline-grid-row-cell": {
            borderColor: alpha(theme.palette.text.secondary, 0.2),
          },
          "& .item-text": {
            alignItems: "center",
          },
          "& .item-description": {
            color: alpha(theme.palette.primary.contrastText, 0.8),
          },
          "& .gstc__chart-timeline-items-row-item": {
            cursor: "pointer",
            ":hover": {
              filter: "brightness(0.8)",
            },
          },
        }}
        ref={scheduleRef}
      ></Box>
      <PlaytimeDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        playtime={selectedPlaytime}
      />
    </Box>
  );
};
export default memo(PlaytimeTracker);
