import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Step,
  useTheme,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Header from "components/Header";
import { Formik, useField } from "formik";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useSnackbar } from "../contexts/Snackbar.context";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useDropzone } from "react-dropzone";
import InfoChip from "components/InfoChip";
import { useAddNewApplicationMutation } from "features/applications/applicationsApiSlice";
import CustomCard from "components/CustomCard";
import dayjs from "dayjs";
import { useGetRecruitmentPhasesQuery } from "features/recruitmentPhases/recruitmentPhasesApiSlice";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

// eslint-disable-next-line
const InactiveRequirement = () => (
  <Typography ml={1} variant="caption" color="warning.main">
    (Diese Voraussetzung ist aktuell außer Kraft gesetzt)
  </Typography>
);

const FormikStepper = ({ children, ...props }) => {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [isCompleted, setIsCompleted] = useState(false);
  const theme = useTheme();

  const isLastStep = () => step === childrenArray.length - 1;

  const handleFormSubmit = async (values, helpers) => {
    if (isLastStep()) {
      await props.onSubmit(values, helpers);

      setIsCompleted(true);
    } else {
      setStep((s) => s + 1);

      helpers.setTouched();
    }
  };

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Stepper orientation="vertical" activeStep={step}>
            {childrenArray.map((child, index) => (
              <Step
                key={child.props.label}
                completed={step > index || isCompleted}
              >
                <StepLabel>{child.props.label}</StepLabel>
                <StepContent>
                  {currentChild}
                  <Box
                    mt={theme.spacing(2)}
                    mr="8px"
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                      float: "right",
                    }}
                  >
                    {step > 0 ? (
                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        onClick={() => setStep((s) => s - 1)}
                        sx={{
                          mr: "20px",
                        }}
                      >
                        Zurück
                      </Button>
                    ) : null}

                    <Button
                      startIcon={
                        isSubmitting ? <CircularProgress size="20px" /> : null
                      }
                      endIcon={
                        !isSubmitting && isLastStep() ? (
                          <SendOutlinedIcon />
                        ) : null
                      }
                      disabled={isSubmitting}
                      variant="contained"
                      type="submit"
                    >
                      {isSubmitting
                        ? "sende Bewerbung"
                        : isLastStep()
                        ? "Absenden"
                        : "Weiter"}
                    </Button>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </form>
      )}
    </Formik>
  );
};

const FormikStep = ({ children }) => {
  return (
    <CustomCard>
      <Box p="8px">{children}</Box>
    </CustomCard>
  );
};

const RequirementsStep = () => {
  const theme = useTheme();
  // eslint-disable-next-line
  const [field, meta, helpers] = useField("didAcceptRequirements");

  return (
    <>
      <Typography
        color={theme.palette.primary.main}
        variant="h6"
        fontWeight="bold"
      >
        Voraussetzungen
      </Typography>
      <ul>
        <li>
          <Box display="flex" alignItems="center">
            <Typography
              variant="body1"
              sx={
                {
                  //textDecoration: "line-through",
                  //color: "action.disabled",
                }
              }
            >
              Du spielst seit mindestens 4 Wochen auf Panthor
            </Typography>
            {/*<InactiveRequirement />*/}
          </Box>
        </li>
        <li>
          <Box display="flex" alignItems="center">
            <Typography
              variant="body1"
              sx={
                {
                  //textDecoration: "line-through",
                  //color: "action.disabled",
                }
              }
            >
              Du hast mindestens 40 Stunden aktive Spielzeit auf dem Server
            </Typography>
            <Tooltip
              arrow
              title={
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography textAlign="center" variant="subtitle1">
                    Spielzeit aus dem{" "}
                    <Link
                      to="https://info.panthor.de/"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: theme.palette.info.main,
                      }}
                    >
                      Info Panel
                    </Link>
                    .
                  </Typography>
                  <Typography>Nicht die "Volle Spielzeit"!</Typography>
                </Box>
              }
            >
              <HelpOutlineOutlinedIcon
                color="info"
                sx={{
                  ml: "8px",
                }}
              />
            </Tooltip>
            {/*<InactiveRequirement />*/}
          </Box>
        </li>
        <li>
          <Typography variant="body1">
            Du hast die{" "}
            <Link
              to="https://wiki.panthor.de/index.php?title=Polizei_Regeln"
              target="_blank"
              style={{
                textDecoration: "none",
                color: theme.palette.info.main,
              }}
            >
              Polizei Regeln
            </Link>{" "}
            gelesen und akzeptiert
          </Typography>
        </li>
      </ul>
      <FormControl error={!!meta.error}>
        <FormControlLabel
          sx={{
            userSelect: "none",
          }}
          control={
            <Checkbox
              onChange={(event) => helpers.setValue(event.target.checked)}
              checked={meta.value}
            />
          }
          label="Ich erfülle alle Voraussetzungen"
        />
        {!!meta.error && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    </>
  );
};

const ApplicationTypeStep = ({ onChange }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField("applicationType");
  const [applicationType, setApplicationType] = useState(meta.value);

  const { data: recruitmentPhases, isSuccess: getRecruitmentPhasesIsSuccess } =
    useGetRecruitmentPhasesQuery("recruitmentPhasesList", {
      refetchOnMountOrArgChange: true,
    });

  const [openPhases, setOpenPhases] = useState([]);

  useEffect(() => {
    if (getRecruitmentPhasesIsSuccess) {
      const today = dayjs();

      setOpenPhases(
        recruitmentPhases.ids
          .filter(
            (id) =>
              today.isAfter(recruitmentPhases.entities[id].from) &&
              today.isBefore(recruitmentPhases.entities[id].until)
          )
          .map((id) => recruitmentPhases.entities[id].allowedApplicationType)
      );
    }
  }, [recruitmentPhases, getRecruitmentPhasesIsSuccess]);

  return (
    <>
      <Typography variant="h6" mb="16px">
        Bewerben als
      </Typography>

      <Box display="flex">
        <Button
          variant={
            applicationType === "Hauptfraktion" ? "contained" : "outlined"
          }
          onClick={() => {
            setApplicationType("Hauptfraktion");
            helpers.setValue("Hauptfraktion");
            onChange("Hauptfraktion");
          }}
          fullWidth
          sx={{
            mr: "20px",
          }}
          disabled={!openPhases.includes("Hauptfraktion")}
        >
          Hauptfraktionist
        </Button>
        <Button
          variant={
            applicationType === "Zweitfraktion" ? "contained" : "outlined"
          }
          onClick={() => {
            setApplicationType("Zweitfraktion");
            helpers.setValue("Zweitfraktion");
            onChange("Zweitfraktion");
          }}
          fullWidth
          disabled={!openPhases.includes("Zweitfraktion")}
        >
          Zweitfraktionist
        </Button>
      </Box>
      {applicationType === "Zweitfraktion" && (
        <Box display="flex" justifyContent="center" mt="16px">
          <InfoChip label="Diese Art der Bewerbung ist ausschließlich Zweitfraktionisten vorbehalten" />
        </Box>
      )}

      {!!meta.error && (
        <FormControl error={!!meta.error} sx={{ mt: "10px" }}>
          <FormHelperText>{meta.error}</FormHelperText>
        </FormControl>
      )}
    </>
  );
};

const PrimaryFactionInfoStep = () => {
  // eslint-disable-next-line
  const [factionField, factionMeta, factionHelpers] =
    useField("primaryFaction");
  // eslint-disable-next-line
  const [factionNameField, factionNameMeta, factionNameHelpers] =
    useField("primaryFactionName");
  // eslint-disable-next-line
  const [factionApproverField, factionApproverMeta, factionApproverHelpers] =
    useField("primaryFactionApprover");

  const theme = useTheme();

  return (
    <>
      <Box mb="16px">
        <InfoChip label="Du benötigst die Erlaubnis der zuständigen Abteilung deiner Hauptfraktion" />
      </Box>
      <Grid container spacing={2}>
        <Grid item md={2} xs={12}>
          <FormControl
            sx={{ minWidth: 135, mr: theme.spacing(2) }}
            error={!!factionMeta.error}
            fullWidth
          >
            <InputLabel id="primary-faction-label">Hauptfraktion</InputLabel>
            <Select
              labelId="primary-faction-label"
              label="Hauptfraktion"
              value={factionMeta.value}
              onChange={(event) => {
                factionHelpers.setValue(event.target.value);
              }}
            >
              <MenuItem value="Justiz">Justiz</MenuItem>
              <MenuItem value="RAC">RAC</MenuItem>
              <MenuItem value="Rettungsdienst">Rettungsdienst</MenuItem>
            </Select>
            {!!factionMeta.error && (
              <FormHelperText>{factionMeta.error}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item md={5} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Name in der Hauptfraktion"
            type="text"
            name="primaryFactionName"
            value={factionNameMeta.value}
            onChange={factionNameField.onChange}
            error={factionNameMeta.touched && !!factionNameMeta.error}
            helperText={factionNameMeta.touched && factionNameMeta.error}
            inputProps={{ maxLength: 30 }}
            sx={{ mr: theme.spacing(2) }}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Genehmiger"
            type="text"
            name="primaryFactionApprover"
            value={factionApproverMeta.value}
            onChange={factionApproverField.onChange}
            error={factionApproverMeta.touched && !!factionApproverMeta.error}
            helperText={
              factionApproverMeta.touched && factionApproverMeta.error
            }
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const FileDropzone = () => {
  // eslint-disable-next-line
  const [fileField, fileMeta, fileHelpers] = useField("file");

  const onDropAccepted = useCallback((files) => {
    if (files.length) {
      fileHelpers.setValue(files[0]);
    }

    // eslint-disable-next-line
  }, []);

  const showSnackbar = useSnackbar();

  const onDropRejected = useCallback((rejections) => {
    if (rejections.length) {
      switch (rejections[0].errors[0].code) {
        case "file-too-large":
          showSnackbar("Die Datei ist zu groß (max. 2MB)", "error");
          break;

        case "file-invalid-type":
          showSnackbar("Ungültiger Dateityp", "error");
          break;
        case "too-many-files":
          showSnackbar("Du kannst nur eine Datei hochladen", "error");
          break;
        default:
          break;
      }
    }

    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    multiple: false,
    maxSize: 2 * 1024 * 1024, // 2 mb
  });

  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        {...getRootProps({
          p: "40px",
          backgroundColor: theme.palette.background.default,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: theme.shape.borderRadius,

          sx: {
            cursor: "pointer",
            borderStyle: "dashed",
            borderColor:
              fileMeta.touched && !!fileMeta.error
                ? theme.palette.error.main
                : theme.palette.text.secondary,
            borderWidth: "2px",
          },
        })}
      >
        <InfoChip label="Das Bewerbungsschreiben und den Lebenslauf lädst du bitte als eine PDF Datei hoch" />
        <input {...getInputProps()} />

        <Typography mt="12px" sx={{ userSelect: "none" }}>
          {isDragActive
            ? "Lege die Datei hier ab"
            : !!fileMeta.value
            ? fileMeta.value.name
            : "Ziehe die Datei in diese Box oder klicke, um eine Datei auszuwählen"}
        </Typography>
      </Box>
      {fileMeta.touched && !!fileMeta.error && (
        <FormControl
          error={fileMeta.touched && !!fileMeta.error}
          sx={{ mt: "10px" }}
        >
          <FormHelperText>{fileMeta.error}</FormHelperText>
        </FormControl>
      )}
    </Box>
  );
};

const MainStep = () => {
  // eslint-disable-next-line
  const [nameField, nameMeta, nameHelpers] = useField("name"); // eslint-disable-next-line
  const [ageField, ageMeta, ageHelpers] = useField("age"); // eslint-disable-next-line
  const [forumNameField, forumNameMeta, forumNameHelpers] =
    useField("forumName"); // eslint-disable-next-line
  const [gangField, gangMeta, gangHelpers] = useField("gang"); // eslint-disable-next-line
  const [arma3HoursField, arma3HoursMeta, arma3HoursHelpers] =
    useField("arma3Hours"); // eslint-disable-next-line
  const [serverHoursField, serverHoursMeta, serverHoursHelpers] =
    useField("serverHours"); // eslint-disable-next-line
  const [commentField, commentMeta, commentHelpers] = useField("comment");

  const theme = useTheme();

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={2} xs={12}>
          <TextField
            variant="outlined"
            label="Reallife Vorname"
            type="text"
            name="name"
            value={nameMeta.value}
            onChange={nameField.onChange}
            error={nameMeta.touched && !!nameMeta.error}
            helperText={nameMeta.touched && nameMeta.error}
            inputProps={{ maxLength: 30 }}
            fullWidth
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            variant="outlined"
            label="Reallife Alter"
            type="number"
            name="age"
            value={ageMeta.value}
            onChange={ageField.onChange}
            error={ageMeta.touched && !!ageMeta.error}
            helperText={ageMeta.touched && ageMeta.error}
            fullWidth
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            variant="outlined"
            label="Forum Name"
            type="text"
            name="forumName"
            value={forumNameMeta.value}
            onChange={forumNameField.onChange}
            error={forumNameMeta.touched && !!forumNameMeta.error}
            helperText={forumNameMeta.touched && forumNameMeta.error}
            inputProps={{ maxLength: 30 }}
            fullWidth
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            variant="outlined"
            label="Gangzugehörigkeit"
            type="text"
            name="gang"
            value={gangMeta.value}
            onChange={gangField.onChange}
            error={gangMeta.touched && !!gangMeta.error}
            helperText={gangMeta.touched && gangMeta.error}
            inputProps={{ maxLength: 30 }}
            fullWidth
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            variant="outlined"
            label="Arma 3 Spielstunden"
            type="number"
            name="arma3Hours"
            value={arma3HoursMeta.value}
            onChange={arma3HoursField.onChange}
            error={arma3HoursMeta.touched && !!arma3HoursMeta.error}
            helperText={arma3HoursMeta.touched && arma3HoursMeta.error}
            fullWidth
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            variant="outlined"
            label="Aktive Panthor Spielstunden"
            type="number"
            name="serverHours"
            value={serverHoursMeta.value}
            onChange={serverHoursField.onChange}
            error={serverHoursMeta.touched && !!serverHoursMeta.error}
            helperText={serverHoursMeta.touched && serverHoursMeta.error}
            fullWidth
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            variant="outlined"
            label="Sonstige Anmerkungen"
            type="text"
            name="comment"
            value={commentMeta.value}
            onChange={commentField.onChange}
            error={commentMeta.touched && !!commentMeta.error}
            helperText={commentMeta.touched && commentMeta.error}
            inputProps={{ maxLength: 500 }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt="16px">
        <Grid item md={4} xs={12}>
          <Box>
            <Typography>
              Deine Bewerbung sollte folgendes beinhalten:
            </Typography>
            <ul>
              <li>
                <Typography>RP-Bewerbungsschreiben</Typography>
              </li>
              <li>
                <Typography>RP-Lebenslauf</Typography>
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            backgroundColor={theme.palette.background.default}
            p="16px"
            mt="32px"
            borderRadius={theme.shape.borderRadius}
            display="flex"
          >
            <Typography fontStyle="italic" sx={{ userSelect: "none" }}>
              Briefkopf:
            </Typography>
            <Typography ml="16px">
              Polizeipräsidium {process.env.REACT_APP_MAP} | Postfach 3013 |
              025899 Desloch
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mt="16px"
          >
            <Box display="flex" mt="16px">
              <FileDropzone />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const ApplyForm = () => {
  useEffect(() => {
    document.title = `Bewerben | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  const initialValues = {
    applicationType: "",
    didAcceptRequirements: false,
    name: "",
    age: "",
    forumName: "",
    primaryFaction: "",
    primaryFactionName: "",
    primaryFactionApprover: "",
    arma3Hours: "",
    serverHours: "",
    gang: "",
    comment: "",
    file: "",
  };

  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  const [applicationType, setApplicationType] = useState("");
  const [addNewApplication, { data: application, isSuccess, isError, error }] =
    useAddNewApplicationMutation();

  const onSubmit = async (values) => {
    const {
      applicationType,
      name,
      age,
      forumName,
      primaryFaction,
      primaryFactionName,
      primaryFactionApprover,
      arma3Hours,
      serverHours,
      gang,
      comment,
      file,
    } = values;

    let formData = new FormData();

    formData.append("applicationType", applicationType);
    formData.append("name", name);
    formData.append("age", age);
    formData.append("forumName", forumName);
    formData.append("primaryFaction", primaryFaction);
    formData.append("primaryFactionName", primaryFactionName);
    formData.append("primaryFactionApprover", primaryFactionApprover);
    formData.append("arma3Hours", arma3Hours);
    formData.append("serverHours", serverHours);
    formData.append("gang", gang);
    formData.append("comment", comment);
    formData.append("file", file);

    await addNewApplication(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      showSnackbar("Bewerbung gesendet");
      navigate(`/profile/my-applications/${application._id}`, {
        replace: true,
      });
    }
    // eslint-disable-next-line
  }, [isSuccess, application]);

  useEffect(() => {
    if (isError) {
      showSnackbar(
        error?.data?.message || "Fehler beim absenden der Bewerbung",
        "error"
      );
    }
    // eslint-disable-next-line
  }, [isError]);

  return (
    <Box>
      <Header title="BEWERBEN" subtitle="Als Anwärter bewerben" />

      <Box maxWidth="1080px">
        <FormikStepper initialValues={initialValues} onSubmit={onSubmit}>
          <FormikStep
            label="Bewerbungsvoraussetzungen"
            validationSchema={yup.object().shape({
              didAcceptRequirements: yup
                .boolean()
                .oneOf(
                  [true],
                  "Du musst alle Voraussetzungen erfüllen, um dich bewerben zu können"
                ),
            })}
          >
            <RequirementsStep />
          </FormikStep>
          <FormikStep
            label="Art der Bewerbung"
            validationSchema={yup.object().shape({
              applicationType: yup
                .string()
                .required("Art der Bewerbung auswählen"),
            })}
          >
            <ApplicationTypeStep onChange={setApplicationType} />
          </FormikStep>
          {applicationType === "Zweitfraktion" && (
            <FormikStep
              label="Informationen zur Hauptfraktion"
              validationSchema={yup.object().shape({
                primaryFaction: yup.string().required("Pflichtfeld"),
                primaryFactionName: yup.string().required("Pflichtfeld"),
                primaryFactionApprover: yup.string().required("Pflichtfeld"),
              })}
            >
              <PrimaryFactionInfoStep />
            </FormikStep>
          )}
          <FormikStep
            label="Unterlagen"
            validationSchema={yup.object().shape({
              name: yup.string().required("Pflichtfeld"),
              age: yup
                .number()
                .min(1, "Bitte gib ein gültiges Alter ein")
                .max(99, "Bitte gib ein gültiges Alter ein")
                .required("Pflichtfeld"),
              forumName: yup.string().required("Pflichtfeld"),
              gang: yup
                .string()
                .required(
                  "Pflichtfeld - Wenn du keiner Gang angehörst, dann gib 'nein' ein"
                ),
              arma3Hours: yup
                .number()
                .min(1, "Bitte gib eine gültige Stundenanzahl ein")
                .required("Pflichtfeld"),
              serverHours: yup
                .number()
                .min(1, "Bitte gib eine gültige Stundenanzahl ein")
                .required("Pflichtfeld"),
              comment: yup.string(),
              file: yup
                .mixed()
                .required(
                  "Bitte lade deine Bewerbungsunterlagen als PDF Datei hoch"
                ),
            })}
          >
            <MainStep />
          </FormikStep>
        </FormikStepper>
      </Box>
    </Box>
  );
};

export default memo(ApplyForm);
