import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "contexts/Snackbar.context";
import {
  useDeleteRequestedRatingMutation,
  useGetUserSentRequestedRatingsQuery,
} from "./requestedRatingsApiSlice";
import { memo, useCallback, useEffect, useState } from "react";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { dataGridSx } from "styles/dataGridSx";
import { dateColumnType, dateTimeColumnType } from "util/dateTimeColumnType";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CustomGridPagination from "components/CustomGridPagination";

const SentRequestedRatingsDataGrid = () => {
  const theme = useTheme();
  const showSnackbar = useSnackbar();

  const [showFullfilled, setShowFullfilled] = useState(false);
  const [rows, setRows] = useState([]);

  const {
    data: requestedRatings,
    isSuccess,
    isLoading,
  } = useGetUserSentRequestedRatingsQuery("sentRequestedRatingsList", {
    refetchOnMountOrArgChange: true,
  });

  const [deleteRequestedRating] = useDeleteRequestedRatingMutation();

  useEffect(() => {
    if (isSuccess) {
      setRows(
        !showFullfilled
          ? requestedRatings.ids.reduce((filtered, id) => {
              const requestedRating = requestedRatings.entities[id];

              if (!requestedRating.fullfilled) {
                filtered.push(requestedRating);
              }

              return filtered;
            }, [])
          : requestedRatings.ids.map((id) => requestedRatings.entities[id])
      );
    }
  }, [isSuccess, requestedRatings, showFullfilled]);

  const showConfirmDialog = useConfirmDialog();

  const handleDeleteRequestedRating = useCallback(async (id) => {
    try {
      await deleteRequestedRating(id).unwrap();
      showSnackbar("Bewertungsanforderung gelöscht");
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = (id) => () => {
    showConfirmDialog({
      title: "Bewertungsanforderung löschen?",
      message: "Soll die Bewertungsanforderung gelöscht werden?",
      onConfirm: () => handleDeleteRequestedRating(id),
    });
  };

  const EditToolbar = () => {
    return (
      <GridToolbarContainer>
        <FormControlLabel
          control={
            <Switch
              checked={showFullfilled}
              onChange={(_, checked) => setShowFullfilled(checked)}
            />
          }
          label="Erfüllte Anforderungen anzeigen"
          sx={{
            userSelect: "none",
            color: theme.palette.text.secondary,
          }}
        />
      </GridToolbarContainer>
    );
  };

  const renderRequestedFrom = (params) => (
    <Autocomplete
      multiple
      fullWidth
      limitTags={7}
      noOptionsText="Keine Einträge"
      disableCloseOnSelect
      forcePopupIcon={false}
      disableClearable
      readOnly
      value={params.value}
      options={params.value}
      getOptionLabel={(option) => option.cop?.user?.name}
      isOptionEqualToValue={(option, value) =>
        option.cop?._id === value.cop?._id
      }
      renderInput={(props) => (
        <TextField
          {...props}
          sx={{
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
            ".MuiInputBase-input": {
              display: "none",
            },
          }}
        />
      )}
    />
  );

  const columns = [
    {
      field: "forFunction",
      headerName: "Für Funktion",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "date",
      headerName: "Datum",
      ...dateColumnType,
      flex: 1,
      maxWidth: 110,
    },
    {
      field: "period",
      headerName: "Server Periode",
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "fullfilled",
      headerName: "Vollständig Erfüllt",
      type: "boolean",
      flex: 1,
      maxWidth: 120,
      renderCell: ({ value }) => (
        <Checkbox checked={value} disableRipple sx={{ cursor: "default" }} />
      ),
    },
    {
      field: "reason",
      headerName: "Grund",
      flex: 1,
      maxWidth: 300,
    },
    {
      field: "requestedFrom",
      headerName: "Angefordert von",
      renderCell: renderRequestedFrom,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "actions",
      headerName: "Aktionen",
      type: "actions",
      getActions: ({ id, row }) => [
        <GridActionsCellItem
          icon={
            <Tooltip
              arrow
              title={
                <Typography textAlign="center">
                  {row.touched
                    ? "Die Anforderung kann nicht mehr von dir gelöscht werden, da bereits mind. eine der angeforderten Bewertungen abgegeben wurde"
                    : ""}
                </Typography>
              }
            >
              <DeleteIcon />
            </Tooltip>
          }
          label="Löschen"
          onClick={handleDeleteClick(id)}
          color="error"
          disabled={row.touched}
          sx={{
            "&.Mui-disabled": {
              pointerEvents: "auto",
            },
          }}
        />,
      ],
    },
  ];

  return (
    <Box
      height="380px"
      sx={{
        ...dataGridSx(theme),
        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
      }}
    >
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows?.length > 0 ? rows : []}
        slots={{
          toolbar: EditToolbar,
          pagination: CustomGridPagination,
        }}
        disableRowSelectionOnClick
        getRowHeight={() => "auto"}
        autoPageSize
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        sx={{
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
      />
    </Box>
  );
};
export default memo(SentRequestedRatingsDataGrid);
