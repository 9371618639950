import { useEffect, useState } from "react";
import { Badge } from "@mui/material";
import { useGetRequestedRatingsQuery } from "features/requestedRatings/requestedRatingsApiSlice";
import { useGetVacationsQuery } from "features/vacations/vacactionsApiSlice";

const StaffOverallBadge = ({ children, ...props }) => {
  const { data: requestedRatings, isSuccess: getRequestedRatingsIsSuccess } =
    useGetRequestedRatingsQuery("requestedRatingsList", {
      refetchOnMountOrArgChange: false,
    });

  const { data: vacations, isSuccess: getVacationsIsSuccess } =
    useGetVacationsQuery("vacationsList", {
      refetchOnMountOrArgChange: false,
    });

  const [sum, setSum] = useState(0);

  useEffect(() => {
    const nrRequestedRatings = getRequestedRatingsIsSuccess
      ? requestedRatings?.ids.reduce((filtered, id) => {
          const requestedRating = requestedRatings?.entities[id];

          if (
            requestedRating.requestedFrom?.some(
              (requestedCop) => !requestedCop?.fullfilled
            )
          ) {
            filtered.push(requestedRating);
          }

          return filtered;
        }, []).length
      : 0;

    const nrNewVacations = getVacationsIsSuccess
      ? vacations.ids.filter((id) => vacations.entities[id].status === "Neu")
          .length
      : 0;

    setSum(nrRequestedRatings + nrNewVacations);
  }, [
    requestedRatings,
    getRequestedRatingsIsSuccess,
    vacations,
    getVacationsIsSuccess,
  ]);

  return (
    <Badge color="error" badgeContent={sum} {...props}>
      {children}
    </Badge>
  );
};
export default StaffOverallBadge;
