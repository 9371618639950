import { Box } from "@mui/material";
import Header from "components/Header";
import RecruitmentPhaseDataGrid from "features/recruitmentPhases/RecruitmentPhaseDataGrid";
import React, { useEffect } from "react";

const RecruitmentPhases = () => {
  useEffect(() => {
    document.title = `Einstellungsphasen | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header
        title="EINSTELLUNGSPHASEN"
        subtitle="Zeiträume, in denen Bewerbungen möglich sind"
      />
      <RecruitmentPhaseDataGrid />
    </Box>
  );
};

export default RecruitmentPhases;
