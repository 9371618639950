import { Box } from "@mui/material";
import Header from "components/Header";
import RequestedRatingsDataGrid from "features/requestedRatings/RequestedRatingsDataGrid";
import { useEffect } from "react";

const OpenRatings = () => {
  useEffect(() => {
    document.title = `Ausstehende Bewertungen | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header
        title="AUSSTEHENDE BEWERTUNGEN"
        subtitle="Angeforderte Bewertungen, die noch nicht getätigt wurden"
      />
      <RequestedRatingsDataGrid />
    </Box>
  );
};

export default OpenRatings;
