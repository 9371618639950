import {
  Autocomplete,
  Box,
  TextField,
  Checkbox,
  Button,
  useTheme,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  useAddRoleMutation,
  useDeleteRoleMutation,
  useGetRolesQuery,
  useUpdateRoleMutation,
} from "./rolesApiSlice";
import { dataGridSx } from "styles/dataGridSx";
import { useGetPermissionsQuery } from "features/permissions/permissionsApiSlice";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useCallback, useEffect, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useSnackbar } from "contexts/Snackbar.context";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import CustomGridPagination from "components/CustomGridPagination";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import { calcGridHeightSubtraction } from "util/calculations";

const RoleDataGrid = () => {
  const theme = useTheme();
  const {
    data: roles,
    isSuccess: getRolesIsSuccess,
    isLoading: getRolesIsLoading,
  } = useGetRolesQuery("rolesList", {
    refetchOnMountOrArgChange: true,
  });

  const { data: permissions, isSuccess: permissionsIsSuccess } =
    useGetPermissionsQuery("permissionsList", {
      refetchOnMountOrArgChange: true,
    });

  const [addRole] = useAddRoleMutation();
  const [updateRole] = useUpdateRoleMutation();
  const [deleteRole] = useDeleteRoleMutation();

  const PermissionAutocomplete = (params) => {
    return (
      <Autocomplete
        fullWidth
        disableCloseOnSelect
        limitTags={6}
        multiple
        noOptionsText="Keine Optionen"
        readOnly={params.readOnly}
        forcePopupIcon={!params.readOnly}
        value={params.value}
        onChange={params.onChange}
        getOptionLabel={(option) => option.description}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        options={
          permissionsIsSuccess
            ? permissions.ids.map((id) => permissions.entities[id])
            : []
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.description}
          </li>
        )}
        renderInput={(props) => (
          <TextField
            {...props}
            sx={{
              ".MuiOutlinedInput-notchedOutline": { border: "none" },
            }}
          />
        )}
      />
    );
  };

  const PermissionsEditInputCell = (params) => {
    const { id, field } = params;

    const apiRef = useGridApiContext();

    const handleChange = (event, newValue) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <PermissionAutocomplete {...params} onChange={handleChange} />;
  };

  const renderPermissionsEditInputCell = (params) => (
    <PermissionsEditInputCell {...params} />
  );

  const renderPermissions = (params) => (
    <PermissionAutocomplete {...params} readOnly />
  );

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const showSnackbar = useSnackbar();

  useEffect(() => {
    if (getRolesIsSuccess) setRows(roles?.ids.map((id) => roles.entities[id]));
  }, [roles, getRolesIsSuccess]);

  const EditToolbar = () => {
    const handleAddRole = async () => {
      const id = `$${Date.now()}`;
      setRows((oldRows) => [
        { id, name: "", defaultFor: "", permissions: [], isNew: true },
        ...oldRows,
      ]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          variant="text"
          onClick={handleAddRole}
          startIcon={<AddOutlinedIcon />}
        >
          Rolle hinzufügen
        </Button>
      </GridToolbarContainer>
    );
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const showConfirmDialog = useConfirmDialog();

  const handleDeleteRole = useCallback(async (id) => {
    try {
      await deleteRole(id).unwrap();
      showSnackbar("Rolle gelöscht");
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = (id) => () => {
    showConfirmDialog({
      title: "Rolle löschen?",
      message: "Die Rolle wird auch aus allen Benutzerstammstätzen entfernt",
      onConfirm: () => handleDeleteRole(id),
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    if (newRow.isNew) {
      await addRole(newRow).unwrap();
      newRow.isNew = false;
      showSnackbar("Rolle angelegt");
    } else {
      await updateRole(newRow).unwrap();
      showSnackbar("Rolle gespeichert");
    }

    return newRow;
  };

  const handleProcessRowUpdateError = (error) => {
    showSnackbar(error.data?.message, "error");
  };

  const columns = [
    {
      field: "name",
      type: "string",
      headerName: "Bezeichnung",
      editable: true,
    },
    {
      field: "level",
      type: "number",
      headerName: "Level",
      editable: true,
      width: 70,
    },
    {
      field: "defaultFor",
      headerName: "Standard für",
      type: "singleSelect",
      valueOptions: ["Neue Benutzer", "Neue Polizisten"],
      editable: true,
    },
    {
      field: "permissions",
      headerName: "Berechtigungen",
      flex: 1,
      editable: true,
      renderCell: renderPermissions,
      renderEditCell: renderPermissionsEditInputCell,
    },
    {
      field: "updatedAt",
      headerName: "Geändert am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "updatedBy",
      headerName: "Geändert von",
      valueGetter: ({ value }) => value?.name,
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "createdBy",
      headerName: "Erstellt von",
      valueGetter: ({ value }) => value?.name,
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "actions",
      headerName: "Aktionen",
      type: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Speichern"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Bearbeiten"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Löschen"
            onClick={handleDeleteClick(id)}
            color="error"
          />,
        ];
      },
    },
  ];

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        loading={getRolesIsLoading}
        editMode="row"
        initialState={{
          sorting: {
            sortModel: [{ field: "level", sort: "desc" }],
          },
        }}
        rows={getRolesIsSuccess ? rows : []}
        slots={{ toolbar: EditToolbar, pagination: CustomGridPagination }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};
export default RoleDataGrid;
