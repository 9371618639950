import { useState } from "react";
import {
  Sidebar as ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import { Avatar, Box, Typography, useTheme, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import useAuth from "hooks/useAuth";
import RequirePermissionForRender from "features/auth/RequirePermissionForRender";
import ApplicationsBadge from "features/applications/ApplicationsBadge";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import CopRankBadge from "components/CopRankBadge";
import EventsBadge from "features/events/EventsBadge";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import ReceivedRequestedRatingsBadge from "features/requestedRatings/ReceivedRequestedRatingsBadge";
import RequestedRatingsBadge from "features/requestedRatings/RequestedRatingsBadge";
import StaffOverallBadge from "components/StaffOverallBadge";
import NewVacationsBadge from "features/vacations/NewVacationsBadge";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import PollsBadge from "features/polls/PollsBadge";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import TerminalOutlinedIcon from "@mui/icons-material/TerminalOutlined";

const SideBarItem = ({ title, to, icon, suffix }) => {
  const theme = useTheme();
  const location = useLocation();

  //const isActive = to === "/" ? location.pathname === to : location.pathname.includes(to);
  const isActive = location.pathname === to;

  return (
    <MenuItem
      active={isActive}
      style={{
        color: isActive
          ? theme.palette.primary.main
          : theme.palette.text.primary,
      }}
      icon={icon}
      component={<Link to={to} />}
      rootStyles={{
        backgroundColor: theme.palette.background.paper,
      }}
      suffix={suffix}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();

  const { user, steamUser } = useAuth();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [isBroken, setIsBroken] = useState(false);

  const menuItemStyles = {
    button: {
      "&:hover": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: "transparent",
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const subMenuStyles = {
    userSelect: "none",
    ".ps-submenu-content": {
      backgroundColor: "transparent",
    },
  };

  const SidebarHeader = () => {
    return (
      <Menu menuItemStyles={menuItemStyles}>
        {/* SIDEBAR COLLAPSE BUTTON */}
        <MenuItem
          onClick={() => setIsCollapsed(!isCollapsed)}
          icon={<MenuOutlinedIcon />}
          style={{
            margin: "10px 0 20px 0",
            color: theme.palette.text.primary,
          }}
        >
          {!isCollapsed && (
            <Box display="flex" alignItems="center" ml="25px" overflow="hidden">
              <Typography fontSize={26} mr="10px">
                Polizei
              </Typography>
              <img
                alt="polizei-logo"
                width="30px"
                height="30px"
                src="/assets/img/polizei.png"
              />
            </Box>
          )}
        </MenuItem>
        {/* SIDEBAR AVATAR */}
        {isCollapsed ? (
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              ".--hover-avatar": {
                borderRadius: "50%",
                ":hover": {
                  boxShadow: theme.shadows[12],
                },
              },
            }}
          >
            <Link
              to="/profile"
              style={{
                borderRadius: "50%",
              }}
            >
              <Avatar
                alt="steam-avatar"
                src={steamUser?.avatarfull}
                className="--hover-avatar"
              />
            </Link>
          </Box>
        ) : (
          <Box
            mb="25px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{
              ".--hover-avatar": {
                borderRadius: "50%",
                ":hover": {
                  boxShadow: theme.shadows[12],
                },
              },
            }}
          >
            <Link
              to="/profile"
              style={{
                borderRadius: "50%",
              }}
            >
              <img
                alt="steam-avatar"
                width="120px"
                height="120px"
                src={steamUser?.avatarfull}
                className="--hover-avatar"
              />
            </Link>

            {!!user?.copData?.rank && user?.copData?.status !== "Gekündigt" && (
              <Box mt="8px">
                <CopRankBadge
                  rank={user?.copData?.rank}
                  hideTitle
                  height="25px"
                />
              </Box>
            )}

            <Box
              textAlign="center"
              display="flex"
              flexDirection="column"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              ml={2}
              mr={2}
            >
              <Typography variant="h5" sx={{ m: "10px 0 0 0" }}>
                {user?.name}
              </Typography>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                {steamUser?.personaname}
              </Typography>
            </Box>
          </Box>
        )}
      </Menu>
    );
  };

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          height: "100vh",
          width: "auto",
          alignSelf: "flex-start",
          display: "flex",
          top: 0,
          bottom: 0,
          zIndex: 1000,
        }}
      >
        <ProSidebar
          collapsed={isCollapsed}
          toggled={isToggled}
          onBackdropClick={() => setIsToggled(false)}
          customBreakPoint="1000px"
          onBreakPoint={setIsBroken}
          backgroundColor={theme.palette.background.paper}
          rootStyles={{
            borderColor: "transparent",
          }}
        >
          <SidebarHeader />
          {/* ALLGEMEINES */}
          <Box p="0 24px" mb="8px">
            <Typography
              variant="body2"
              fontWeight={600}
              style={{ opacity: isCollapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
            >
              Allgemeines
            </Typography>
          </Box>

          <Menu menuItemStyles={menuItemStyles}>
            <SideBarItem title="Dashboard" to="/" icon={<HomeOutlinedIcon />} />
            <SideBarItem
              title="Polizisten"
              to="/cops"
              icon={<LocalPoliceOutlinedIcon />}
            />
            <RequirePermissionForRender permissions={["copBasic"]}>
              <SideBarItem
                title="Kalender"
                to="/calendar"
                icon={
                  <EventsBadge>
                    <CalendarMonthOutlinedIcon />
                  </EventsBadge>
                }
              />

              <SideBarItem
                title="Abstimmungen"
                to="/polls"
                icon={
                  <PollsBadge>
                    <PollOutlinedIcon />
                  </PollsBadge>
                }
              />

              {/* SELBSTVERWALTUNG */}
              <SubMenu
                label="Selbstverwaltung"
                rootStyles={subMenuStyles}
                icon={
                  <ReceivedRequestedRatingsBadge variant="dot">
                    <AccountBoxOutlinedIcon />
                  </ReceivedRequestedRatingsBadge>
                }
              >
                <SideBarItem
                  title="Bewertung verfassen"
                  to="/self-administration/add-rating"
                />
                <ReceivedRequestedRatingsBadge isText>
                  <SideBarItem
                    title="Angeforderte Bewertungen"
                    to="/self-administration/requested-ratings"
                  />
                </ReceivedRequestedRatingsBadge>
                <SideBarItem
                  title="Leitstelle eintragen"
                  to="/self-administration/add-controlcenter"
                />
                <SideBarItem
                  title="Einsatz eintragen"
                  to="/self-administration/add-operation"
                />
                <SideBarItem
                  title="Urlaub beantragen"
                  to="/self-administration/request-vacation"
                />
              </SubMenu>
            </RequirePermissionForRender>
          </Menu>

          {/* ABTEILUNGEN */}
          <RequirePermissionForRender
            permissions={[
              "editRecruitmentPhases",
              "editLearningModules",
              "getAllApplications",
              "editRecruits",
              "seeRecruitData",
              "editCops",
            ]}
          >
            <Box p="0 24px" mb="8px" mt="32px">
              <Typography
                variant="body2"
                fontWeight={600}
                style={{
                  opacity: isCollapsed ? 0 : 0.7,
                  letterSpacing: "0.5px",
                }}
              >
                Abteilungen
              </Typography>
            </Box>
          </RequirePermissionForRender>

          <Menu menuItemStyles={menuItemStyles}>
            {/* AKADEMIE */}
            <RequirePermissionForRender
              permissions={[
                "editLearningModules",
                "editRecruitmentPhases",
                "editApplications",
                "editRecruits",
                "seeRecruitData",
                "postEventsETR",
              ]}
            >
              <Menu menuItemStyles={menuItemStyles}>
                <SubMenu
                  label="Polizeiakademie"
                  rootStyles={subMenuStyles}
                  icon={
                    <ApplicationsBadge variant="dot" overlap="rectangular">
                      <SchoolOutlinedIcon />
                    </ApplicationsBadge>
                  }
                >
                  <RequirePermissionForRender
                    permissions={["editLearningModules"]}
                  >
                    <SideBarItem
                      icon={<AutoStoriesOutlinedIcon />}
                      title="Module"
                      to="/academy/modules"
                    />
                  </RequirePermissionForRender>
                  {/* REKRUTIERUNG */}
                  <RequirePermissionForRender
                    permissions={["editRecruitmentPhases", "editApplications"]}
                  >
                    <SubMenu
                      label="Rekrutierung"
                      icon={
                        <ApplicationsBadge variant="dot" overlap="rectangular">
                          <PersonAddOutlinedIcon />
                        </ApplicationsBadge>
                      }
                      rootStyles={subMenuStyles}
                    >
                      <RequirePermissionForRender
                        permissions={["editRecruitmentPhases"]}
                      >
                        <SideBarItem
                          title="Einstellungsphasen"
                          to="/academy/recruitment-phases"
                        />
                      </RequirePermissionForRender>
                      <RequirePermissionForRender
                        permissions={["editApplications"]}
                      >
                        <SideBarItem
                          title="Bewerbungen"
                          to="/academy/applications"
                          suffix={<ApplicationsBadge />}
                        />
                      </RequirePermissionForRender>
                    </SubMenu>
                  </RequirePermissionForRender>

                  {/* AUSBILDUNG */}
                  <RequirePermissionForRender
                    permissions={["editRecruits", "seeRecruitData"]}
                  >
                    <SubMenu
                      label="Ausbildung"
                      rootStyles={subMenuStyles}
                      icon={<SchoolOutlinedIcon />}
                    >
                      <SideBarItem title="Anwärter" to="/academy/recruits" />
                      <SideBarItem
                        title="Notizen"
                        to="/academy/recruits/notes"
                      />
                      <SideBarItem
                        title="Sanktionen"
                        to="/academy/recruits/sanctions"
                      />
                    </SubMenu>
                  </RequirePermissionForRender>

                  {/* FORTBILDUNG */}
                  <RequirePermissionForRender permissions={["postEventsETR"]}>
                    <SubMenu
                      label="Fortbildung"
                      rootStyles={subMenuStyles}
                      icon={<LocalLibraryOutlinedIcon />}
                    >
                      <SideBarItem
                        title="Einsatztrainings"
                        to="/academy/operational-trainings"
                      />
                    </SubMenu>
                  </RequirePermissionForRender>
                </SubMenu>
              </Menu>
            </RequirePermissionForRender>

            {/* PERSONALRAT */}
            <RequirePermissionForRender
              permissions={[
                "editRatings",
                "getAllRatings",
                "editCops",
                "editControlcenters",
                "editOperations",
                "editVacations",
                "readCopStatistics",
                "readCopRatioViolations",
                "sendMessages",
                "readUpcomingUpranks",
              ]}
            >
              <SubMenu
                label="Personalrat"
                rootStyles={subMenuStyles}
                icon={
                  <StaffOverallBadge variant="dot">
                    <GavelOutlinedIcon />
                  </StaffOverallBadge>
                }
              >
                <RequirePermissionForRender
                  permissions={[
                    "readCopStatistics",
                    "readCopRatioViolations",
                    "sendMessages",
                    "readUpcomingUpranks",
                  ]}
                >
                  <SideBarItem title="Cockpit" to="/staff" />
                </RequirePermissionForRender>

                <RequirePermissionForRender permissions={["editVacations"]}>
                  <NewVacationsBadge>
                    <SideBarItem title="Urlaubsanträge" to="/staff/vacations" />
                  </NewVacationsBadge>
                </RequirePermissionForRender>

                <RequirePermissionForRender
                  permissions={["editRatings", "getAllRatings"]}
                >
                  <SideBarItem title="Bewertungen" to="/staff/ratings" />

                  <RequestedRatingsBadge>
                    <SideBarItem
                      title="Ausstehende Bewertungen"
                      to="/staff/open-ratings"
                    />
                  </RequestedRatingsBadge>
                </RequirePermissionForRender>

                <RequirePermissionForRender
                  permissions={["editControlcenters"]}
                >
                  <SideBarItem title="Leitstellen" to="/staff/controlcenters" />
                </RequirePermissionForRender>

                <RequirePermissionForRender permissions={["editOperations"]}>
                  <SideBarItem title="Einsätze" to="/staff/operations" />
                </RequirePermissionForRender>

                <RequirePermissionForRender permissions={["editCops"]}>
                  <SideBarItem title="Notizen" to="/staff/notes" />
                  <SideBarItem title="Sanktionen" to="/staff/sanctions" />
                </RequirePermissionForRender>
              </SubMenu>
            </RequirePermissionForRender>
          </Menu>

          {/* VERWALTUNG */}
          <RequirePermissionForRender
            permissions={[
              "getUsersList",
              "accessRCon",
              "editLinks",
              "editNews",
              "viewPlaytimeTracker",
            ]}
          >
            <Box p="0 24px" mb="8px" mt="32px">
              <Typography
                variant="body2"
                fontWeight={600}
                style={{
                  opacity: isCollapsed ? 0 : 0.7,
                  letterSpacing: "0.5px",
                }}
              >
                Verwaltung
              </Typography>
            </Box>
          </RequirePermissionForRender>

          {/* ETR RCon */}
          <RequirePermissionForRender permissions={["accessRCon"]}>
            <Menu menuItemStyles={menuItemStyles}>
              <SideBarItem
                icon={<TerminalOutlinedIcon />}
                title="RCon"
                to="/rcon"
              />
            </Menu>
          </RequirePermissionForRender>

          {/* INFORMATIONEN */}
          <RequirePermissionForRender permissions={["editNews", "editLinks"]}>
            <Menu menuItemStyles={menuItemStyles}>
              <SubMenu
                label="Informationen"
                rootStyles={subMenuStyles}
                icon={<InfoOutlinedIcon />}
              >
                <RequirePermissionForRender permissions={["editNews"]}>
                  <SideBarItem title="News" to="/news" />
                </RequirePermissionForRender>

                <RequirePermissionForRender permissions={["editLinks"]}>
                  <SideBarItem title="Links" to="/links" />
                </RequirePermissionForRender>
              </SubMenu>
            </Menu>
          </RequirePermissionForRender>

          {/* SPIELZEIT TRACKER */}
          <RequirePermissionForRender permissions={["viewPlaytimeTracker"]}>
            <Menu menuItemStyles={menuItemStyles}>
              <SubMenu
                label="Spielzeiten"
                rootStyles={subMenuStyles}
                icon={<AccessTimeOutlinedIcon />}
              >
                <SideBarItem title="Tracker" to="/playtimes/tracker" />
                <SideBarItem title="Liste" to="/playtimes/list" />
              </SubMenu>
            </Menu>
          </RequirePermissionForRender>

          {/* LOGS */}
          <RequirePermissionForRender
            permissions={[
              "viewArmoryLogs",
              "viewExpCalculations",
              "viewDatabaseLogs",
            ]}
          >
            <Menu menuItemStyles={menuItemStyles}>
              <SubMenu
                label="Logs"
                rootStyles={subMenuStyles}
                icon={<ListAltOutlinedIcon />}
              >
                <RequirePermissionForRender permissions={["viewArmoryLogs"]}>
                  <SideBarItem title="Waffenkammer" to="/logs/armory" />
                </RequirePermissionForRender>

                <RequirePermissionForRender
                  permissions={["viewExpCalculations"]}
                >
                  <SideBarItem
                    title="EP Kalkulation"
                    to="/logs/exp-calculation"
                  />
                </RequirePermissionForRender>

                <RequirePermissionForRender permissions={[]}>
                  <SideBarItem title="Jobs" to="/logs/jobs" />
                </RequirePermissionForRender>

                <RequirePermissionForRender permissions={["viewDatabaseLogs"]}>
                  <SideBarItem title="Datenbank" to="/logs/database" />
                </RequirePermissionForRender>
              </SubMenu>
            </Menu>
          </RequirePermissionForRender>

          {/* BENUTZER */}
          <RequirePermissionForRender permissions={["getUsersList"]}>
            <Menu menuItemStyles={menuItemStyles}>
              <SideBarItem
                title="Benutzer"
                to="/users"
                icon={<PeopleOutlinedIcon />}
              />
            </Menu>
          </RequirePermissionForRender>

          {/* ROLLEN */}
          <RequirePermissionForRender permissions={[]}>
            <Menu menuItemStyles={menuItemStyles}>
              <SideBarItem
                title="Rollen"
                to="/roles"
                icon={<KeyOutlinedIcon />}
              />
            </Menu>
          </RequirePermissionForRender>
        </ProSidebar>
      </Box>
      {isBroken && (
        <Box
          sx={{
            position: "fixed",
            top: 16,
            right: 16,
            zIndex: 900,
          }}
        >
          <Button
            onClick={() => setIsToggled(!isToggled)}
            variant="contained"
            sx={{
              p: 2,
              m: 0,
              borderRadius: "50%",

              minWidth: 0,
            }}
          >
            <MenuOutlinedIcon />
          </Button>
        </Box>
      )}
    </>
  );
};

export default Sidebar;
