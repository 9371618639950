import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import CustomCard from "components/CustomCard";
import { memo, useState } from "react";
import CopInfoDataGrid from "./CopInfoDataGrid";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box mt="24px">{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => ({
  id: `tab-${index}`,
  "aria-controls": `tabpanel-${index}`,
});

const CopInfos = ({ copId, canEditCop }) => {
  const theme = useTheme();

  const [tab, setTab] = useState(0);

  const handleTabChange = (_, newValue) => {
    setTab(newValue);
  };

  return (
    <CustomCard>
      <Box display="flex" alignItems="center" mb="16px">
        <Tabs
          value={tab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
          }}
          sx={{
            mt: "-12px",
            ml: "-16px",
            "& .MuiTabs-indicator": {
              display: "flex",
              justifyContent: "center",
              backgroundColor: "transparent",
            },
            "& .MuiTabs-indicatorSpan": {
              maxWidth: 40,
              width: "100%",
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          <Tab
            label={
              <Typography variant="subtitle1" letterSpacing={1}>
                Notizen
              </Typography>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Typography variant="subtitle1" letterSpacing={1}>
                Bestrafungen
              </Typography>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <Box>
        <CustomTabPanel value={tab} index={0}>
          <Box height="500px">
            <CopInfoDataGrid
              copId={copId}
              infoType={"note"}
              canEditCop={canEditCop}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <Box height="500px">
            <CopInfoDataGrid
              copId={copId}
              infoType={"sanction"}
              canEditCop={canEditCop}
            />
          </Box>
        </CustomTabPanel>
      </Box>
    </CustomCard>
  );
};
export default memo(CopInfos);
