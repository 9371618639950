import { Badge } from "@mui/material";
import { useGetApplicationsQuery } from "features/applications/applicationsApiSlice";

const ApplicationsBadge = ({ children, ...props }) => {
  const { data: applications, isSuccess: getApplicationsIsSuccess } =
    useGetApplicationsQuery({ isClosed: false });

  return (
    <Badge
      color="error"
      badgeContent={getApplicationsIsSuccess ? applications.ids.length : 0}
      {...props}
    >
      {children}
    </Badge>
  );
};
export default ApplicationsBadge;
