import { useParams } from "react-router-dom";
import {
  useDeleteMessageMutation,
  useGetUserApplicationsQuery,
  usePostMessageMutation,
  useWithdrawApplicationMutation,
} from "./applicationsApiSlice";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "components/Header";
import dayjs from "dayjs";
import CustomCard from "components/CustomCard";
import ApplicationFileView from "./ApplicationFileView";
import useAuth from "hooks/useAuth";
import ApplicationHeader from "./ApplicationHeader";
import Chat from "components/Chat";
import { useCallback, useEffect } from "react";
import { useSnackbar } from "contexts/Snackbar.context";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";

const MyApplication = () => {
  const { id } = useParams();
  const theme = useTheme();
  const { user } = useAuth();

  const { application } = useGetUserApplicationsQuery("userApplicationsList", {
    selectFromResult: ({ data }) => ({
      application: data?.entities[id],
    }),
  });

  useEffect(() => {
    document.title = `Mein Profil | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  const [withdrawApplication, { isSuccess: withdrawApplicationIsSuccess }] =
    useWithdrawApplicationMutation();
  const [postMessage, { isSuccess: postMessageIsSuccess }] =
    usePostMessageMutation();
  const [deleteMessage] = useDeleteMessageMutation();

  const showSnackbar = useSnackbar();
  const showConfirmDialog = useConfirmDialog();

  const handleWithdrawApplication = useCallback(async () => {
    try {
      if (application._id) {
        await withdrawApplication(application._id);

        await postMessage({
          id: application._id,
          message: "Bewerbung zurückgezogen",
        });
      }
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, [application]);

  const onWithdrawApplication = async () => {
    showConfirmDialog({
      title: "Bewerbung zurückziehen?",
      message: "Die Bewerbung wird geschlossen und nicht weiter bearbeitet!",
      onConfirm: () => handleWithdrawApplication(),
    });
  };

  useEffect(() => {
    if (withdrawApplicationIsSuccess && postMessageIsSuccess)
      showSnackbar("Bewerbung zurückgezogen");
    // eslint-disable-next-line
  }, [withdrawApplicationIsSuccess, postMessageIsSuccess]);

  if (!application)
    return (
      <Box m="16px">
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      <Header
        title="MEINE BEWERBUNG"
        subtitle={`Vom ${dayjs(application.createdAt).format(
          "DD.MM.YYYY HH:mm"
        )}`}
      />

      <Grid container columns={2} spacing={theme.spacing(2)}>
        <Grid item xs={2}>
          <ApplicationHeader application={application} user={user} />
        </Grid>
        {!application.isClosed && (
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="error"
              onClick={onWithdrawApplication}
            >
              Bewerbung zurückziehen
            </Button>
          </Grid>
        )}

        <Grid item xs={2}>
          <CustomCard title="ANMERKUNG">
            <Typography fontStyle="italic">{application.comment}</Typography>
          </CustomCard>
        </Grid>
        <Grid item xs={2} xl={1}>
          <CustomCard title="NACHRICHTEN" expandable defaultExpanded>
            <Chat
              placeholder="Nachricht an Rekrutierer"
              messages={application.messages}
              onSubmit={async (message) => {
                await postMessage({ id: application.id, message: message });
              }}
              onDelete={async (messageId) => {
                await deleteMessage({
                  id: application.id,
                  messageId: messageId,
                });
              }}
              windowMinHeight={200}
              textInputDisabled={application.isClosed}
              deleteMessageDisabled={application.isClosed}
            />
          </CustomCard>
        </Grid>
        <Grid item xs={2} xl={1}>
          <CustomCard title="BEWERBUNG" expandable>
            <ApplicationFileView id={application.id} />
          </CustomCard>
        </Grid>
      </Grid>
    </Box>
  );
};
export default MyApplication;
