import { Box, alpha, useTheme, IconButton } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import CustomCard from "components/CustomCard";
import { ranks } from "util/copEnums";
import dayjs from "dayjs";
import { memo, useEffect, useState } from "react";
import RequirePermissionForRender from "features/auth/RequirePermissionForRender";
import EditIcon from "@mui/icons-material/Edit";
import CopCareerDialog from "./CopCareerDialog";

const CopCareerChart = ({ copId, career }) => {
  const theme = useTheme();

  const [data, setData] = useState([]);
  const [uniqueYValues, setUniqueYValues] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setData([
      {
        id: "Karriere",
        data:
          career?.map((item) => ({
            x: new Date(item.date),
            y: ranks[item.newRank].value,
          })) || [],
      },
    ]);

    setUniqueYValues([
      ...new Set(career?.map((item) => ranks[item.newRank].value)),
    ]);
  }, [career]);

  return (
    <>
      <RequirePermissionForRender permissions={[]}>
        <IconButton
          onClick={() => setDialogOpen(true)}
          color="primary"
          sx={{
            position: "relative",
            zIndex: 10,
            mb: "-100px",
            ml: "90px",
            mt: "8px",
            float: "left",
          }}
        >
          <EditIcon />
        </IconButton>
      </RequirePermissionForRender>
      <CustomCard title="KARRIERE" id="cop-career-chart">
        <Box height="350px">
          <ResponsiveLine
            data={data}
            colors={[theme.palette.secondary.main]}
            theme={{
              grid: {
                line: {
                  stroke: alpha(theme.palette.text.secondary, 0.15),
                },
              },
              axis: {
                legend: {
                  text: {
                    fill: theme.palette.text.secondary,
                  },
                },
                ticks: {
                  text: {
                    fill: theme.palette.text.secondary,
                  },
                  line: {
                    stroke: alpha(theme.palette.text.secondary, 0.15),
                  },
                },
              },
              tooltip: {
                container: {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.background.default,
                },
              },
            }}
            margin={{ top: 10, right: 20, bottom: 50, left: 60 }}
            xScale={{
              type: "time",
              precision: "minute",
              max: "auto",
              min: "auto",
            }}
            xFormat={(value) => dayjs(value).format("DD.MM.YY HH:mm")}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
            }}
            yFormat={(value) =>
              Object.values(ranks).find((rank) => rank.value === value)
                ?.description
            }
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 10,
              tickValues: 5,
              tickPadding: 10,
              tickRotation: 0,
              legend: "",
              legendOffset: 0,
              legendPosition: "middle",
              truncateTickAt: 0,
              format: (value) => dayjs(value).format("DD.MM.YY"),
            }}
            axisLeft={{
              tickValues: uniqueYValues,
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              legend: "Dienstgrad",
              legendOffset: -40,
              legendPosition: "middle",
              truncateTickAt: 0,
              format: (value) =>
                Object.values(ranks).find((rank) => rank.value === value)?.id,
            }}
            pointSize={10}
            pointColor={theme.palette.background.paper}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            enableCrosshair={false}
            useMesh={true}
            curve="catmullRom"
          />
        </Box>
      </CustomCard>
      <CopCareerDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        career={career}
        copId={copId}
      />
    </>
  );
};
export default memo(CopCareerChart);
