import { Box, Chip, useTheme } from "@mui/material";
import { useGetJobProtocolsQuery } from "./jobProtocolsApiSlice";
import { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import CustomGridPagination from "components/CustomGridPagination";
import { DataGrid } from "@mui/x-data-grid";
import { dataGridSx } from "styles/dataGridSx";
import { calcGridHeightSubtraction } from "util/calculations";

const JobProtocolDataGrid = () => {
  const theme = useTheme();

  const {
    data: protocols,
    isSuccess,
    isLoading,
  } = useGetJobProtocolsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (isSuccess) setRows(protocols.ids.map((id) => protocols.entities[id]));
  }, [isSuccess, protocols]);

  const columns = [
    {
      field: "type",
      headerName: "Meldungstyp",
      flex: 1,
      maxWidth: 150,
      type: "singleSelect",
      valueOptions: ["Fehler", "Information"],
      renderCell: ({ value }) => (
        <Chip
          icon={
            value === "Fehler" ? (
              <WarningAmberOutlinedIcon />
            ) : (
              <InfoOutlinedIcon />
            )
          }
          color={value === "Fehler" ? "error" : "info"}
          label={value}
        />
      ),
    },
    {
      field: "name",
      headerName: "Job Name",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "startedAt",
      headerName: "Startzeitpunkt",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "runtimeMs",
      headerName: "Laufzeit (ms)",
      type: "number",
      flex: 1,
      maxWidth: 80,
    },
    {
      field: "message",
      headerName: "Nachricht",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
  ];

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),

        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        rows={isSuccess ? rows : []}
        loading={isLoading}
        rowSelection={false}
        disableRowSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
          filter: {
            filterModel: {
              items: [{ field: "type", operator: "is", value: "Fehler" }],
            },
          },
        }}
        slots={{
          pagination: CustomGridPagination,
        }}
      />
    </Box>
  );
};
export default JobProtocolDataGrid;
