import CustomCard from "components/CustomCard";
import {
  useGetOnlinePlayersQuery,
  useKickPlayerMutation,
} from "./rconApiSlice";
import { useCallback, useMemo, useState } from "react";
import { Box, Button, CircularProgress, useTheme } from "@mui/material";
import { dataGridSx } from "styles/dataGridSx";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import SportsMartialArtsOutlinedIcon from "@mui/icons-material/SportsMartialArtsOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import { useSnackbar } from "contexts/Snackbar.context";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import PlayerBanDialog from "./PlayerBanDialog";
import CustomGridPagination from "components/CustomGridPagination";

const GridToolbar = () => {
  const { refetch, isLoading } = useGetOnlinePlayersQuery(undefined, {});

  return (
    <GridToolbarContainer>
      <Button
        startIcon={
          isLoading ? <CircularProgress size="1em" /> : <CachedOutlinedIcon />
        }
        onClick={() => refetch()}
        disabled={isLoading}
      >
        Aktualisieren
      </Button>
    </GridToolbarContainer>
  );
};

const RConPlayerList = () => {
  const {
    data: players,
    isLoading,
    isSuccess,
    refetch,
  } = useGetOnlinePlayersQuery(undefined, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 300000, // 5 Minuten
  });

  const [kickPlayer, { isLoading: kickPlayerIsLoading }] =
    useKickPlayerMutation();

  const theme = useTheme();
  const showSnackbar = useSnackbar();
  const [openBanDialog, setOpenBanDialog] = useState(false);
  const [banPlayer, setBanPlayer] = useState({
    id: "",
    name: "",
  });

  const onKickPlayer = useCallback(
    ({ id, name }) => {
      kickPlayer({ player: id })
        .unwrap()
        .then(() => {
          refetch();
          showSnackbar(`Spieler ${name} gekickt`);
        })
        .catch((error) => {
          refetch();
          showSnackbar(
            error.data?.message || "Fehler beim Kicken des Spielers",
            "error"
          );
        });
    },
    [kickPlayer, refetch, showSnackbar]
  );

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "#",
        width: 50,
      },
      {
        field: "name",
        headerName: "Name (Ping)",
        valueGetter: ({ row, value }) => `${value} (${row.ping})`,
        flex: 1,
        maxWidth: 300,
      },
      {
        field: "beguid",
        headerName: "BattleEye GUID",
        flex: 1,
      },
      {
        field: "lobby",
        type: "boolean",
        headerName: "In Lobby",
      },
      {
        field: "actions",
        type: "actions",
        getActions: ({ row }) => [
          <GridActionsCellItem
            icon={<SportsMartialArtsOutlinedIcon color="primary" />}
            label="Kicken"
            showInMenu
            onClick={() => onKickPlayer({ id: row.id, name: row.name })}
            disabled={kickPlayerIsLoading}
            sx={{
              color: "primary.main",
            }}
          />,
          <GridActionsCellItem
            icon={<BlockOutlinedIcon color="error" />}
            label="Bannen"
            showInMenu
            onClick={() => {
              setBanPlayer({ id: row.id, name: row.name, beguid: row.beguid });
              setOpenBanDialog(true);
            }}
            sx={{
              color: "error.main",
            }}
          />,
        ],
      },
    ],
    [onKickPlayer, kickPlayerIsLoading]
  );

  return (
    <CustomCard title="SPIELER ONLINE" expandable defaultExpanded>
      <Box sx={dataGridSx(theme)} height="400px">
        <DataGrid
          rows={isSuccess ? players : []}
          loading={isLoading}
          columns={columns}
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
            pagination: CustomGridPagination,
          }}
        />
      </Box>
      <PlayerBanDialog
        open={openBanDialog}
        onClose={() => setOpenBanDialog(false)}
        {...banPlayer}
      />
    </CustomCard>
  );
};
export default RConPlayerList;
