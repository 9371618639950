import TextBadge from "components/TextBadge";
import { useGetRequestedRatingsQuery } from "./requestedRatingsApiSlice";

const RequestedRatingsBadge = ({ children, ...props }) => {
  const { data: requestedRatings, isSuccess } = useGetRequestedRatingsQuery(
    "requestedRatingsList",
    {
      refetchOnMountOrArgChange: false,
    }
  );

  return (
    <TextBadge
      color="error"
      badgeContent={
        isSuccess
          ? requestedRatings.ids.reduce((filtered, id) => {
              const requestedRating = requestedRatings.entities[id];

              if (
                requestedRating.requestedFrom?.some(
                  (requestedCop) => !requestedCop?.fullfilled
                )
              ) {
                filtered.push(requestedRating);
              }

              return filtered;
            }, []).length
          : 0
      }
      {...props}
    >
      {children}
    </TextBadge>
  );
};
export default RequestedRatingsBadge;
