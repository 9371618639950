import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const operationsAdapter = createEntityAdapter();

const initialState = operationsAdapter.getInitialState();

export const operationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOperations: builder.query({
      query: () => ({
        url: "/operations",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedOperations = responseData.map((operation) => {
          operation.id = operation._id;
          return operation;
        });

        return operationsAdapter.setAll(initialState, loadedOperations);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Operation", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Operation", id })),
          ];
        } else return [{ type: "Operation", id: "LIST" }];
      },
    }),
    addOperation: builder.mutation({
      query: (operation) => ({
        url: "/operations",
        method: "POST",
        body: { ...operation },
      }),
      invalidatesTags: [{ type: "Operation", id: "LIST" }],
    }),
    updateOperation: builder.mutation({
      query: (operation) => ({
        url: "/operations",
        method: "PATCH",
        body: { ...operation },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Operation", id: arg.id },
      ],
    }),
    deleteOperation: builder.mutation({
      query: (id) => ({
        url: "/operations",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Operation", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetOperationsQuery,
  useAddOperationMutation,
  useUpdateOperationMutation,
  useDeleteOperationMutation,
} = operationsApiSlice;

export const selectOperationsResult =
  operationsApiSlice.endpoints.getOperations.select();

const selectOperationsData = createSelector(
  selectOperationsResult,
  (operationsResult) => operationsResult.data
);

export const {
  selectAll: selectAllOperations,
  selectIds: selectOperationsIds,
} = operationsAdapter.getSelectors(
  (state) => selectOperationsData(state) ?? initialState
);
