import { apiSlice } from "app/api/apiSlice";

export const copStatisticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActivityStatistics: builder.query({
      query: (params) => ({
        url: "/statistics/activity",
        params,
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
    }),
    getControlcentersStatistics: builder.query({
      query: (params) => ({
        url: "/statistics/controlcenters",
        params,
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
    }),
    getOperationsStatistics: builder.query({
      query: (params) => ({
        url: "/statistics/operations",
        params,
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
    }),
  }),
});

export const {
  useGetActivityStatisticsQuery,
  useGetControlcentersStatisticsQuery,
  useGetOperationsStatisticsQuery,
} = copStatisticsApiSlice;
