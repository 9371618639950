import { Button } from "@mui/material";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import RequirePermissionForRender from "features/auth/RequirePermissionForRender";

const SupportPanelButton = ({ steamid, sx }) => {
  return (
    <RequirePermissionForRender permissions={["supportPanel"]}>
      <Button
        color="info"
        size="small"
        variant="contained"
        startIcon={<SecurityOutlinedIcon />}
        onClick={() =>
          window.open(
            `${process.env.REACT_APP_SUPPORT_TOOL_BASE_URL}/${steamid}`,
            "_blank"
          )
        }
        sx={sx}
      >
        Support Panel
      </Button>
    </RequirePermissionForRender>
  );
};
export default SupportPanelButton;
