import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";

const CopRatioViolationDetailChart = ({ violations }) => {
  const theme = useTheme();

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(
      violations.reduce(
        (data, violation) => {
          const createdAt = new Date(violation.createdAt);

          data[0].data.push({
            x: createdAt,
            y: violation.cops,
            violation: violation,
          });

          if (violation.civs >= violation.rule.civLimit) {
            data[1].data.push({
              x: createdAt,
              y: violation.rule.requiredCops,
              violation: violation,
            });
          }

          return data;
        },
        [
          {
            id: "Gewertete Polizisten",
            data: [],
          },
          {
            id: "Cop Quote",
            data: [],
          },
        ]
      )
    );
  }, [violations]);

  return (
    <Box display="flex" height="300px">
      <ResponsiveLine
        data={data}
        colors={[theme.palette.cop.main, theme.palette.error.main]}
        theme={{
          grid: {
            line: {
              stroke: alpha(theme.palette.text.secondary, 0.15),
            },
          },
          axis: {
            legend: {
              text: {
                fill: theme.palette.text.secondary,
              },
            },
            ticks: {
              text: {
                fill: theme.palette.text.secondary,
              },
              line: {
                stroke: alpha(theme.palette.text.secondary, 0.15),
              },
            },
          },
          tooltip: {
            container: {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.default,
            },
          },
          legends: {
            text: {
              fill: theme.palette.text.secondary,
            },
          },
          crosshair: {
            line: {
              stroke: theme.palette.text.primary,
            },
          },
        }}
        margin={{ top: 10, right: 20, bottom: 60, left: 60 }}
        xScale={{
          type: "time",
          precision: "second",
          max: "auto",
          min: "auto",
        }}
        xFormat={(value) => dayjs(value).format("DD.MM.YY HH:mm")}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickValues: 4,
          tickSize: 10,
          tickPadding: 10,
          tickRotation: 0,
          legend: "",
          legendOffset: 0,
          legendPosition: "middle",
          truncateTickAt: 0,
          format: (value) => dayjs(value).format("DD.MM.YY HH:mm"),
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: "Spieleranzahl",
          legendOffset: -40,
          legendPosition: "middle",
          truncateTickAt: 0,
          format: (value) => value,
        }}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateY: 60,
            itemsSpacing: 50,
            itemDirection: "left-to-right",
            itemWidth: 100,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "transparent",
          },
        ]}
        enablePoints={false}
        enableSlices="x"
        enableCrosshair
        curve="catmullRom"
        sliceTooltip={({ slice }) => (
          <Box
            sx={{
              p: "4px 12px",
              backgroundColor: theme.palette.background.default,
              borderRadius: theme.shape.borderRadius / 3,
              boxShadow: theme.shadows[2],
            }}
          >
            <Typography>{slice.points[0].data.xFormatted}</Typography>

            {slice.points[0].data.violation.violated && (
              <Box
                m="8px 0"
                display="flex"
                alignItems="center"
                color="warning.main"
              >
                <WarningIcon fontSize="small" sx={{ mr: 1 }} />
                <Typography>Cop Quoten Verstoß</Typography>
              </Box>
            )}

            <List dense disablePadding>
              <ListItem disablePadding alignItems="center">
                <Box
                  width="10px"
                  height="10px"
                  mr={2}
                  sx={{
                    backgroundColor: theme.palette.civ.main,
                  }}
                />
                <ListItemText primary="Zivilisten" />
                <Box display="flex" minWidth="20px" ml={2}>
                  <Typography fontWeight="bold" textAlign="right">
                    {slice.points[0].data.violation.civs}
                  </Typography>
                </Box>
              </ListItem>
              {slice.points.map((point) => (
                <ListItem disablePadding key={point.id} alignItems="center">
                  <Box
                    width="10px"
                    height="10px"
                    mr={2}
                    sx={{
                      backgroundColor: point.serieColor,
                    }}
                  />
                  <ListItemText primary={point.serieId} />
                  <Box display="flex" minWidth="20px" ml={2}>
                    <Typography fontWeight="bold" textAlign="right">
                      {point.data.y}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </List>

            <Box
              m="8px 0"
              display="flex"
              alignItems="center"
              color="info.main"
              fontSize="1em"
            >
              <InfoIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="subtitle2">
                Nicht gewertete Ränge:{" "}
                {slice.points[0].data.violation.rule.excludedRanks.join(", ")}
              </Typography>
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};
export default CopRatioViolationDetailChart;
