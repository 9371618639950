import {
  Avatar,
  Box,
  IconButton,
  TextField,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useState } from "react";
import useAuth from "hooks/useAuth";
import dayjs from "dayjs";

const Chat = ({
  messages,
  onSubmit,
  onDelete,
  placeholder = "Nachricht",
  noMessagesText = "Noch keine Nachrichten vorhanden",
  windowMinHeight,
  textInputDisabled = false,
  deleteMessageDisabled = false,
}) => {
  const [newMessage, setNewMessage] = useState("");
  const theme = useTheme();
  const { user } = useAuth();

  const MessageList = () => {
    return (
      <Box>
        {messages.map((message, index) => {
          const isAuthor = message.author._id === user._id;
          const nextAuthorIsDiffrent =
            (index < messages.length - 1 &&
              messages[index + 1].author._id !== message.author._id) ||
            index === messages.length - 1;

          const prevAuthorIsDiffrent =
            index > 0 && messages[index - 1].author._id !== message.author._id;
          const backgroundColor = isAuthor
            ? theme.palette.primary.main
            : theme.palette.background.paper;
          return (
            <Box
              mb={
                nextAuthorIsDiffrent && index !== messages.length - 1 && "16px"
              }
              key={message._id}
              display="flex"
              alignItems="end"
              justifyContent={isAuthor ? "flex-end" : "flex-start"}
            >
              {!isAuthor && nextAuthorIsDiffrent ? (
                <Avatar
                  alt="steam-avatar"
                  src={message.author?.steamData?.avatarmedium}
                  sx={{
                    mr: "8px",
                    mb: "4px",
                  }}
                />
              ) : (
                <Box
                  width="40px"
                  sx={{
                    mr: "8px",
                  }}
                />
              )}
              <Box
                p="8px"
                mb="4px"
                borderRadius="15px"
                sx={{
                  backgroundColor: backgroundColor,
                  borderTopLeftRadius:
                    !isAuthor && !prevAuthorIsDiffrent && index !== 0 && "0px",
                  borderBottomLeftRadius:
                    !isAuthor && !nextAuthorIsDiffrent && "0px",
                  borderTopRightRadius:
                    isAuthor && !prevAuthorIsDiffrent && index !== 0 && "0px",
                  borderBottomRightRadius:
                    isAuthor && !nextAuthorIsDiffrent && "0px",
                }}
              >
                {!isAuthor && (
                  <Box mb="6px">
                    <Typography color="primary" variant="body2">
                      {message.author.name}
                    </Typography>
                  </Box>
                )}
                <Typography
                  color={theme.palette.getContrastText(backgroundColor)}
                  whiteSpace="pre-line"
                  sx={{
                    maxWidth: "800px",
                  }}
                >
                  {message.message}
                </Typography>
                <Box
                  display="flex"
                  justifyContent={isAuthor ? "flex-end" : "flex-start"}
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    color={alpha(
                      theme.palette.getContrastText(backgroundColor),
                      0.6
                    )}
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    {dayjs(message.createdAt).format("DD.MM.YYYY HH:mm")}
                  </Typography>
                  {isAuthor && !!onDelete && (
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => onDelete(message._id)}
                      disabled={deleteMessageDisabled}
                    >
                      <DeleteOutlinedIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column-reverse"
        borderRadius={theme.shape.borderRadius}
        sx={{ backgroundColor: "background.default" }}
        padding="32px 16px 16px 16px"
        minHeight={windowMinHeight && `${windowMinHeight}px`}
        maxHeight="500px"
        overflow="auto"
      >
        {messages?.length > 0 ? (
          <MessageList />
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={windowMinHeight && `${windowMinHeight - 32}px`}
          >
            <Typography>{noMessagesText}</Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center" mt="16px">
        <TextField
          multiline
          disabled={textInputDisabled}
          maxRows={10}
          fullWidth
          placeholder={placeholder}
          value={newMessage}
          onChange={(event) => setNewMessage(event.target.value)}
          InputProps={{
            sx: {
              borderRadius: theme.shape.borderRadius,
            },
          }}
          inputProps={{
            maxLength: 1000,
          }}
        />
        <IconButton
          size="large"
          color="primary"
          sx={{ m: "0 8px" }}
          onClick={() => {
            onSubmit(newMessage);
            setNewMessage("");
          }}
          disabled={!!!newMessage}
        >
          <SendOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </Box>
  );
};
export default Chat;
