import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useGetRecruitmentPhasesQuery } from "features/recruitmentPhases/recruitmentPhasesApiSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InfoChip from "./InfoChip";

const getRecruitmentPhaseTypeValue = (recruitmentPhase) =>
  ({
    Hauptfraktion: -1,
    Zweitfraktion: 1,
  }[recruitmentPhase] ?? 0);

const RecruitmentPhaseInfoDialog = ({ phase, open, onClose }) => {
  const today = dayjs();
  const isBetween = today.isAfter(phase?.from) && today.isBefore(phase?.until);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{phase?.name}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} columns={5} maxWidth="500px">
          <Grid item xs={2}>
            <InputLabel>Status</InputLabel>
          </Grid>
          <Grid item xs={3}>
            <Chip
              size="small"
              color={isBetween ? "success" : "error"}
              label={isBetween ? "Offen" : "Geschlossen"}
            />
          </Grid>
          <Grid item xs={2}>
            <InputLabel>Zeitraum</InputLabel>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              {`${dayjs(phase?.from).format("DD.MM.YYYY HH:mm")} - ${dayjs(
                phase?.until
              ).format("DD.MM.YYYY HH:mm")}`}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <InputLabel>Zulässiger Bewerbungstyp</InputLabel>
          </Grid>
          <Grid item xs={3}>
            <Typography>{phase?.allowedApplicationType}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const PhaseNavigationListItem = ({ phase }) => {
  const today = dayjs();
  const isBetween = today.isAfter(phase?.from) && today.isBefore(phase?.until);

  const [open, setOpen] = useState(false);

  return (
    <Box display="flex" alignItems="center">
      <Chip
        icon={
          <Tooltip
            arrow
            color={isBetween ? "success" : "error"}
            title={
              <Typography>
                {isBetween
                  ? "Einstellungsphase ist geöffnet"
                  : "Einstellungsphase ist geschlossen"}
              </Typography>
            }
          >
            {isBetween ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}
          </Tooltip>
        }
        onClick={() => setOpen(true)}
        label={
          <Box display="flex">
            <Typography sx={{ mr: "4px" }}>{phase?.name}</Typography>
            <HelpOutlineOutlinedIcon color="info" />
          </Box>
        }
      />
      <RecruitmentPhaseInfoDialog
        phase={phase}
        open={open}
        onClose={() => setOpen(false)}
      />
    </Box>
  );
};

const PhaseNavigationList = ({ phases }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(0);
  }, [phases]);

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        disabled={index === 0}
        size="small"
        onClick={() => setIndex((index) => index - 1)}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <PhaseNavigationListItem phase={phases[index]} />
      <IconButton
        disabled={index === phases?.length - 1}
        size="small"
        onClick={() => setIndex((index) => index + 1)}
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

const ApplyTopBar = () => {
  const navigate = useNavigate();

  const {
    data: recruitmentPhases,
    isSuccess: getRecruitmentPhasesIsSuccess,
    isLoading: getRecruitmentPhasesIsLoading,
  } = useGetRecruitmentPhasesQuery("recruitmentPhasesList", {});

  const [loading, setIsLoadig] = useState(true);
  const [selectedPhaseType, setSelectedPhaseType] = useState("open");
  const [openPhases, setOpenPhases] = useState([]);
  const [upcomingPhases, setUpcomingPhases] = useState([]);

  useEffect(() => {
    if (getRecruitmentPhasesIsSuccess) {
      const today = dayjs();

      setOpenPhases(
        recruitmentPhases.ids
          .filter(
            (id) =>
              today.isAfter(recruitmentPhases.entities[id].from) &&
              today.isBefore(recruitmentPhases.entities[id].until)
          )
          .map((id) => recruitmentPhases.entities[id])
          .sort(
            (a, b) =>
              getRecruitmentPhaseTypeValue(a.allowedApplicationType) -
              getRecruitmentPhaseTypeValue(b.allowedApplicationType)
          )
      );

      setUpcomingPhases(
        recruitmentPhases.ids
          .filter((id) => today.isBefore(recruitmentPhases.entities[id].from))
          .map((id) => recruitmentPhases.entities[id])
          .sort(
            (a, b) =>
              getRecruitmentPhaseTypeValue(a.allowedApplicationType) -
              getRecruitmentPhaseTypeValue(b.allowedApplicationType)
          )
      );

      setIsLoadig(false);
    }
    // eslint-disable-next-line
  }, [recruitmentPhases, getRecruitmentPhasesIsSuccess]);

  useEffect(() => {
    setSelectedPhaseType(openPhases.length !== 0 ? "open" : "upcoming");
  }, [openPhases]);

  return (
    <Box display="flex" alignItems="center">
      {loading || getRecruitmentPhasesIsLoading ? (
        <Box display="flex" alignItems="center">
          <CircularProgress size="2em" />
        </Box>
      ) : openPhases.length === 0 && upcomingPhases.length === 0 ? (
        <InfoChip label="Aktuell ist keine Einstellungsphase geöffnet" />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md="auto" display="flex" justifyContent="center">
            <Box width="250px">
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                select
                value={selectedPhaseType}
                onChange={(event) => setSelectedPhaseType(event.target.value)}
              >
                <MenuItem
                  key="open"
                  value="open"
                  disabled={openPhases.length === 0}
                >
                  Offene Einstellungsphasen
                </MenuItem>
                <MenuItem
                  key="upcoming"
                  value="upcoming"
                  disabled={upcomingPhases.length === 0}
                >
                  Bevorstehende Einstellungsphasen
                </MenuItem>
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md="auto" display="flex" justifyContent="center">
            <PhaseNavigationList
              phases={
                selectedPhaseType === "open" ? openPhases : upcomingPhases
              }
            />
          </Grid>

          <Grid item xs={12} md="auto" display="flex" justifyContent="center">
            <Button
              fullWidth
              startIcon={<MailOutlinedIcon />}
              variant="contained"
              onClick={() => navigate("/apply")}
              disabled={
                openPhases.length === 0 || selectedPhaseType === "upcoming"
              }
            >
              Bewerben
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default ApplyTopBar;
