import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FaDiscord } from "react-icons/fa";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useUpdateNotificationSettingsMutation } from "features/users/usersApiSlice";
import useAuth from "hooks/useAuth";
import { hasPermission } from "util/permissionHelper";

const SettingsDialog = ({ open, onClose }) => {
  const [updateNotificationSettings] = useUpdateNotificationSettingsMutation();

  const { user, roles } = useAuth();

  const [notificationSettings, setNotificationSettings] = useState({});

  useEffect(() => {
    setNotificationSettings(user.settings?.notifications);
  }, [user]);

  const items = useMemo(() => {
    const items = [];

    if (hasPermission({ roles, permissions: ["sendApplications"] })) {
      items.push(
        ...[
          <Grid item xs={4} key={0}>
            <InputLabel>Antworten auf Bewerbungen</InputLabel>
          </Grid>,
          <Grid item xs={4} key={1}>
            <Switch
              checked={notificationSettings?.applicationMessage?.app}
              onChange={(_, value) => {
                updateNotificationSettings({
                  ...notificationSettings,
                  applicationMessage: {
                    app: value,
                    discord: notificationSettings?.applicationMessage?.discord,
                  },
                });
              }}
            />
          </Grid>,
          <Grid item xs={2} key={2}>
            <Switch
              checked={notificationSettings?.applicationMessage?.discord}
              onChange={(_, value) => {
                updateNotificationSettings({
                  ...notificationSettings,
                  applicationMessage: {
                    discord: value,
                    app: notificationSettings?.applicationMessage?.app,
                  },
                });
              }}
            />
          </Grid>,
        ]
      );
    }

    if (hasPermission({ roles, permissions: ["copBasic"] })) {
      items.push(
        ...[
          <Grid item xs={4} key={3}>
            <InputLabel>EP Kalkulation</InputLabel>
          </Grid>,
          <Grid item xs={4} key={4}>
            <Switch
              checked={notificationSettings?.expCalculation?.app}
              onChange={(_, value) => {
                updateNotificationSettings({
                  ...notificationSettings,
                  expCalculation: {
                    app: value,
                    discord: notificationSettings?.expCalculation?.discord,
                  },
                });
              }}
            />
          </Grid>,
          <Grid item xs={2} key={5}>
            <Switch
              checked={notificationSettings?.expCalculation?.discord}
              onChange={(_, value) => {
                updateNotificationSettings({
                  ...notificationSettings,
                  expCalculation: {
                    discord: value,
                    app: notificationSettings?.expCalculation?.app,
                  },
                });
              }}
            />
          </Grid>,

          <Grid item xs={4} key={6}>
            <InputLabel>Bewertungsanforderungen</InputLabel>
          </Grid>,
          <Grid item xs={4} key={7}>
            <Switch
              checked={notificationSettings?.ratingRequest?.app}
              onChange={(_, value) => {
                updateNotificationSettings({
                  ...notificationSettings,
                  ratingRequest: {
                    app: value,
                    discord: !value
                      ? true
                      : notificationSettings?.ratingRequest?.discord,
                  },
                });
              }}
            />
          </Grid>,
          <Grid item xs={2} key={8}>
            <Switch
              checked={notificationSettings?.ratingRequest?.discord}
              onChange={(_, value) => {
                updateNotificationSettings({
                  ...notificationSettings,
                  ratingRequest: {
                    discord: value,
                    app: !value
                      ? true
                      : notificationSettings?.ratingRequest?.app,
                  },
                });
              }}
            />
          </Grid>,

          <Grid item xs={4} key={9}>
            <InputLabel>Statusänderung Urlaubsantrag</InputLabel>
          </Grid>,
          <Grid item xs={4} key={10}>
            <Switch
              checked={notificationSettings?.vacationUpdate?.app}
              onChange={(_, value) => {
                updateNotificationSettings({
                  ...notificationSettings,
                  vacationUpdate: {
                    app: value,
                    discord: notificationSettings?.vacationUpdate?.discord,
                  },
                });
              }}
            />
          </Grid>,
          <Grid item xs={2} key={11}>
            <Switch
              checked={notificationSettings?.vacationUpdate?.discord}
              onChange={(_, value) => {
                updateNotificationSettings({
                  ...notificationSettings,
                  vacationUpdate: {
                    discord: value,
                    app: notificationSettings?.vacationUpdate?.app,
                  },
                });
              }}
            />
          </Grid>,
        ]
      );
    }

    return items;
  }, [roles, notificationSettings, updateNotificationSettings]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Einstellungen</DialogTitle>
      <DialogContent>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Typography fontWeight="bold">Benachrichtigungen</Typography>
          </Grid>
          <Grid item xs={4}>
            <Box
              display="flex"
              alignItems="center"
              height="100%"
              fontWeight="bold"
            >
              App
              <NotificationsIcon
                sx={{
                  ml: "4px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              display="flex"
              alignItems="center"
              height="100%"
              fontWeight="bold"
            >
              Discord
              <FaDiscord
                size="1.25rem"
                style={{
                  marginLeft: "8px",
                }}
              />
            </Box>
          </Grid>
          {items}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const Settings = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <SettingsOutlinedIcon />
      </IconButton>
      <SettingsDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};
export default Settings;
