import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useGetCopRatioViolationsQuery,
  useSetCopRatioViolationUndoneMutation,
} from "./copRatioViolationsApiSlice";
import { Link } from "react-router-dom";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import { dataGridSx } from "styles/dataGridSx";
import { calcGridHeightSubtraction } from "util/calculations";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import CustomGridPagination from "components/CustomGridPagination";
import CloseIcon from "@mui/icons-material/Close";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import IsOnlineBadge from "components/IsOnlineBadge";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import DoneIcon from "@mui/icons-material/Done";
import PlaytimeDialog from "features/playtimes/PlaytimeDialog";
import CopRatioViolationDoneDialog from "./CopRatioViolationDoneDialog";
import { useSnackbar } from "contexts/Snackbar.context";
import CopRatioViolationDetailDialog from "./CopRatioViolationDetailDialog";

dayjs.extend(duration);

const calculateViolationDuration = ({ violations }) => {
  let sumMs = 0;
  let start = undefined;

  for (const [index, violation] of violations.entries()) {
    if (!start && violation.violated) {
      start = violation.createdAt;
    }

    if (
      (start && !violation.violated) ||
      (index === violations.length - 1 && violation.violated)
    ) {
      sumMs += dayjs(violation.createdAt).diff(dayjs(start));

      start = undefined;
    }
  }

  return sumMs;
};

const renderCopName = ({ value, row }) => (
  <Link to={`/cops/${row.cop?._id}`} style={{ textDecoration: "none" }}>
    <Box color="info.main">{value}</Box>
  </Link>
);

const renderActive = ({ value }) =>
  value ? (
    <IsOnlineBadge
      variant="dot"
      badgeContent={1}
      color="warning"
      overlap="circular"
    >
      <AutorenewOutlinedIcon
        color="primary"
        sx={{
          animation: "spin 2s linear infinite",
          "@keyframes spin": {
            "0%": {
              transform: "rotate(0deg)",
            },
            "100%": {
              transform: "rotate(360deg)",
            },
          },
        }}
      />
    </IsOnlineBadge>
  ) : (
    <CloseIcon color="disabled" />
  );

const staticColumns = [
  {
    field: "copName",
    headerName: "Name",
    valueGetter: ({ row }) => row.cop?.user?.name,
    renderCell: renderCopName,
    flex: 1,
    maxWidth: 200,
  },
  {
    field: "active",
    headerName: "Aktiv",
    type: "boolean",
    renderCell: renderActive,
  },
  {
    field: "createdAt",
    headerName: "Bemerkt am",
    ...dateTimeColumnType,
    flex: 1,
    maxWidth: 120,
  },
  {
    field: "playtimeStart",
    headerName: "Beginn Spielzeit",
    ...dateTimeColumnType,
    flex: 1,
    maxWidth: 120,
  },
  {
    field: "playtimeEnd",
    headerName: "Ende Spielzeit",
    ...dateTimeColumnType,
    flex: 1,
    maxWidth: 120,
  },
  {
    field: "playtimeDuration",
    headerName: "Spieldauer",
    type: "number",
    valueFormatter: ({ value }) =>
      value >= 0 ? dayjs.duration(value).format("HH[h] mm[m]") : "",
    flex: 1,
    maxWidth: 120,
  },
  {
    field: "violationDuration",
    headerName: "Dauer des Verstoßes",
    type: "number",
    valueFormatter: ({ value }) => dayjs.duration(value).format("HH[h] mm[m]"),
    flex: 1,
    maxWidth: 120,
  },
  {
    field: "done",
    headerName: "Erledigt",
    type: "boolean",
    valueGetter: ({ value }) => !!value,
    renderCell: ({ value }) => (
      <Checkbox
        checked={value}
        disableRipple
        sx={{
          ":hover": {
            cursor: "default",
          },
        }}
      />
    ),
  },
  {
    field: "doneAt",
    headerName: "Erledigt am",
    ...dateTimeColumnType,
    flex: 1,
    maxWidth: 120,
  },
  {
    field: "doneBy",
    headerName: "Ereldigt von",
    flex: 1,
    maxWidth: 200,
  },
  {
    field: "approved",
    headerName: "Genehmigt",
    type: "boolean",
    renderCell: ({ value }) =>
      value ? <DoneIcon color="success" /> : <CloseIcon color="error" />,
  },
  {
    field: "comment",
    headerName: "Kommentar",
    flex: 1,
  },
];

const CopRatioViolationsDataGrid = () => {
  const theme = useTheme();
  const showSnackbar = useSnackbar();

  const [selectDoneViolations, setSelectDoneViolations] = useState(false);

  const {
    data: copRatioViolations,
    isSuccess,
    isLoading,
  } = useGetCopRatioViolationsQuery(
    selectDoneViolations ? undefined : { hideDone: true },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 60000, // 1 Minute
    }
  );

  const [setViolationUndone, { isLoading: setUndoneIsLoading }] =
    useSetCopRatioViolationUndoneMutation();

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setRows(
        copRatioViolations.ids.map((id) => {
          const copRatioViolation = copRatioViolations.entities[id];

          return {
            ...copRatioViolation,
            playtimeStart: copRatioViolation.playtime?.start,
            playtimeEnd: copRatioViolation.playtime?.end,
            playtimeDuration: !!copRatioViolation.playtime?.end
              ? dayjs(copRatioViolation.playtime?.end).diff(
                  dayjs(copRatioViolation.playtime?.start)
                )
              : -1,
            violationDuration: calculateViolationDuration({
              violations: copRatioViolation.violations,
            }),
            doneAt: copRatioViolation.done?.at,
            doneBy: copRatioViolation.done?.by?.name,
            approved: copRatioViolation.done?.approved,
            comment: copRatioViolation.done?.comment,
          };
        })
      );
    }
  }, [isSuccess, copRatioViolations]);

  const [playtimeDialogOpen, setPlaytimeDialogOpen] = useState(false);
  const [playtime, setPlaytime] = useState({});

  const [doneDialogOpen, setDoneDialogOpen] = useState(false);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [violation, setViolation] = useState({});

  const columns = useMemo(
    () => [
      ...staticColumns,
      {
        field: "actions",
        type: "actions",
        getActions: ({ row, id }) => {
          const actions = [];

          actions.push(
            <GridActionsCellItem
              showInMenu
              icon={<TimelineOutlinedIcon color="primary" />}
              label="Verstoßzeiten"
              onClick={() => {
                setViolation(row);
                setDetailDialogOpen(true);
              }}
              sx={{
                color: "primary.main",
              }}
            />
          );

          if (!row.active) {
            actions.push(
              <GridActionsCellItem
                showInMenu
                icon={<AccessTimeOutlinedIcon color="primary" />}
                label="Spielzeit"
                onClick={() => {
                  setPlaytime(row.playtime || {});
                  setPlaytimeDialogOpen(true);
                }}
                sx={{
                  color: "primary.main",
                }}
              />
            );

            if (row.done) {
              actions.push(
                <GridActionsCellItem
                  showInMenu
                  icon={
                    setUndoneIsLoading ? (
                      <CircularProgress size="1em" color="inherit" />
                    ) : (
                      <CloseIcon color="error" />
                    )
                  }
                  label="Unerledigt"
                  onClick={() => {
                    setViolationUndone(id)
                      .unwrap()
                      .then(() => {
                        showSnackbar("Verstoß auf unerledigt gesetzt");
                      })
                      .catch((error) => {
                        showSnackbar(error.data?.message || "Fehler", "error");
                      });
                  }}
                  disabled={setUndoneIsLoading}
                  sx={{
                    color: "error.main",
                  }}
                />
              );
            } else {
              actions.push(
                <GridActionsCellItem
                  showInMenu
                  icon={<DoneIcon color="success" />}
                  label="Erledigt"
                  onClick={() => {
                    setViolation(row);
                    setDoneDialogOpen(true);
                  }}
                  sx={{
                    color: "success.main",
                  }}
                />
              );
            }
          }

          return actions;
        },
      },
    ],
    [setUndoneIsLoading, setViolationUndone, showSnackbar]
  );

  const GridToolbar = useCallback(() => {
    return (
      <GridToolbarContainer>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{
            mr: 4,
          }}
        >
          Verstöße
        </Typography>
        <GridToolbarQuickFilter />
        <FormControlLabel
          control={
            <Switch
              checked={selectDoneViolations}
              onChange={(_, checked) => setSelectDoneViolations(checked)}
            />
          }
          label="Erledigte Verstöße anzeigen"
          sx={{
            userSelect: "none",
            color: "text.secondary",
          }}
        />
      </GridToolbarContainer>
    );
  }, [selectDoneViolations]);

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(
      calcGridHeightSubtraction() +
        (document.getElementById("staff-cockpit-tabs")?.offsetHeight || 0) +
        16
    );
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        rows={rows}
        loading={isLoading}
        rowSelection={false}
        slots={{
          pagination: CustomGridPagination,
          toolbar: GridToolbar,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
      />
      <CopRatioViolationDetailDialog
        open={detailDialogOpen}
        onClose={() => setDetailDialogOpen(false)}
        violation={violation}
      />
      <PlaytimeDialog
        open={playtimeDialogOpen}
        onClose={() => setPlaytimeDialogOpen(false)}
        playtime={playtime}
      />
      <CopRatioViolationDoneDialog
        open={doneDialogOpen}
        onClose={() => setDoneDialogOpen(false)}
        violation={violation}
      />
    </Box>
  );
};
export default CopRatioViolationsDataGrid;
