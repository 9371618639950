import TextBadge from "components/TextBadge";
import { useGetUserReceivedRequestedRatingsQuery } from "./requestedRatingsApiSlice";
import { Badge } from "@mui/material";

const ReceivedRequestedRatingsBadge = ({ isText, children, ...props }) => {
  const { data: requestedRatings, isSuccess } =
    useGetUserReceivedRequestedRatingsQuery("receivedRequestedRatingsList", {
      refetchOnMountOrArgChange: false,
    });

  const Component = isText ? TextBadge : Badge;

  return (
    <Component
      color="error"
      badgeContent={isSuccess ? requestedRatings.ids.length : 0}
      {...props}
    >
      {children}
    </Component>
  );
};
export default ReceivedRequestedRatingsBadge;
