import { Box } from "@mui/material";
import Header from "components/Header";
import PollDataGrid from "features/polls/PollDataGrid";
import { useEffect } from "react";

const Polls = () => {
  useEffect(() => {
    document.title = `Abstimmungen | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="ABSTIMMUNGEN" subtitle="Aktuelle Abstimmungen" />
      <PollDataGrid />
    </Box>
  );
};
export default Polls;
