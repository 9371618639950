import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const notificationsAdapter = createEntityAdapter();

const initialState = notificationsAdapter.getInitialState();

export const notificationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: () => ({
        url: "/notifications",
      }),
      transformResponse: (responseData) => {
        const loadedNotifications = responseData.map((notification) => {
          notification.id = notification._id;
          return notification;
        });

        loadedNotifications.sort(
          (a, b) =>
            new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
        );

        return notificationsAdapter.setAll(initialState, loadedNotifications);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Notification", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Notification", id })),
          ];
        } else return [{ type: "Notification", id: "LIST" }];
      },
    }),
    setNotificationRead: builder.mutation({
      query: (ids) => ({
        url: "/notifications/set-read",
        method: "PATCH",
        body: { ids },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Notification", id: arg.id },
      ],
    }),
  }),
});

export const { useGetNotificationsQuery, useSetNotificationReadMutation } =
  notificationsApiSlice;

export const selectNotificationsResult =
  notificationsApiSlice.endpoints.getNotifications.select();

const selectNotificationsData = createSelector(
  selectNotificationsResult,
  (notificationsResult) => notificationsResult.data
);

export const {
  selectAll: selectAllNotifications,
  selectIds: selectNotificationIds,
} = notificationsAdapter.getSelectors(
  (state) => selectNotificationsData(state) ?? initialState
);
