import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticateMutation } from "./authApiSlice";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "contexts/Snackbar.context";

const AuthCallback = () => {
  const navigate = useNavigate();
  const effectRan = useRef(false);
  const showSnackbar = useSnackbar();

  const [authenticate, { isLoading, isError, error }] =
    useAuthenticateMutation();

  useEffect(() => {
    (async () => {
      try {
        if (effectRan.current) return;
        effectRan.current = true;

        const args = new URLSearchParams(window.location.search);
        await authenticate(Object.fromEntries(args));

        navigate("/", { replace: true });
      } catch (err) {
        console.log(err);
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isError && error?.data?.message)
      showSnackbar(error?.data?.message, "error");
    // eslint-disable-next-line
  }, [error, isError, isLoading]);

  return isLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100%"
    >
      <CircularProgress color="info" size={80} />
    </Box>
  ) : (
    <></>
  );
};

export default AuthCallback;
