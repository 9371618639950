import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const controlcentersAdapter = createEntityAdapter();

const initialState = controlcentersAdapter.getInitialState();

export const controlcentersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getControlcenters: builder.query({
      query: () => ({
        url: "/controlcenters",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedControlcenters = responseData.map((controlcenter) => {
          controlcenter.id = controlcenter._id;
          return controlcenter;
        });

        return controlcentersAdapter.setAll(initialState, loadedControlcenters);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Controlcenter", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Controlcenter", id })),
          ];
        } else return [{ type: "Controlcenter", id: "LIST" }];
      },
    }),
    addControlcenter: builder.mutation({
      query: (controlcenter) => ({
        url: "/controlcenters",
        method: "POST",
        body: { ...controlcenter },
      }),
      invalidatesTags: [{ type: "Controlcenter", id: "LIST" }],
    }),
    updateControlcenter: builder.mutation({
      query: (controlcenter) => ({
        url: "/controlcenters",
        method: "PATCH",
        body: { ...controlcenter },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Controlcenter", id: arg.id },
      ],
    }),
    deleteControlcenter: builder.mutation({
      query: (id) => ({
        url: "/controlcenters",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Controlcenter", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetControlcentersQuery,
  useAddControlcenterMutation,
  useUpdateControlcenterMutation,
  useDeleteControlcenterMutation,
} = controlcentersApiSlice;

export const selectControlcentersResult =
  controlcentersApiSlice.endpoints.getControlcenters.select();

const selectControlcentersData = createSelector(
  selectControlcentersResult,
  (controlcentersResult) => controlcentersResult.data
);

export const {
  selectAll: selectAllControlcenters,
  selectIds: selectControlcentersIds,
} = controlcentersAdapter.getSelectors(
  (state) => selectControlcentersData(state) ?? initialState
);
