import { useCallback, useEffect, useState } from "react";
import {
  useGetApplicationsQuery,
  useGetAvailableNumbersQuery,
} from "./applicationsApiSlice";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Switch,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { dataGridSx } from "styles/dataGridSx";
import { getApplicationStatusColor } from "util/muiColorGetter";
import CustomGridPagination from "components/CustomGridPagination";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useSnackbar } from "contexts/Snackbar.context";
import { calcGridHeightSubtraction } from "util/calculations";
import { dateTimeColumnType } from "util/dateTimeColumnType";

const renderStatus = (params) => (
  <Chip label={params.value} color={getApplicationStatusColor(params.value)} />
);

const renderIsClosed = (params) => (
  <Checkbox defaultChecked={params.value} readOnly disableRipple />
);

const columns = [
  {
    field: "user",
    headerName: "Benutzer",
    flex: 1,
  },
  {
    field: "gang",
    headerName: "Gangzugehörigkeit",
    flex: 1,
    maxWidth: 150,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: renderStatus,
    flex: 0.7,
  },
  {
    field: "isClosed",
    headerName: "Geschlossen",
    renderCell: renderIsClosed,
    flex: 0.6,
  },
  {
    field: "createdAt",
    headerName: "Erstellt am",
    ...dateTimeColumnType,
    flex: 1,
  },
  {
    field: "applicationType",
    headerName: "Bewerbungstyp",
    flex: 1,
  },
  {
    field: "recruitmentPhase",
    headerName: "Einstellungsphase",
    flex: 1,
  },
  {
    field: "recruiterComments",
    headerName: "Kommentare",
    flex: 1,
    type: "number",
    maxWidth: 100,
  },
  {
    field: "messages",
    headerName: "Nachrichten",
    flex: 1,
    type: "number",
    maxWidth: 100,
  },
];

const ApplicationDataGrid = () => {
  const theme = useTheme();
  const [showClosedApplications, setShowClosedApplications] = useState(false);
  const showSnackbar = useSnackbar();

  const {
    data: applications,
    isSuccess,
    isLoading,
  } = useGetApplicationsQuery(
    showClosedApplications ? undefined : { isClosed: false },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { refetch: refetchNumbers } = useGetAvailableNumbersQuery(
    "availableNumbersList",
    {}
  );

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (isSuccess)
      setRows(
        applications?.ids.map((id) => ({
          id: id,
          user: applications.entities[id].user.name,
          gang: applications.entities[id].gang,
          recruitmentPhase: applications.entities[id].recruitmentPhase?.name,
          applicationType: applications.entities[id].applicationType,
          status: applications.entities[id].status,
          isClosed: applications.entities[id].isClosed,
          createdAt: applications.entities[id].createdAt,
          recruiterComments:
            applications.entities[id].recruiterComments?.length || 0,
          messages: applications.entities[id].messages?.length || 0,
        }))
      );
  }, [isSuccess, applications]);

  const EditToolbar = useCallback(() => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between", pr: 0 }}>
        <Box>
          <GridToolbarQuickFilter />
          <FormControlLabel
            control={
              <Switch
                checked={showClosedApplications}
                onChange={(_, checked) => setShowClosedApplications(checked)}
              />
            }
            label="Geschlossene Bewerbungen anzeigen"
            sx={{
              userSelect: "none",
              color: "text.secondary",
              ml: 1,
            }}
          />
        </Box>
        <Button
          sx={{ mb: 1 }}
          variant="contained"
          startIcon={<ContentCopyOutlinedIcon />}
          onClick={async () => {
            const { data: availableNumbers } = await refetchNumbers();

            const clipboardText = `Für Hauptfraktionisten: ${availableNumbers
              ?.filter((number) => number < 100)
              .join(", ")}\nFür Zweitfraktionisten: ${availableNumbers
              ?.filter((number) => number >= 100)
              .join(", ")}`;

            navigator.clipboard?.writeText(clipboardText);

            showSnackbar("Freie Dienstnummern in Zwischenablage kopiert");
          }}
        >
          Freie Dienstnummern
        </Button>
      </GridToolbarContainer>
    );
    // eslint-disable-next-line
  }, [showClosedApplications]);

  const navigate = useNavigate();

  const handleRowClick = useCallback((params) => {
    navigate(`/academy/applications/${params.row.id}`);
    // eslint-disable-next-line
  }, []);

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        ".MuiDataGrid-row": {
          ":hover": {
            cursor: "pointer !important",
          },
        },
        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        rows={isSuccess ? rows : []}
        loading={isLoading}
        onRowClick={handleRowClick}
        rowSelection={false}
        slots={{
          toolbar: EditToolbar,
          pagination: CustomGridPagination,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
      />
    </Box>
  );
};
export default ApplicationDataGrid;
