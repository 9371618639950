import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const ratingsAdapter = createEntityAdapter();

const initialState = ratingsAdapter.getInitialState();

export const ratingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRatings: builder.query({
      query: () => ({
        url: "/ratings",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedRatings = responseData.map((rating) => {
          rating.id = rating._id;
          return rating;
        });

        return ratingsAdapter.setAll(initialState, loadedRatings);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Rating", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Rating", id })),
          ];
        } else return [{ type: "Rating", id: "LIST" }];
      },
    }),
    getCopRatings: builder.query({
      query: (id) => ({
        url: `/cops/${id}/ratings`,
      }),
      transformResponse: (responseData) => {
        const loadedRatings = responseData.map((rating) => {
          rating.id = rating._id;
          return rating;
        });

        return ratingsAdapter.setAll(initialState, loadedRatings);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Rating", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Rating", id })),
          ];
        } else return [{ type: "Rating", id: "LIST" }];
      },
    }),
    getUserRatings: builder.query({
      query: () => ({
        url: "/ratings/user",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedRatings = responseData.map((rating) => {
          rating.id = rating._id;
          return rating;
        });

        return ratingsAdapter.setAll(initialState, loadedRatings);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Rating", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Rating", id })),
          ];
        } else return [{ type: "Rating", id: "LIST" }];
      },
    }),
    getSentRatings: builder.query({
      query: () => ({
        url: "/ratings/sent",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedRatings = responseData.map((rating) => {
          rating.id = rating._id;
          return rating;
        });

        return ratingsAdapter.setAll(initialState, loadedRatings);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Rating", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Rating", id })),
          ];
        } else return [{ type: "Rating", id: "LIST" }];
      },
    }),
    addRating: builder.mutation({
      query: (rating) => ({
        url: "/ratings",
        method: "POST",
        body: { ...rating },
      }),
      invalidatesTags: [
        { type: "Rating", id: "LIST" },
        { type: "ReceivedRequestedRating", id: "LIST" },
      ],
    }),
    updateRating: builder.mutation({
      query: (rating) => ({
        url: "/ratings",
        method: "PATCH",
        body: { ...rating },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Rating", id: arg.id }],
    }),
    deleteRating: builder.mutation({
      query: (id) => ({
        url: "/ratings",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Rating", id: arg.id }],
    }),
  }),
});

export const {
  useGetRatingsQuery,
  useGetUserRatingsQuery,
  useGetSentRatingsQuery,
  useGetCopRatingsQuery,
  useAddRatingMutation,
  useUpdateRatingMutation,
  useDeleteRatingMutation,
} = ratingsApiSlice;

export const selectRatingsResult =
  ratingsApiSlice.endpoints.getRatings.select();

const selectRatingsData = createSelector(
  selectRatingsResult,
  (ratingsResult) => ratingsResult.data
);

export const { selectAll: selectAllRatings, selectIds: selectRatingsIds } =
  ratingsAdapter.getSelectors(
    (state) => selectRatingsData(state) ?? initialState
  );
