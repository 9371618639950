import {
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useGetCopRatioStatusQuery } from "./copRatioViolationsApiSlice";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TodayIcon from "@mui/icons-material/Today";
import dayjs from "dayjs";
import { useCallback } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import FaceIcon from "@mui/icons-material/Face";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";

const StatusChip = ({ icon, color, text, tstmp, civs, cops, chipProps }) => {
  const { isLoading, isFetching, refetch } = useGetCopRatioStatusQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const RefreshButton = useCallback(
    () => (
      <Tooltip arrow title={<Typography>Aktualisieren</Typography>}>
        <span>
          <IconButton
            disabled={isLoading || isFetching}
            size="small"
            sx={{ color: `${color}.contrastText` }}
            onClick={async () => await refetch()}
          >
            {isLoading || isFetching ? (
              <CircularProgress
                size="1em"
                sx={{
                  color: `${color}.contrastText`,
                  mr: "5px",
                }}
              />
            ) : (
              <RefreshIcon />
            )}
          </IconButton>
        </span>
      </Tooltip>
    ),
    [isLoading, isFetching, refetch, color]
  );

  return (
    <Chip
      icon={icon}
      label={
        <Typography alignItems="center" display="flex">
          {text}
          {civs >= 0 && <FaceIcon fontSize="small" sx={{ ml: 1, mr: 0.5 }} />}
          {civs >= 0 && civs}
          {cops >= 0 && (
            <LocalPoliceIcon fontSize="small" sx={{ ml: 1, mr: 0.5 }} />
          )}
          {cops >= 0 && cops}
          {tstmp && <TodayIcon fontSize="small" sx={{ ml: 1, mr: 0.5 }} />}
          {tstmp && dayjs(tstmp).format("DD.MM.YYYY HH:mm")}
        </Typography>
      }
      color={color}
      deleteIcon={<RefreshButton />}
      onDelete={() => {}}
      {...chipProps}
    />
  );
};

const CopRatioStatus = ({ chipProps }) => {
  const {
    data: copRatioStatus,
    isSuccess,
    isError,
    error,
  } = useGetCopRatioStatusQuery(undefined, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 60000, // 1 Minute
  });

  if (isError) {
    return (
      <StatusChip
        text={`Status Cop Quote: ${error?.data?.message || "Fehler"}`}
        color="error"
        icon={<ErrorOutlineIcon />}
        chipProps={chipProps}
      />
    );
  }

  if (isSuccess && !copRatioStatus.active) {
    return (
      <StatusChip
        text="Cop Quote ist ausgesetzt"
        color="success"
        icon={<CheckIcon />}
        tstmp={copRatioStatus.tstmp}
        chipProps={chipProps}
      />
    );
  }

  if (isSuccess && copRatioStatus.violated) {
    return (
      <StatusChip
        text="Cop Quote ist nicht erfüllt"
        color="error"
        icon={<CloseIcon />}
        tstmp={copRatioStatus.tstmp}
        civs={copRatioStatus.civs}
        cops={copRatioStatus.evaluatedCops}
        chipProps={chipProps}
      />
    );
  }

  if (isSuccess) {
    return (
      <StatusChip
        text="Cop Quote ist erfüllt"
        color="success"
        icon={<CheckIcon />}
        tstmp={copRatioStatus.tstmp}
        civs={copRatioStatus.civs}
        cops={copRatioStatus.evaluatedCops}
        chipProps={chipProps}
      />
    );
  }

  return <></>;
};
export default CopRatioStatus;
