import {
  Box,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  styled,
  useTheme,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { memo, useState } from "react";
import { DateTimeField } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { RichTreeView, TreeItem2 } from "@mui/x-tree-view";
import { v4 } from "uuid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const RotatedExpandIcon = styled((props) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(360deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const object2muiTree = (object) => {
  const muiTree = [];

  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      if (typeof object === "object") {
        const children = object2muiTree(object[key]);

        if (children.length === 0) {
          muiTree.push({
            id: v4(),
            label: `${key}: ${object[key]}`,
          });
        } else {
          muiTree.push({
            id: v4(),
            label: key,
            children,
          });
        }
      }
    } else {
      muiTree.push({
        id: v4(),
        label: object[key],
      });
    }
  }

  return muiTree;
};

const DocumentChangeDialog = ({ change, open, onClose }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setExpanded(false);
        onClose();
      }}
    >
      <DialogTitle display="flex" alignItems="center">
        Änderungsprotokoll
        <Chip
          size="small"
          label={change.operationType}
          color={
            {
              Anlage: "info",
              Änderung: "warning",
              Löschung: "error",
            }[change.operationType] ?? "default"
          }
          icon={
            {
              Anlage: <AddCircleOutlineOutlinedIcon />,
              Änderung: <EditIcon />,
              Löschung: <DeleteOutlinedIcon />,
            }[change.operationType] ?? <></>
          }
          sx={{
            ml: 2,
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 0 }} columns={3}>
          <Grid item xs={3} sm={1}>
            <TextField
              label="Entität"
              value={change.modelName}
              focused={false}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={3} sm={1}>
            <TextField
              label="Benutzer"
              value={change?.user?.name}
              focused={false}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={3} sm={1}>
            <DateTimeField
              label="Zeitpunkt"
              value={dayjs(change.createdAt)}
              focused={false}
              readOnly
            />
          </Grid>
          <Grid item xs={3} sm={1}>
            <TextField
              label="DB Operation"
              value={change.operation}
              focused={false}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={3} sm={2}>
            <TextField
              fullWidth
              label="Dokumenten ID"
              value={change.documentId}
              focused={false}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        {change.query && (
          <Box mt={2}>
            <InputLabel>Query</InputLabel>
            <Box mt={1}>
              <RichTreeView
                items={object2muiTree(change.query)}
                slots={{ item: TreeItem2 }}
                disableSelection
                slotProps={{
                  item: {},
                }}
              />
            </Box>
          </Box>
        )}
        {change.changedFields && (
          <Box mt={2}>
            <InputLabel>Geänderte Felder</InputLabel>
            <Box mt={1}>
              <RichTreeView
                items={object2muiTree(change.changedFields)}
                slots={{ item: TreeItem2 }}
                disableSelection
                slotProps={{
                  item: {},
                }}
              />
            </Box>
          </Box>
        )}
        {change.document && (
          <Box mt={2}>
            <Button
              onClick={() => setExpanded((value) => !value)}
              variant="contained"
              sx={{ mb: 1 }}
            >
              <Box mr="8px">Dokument</Box>
              <RotatedExpandIcon expand={expanded} />
            </Button>
            <Collapse
              in={expanded}
              timeout="auto"
              unmountOnExit={false}
              sx={{
                border: "solid 1px",
                borderColor: theme.palette.action.disabled,
                borderRadius: theme.shape.borderRadius / 3,
              }}
            >
              <RichTreeView
                items={object2muiTree(change.document)}
                slots={{ item: TreeItem2 }}
                disableSelection
                slotProps={{
                  item: {},
                }}
              />
            </Collapse>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default memo(DocumentChangeDialog);
