import { Box } from "@mui/material";
import Header from "components/Header";
import CopDataGrid from "features/cops/CopDataGrid";
import { useEffect } from "react";

const CopList = () => {
  useEffect(() => {
    document.title = `Polizisten | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="POLIZISTEN" subtitle="Aktive Mitglieder der Polizei" />

      <Box height="100%" display="flex" flexDirection="column">
        <CopDataGrid />
      </Box>
    </Box>
  );
};

export default CopList;
