import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  useDisenrollMutation,
  useEnrollMutation,
} from "features/events/eventsApiSlice";
import { Formik } from "formik";
import * as yup from "yup";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { eventTypes, ranks } from "util/copEnums";
import { useGetLearningModulesQuery } from "features/learningModules/learningModulesApiSlice";
import CopFunctions from "features/cops/CopFunctions";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import CopRankBadge from "components/CopRankBadge";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSnackbar } from "contexts/Snackbar.context";
import useAuth from "hooks/useAuth";
import { useGetCopsQuery } from "features/cops/copsApiSlice";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { hasPermission } from "util/permissionHelper";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CopsAutocomplete from "components/CopsAutocomplete";
import { memo } from "react";

const EventDialog = ({
  open,
  onClose,
  onSubmit,
  onDelete,
  initialValues,
  readOnly,
}) => {
  const { id } = initialValues;
  const { user } = useAuth();
  const showSnackbar = useSnackbar();

  const { data: learningModules, isSuccess: getLearningModulesIsSuccess } =
    useGetLearningModulesQuery("learningModulesList", {
      refetchOnMountOrArgChange: true,
    });

  const { data: cops, isSuccess: getCopIsSuccess } = useGetCopsQuery(
    "copsList",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [enroll] = useEnrollMutation();
  const [disenroll] = useDisenrollMutation();

  yup.addMethod(yup.object, "dayjs", function method(message) {
    return this.test("dayjs", message, function validate(value) {
      if (!value) {
        return true;
      }

      if (dayjs.isDayjs(value) && !!value.isValid) return value.isValid();

      return false;
    });
  });

  const eventSchema = yup.object().shape({
    title: yup.string().required("Pflichtfeld"),
    start: yup
      .object()
      .nullable()
      .required("Pflichtfeld")
      .dayjs("Ungültiges Datum"),
    end: yup
      .object()
      .nullable()
      .required("Pflichtfeld")
      .dayjs("Ungültiges Datum"),
    allDay: yup.boolean(),
    type: yup.string().required("Pflichtfeld"),
    learningModule: yup.string(),
    note: yup.string(),
    affectedFunctions: yup.array().of(yup.string()),
    affectedRanks: yup.array().of(yup.string()),
    affectionCondition: yup.string().required("Pflichtfeld"),
    organizedBy: yup.array().min(1, "Pflichtfeld").of(yup.string()),
  });

  const cops2participants = ({ copIds, participants }) =>
    copIds.map((id) => {
      const participant = participants.find(
        (participant) => id === participant?.cop?._id
      );

      return {
        cop: cops.entities[id],
        enteredAt: participant?.enteredAt,
        enteredBy: participant?.enteredBy,
        processed: participant?.processed,
        processedAt: participant?.processedAt,
        processedBy: participant?.processedBy,
      };
    });

  const handleEnroll = async () => {
    await enroll(id)
      .unwrap()
      .then(() => {
        showSnackbar("Eingeschrieben");
        onClose();
      })
      .catch((error) => showSnackbar(error.data?.message || "Fehler", "error"));
  };

  const handleDisenroll = async () => {
    await disenroll(id)
      .unwrap()
      .then(() => {
        showSnackbar("Ausgeschrieben");
        onClose();
      })
      .catch((error) => showSnackbar(error.data?.message || "Fehler", "error"));
  };

  const isEventTypeSelectable = (type) => {
    switch (type) {
      case "Modul":
        return hasPermission({
          roles: user.roles,
          permissions: ["postEventsCA", "postEventsC1+"],
        });

      case "Einsatztraining":
        return hasPermission({
          roles: user.roles,
          permissions: ["postEventsETR"],
        });

      case "Fachbereichstraining":
        return hasPermission({
          roles: user.roles,
          permissions: ["postEventsFBTR"],
        });

      case "Sonstiges":
        return hasPermission({
          roles: user.roles,
          permissions: ["postEvents"],
        });

      default:
        return false;
    }
  };

  const isModuleSelectable = (learningModule) => {
    if (learningModule.requiredForRank === "C1") {
      return hasPermission({
        roles: user.roles,
        permissions: ["postEventsCA"],
      });
    } else {
      return hasPermission({
        roles: user.roles,
        permissions: ["postEventsC1+"],
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {!!id
            ? readOnly
              ? "Veranstaltung anzeigen"
              : "Veranstaltung ändern"
            : "Neue Veranstaltung"}
          {!!onDelete && !!id && !readOnly && (
            <Button
              onClick={() => onDelete(id)}
              color="error"
              startIcon={<DeleteOutlinedIcon />}
              variant="contained"
            >
              Löschen
            </Button>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={eventSchema}
        >
          {({
            values,
            errors,
            touched,
            setFieldTouched,
            handleBlur,
            handleChange,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2} sx={{ mt: "0px" }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Titel"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    name="title"
                    error={!!touched.title && !!errors.title}
                    helperText={touched.title && errors.title}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <DateTimePicker
                    value={dayjs(values.start)}
                    name="start"
                    label="Start"
                    onChange={(value) => setFieldValue("start", value)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        onBlur: handleBlur,
                        error: !!touched.start && !!errors.start,
                        helperText: touched.start && errors.start,
                      },
                    }}
                    readOnly={readOnly}
                  />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <DateTimePicker
                    value={dayjs(values.end)}
                    name="end"
                    label="Ende"
                    onChange={(value) => setFieldValue("end", value)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        onBlur: handleBlur,
                        error: !!touched.end && !!errors.end,
                        helperText: touched.end && errors.end,
                      },
                    }}
                    readOnly={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.allDay}
                        name="allDay"
                        onChange={handleChange}
                        disabled={readOnly}
                      />
                    }
                    label="Ganztages Veranstaltung"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Art der Veranstaltung"
                    name="type"
                    value={values.type}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      if (
                        event.target.value !== "Modul" &&
                        values.type === "Modul"
                      ) {
                        setFieldValue("title", "");
                        setFieldValue("learningModule", "");
                        setFieldTouched("learningModule", false);
                      }

                      handleChange(event);
                    }}
                    error={!!touched.type && !!errors.type}
                    helperText={touched.type && errors.type}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    sx={{
                      "& .MuiSelect-icon": {
                        display: readOnly && "none",
                      },
                    }}
                  >
                    {eventTypes.map((eventType) => (
                      <MenuItem
                        key={eventType}
                        value={eventType}
                        disabled={!isEventTypeSelectable(eventType)}
                      >
                        {eventType}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Box mt={2} display={values.type === "Modul" ? "" : "none"}>
                    <TextField
                      select
                      fullWidth
                      label="Modul"
                      name="learningModule"
                      value={values.learningModule}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setFieldValue(
                          "title",
                          `${
                            learningModules.entities[event.target.value]
                              .shortcut
                          } - ${
                            learningModules.entities[event.target.value].name
                          }`
                        );

                        handleChange(event);
                      }}
                      error={
                        !!touched.learningModule && !!!values.learningModule
                      }
                      helperText={
                        touched.learningModule &&
                        !!!values.learningModule &&
                        "Pflichtfeld"
                      }
                      InputProps={{
                        readOnly: readOnly,
                      }}
                      sx={{
                        "& .MuiSelect-icon": {
                          display: readOnly && "none",
                        },
                      }}
                    >
                      {getLearningModulesIsSuccess ? (
                        learningModules.ids.map((id) => (
                          <MenuItem
                            key={id}
                            value={id}
                            disabled={
                              !isModuleSelectable(learningModules.entities[id])
                            }
                          >
                            {learningModules.entities[id].shortcut}
                          </MenuItem>
                        ))
                      ) : (
                        <Box display="flex" justifyContent="center">
                          <CircularProgress />
                        </Box>
                      )}
                    </TextField>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    label="Notiz"
                    name="note"
                    value={values.note}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!touched.note && !!errors.note}
                    helperText={touched.note && errors.note}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    inputProps={{
                      maxLength: 200,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CopFunctions
                    functions={values.affectedFunctions}
                    onChange={(_, value) => {
                      setFieldValue("affectedFunctions", value);
                    }}
                    hideInputOnReadOnly={false}
                    inputProps={{
                      limitTags: 3,
                      name: "affectedFunctions",
                      label: "Für Funktion",
                      onBlur: handleBlur,
                      error:
                        touched.affectedFunctions && !!errors.affectedFunctions,
                      helperText:
                        touched.affectedFunctions && errors.affectedFunctions,
                    }}
                    readOnly={readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label=""
                    size="small"
                    name="affectionCondition"
                    value={values.affectionCondition}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      !!touched.affectionCondition &&
                      !!errors.affectionCondition
                    }
                    helperText={
                      touched.affectionCondition && errors.affectionCondition
                    }
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip
                            arrow
                            title={
                              <Typography>
                                <ul>
                                  <li>
                                    Und: Polizist muss Funktion UND Rang
                                    erfüllen, um Veranstaltung zu sehen
                                  </li>
                                  <li>
                                    Oder: Polizist muss Funktion ODER Rang
                                    erfüllen, um Veranstaltung zu sehen
                                  </li>
                                </ul>
                                Sind keine Funktionen/Ränge angeben, dann ist
                                die Veranstaltung für alle Funktionen/Ränge
                                sichtbar
                              </Typography>
                            }
                          >
                            <HelpOutlineOutlinedIcon color="primary" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiSelect-icon": {
                        display: readOnly && "none",
                      },
                    }}
                  >
                    <MenuItem key="and" value="and">
                      Und
                    </MenuItem>
                    <MenuItem key="or" value="or">
                      Oder
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="affectedRanks"
                    fullWidth
                    disableCloseOnSelect
                    multiple
                    noOptionsText="Keine Optionen"
                    readOnly={readOnly}
                    forcePopupIcon={!readOnly}
                    limitTags={3}
                    value={values.affectedRanks}
                    options={Object.keys(ranks).filter((rank) => rank !== "AD")}
                    onChange={(_, value) =>
                      setFieldValue("affectedRanks", value)
                    }
                    onBlur={handleBlur}
                    renderTags={(value, getTagProps) =>
                      value.map((rank, index) => (
                        <Box key={rank} m="4px">
                          <CopRankBadge rank={rank} hideTitle />
                        </Box>
                      ))
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        <CopRankBadge rank={option} />
                      </li>
                    )}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        error={touched.affectedRanks && !!errors.affectedRanks}
                        helperText={
                          touched.affectedRanks && errors.affectedRanks
                        }
                        label="Für Rang"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CopsAutocomplete
                    cops={cops}
                    autocompleteProps={{
                      id: "organizedBy",
                      limitTags: 3,
                      readOnly: readOnly,
                      forcePopupIcon: !readOnly,
                      value: values.organizedBy,
                      onChange: (_, value) =>
                        setFieldValue("organizedBy", value),
                      onBlur: handleBlur,
                    }}
                    inputProps={{
                      error: touched.organizedBy && !!errors.organizedBy,
                      helperText: touched.organizedBy && errors.organizedBy,
                      label: "Organisatoren",
                    }}
                  />
                </Grid>
                {!!id && (
                  <>
                    <Grid item xs={12}>
                      <Autocomplete
                        id="participants"
                        fullWidth
                        disableCloseOnSelect
                        multiple
                        noOptionsText="Keine Optionen"
                        freeSolo
                        limitTags={3}
                        readOnly={readOnly}
                        forcePopupIcon={!readOnly}
                        value={values.participants}
                        options={
                          getCopIsSuccess
                            ? cops2participants({
                                copIds: cops.ids,
                                participants: values.participants,
                              })
                            : []
                        }
                        onChange={(_, value) => {
                          setFieldValue("participants", [
                            ...values.participants.filter(
                              (participant) => participant?.processed
                            ),
                            ...value.filter(
                              (participant) => !participant?.processed
                            ),
                          ]);
                        }}
                        onBlur={handleBlur}
                        getOptionLabel={(option) => option?.cop?.user?.name}
                        isOptionEqualToValue={(option, value) =>
                          option.cop?._id === value.cop?._id
                        }
                        renderTags={(value, getTagProps) =>
                          value.map((participant, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              label={participant?.cop?.user?.name}
                              disabled={participant?.processed}
                            />
                          ))
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            <Box
                              display="flex"
                              width="100%"
                              alignItems="center"
                              justifyContent="space-between"
                              mr="16px"
                            >
                              <Box ml="8px">{option.cop?.user?.name}</Box>
                              <Box display="flex" alignItems="center">
                                <Tooltip
                                  arrow
                                  title={
                                    <Typography>Eingeschrieben am</Typography>
                                  }
                                >
                                  <Box
                                    display={
                                      !!option.enteredAt ? "flex" : "none"
                                    }
                                    alignItems="center"
                                  >
                                    <TodayOutlinedIcon
                                      sx={{ mr: "4px" }}
                                      color="primary"
                                    />

                                    {dayjs(option.enteredAt).format(
                                      "DD.MM.YYYY HH:mm"
                                    )}
                                  </Box>
                                </Tooltip>

                                <Tooltip
                                  sx={{ ml: "16px" }}
                                  arrow
                                  title={
                                    <Typography>Eingeschrieben von</Typography>
                                  }
                                >
                                  <Box
                                    display={
                                      !!option.enteredBy?.name ? "flex" : "none"
                                    }
                                    alignItems="center"
                                  >
                                    <PersonOutlinedIcon
                                      sx={{ mr: "4px" }}
                                      color="primary"
                                    />

                                    {option.enteredBy?.name}
                                  </Box>
                                </Tooltip>

                                <Tooltip
                                  arrow
                                  slotProps={{
                                    tooltip: {
                                      sx: {
                                        backgroundColor: "background.paper",
                                      },
                                    },
                                  }}
                                  title={
                                    <Grid container columns={2} rowSpacing={1}>
                                      <Grid item xs={2}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              defaultChecked={option.processed}
                                              disabled
                                            />
                                          }
                                          label="Verarbeitet"
                                        />
                                      </Grid>
                                      {option.processed && (
                                        <>
                                          <Grid item xs={1}>
                                            <InputLabel>
                                              Verarbeitet von
                                            </InputLabel>
                                          </Grid>
                                          <Grid item xs={1}>
                                            <Typography color="text.primary">
                                              {option.processedBy?.name}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={1}>
                                            <InputLabel color="primary">
                                              Verarbeitet am
                                            </InputLabel>
                                          </Grid>
                                          <Grid item xs={1}>
                                            <Typography color="text.primary">
                                              {!!option.processedAt
                                                ? dayjs(
                                                    option.processedAt
                                                  ).format("DD.MM.YYYY HH:mm")
                                                : ""}
                                            </Typography>
                                          </Grid>
                                        </>
                                      )}
                                    </Grid>
                                  }
                                >
                                  <HelpOutlineOutlinedIcon
                                    sx={{ ml: "16px" }}
                                    color="primary"
                                  />
                                </Tooltip>
                              </Box>
                            </Box>
                          </li>
                        )}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            error={
                              touched.participants && !!errors.participants
                            }
                            helperText={
                              touched.participants && errors.participants
                            }
                            label="Teilnehmer"
                          />
                        )}
                      />
                    </Grid>
                    {values.start.isAfter(dayjs()) && (
                      <>
                        <Grid item xs={12} xl={6}>
                          <Button
                            variant="contained"
                            color="success"
                            fullWidth
                            startIcon={<CheckOutlinedIcon />}
                            disabled={values.participants.some(
                              (participant) =>
                                participant?.cop?._id === user?.copData?._id
                            )}
                            onClick={handleEnroll}
                          >
                            Teilnehmen
                          </Button>
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <Button
                            variant="contained"
                            color="warning"
                            fullWidth
                            startIcon={<CloseOutlinedIcon />}
                            disabled={
                              !values.participants.some(
                                (participant) =>
                                  participant?.cop?._id === user.copData?._id
                              )
                            }
                            onClick={handleDisenroll}
                          >
                            Nicht mehr Teilnehmen
                          </Button>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Divider sx={{ mb: "16px", mt: "32px" }} />
                      <Grid container direction="row" rowSpacing={1}>
                        <Grid item xs={2}>
                          <InputLabel>Geändert am</InputLabel>
                        </Grid>
                        <Grid item xs={2.5}>
                          <Typography textAlign="left">
                            {dayjs(initialValues.updatedAt).format(
                              "DD.MM.YYYY HH:mm"
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={7.5}>
                          <Typography textAlign="left" display="flex">
                            <InputLabel sx={{ mr: "8px" }}>von</InputLabel>
                            {initialValues.updatedBy?.name}
                          </Typography>
                        </Grid>

                        <Grid item xs={2}>
                          <InputLabel>Erstellt am</InputLabel>
                        </Grid>
                        <Grid item xs={2.5}>
                          <Typography textAlign="left">
                            {dayjs(initialValues.createdAt).format(
                              "DD.MM.YYYY HH:mm"
                            )}
                          </Typography>
                        </Grid>

                        <Grid item xs={7.5}>
                          <Typography textAlign="left" display="flex">
                            <InputLabel sx={{ mr: "8px" }}>von</InputLabel>
                            {initialValues.createdBy?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                {!readOnly && (
                  <Grid item xs={12}>
                    {values.end.isBefore(dayjs()) &&
                      values.type !== "Sonstiges" &&
                      !!id && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.processEvent}
                              name="processEvent"
                              onChange={handleChange}
                              disabled={readOnly}
                            />
                          }
                          label={
                            values.type === "Modul"
                              ? "Modul bei Teilnehmern eintragen"
                              : "Erfahrungspunkte verteilen"
                          }
                        />
                      )}
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      startIcon={
                        !!id ? <SaveOutlinedIcon /> : <EventOutlinedIcon />
                      }
                    >
                      {!!id ? "Speichern" : "Erstellen"}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default memo(EventDialog);
