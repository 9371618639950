import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import { Chip } from "@mui/material";

const PlayerSideChip = ({ side, ...props }) => {
  return (
    <Chip
      icon={side === "cop" ? <LocalPoliceOutlinedIcon /> : <FaceOutlinedIcon />}
      color={side}
      label={side === "cop" ? "Polizist" : "Zivilist"}
      {...props}
    />
  );
};
export default PlayerSideChip;
