import { Box, useTheme } from "@mui/material";
import {
  useDeleteSubmittedQuestionMutation,
  useGetSubmittedQuestionsQuery,
} from "./questionsApiSlice";
import { memo, useCallback, useEffect, useState } from "react";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import { useSnackbar } from "contexts/Snackbar.context";
import { dataGridSx } from "styles/dataGridSx";
import CustomGridPagination from "components/CustomGridPagination";

const QuestionsDataGrid = ({ onCreateFAQ }) => {
  const theme = useTheme();
  const showSnackbar = useSnackbar();

  const {
    data: questions,
    isSuccess,
    isLoading,
  } = useGetSubmittedQuestionsQuery("questionsList", {
    refetchOnMountOrArgChange: true,
  });

  const [deleteSubmittedQuestion] = useDeleteSubmittedQuestionMutation();

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setRows(questions.ids.map((id) => questions.entities[id]));
    }
  }, [questions, isSuccess]);

  const showConfirmDialog = useConfirmDialog();

  const onDelete = useCallback(async (id) => {
    try {
      await deleteSubmittedQuestion(id).unwrap();
      showSnackbar("Frage gelöscht");
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = (id) => () => {
    showConfirmDialog({
      title: "Frage löschen?",
      message: "Soll die Frage gelöscht werden?",
      onConfirm: () => onDelete(id),
    });
  };

  const columns = [
    {
      field: "question",
      headerName: "Frage",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Eingereicht am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "createdBy",
      headerName: "Eingereicht von",
      valueGetter: ({ value }) => value.name,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "actions",
      headerName: "Aktionen",
      type: "actions",
      getActions: ({ id, row }) => [
        <GridActionsCellItem
          icon={<AddOutlinedIcon />}
          label="Frage ins FAQ aufnehmen"
          onClick={() => onCreateFAQ(row.question, id)}
          color="primary"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Löschen"
          onClick={handleDeleteClick(id)}
          color="error"
        />,
      ],
    },
  ];

  return (
    <Box height="400px" sx={dataGridSx(theme)}>
      <DataGrid
        columns={columns}
        loading={isLoading}
        rows={isSuccess ? rows : []}
        slots={{ pagination: CustomGridPagination }}
        disableRowSelectionOnClick
      />
    </Box>
  );
};
export default memo(QuestionsDataGrid);
