import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const requestedRatingsAdapter = createEntityAdapter();

const initialState = requestedRatingsAdapter.getInitialState();

export const requestedRatingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRequestedRatings: builder.query({
      query: () => ({
        url: "/requested-ratings",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedRequestedRatings = responseData.map((requestedRating) => {
          requestedRating.id = requestedRating._id;
          return requestedRating;
        });

        return requestedRatingsAdapter.setAll(
          initialState,
          loadedRequestedRatings
        );
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "RequestedRating", id: "LIST" },
            ...result.ids.map((id) => ({ type: "RequestedRating", id })),
          ];
        } else return [{ type: "RequestedRating", id: "LIST" }];
      },
    }),
    getUserSentRequestedRatings: builder.query({
      query: () => ({
        url: "/requested-ratings/sent",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedRequestedRatings = responseData.map((requestedRating) => {
          requestedRating.id = requestedRating._id;
          return requestedRating;
        });

        return requestedRatingsAdapter.setAll(
          initialState,
          loadedRequestedRatings
        );
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "SentRequestedRating", id: "LIST" },
            ...result.ids.map((id) => ({ type: "SentRequestedRating", id })),
          ];
        } else return [{ type: "SentRequestedRating", id: "LIST" }];
      },
    }),
    getUserReceivedRequestedRatings: builder.query({
      query: () => ({
        url: "/requested-ratings/received",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedRequestedRatings = responseData.map((requestedRating) => {
          requestedRating.id = requestedRating._id;
          return requestedRating;
        });

        return requestedRatingsAdapter.setAll(
          initialState,
          loadedRequestedRatings
        );
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "ReceivedRequestedRating", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "ReceivedRequestedRating",
              id,
            })),
          ];
        } else return [{ type: "ReceivedRequestedRating", id: "LIST" }];
      },
    }),
    addRequestedRating: builder.mutation({
      query: (requestedRating) => ({
        url: "/requested-ratings",
        method: "POST",
        body: { ...requestedRating },
      }),
      invalidatesTags: [
        { type: "RequestedRating", id: "LIST" },
        { type: "SentRequestedRating", id: "LIST" },
      ],
    }),
    deleteRequestedRating: builder.mutation({
      query: (id) => ({
        url: "/requested-ratings",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "RequestedRating", id: arg.id },
        { type: "SentRequestedRating", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetRequestedRatingsQuery,
  useGetUserReceivedRequestedRatingsQuery,
  useGetUserSentRequestedRatingsQuery,
  useAddRequestedRatingMutation,
  useDeleteRequestedRatingMutation,
} = requestedRatingsApiSlice;

export const selectRequestedRatingsResult =
  requestedRatingsApiSlice.endpoints.getRequestedRatings.select();

const selectRequestedRatingsData = createSelector(
  selectRequestedRatingsResult,
  (requestedRatingsResult) => requestedRatingsResult.data
);

export const {
  selectAll: selectRequestedAllRatings,
  selectIds: selectRequestedRatingsIds,
} = requestedRatingsAdapter.getSelectors(
  (state) => selectRequestedRatingsData(state) ?? initialState
);
