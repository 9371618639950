import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const recruitmentPhasesAdapter = createEntityAdapter();

const initialState = recruitmentPhasesAdapter.getInitialState();

export const recruitmentPhasesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecruitmentPhases: builder.query({
      query: () => ({
        url: "/recruitment-phases",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedRecruitmentPhases = responseData.map((recruitmentPhase) => {
          recruitmentPhase.id = recruitmentPhase._id;
          return recruitmentPhase;
        });

        return recruitmentPhasesAdapter.setAll(
          initialState,
          loadedRecruitmentPhases
        );
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "RecruitmentPhase", id: "LIST" },
            ...result.ids.map((id) => ({ type: "RecruitmentPhase", id })),
          ];
        } else return [{ type: "RecruitmentPhase", id: "LIST" }];
      },
    }),
    addRecruitmentPhase: builder.mutation({
      query: (recruitmentPhase) => ({
        url: "/recruitment-phases",
        method: "POST",
        body: { ...recruitmentPhase },
      }),
      invalidatesTags: [{ type: "RecruitmentPhase", id: "LIST" }],
    }),
    updateRecruitmentPhase: builder.mutation({
      query: (recruitmentPhase) => ({
        url: "/recruitment-phases",
        method: "PATCH",
        body: { ...recruitmentPhase },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "RecruitmentPhase", id: arg.id },
      ],
    }),
    deleteRecruitmentPhase: builder.mutation({
      query: (id) => ({
        url: "/recruitment-phases",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "RecruitmentPhase", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetRecruitmentPhasesQuery,
  useAddRecruitmentPhaseMutation,
  useUpdateRecruitmentPhaseMutation,
  useDeleteRecruitmentPhaseMutation,
} = recruitmentPhasesApiSlice;

export const selectRecruitmentPhasesResult =
  recruitmentPhasesApiSlice.endpoints.getRecruitmentPhases.select();

const selectRecruitmentPhasesData = createSelector(
  selectRecruitmentPhasesResult,
  (recruitmentPhasesResult) => recruitmentPhasesResult.data
);

export const {
  selectAll: selectAllRecruitmentPhases,
  selectIds: selectRecruitmentPhasesIds,
} = recruitmentPhasesAdapter.getSelectors(
  (state) => selectRecruitmentPhasesData(state) ?? initialState
);
