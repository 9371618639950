import { Box } from "@mui/material";
import Header from "components/Header";
import LinksDataGrid from "features/links/LinksDataGrid";
import { useEffect } from "react";

const LinkList = () => {
  useEffect(() => {
    document.title = `Links | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header
        title="LINKS"
        subtitle="Hinzufügen / Bearbeiten nützlicher Links"
      />
      <LinksDataGrid />
    </Box>
  );
};
export default LinkList;
