import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logout } from "features/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_SERVER,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, options) => {
  let result = await baseQuery(args, api, options);

  if (result?.error?.originalStatus === 403) {
    const refreshResult = await baseQuery("/auth/refresh", api, options);

    if (refreshResult?.data) {
      api.dispatch(setCredentials({ ...refreshResult.data }));
      result = await baseQuery(args, api, options);
    } else {
      api.dispatch(logout());

      window.location.href = "/login";
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "User",
    "Application",
    "Permission",
    "Role",
    "RecruitmentPhase",
    "Cop",
    "LearningModule",
    "Event",
    "Rating",
    "RequestedRating",
    "ReceivedRequestedRating",
    "SentRequestedRating",
    "Controlcenter",
    "Operation",
    "Vacation",
    "Info",
    "Poll",
    "FAQ",
    "SubmittedQuestion",
    "Link",
    "News",
    "Playtime",
    "ExpCalculation",
    "JobProtocol",
    "Armory",
    "Notification",
    "DocumentChange",
    "RConConsoleLog",
    "RConOnlinePlayer",
    "PlayerCount",
    "CopRatioViolation",
  ],
  endpoints: (build) => ({}),
});
