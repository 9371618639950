import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const eventsAdapter = createEntityAdapter();

const initialState = eventsAdapter.getInitialState();

export const eventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: () => ({
        url: "/events",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedEvents = responseData.map((event) => {
          event.id = event._id;
          return event;
        });

        return eventsAdapter.setAll(initialState, loadedEvents);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Event", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Event", id })),
          ];
        } else return [{ type: "Event", id: "LIST" }];
      },
    }),
    getCopEvents: builder.query({
      query: (id) => ({
        url: `/cops/${id}/events`,
      }),
      transformResponse: (responseData) => {
        const loadedEvents = responseData.map((event) => {
          event.id = event._id;
          return event;
        });

        return eventsAdapter.setAll(initialState, loadedEvents);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Event", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Event", id })),
          ];
        } else return [{ type: "Event", id: "LIST" }];
      },
    }),
    addEvent: builder.mutation({
      query: (event) => ({
        url: "/events",
        method: "POST",
        body: { ...event },
      }),
      invalidatesTags: [{ type: "Event", id: "LIST" }],
    }),
    updateEvent: builder.mutation({
      query: (event) => ({
        url: "/events",
        method: "PATCH",
        body: { ...event },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Event", id: arg.id }],
    }),
    deleteEvent: builder.mutation({
      query: (id) => ({
        url: "/events",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Event", id: arg.id }],
    }),
    enroll: builder.mutation({
      query: (id) => ({
        url: "/events/enroll",
        method: "PATCH",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Event", id: arg.id }],
    }),
    disenroll: builder.mutation({
      query: (id) => ({
        url: "/events/disenroll",
        method: "PATCH",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Event", id: arg.id }],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetCopEventsQuery,
  useAddEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useEnrollMutation,
  useDisenrollMutation,
} = eventsApiSlice;

export const selectEventsResult = eventsApiSlice.endpoints.getEvents.select();

const selectEventsData = createSelector(
  selectEventsResult,
  (eventsResult) => eventsResult.data
);

export const { selectAll: selectAllEvents, selectIds: selectEventsIds } =
  eventsAdapter.getSelectors(
    (state) => selectEventsData(state) ?? initialState
  );
