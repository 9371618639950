import {
  Box,
  Button,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useInitNameMutation } from "features/users/usersApiSlice";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "contexts/Snackbar.context";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState, useMemo } from "react";
import { Dialog, DialogContent, useTheme } from "@mui/material";
import * as React from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import Slide from "@mui/material/Slide";
import useAuth from "hooks/useAuth";
import { FaDiscord } from "react-icons/fa";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object().shape({
  name: yup.string().required("Pflichtfeld"),
});

const InitNameFormDialog = () => {
  const [initName, { isLoading, isError, error }] = useInitNameMutation();

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const showSnackbar = useSnackbar();

  const { user } = useAuth();
  const theme = useTheme();

  const initialValues = useMemo(
    () => ({
      name: user.name || "",
    }),
    [user.name]
  );

  useEffect(() => {
    if (!user.name || !user.discord) {
      setDialogIsOpen(true);
    } else {
      setDialogIsOpen(false);
    }
  }, [user]);

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") return;

    setDialogIsOpen(false);
  };

  useEffect(() => {
    if (isError)
      showSnackbar(
        error?.data
          ? `Fehler - ${error?.data} (${error?.originalStatus})`
          : "Fehler",
        "error"
      );
    // eslint-disable-next-line
  }, [isError, error]);

  const handleFormSubmit = async (values) => {
    await initName(values.name)
      .unwrap()
      .then(() => showSnackbar("Name gespeichert"));
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={dialogIsOpen}
      onClose={handleDialogClose}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        sx: {
          mb: "20%",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        Charakter Name / Discord
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Box display="flex" flexDirection="column" mt={1}>
            <Grid container spacing={2} mb={1} alignItems="center">
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  disabled
                  value={user.discord?.username || ""}
                  label="Discord Benutzername"
                  InputProps={
                    !!user.discord
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <CheckOutlinedIcon color="success" />
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  startIcon={<FaDiscord size="1rem" />}
                  variant="contained"
                  disabled={!!user.discord}
                  fullWidth
                  href={`${process.env.REACT_APP_SERVER}/discord`}
                >
                  Verknüpfen
                </Button>
              </Grid>
            </Grid>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={8}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Ingame Name"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        name="name"
                        error={!!touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        inputProps={{ maxLength: 30 }}
                        disabled={!!user.name}
                        InputProps={
                          !!user.name
                            ? {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <CheckOutlinedIcon color="success" />
                                  </InputAdornment>
                                ),
                              }
                            : {}
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={!!user.name}
                        fullWidth
                      >
                        Speichern
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InitNameFormDialog;
