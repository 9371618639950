import { Box } from "@mui/material";
import Header from "components/Header";
import EventDataGrid from "features/events/EventDataGrid";
import { useEffect } from "react";

const OperationalTrainings = () => {
  useEffect(() => {
    document.title = `Einsatztrainings | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="EINSATZTRAININGS" subtitle="Übungen der Polizei" />
      <EventDataGrid eventType="Einsatztraining" />
    </Box>
  );
};

export default OperationalTrainings;
