import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { dataGridSx } from "styles/dataGridSx";
import { dateColumnType, dateTimeColumnType } from "util/dateTimeColumnType";
import { useGetUserReceivedRequestedRatingsQuery } from "./requestedRatingsApiSlice";
import { DataGrid } from "@mui/x-data-grid";
import RatingForm from "features/ratings/RatingForm";
import dayjs from "dayjs";
import CustomGridPagination from "components/CustomGridPagination";

const ReceivedRequestedRatingsDataGrid = () => {
  const theme = useTheme();

  const [rows, setRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [ratingInitialValues, setRatingInitialValues] = useState({});

  const {
    data: requestedRatings,
    isSuccess,
    isLoading,
  } = useGetUserReceivedRequestedRatingsQuery("receivedRequestedRatingsList", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isSuccess)
      setRows(requestedRatings.ids.map((id) => requestedRatings.entities[id]));
  }, [isSuccess, requestedRatings]);

  const columns = [
    {
      field: "requestor",
      headerName: "Anforderer",
      valueGetter: ({ value }) => value?.user?.name,
      flex: 1,
      maxWidth: 300,
    },
    {
      field: "forFunction",
      headerName: "Für Funktion",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "date",
      headerName: "Datum",
      ...dateColumnType,
      flex: 1,
      maxWidth: 110,
    },
    {
      field: "period",
      headerName: "Server Periode",
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "reason",
      headerName: "Grund",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Angefordert am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
  ];

  const handleRowClick = ({ row }) => {
    setRatingInitialValues({
      evaluator: { function: "" },
      rated: {
        cop: row.requestor?._id,
        function: row.forFunction,
      },
      date: dayjs(row.date),
      period: row.period,
    });
    setDialogOpen(true);
  };

  return (
    <Box
      height="380px"
      sx={{
        ...dataGridSx(theme),
        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-row": {
          cursor: "pointer",
        },
      }}
    >
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows?.length > 0 ? rows : []}
        disableRowSelectionOnClick
        onRowClick={handleRowClick}
        autoPageSize
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        sx={{
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
        slots={{
          pagination: CustomGridPagination,
        }}
      />

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Bewertung verfassen</DialogTitle>
        <DialogContent>
          <Box mt="8px">
            <RatingForm
              initialValues={ratingInitialValues}
              postSubmit={() => setDialogOpen(false)}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default memo(ReceivedRequestedRatingsDataGrid);
