import {
  MenuButtonAddTable,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonCode,
  MenuButtonCodeBlock,
  MenuButtonEditLink,
  MenuButtonHorizontalRule,
  MenuButtonIndent,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonRemoveFormatting,
  MenuButtonStrikethrough,
  MenuButtonSubscript,
  MenuButtonSuperscript,
  MenuButtonTaskList,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonUnindent,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectFontSize,
  MenuSelectHeading,
  isTouchDevice,
} from "mui-tiptap";

export default function EditorMenuControls() {
  return (
    <MenuControlsContainer>
      <MenuSelectHeading
        tooltipTitle="Absatzstil"
        labels={{
          paragraph: "Text",
          empty: "Leer",
          heading1: "Überschrift 1",
          heading2: "Überschrift 2",
          heading3: "Überschrift 3",
          heading4: "Überschrift 4",
          heading5: "Überschrift 5",
          heading6: "Überschrift 6",
        }}
      />

      <MenuDivider />

      <MenuSelectFontSize
        tooltipTitle="Schriftgröße"
        unsetOptionLabel="Standard"
      />

      <MenuDivider />

      <MenuButtonBold tooltipLabel="Fett" />

      <MenuButtonItalic tooltipLabel="Kursiv" />

      <MenuButtonUnderline tooltipLabel="Unterstrichen" />

      <MenuButtonStrikethrough tooltipLabel="Durchgestrichen" />

      <MenuButtonSubscript tooltipLabel="Tiefgestellt" />

      <MenuButtonSuperscript tooltipLabel="Hochgestellt" />

      <MenuDivider />

      <MenuButtonEditLink />

      <MenuDivider />

      <MenuButtonOrderedList tooltipLabel="Nummerierte Liste" />

      <MenuButtonBulletedList tooltipLabel="Aufzählungsliste" />

      <MenuButtonTaskList tooltipLabel="Checkliste" />

      {/* On touch devices, we'll show indent/unindent buttons, since they're
      unlikely to have a keyboard that will allow for using Tab/Shift+Tab. These
      buttons probably aren't necessary for keyboard users and would add extra
      clutter. */}
      {isTouchDevice() && (
        <>
          <MenuButtonIndent />

          <MenuButtonUnindent />
        </>
      )}

      <MenuDivider />

      <MenuButtonBlockquote tooltipLabel="Zitat" />

      <MenuDivider />

      <MenuButtonCode tooltipLabel="Code" />

      <MenuButtonCodeBlock tooltipLabel="Code Block" />

      <MenuDivider />

      <MenuButtonHorizontalRule tooltipLabel="Horizontale Linie einfügen" />

      <MenuButtonAddTable tooltipLabel="Tabelle einfügen" />

      <MenuDivider />

      <MenuButtonRemoveFormatting tooltipLabel="Formatierung entfernen" />

      <MenuDivider />

      <MenuButtonUndo tooltipLabel="Rückgängig machen" />
      <MenuButtonRedo tooltipLabel="Wiederholen" />
    </MenuControlsContainer>
  );
}
