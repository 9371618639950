import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { memo, useState } from "react";
import { MathJaxProvider, Tex2SVG } from "react-hook-mathjax";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CircularProgressWithBackground from "components/CircularProgressWithBackground";
import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined";

const mathJaxOptions = {
  svg: {
    scale: 1, // global scaling factor for all expressions
    minScale: 0.5, // smallest scaling factor to use
    mtextInheritFont: false, // true to make mtext elements use surrounding font
    merrorInheritFont: true, // true to make merror text use surrounding font
    mathmlSpacing: false, // true for MathML spacing rules, false for TeX rules
    skipAttributes: {}, // RFDa and other attributes NOT to copy to the output
    exFactor: 0.5, // default size of ex in em units
    displayAlign: "center", // default for indentalign when set to 'auto'
    displayIndent: "0", // default for indentshift when set to 'auto'
    fontCache: "local", // or 'global' or 'none'
    localID: null, // ID to use for local font cache (for single equation processing)
    internalSpeechTitles: true, // insert <title> tags with speech content
    titleID: 0, // initial id number to use for aria-labeledby titles
  },
};

const EvaluatedPlaytimeDialog = ({ open, onClose, evaluatedPlaytime }) => {
  const [tab, setTab] = useState(0);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>EP Kalkulation zur Spielzeit</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              defaultValue={evaluatedPlaytime.durationMs}
              label="Spieldauer (ms)"
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              defaultValue={evaluatedPlaytime.durationHours}
              label="Spieldauer (h)"
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              defaultValue={evaluatedPlaytime.expPerHour}
              label="EP pro Stunde"
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              defaultValue={evaluatedPlaytime.civCountBase}
              label="Basis Zivilistenanzahl"
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              defaultValue={evaluatedPlaytime.averageCivCount}
              label="Durchschnittliche Zivilistenanzahl im Spielzeitraum"
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              defaultValue={evaluatedPlaytime.civCountStepValueIncrement}
              label="Additions Interval"
              InputProps={{
                readOnly: true,
              }}
              color={
                evaluatedPlaytime.averageCivCount >
                evaluatedPlaytime.civCountBase
                  ? "success"
                  : "primary"
              }
              focused={
                evaluatedPlaytime.averageCivCount >
                evaluatedPlaytime.civCountBase
              }
              helperText={
                evaluatedPlaytime.averageCivCount >
                  evaluatedPlaytime.civCountBase && "Angewendet"
              }
              sx={{
                "& .MuiFormHelperText-root": {
                  color: "success.main",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              defaultValue={evaluatedPlaytime.civCountExpFactorIncrement}
              label="Faktor Summand"
              InputProps={{
                readOnly: true,
              }}
              color={
                evaluatedPlaytime.averageCivCount >
                evaluatedPlaytime.civCountBase
                  ? "success"
                  : "primary"
              }
              focused={
                evaluatedPlaytime.averageCivCount >
                evaluatedPlaytime.civCountBase
              }
              helperText={
                evaluatedPlaytime.averageCivCount >
                  evaluatedPlaytime.civCountBase && "Angewendet"
              }
              sx={{
                "& .MuiFormHelperText-root": {
                  color: "success.main",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              defaultValue={evaluatedPlaytime.civCountStepValueDecrement}
              label="Subtraktions Interval"
              InputProps={{
                readOnly: true,
              }}
              color={
                evaluatedPlaytime.averageCivCount <
                evaluatedPlaytime.civCountBase
                  ? "success"
                  : "primary"
              }
              focused={
                evaluatedPlaytime.averageCivCount <
                evaluatedPlaytime.civCountBase
              }
              helperText={
                evaluatedPlaytime.averageCivCount <
                  evaluatedPlaytime.civCountBase && "Angewendet"
              }
              sx={{
                "& .MuiFormHelperText-root": {
                  color: "success.main",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              defaultValue={evaluatedPlaytime.civCountExpFactorDecrement}
              label="Faktor Subtrahend"
              InputProps={{
                readOnly: true,
              }}
              color={
                evaluatedPlaytime.averageCivCount <
                evaluatedPlaytime.civCountBase
                  ? "success"
                  : "primary"
              }
              focused={
                evaluatedPlaytime.averageCivCount <
                evaluatedPlaytime.civCountBase
              }
              helperText={
                evaluatedPlaytime.averageCivCount <
                  evaluatedPlaytime.civCountBase && "Angewendet"
              }
              sx={{
                "& .MuiFormHelperText-root": {
                  color: "success.main",
                },
              }}
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <MathJaxProvider options={mathJaxOptions}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Tabs value={tab} onChange={(_, value) => setTab(value)}>
                <Tab label="Formel" />
                <Tab label="Rechnung" />
              </Tabs>
              {tab === 0 && (
                <Tooltip
                  arrow
                  title={
                    <Typography>
                      <Tex2SVG latex="a = \varnothing Zivilistenanzahl" />
                      <Tex2SVG latex="b = Basis Zivilistenanzahl" />
                      <Tex2SVG latex="c = Additions Interval" />
                      <Tex2SVG latex="d = Faktor Summand" />
                      <Tex2SVG latex="e = Subtraktions Interval" />
                      <Tex2SVG latex="f = Faktor Subtrahend" />
                    </Typography>
                  }
                >
                  <HelpOutlineOutlinedIcon color="info" />
                </Tooltip>
              )}
            </Box>

            {tab === 0 && (
              <Box>
                <Tex2SVG latex="GrundEP = Spieldauer(h) * \dfrac{EP}{h}" />
                <Tex2SVG
                  latex="ZivilistenFaktor = \begin{cases}
                                            1 + \lfloor\dfrac{|b-a|}{c}\rfloor * d,& \text{if } a > b\\
                                            1 + \lfloor\dfrac{|b-a|}{e}\rfloor * f,& \text{if } a < b\\
                                            1,              & \text{otherwise}
                                        \end{cases}"
                />
                <Tex2SVG latex="EP = GrundEP * ZivilistenFaktor" />
              </Box>
            )}
            {tab === 1 && (
              <Box>
                <Tex2SVG
                  latex={`GrundEP = ${evaluatedPlaytime.durationHours} * ${evaluatedPlaytime.expPerHour} = ${evaluatedPlaytime.baseExp}`}
                />
                {evaluatedPlaytime.averageCivCount >
                evaluatedPlaytime.civCountBase ? (
                  <Tex2SVG
                    latex={`ZivilistenFaktor = 1 + \\lfloor\\dfrac{|${evaluatedPlaytime.civCountBase}-${evaluatedPlaytime.averageCivCount}|}{${evaluatedPlaytime.civCountStepValueIncrement}}\\rfloor * ${evaluatedPlaytime.civCountExpFactorIncrement} = ${evaluatedPlaytime.civCountExpFactor}`}
                  />
                ) : evaluatedPlaytime.averageCivCount <
                  evaluatedPlaytime.civCountBase ? (
                  <Tex2SVG
                    latex={`ZivilistenFaktor = 1 + \\lfloor\\dfrac{|${evaluatedPlaytime.civCountBase}-${evaluatedPlaytime.averageCivCount}|}{${evaluatedPlaytime.civCountStepValueDecrement}}\\rfloor * ${evaluatedPlaytime.civCountExpFactorDecrement} = ${evaluatedPlaytime.civCountExpFactor}`}
                  />
                ) : (
                  <Tex2SVG latex="ZivilistenFaktor = 1" />
                )}
                <Tex2SVG
                  latex={`EP = ${evaluatedPlaytime.baseExp} * ${evaluatedPlaytime.civCountExpFactor} = ${evaluatedPlaytime.calculatedExp}`}
                />
              </Box>
            )}
          </MathJaxProvider>
        </Box>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              defaultValue={evaluatedPlaytime.calculatedExp}
              label="Berechnete EP"
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </Grid>
          <Grid
            item
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Tooltip
              arrow
              title={<Typography>Angerechneter Anteil</Typography>}
            >
              <Box borderRadius="100%">
                <CircularProgressWithBackground
                  value={evaluatedPlaytime.creditedPortion * 100}
                  variant="determinate"
                  size={50}
                  thickness={8}
                  color="primary"
                  label
                />
              </Box>
            </Tooltip>
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              defaultValue={
                Math.round(
                  evaluatedPlaytime.durationHours *
                    evaluatedPlaytime.creditedPortion *
                    100
                ) / 100
              }
              label="Gewertete Spielzeit (h)"
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              defaultValue={evaluatedPlaytime.creditedExp}
              label="Angerechnete EP"
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <FunctionsOutlinedIcon
                      sx={{
                        color: "text.secondary",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              focused={evaluatedPlaytime.creditedExp > 0}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default memo(EvaluatedPlaytimeDialog);
