import {
  Box,
  Chip,
  Grid,
  InputLabel,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import CustomCard from "components/CustomCard";
import { getApplicationStatusColor } from "util/muiColorGetter";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SteamProfileLinkButton from "components/SteamProfileLinkButton";
import UserLink from "components/UserLink";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

const ApplicationHeader = ({ application, user }) => {
  const theme = useTheme();

  const columns = application.applicationType === "Zweitfraktion" ? 5 : 5;

  return (
    <Box>
      {application.isClosed && (
        <Box
          mb={theme.spacing(2)}
          alignItems="center"
          sx={{ backgroundColor: "warning.main" }}
          pt="12px"
          pb="12px"
          display="flex"
          justifyContent="center"
          borderRadius={theme.shape.borderRadius}
          color="background.default"
        >
          <WarningAmberOutlinedIcon fontSize="large" sx={{ mr: "8px" }} />
          <Typography variant="h6">Diese Bewerbung ist geschlossen</Typography>
        </Box>
      )}
      <CustomCard>
        <Box display="flex" alignItems="center">
          <UserLink id={user._id}>
            <img
              alt="steam-avatar"
              src={user.steamData?.avatarfull}
              width="120px"
              height="120px"
              style={{ borderRadius: "5%" }}
            />
          </UserLink>
          <Box ml="32px" width="100%">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={1}
              columns={columns}
              alignItems="center"
            >
              <Grid item md={columns} xl={1}>
                <Grid container columns={2} rowSpacing={1}>
                  <Grid item xs={1}>
                    <InputLabel>Vorname</InputLabel>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>{application.name}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <InputLabel>Alter</InputLabel>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>{application.age}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <InputLabel>Forum Name</InputLabel>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>{application.forumName}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <InputLabel>Steam ID</InputLabel>
                  </Grid>
                  <Grid item xs={1}>
                    <Box display="flex" alignItems="center">
                      <Typography>{user.steamid}</Typography>
                      <SteamProfileLinkButton
                        profileurl={user.steamData?.profileurl}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={columns} xl={1}>
                <Grid container columns={2} rowSpacing={1}>
                  <Grid item xs={1}>
                    <InputLabel>Arma 3 Spielstunden</InputLabel>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>{application.arma3Hours}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <InputLabel>Spielstunden auf dem Server</InputLabel>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>{application.serverHours}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <InputLabel>Gangzugehörigkeit</InputLabel>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>{application.gang}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={columns} xl={1}>
                <Grid container columns={2} rowSpacing={1}>
                  <Grid item xs={1}>
                    <InputLabel>Bewerbungstyp</InputLabel>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>{application.applicationType}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <InputLabel>Einstellungsphase</InputLabel>
                  </Grid>
                  <Grid item xs={1}>
                    <Box display="flex">
                      <Typography>
                        {application.recruitmentPhase.name}
                      </Typography>
                      {dayjs().isAfter(application.recruitmentPhase.from) &&
                      dayjs().isBefore(application.recruitmentPhase.until) ? (
                        <Tooltip
                          arrow
                          title={
                            <Typography variant="subtitle1">
                              Einstellungsphase geöffnet
                            </Typography>
                          }
                        >
                          <LockOpenOutlinedIcon
                            sx={{ ml: "8px" }}
                            color="success"
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          arrow
                          title={
                            <Typography variant="subtitle1">
                              Einstellungsphase geschlossen
                            </Typography>
                          }
                        >
                          <LockOutlinedIcon sx={{ ml: "8px" }} color="error" />
                        </Tooltip>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={1}>
                    <InputLabel>Status</InputLabel>
                  </Grid>
                  <Grid item xs={1}>
                    <Chip
                      size="small"
                      label={application.status}
                      color={getApplicationStatusColor(application.status)}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {application.applicationType === "Zweitfraktion" && (
                <Grid item md={columns} xl={1}>
                  <Grid container columns={2} rowSpacing={1}>
                    <Grid item xs={1}>
                      <InputLabel>Hauptfraktion</InputLabel>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>{application.primaryFaction}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <InputLabel>Name in der Hauptfraktion</InputLabel>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>{application.primaryFactionName}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <InputLabel>Genehmigt von</InputLabel>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>
                        {application.primaryFactionApprover}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </CustomCard>
    </Box>
  );
};
export default ApplicationHeader;
