import {
  Box,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tab,
  Tabs,
  TextField,
  styled,
} from "@mui/material";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DateTimeField } from "@mui/x-date-pickers";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import PlayerSideChip from "components/PlayerSideChip";
import CustomSearch from "components/CustomSearch";
import { Link } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

dayjs.extend(duration);

const CustomList = ({ title, children }) => (
  <List
    subheader={
      <ListSubheader
        sx={{
          backgroundColor: "transparent",
        }}
      >
        {title}
      </ListSubheader>
    }
  >
    <Box
      sx={{
        maxHeight: "400px",
        overflow: "auto",
      }}
    >
      {children}
    </Box>
  </List>
);

const RotatedExpandIcon = styled((props) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(360deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const PlaytimeDialog = ({ open, onClose, playtime }) => {
  const [expanded, setExpanded] = useState(false);
  const [tab, setTab] = useState("start");
  const [filterValue, setFilterValue] = useState("");

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle display="flex" alignItems="center">
        Spielzeit Protokoll
        <PlayerSideChip
          side={playtime?.side}
          sx={{
            ml: 2,
            mr: 2,
          }}
        />
        <Chip
          icon={<StorageOutlinedIcon />}
          label={`Server ${playtime?.server}`}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center" sx={{ mt: 0 }}>
          <Grid item display="flex" xs={12} md={3}>
            <TextField
              label="Name"
              defaultValue={playtime?.cop?.user.name}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      component={Link}
                      to={`/cops/${playtime?.cop?._id}`}
                      color="primary"
                    >
                      <AccountCircleOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item display="flex" xs={12} md={3}>
            <TextField
              label="Name auf Server"
              defaultValue={playtime?.name}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item display="flex" xs={12} md={2}>
            <DateTimeField
              label="Von"
              defaultValue={dayjs(playtime?.start)}
              fullWidth
              readOnly
            />
          </Grid>
          <Grid item display="flex" xs={12} md={2}>
            <DateTimeField
              label="Bis"
              defaultValue={dayjs(playtime?.end)}
              fullWidth
              readOnly
            />
          </Grid>
          <Grid item display="flex" xs={12} md={2}>
            <TextField
              label="Spieldauer"
              defaultValue={dayjs
                .duration(dayjs(playtime?.end).diff(dayjs(playtime?.start)))
                .format("HH[h] mm[m]")}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        {!!playtime?.snapshots && (
          <Box mt={2}>
            <Button
              onClick={() => setExpanded((value) => !value)}
              variant="contained"
              sx={{ mb: 1 }}
            >
              <Box mr="8px">Spieler Snapshot</Box>
              <RotatedExpandIcon expand={expanded} />
            </Button>
            <Collapse in={expanded} timeout="auto" unmountOnExit={false}>
              <Box display="flex" alignItems="center">
                <Tabs value={tab} onChange={(_, value) => setTab(value)}>
                  <Tab
                    value="start"
                    label={`Zum Beitritt (${dayjs(playtime?.start).format(
                      "HH:mm"
                    )})`}
                  />
                  <Tab
                    value="end"
                    label={`Nach verlassen (${dayjs(playtime?.end).format(
                      "HH:mm"
                    )})`}
                  />
                </Tabs>
                <Box display="flex" ml={2}>
                  <CustomSearch
                    value={filterValue}
                    onChange={(event) => setFilterValue(event.target.value)}
                    searchProps={{
                      sx: {
                        backgroundColor: "action.selected",
                        ":hover": {
                          backgroundColor: "action.selected",
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
              <Grid container spacing={2} columns={5}>
                <Grid item xs={5} md={1}>
                  <CustomList
                    title={`Zivilisten (${
                      playtime?.snapshots[tab].civs?.length || 0
                    })`}
                  >
                    {playtime?.snapshots[tab].civs
                      ?.filter((item) =>
                        item.name
                          .toLowerCase()
                          .includes(filterValue.toLowerCase())
                      )
                      .map((civ, index) => (
                        <ListItem key={`civ-${index}`}>
                          <ListItemText
                            primary={civ.name}
                            secondary={civ.cop?.user.name}
                          />
                          {!!civ.cop && (
                            <ListItemIcon>
                              <LocalPoliceOutlinedIcon color="primary" />
                            </ListItemIcon>
                          )}
                        </ListItem>
                      ))}
                  </CustomList>
                </Grid>
                <Grid item xs={5} md={1}>
                  <CustomList
                    title={`Polizei (${
                      playtime?.snapshots[tab].cops?.length || 0
                    })`}
                  >
                    {playtime?.snapshots[tab].cops
                      ?.filter((item) =>
                        item.toLowerCase().includes(filterValue.toLowerCase())
                      )
                      .map((cop, index) => (
                        <ListItem key={`cop-${index}`}>
                          <ListItemText primary={cop} />
                        </ListItem>
                      ))}
                  </CustomList>
                </Grid>
                <Grid item xs={5} md={1}>
                  <CustomList
                    title={`Justiz (${
                      playtime?.snapshots[tab].justice?.length || 0
                    })`}
                  >
                    {playtime?.snapshots[tab].justice
                      ?.filter((item) =>
                        item.toLowerCase().includes(filterValue.toLowerCase())
                      )
                      .map((justiceMember, index) => (
                        <ListItem key={`justice-${index}`}>
                          <ListItemText primary={justiceMember} />
                        </ListItem>
                      ))}
                  </CustomList>
                </Grid>
                <Grid item xs={5} md={1}>
                  <CustomList
                    title={`Rettungsdienst (${
                      playtime?.snapshots[tab].medics?.length || 0
                    })`}
                  >
                    {playtime?.snapshots[tab].medics
                      ?.filter((item) =>
                        item.toLowerCase().includes(filterValue.toLowerCase())
                      )
                      .map((medic, index) => (
                        <ListItem key={`medic-${index}`}>
                          <ListItemText primary={medic} />
                        </ListItem>
                      ))}
                  </CustomList>
                </Grid>
                <Grid item xs={5} md={1}>
                  <CustomList
                    title={`RAC (${playtime?.snapshots[tab].rac?.length || 0})`}
                  >
                    {playtime?.snapshots[tab].rac
                      ?.filter((item) =>
                        item.toLowerCase().includes(filterValue.toLowerCase())
                      )
                      .map((racMember, index) => (
                        <ListItem key={`rac-${index}`}>
                          <ListItemText primary={racMember} />
                        </ListItem>
                      ))}
                  </CustomList>
                </Grid>
              </Grid>
            </Collapse>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default PlaytimeDialog;
