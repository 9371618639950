import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";
import { getCopFunctionColor, getCopFunctionIcon } from "util/muiColorGetter";
import { functions as functionsOptions } from "util/copEnums";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { memo } from "react";

const CopFunctions = ({
  functions,
  readOnly,
  hideOutlines,
  hideLabel,
  onChange,
  inputProps,
  autocompleteProps,
  hideInputOnReadOnly = true,
}) => {
  return (
    <Autocomplete
      id={inputProps?.name}
      fullWidth
      disableCloseOnSelect
      multiple
      readOnly={readOnly}
      limitTags={inputProps?.limitTags}
      forcePopupIcon={!readOnly}
      value={functions}
      options={functionsOptions}
      onChange={onChange}
      onBlur={inputProps?.onBlur}
      noOptionsText="Keine Optionen"
      renderTags={(value, getTagProps) =>
        value.map((copFunction, index) => (
          <Chip
            {...getTagProps({ index })}
            label={copFunction}
            color={getCopFunctionColor(copFunction)}
            icon={getCopFunctionIcon(copFunction)}
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      renderInput={(props) => (
        <TextField
          {...props}
          error={inputProps?.error}
          helperText={inputProps?.helperText}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: hideOutlines && "none",
            },
            ".MuiInputBase-input": {
              display: readOnly && hideInputOnReadOnly && "none",
            },
          }}
          label={hideLabel ? "" : inputProps?.label || "Funktionen"}
        />
      )}
      {...autocompleteProps}
    />
  );
};
export default memo(CopFunctions);
