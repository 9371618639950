import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useGetOnlineCopsQuery } from "features/panthorApi/panthorApiSlice";
import { useSnackbar } from "contexts/Snackbar.context";
import IsOnlineBadge from "./IsOnlineBadge";
import { getRankFromTag, ranks } from "util/copEnums";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

const StartSlotCheckerDialog = ({ open, onClose, onStart, onlineCops }) => {
  const [tab, setTab] = useState(0);
  const theme = useTheme();

  const handleTabChange = (_, value) => {
    setTab(value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Slot Checker</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center">
          <InfoOutlinedIcon fontSize="large" sx={{ mr: 2 }} color="info" />
          <Typography>
            Der Slot Checker überprüft alle 30 Sekunden, ob es auf dem
            ausgewählten Server einen freien Slot gibt und benachrichtigt dich
            in Form eines Sounds und einer Push-Nachricht.
          </Typography>
        </Box>
        {!!onlineCops && onlineCops.length > 0 ? (
          <Box mt={2}>
            <Tabs value={tab} onChange={handleTabChange}>
              {onlineCops.map((server, index) => (
                <Tab
                  key={index}
                  label={`Server ${server.id} (${server.cops?.length}/${process.env.REACT_APP_COP_SLOTS})`}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
            <Box>
              {onlineCops.map((server, index) => (
                <CustomTabPanel key={index} value={tab} index={index}>
                  <Box mb={2}>
                    <Typography>
                      <span
                        style={{
                          color:
                            process.env.REACT_APP_COP_SLOTS -
                              server.cops?.length >
                            0
                              ? theme.palette.success.main
                              : theme.palette.error.main,
                          fontWeight: "bold",
                        }}
                      >
                        {process.env.REACT_APP_COP_SLOTS - server.cops?.length >
                        0
                          ? process.env.REACT_APP_COP_SLOTS -
                            server.cops?.length
                          : 0}
                      </span>{" "}
                      freie Slots
                    </Typography>
                  </Box>
                  {server.cops?.length > 0 ? (
                    <List
                      disablePadding
                      sx={{
                        columnCount: 2,
                      }}
                    >
                      {server.cops?.map((cop, index) => (
                        <ListItem key={index} disablePadding>
                          <ListItemText primary={cop.name} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Chip
                      icon={<InfoOutlinedIcon color="info" />}
                      label={
                        <Typography>
                          Es sind keine Polizisten auf dem Server
                        </Typography>
                      }
                    />
                  )}
                </CustomTabPanel>
              ))}
            </Box>
          </Box>
        ) : (
          <Box mt={4}>
            <Chip
              color="warning"
              label={
                <Typography>
                  Fehler beim ermitteln der Polizisten auf dem Server. Versuche
                  die Seite erneut zu laden.
                </Typography>
              }
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!onlineCops || onlineCops.length === 0}
          variant="contained"
          endIcon={<PlayCircleFilledOutlinedIcon />}
          onClick={() => {
            onStart(onlineCops[tab]?.id);
            onClose();
          }}
        >
          Starten
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SlotChecker = () => {
  const [active, setActive] = useState(false);
  const [selectedServer, setSelectedServer] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const intervalRef = useRef(null);
  const showSnackbar = useSnackbar();
  const notificationSound = useMemo(
    () => new Audio("/assets/sound/notification.mp3"),
    []
  );

  const {
    data: onlineCops,
    isSuccess,
    refetch,
  } = useGetOnlineCopsQuery("onlineCopsList", {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ data, isSuccess, isLoading }) => ({
      data: data?.map((server) => ({
        ...server,
        cops: [...server.cops]?.sort(
          (a, b) =>
            (ranks[getRankFromTag(b.name)]?.value || 0) -
            (ranks[getRankFromTag(a.name)]?.value || 0)
        ),
      })),
      isSuccess,
      isLoading,
    }),
  });

  const handleClick = () => {
    if (active) {
      setActive(false);
    } else {
      setDialogOpen(true);
    }
  };

  const checkForSlot = useCallback(async () => {
    const { data } = await refetch();

    const copsOnServer = data.find((server) => server.id === selectedServer)
      ?.cops?.length;

    if (copsOnServer === undefined) return;

    if (process.env.REACT_APP_COP_SLOTS - copsOnServer > 0) {
      // Sound
      notificationSound.play();

      // Push Benachrichtigung
      const title = "Slot Checker";
      const options = {
        body: `Freier Polizeislot auf Server ${selectedServer}`,
        icon: "/assets/img/polizei.png",
      };
      if (Notification.permission === "granted") {
        new Notification(title, options);
      } else {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            new Notification(title, options);
          }
        });
      }

      showSnackbar(`Freier Polizeislot auf Server ${selectedServer}`, "info");

      // Slot Checker stoppen
      setActive(false);
    }
    // eslint-disable-next-line
  }, [selectedServer]);

  useEffect(() => {
    if (active) {
      showSnackbar("Slot Checker gestartet", "info");
      checkForSlot();
      intervalRef.current = setInterval(checkForSlot, 30000); // 30 Sekunden
    } else {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line
  }, [active]);

  return (
    <>
      <Tooltip
        arrow
        title={
          <Typography>
            {active ? "Slot Checker stoppen" : "Slot Checker"}
          </Typography>
        }
      >
        <IconButton onClick={handleClick}>
          <IsOnlineBadge
            variant="dot"
            badgeContent={active ? 1 : 0}
            color="success"
            overlap="circular"
          >
            <AutorenewOutlinedIcon
              color={active ? "primary" : "inherit"}
              sx={
                active
                  ? {
                      animation: "spin 2s linear infinite",
                      "@keyframes spin": {
                        "0%": {
                          transform: "rotate(0deg)",
                        },
                        "100%": {
                          transform: "rotate(360deg)",
                        },
                      },
                    }
                  : {}
              }
            />
          </IsOnlineBadge>
        </IconButton>
      </Tooltip>
      <StartSlotCheckerDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onStart={(server) => {
          setSelectedServer(server);
          setActive(true);
        }}
        onlineCops={isSuccess ? onlineCops : []}
      />
    </>
  );
};
export default SlotChecker;
