import { Routes, Route } from "react-router-dom";
import Login from "screens/Login";
import { CssBaseline, ThemeProvider } from "@mui/material";
import RequireAuth from "features/auth/RequireAuth";
import PersistLogin from "features/auth/PersistLogin";
import AuthCallback from "features/auth/AuthCallback";
import { ColorModeContext, useMode } from "theme";
import Layout from "screens/global/Layout";
import Profile from "screens/Profile";
import SnackbarProvider from "./contexts/Snackbar.context";
import ConfirmDialogProvider from "contexts/ConfirmDialog.context";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/de";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dashboard from "screens/Dashboard";
import CopList from "screens/CopList";
import Calendar from "screens/Calendar";
import RecruitmentPhases from "screens/recruitment/RecruitmentPhases";
import Applications from "screens/recruitment/Applications";
import Recruits from "screens/education/Recruits";
import OperationalTrainings from "screens/training/OperationalTrainings";
import Vacations from "screens/staff/Vacations";
import Ratings from "screens/staff/Ratings";
import OpenRatings from "screens/staff/OpenRatings";
import Controlcenters from "screens/staff/Controllcenters";
import Operations from "screens/staff/Operations";
import UserList from "screens/UserList";
import RoleList from "screens/RolesList";
import ApplyForm from "screens/ApplyForm";
import User from "features/users/User";
import RequirePermission from "features/auth/RequirePermission";
import Application from "features/applications/Application";
import MyApplication from "features/applications/MyApplication";
import Cop from "features/cops/Cop";
import LearningModuleList from "screens/LearningModuleList";
import AddRating from "screens/selfAdministration/AddRating";
import RequestedRatings from "screens/selfAdministration/RequestedRatings";
import AddControlcenter from "screens/selfAdministration/AddControlcenter";
import AddOperation from "screens/selfAdministration/AddOperation";
import RequestVacation from "screens/selfAdministration/RequestVacation";
import RecruitNotes from "screens/education/RecruitNotes";
import RecruitSanctions from "screens/education/RecruitSanctions";
import Notes from "screens/staff/Notes";
import Sanctions from "screens/staff/Sanctions";
import Polls from "screens/Polls";
import FAQ from "screens/FAQ";
import UsefulLinks from "screens/UsefulLinks";
import LinkList from "screens/LinkList";
import NewsList from "screens/NewsList";
import PlaytimeTracker from "screens/playtimes/PlaytimeTracker";
import PlaytimeList from "screens/playtimes/PlaytimeList";
import ExpCalculation from "screens/logs/ExpCalculation";
import Jobs from "screens/logs/Jobs";
import DatabaseChanges from "screens/logs/DatabaseChanges";
import Armory from "screens/logs/Armory";
import { useEffect } from "react";
import FallbackPage from "screens/FallbackPage";
import dayjs from "dayjs";
import DiscordCallback from "features/discord/DiscordCallback";
import RCon from "screens/RCon";
import Cockpit from "screens/staff/Cockpit";

dayjs.locale("de");

function App() {
  const [theme, colorMode] = useMode();

  useEffect(() => {
    document.title = `Polizei Verwaltung ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
          <SnackbarProvider>
            <ConfirmDialogProvider>
              <div className="app">
                <Routes>
                  {/* PUBLIC ROUTES */}
                  <Route exact path="/login" element={<Login />} />
                  <Route
                    exact
                    path="/auth/steam/authenticate"
                    element={<AuthCallback />}
                  />
                  {/* PROTECTED ROUTES */}
                  <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth />}>
                      <Route element={<Layout />}>
                        <Route
                          exact
                          path="/discord/authenticate"
                          element={<DiscordCallback />}
                        />

                        <Route path="/" element={<Dashboard />} />
                        <Route path="/profile">
                          <Route index element={<Profile />} />

                          <Route
                            path="my-applications/:id"
                            element={<MyApplication />}
                          />
                        </Route>

                        <Route path="/cops">
                          <Route index element={<CopList />} />
                          <Route
                            element={
                              <RequirePermission
                                permissions={[
                                  "editCops",
                                  "seeCopData",
                                  "editRecruits",
                                  "seeRecruitData",
                                ]}
                              />
                            }
                          >
                            <Route path=":id" element={<Cop />}></Route>
                          </Route>
                        </Route>

                        <Route
                          element={
                            <RequirePermission permissions={["copBasic"]} />
                          }
                        >
                          <Route path="/faq" element={<FAQ />} />
                          <Route
                            path="/useful-links"
                            element={<UsefulLinks />}
                          />
                          <Route path="/calendar" element={<Calendar />} />
                          <Route path="/polls" element={<Polls />} />

                          {/* SELBSTVERWALTUNG */}
                          <Route path="/self-administration">
                            <Route path="add-rating" element={<AddRating />} />
                            <Route
                              path="requested-ratings"
                              element={<RequestedRatings />}
                            />
                            <Route
                              path="add-controlcenter"
                              element={<AddControlcenter />}
                            />
                            <Route
                              path="add-operation"
                              element={<AddOperation />}
                            />
                            <Route
                              path="request-vacation"
                              element={<RequestVacation />}
                            />
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequirePermission
                              permissions={["sendApplications"]}
                            />
                          }
                        >
                          <Route path="/apply" element={<ApplyForm />} />
                        </Route>

                        {/* POLIZEI AKADEMIE */}
                        <Route path="/academy">
                          <Route
                            element={
                              <RequirePermission
                                permissions={["editLearningModules"]}
                              />
                            }
                          >
                            <Route
                              path="modules"
                              element={<LearningModuleList />}
                            />
                          </Route>

                          {/* REKRUTIERUNG */}
                          <Route
                            element={
                              <RequirePermission
                                permissions={["editRecruitmentPhases"]}
                              />
                            }
                          >
                            <Route
                              path="recruitment-phases"
                              element={<RecruitmentPhases />}
                            />
                          </Route>
                          <Route
                            element={
                              <RequirePermission
                                permissions={["editApplications"]}
                              />
                            }
                          >
                            <Route path="applications">
                              <Route index element={<Applications />} />
                              <Route path=":id" element={<Application />} />
                            </Route>
                          </Route>

                          {/* AUSBILDUNG */}
                          <Route
                            element={
                              <RequirePermission
                                permissions={["editRecruits", "seeRecruitData"]}
                              />
                            }
                          >
                            <Route path="recruits">
                              <Route index element={<Recruits />} />
                              <Route path="notes" element={<RecruitNotes />} />
                              <Route
                                path="sanctions"
                                element={<RecruitSanctions />}
                              />
                            </Route>
                          </Route>
                          {/* FORTBILDUNG */}

                          <Route
                            element={
                              <RequirePermission
                                permissions={["postEventsETR"]}
                              />
                            }
                          >
                            <Route
                              path="operational-trainings"
                              element={<OperationalTrainings />}
                            />
                          </Route>
                        </Route>

                        {/* PERSONALRAT */}
                        <Route path="/staff">
                          <Route
                            element={
                              <RequirePermission
                                permissions={[
                                  "readCopStatistics",
                                  "readCopRatioViolations",
                                  "sendMessages",
                                  "readUpcomingUpranks",
                                ]}
                              />
                            }
                          >
                            <Route index element={<Cockpit />} />
                          </Route>

                          <Route
                            element={
                              <RequirePermission
                                permissions={["editVacations"]}
                              />
                            }
                          >
                            <Route path="vacations" element={<Vacations />} />
                          </Route>

                          <Route
                            element={
                              <RequirePermission
                                permissions={["getAllRatings", "editRatings"]}
                              />
                            }
                          >
                            <Route path="ratings" element={<Ratings />} />
                            <Route
                              path="open-ratings"
                              element={<OpenRatings />}
                            />
                          </Route>

                          <Route
                            element={
                              <RequirePermission
                                permissions={["editControlcenters"]}
                              />
                            }
                          >
                            <Route
                              path="controlcenters"
                              element={<Controlcenters />}
                            />
                          </Route>

                          <Route
                            element={
                              <RequirePermission
                                permissions={["editOperations"]}
                              />
                            }
                          >
                            <Route path="operations" element={<Operations />} />
                          </Route>

                          <Route
                            element={
                              <RequirePermission permissions={["editCops"]} />
                            }
                          >
                            <Route path="notes" element={<Notes />} />
                            <Route path="sanctions" element={<Sanctions />} />
                          </Route>
                        </Route>

                        {/* VERWALTUNG */}
                        <Route
                          element={
                            <RequirePermission permissions={["accessRCon"]} />
                          }
                        >
                          <Route path="/rcon" element={<RCon />} />
                        </Route>

                        <Route
                          element={
                            <RequirePermission permissions={["editLinks"]} />
                          }
                        >
                          <Route path="/links" element={<LinkList />} />
                        </Route>
                        <Route
                          element={
                            <RequirePermission permissions={["editNews"]} />
                          }
                        >
                          <Route path="/news" element={<NewsList />} />
                        </Route>

                        <Route
                          path="/playtimes"
                          element={
                            <RequirePermission
                              permissions={["viewPlaytimeTracker"]}
                            />
                          }
                        >
                          <Route path="tracker" element={<PlaytimeTracker />} />
                          <Route path="list" element={<PlaytimeList />} />
                        </Route>

                        <Route
                          element={
                            <RequirePermission
                              permissions={[
                                "getUsersList",
                                "lockUser",
                                "editUserName",
                                "editUserRoles",
                                "setUserApplicationLock",
                              ]}
                            />
                          }
                        >
                          <Route path="/users">
                            <Route index element={<UserList />} />

                            <Route path=":id" element={<User />} />
                          </Route>
                        </Route>

                        <Route element={<RequirePermission permissions={[]} />}>
                          <Route path="/roles" element={<RoleList />} />
                        </Route>

                        <Route path="/logs">
                          <Route
                            element={
                              <RequirePermission
                                permissions={["viewArmoryLogs"]}
                              />
                            }
                          >
                            <Route path="armory" element={<Armory />} />
                          </Route>

                          <Route
                            element={
                              <RequirePermission
                                permissions={["viewExpCalculations"]}
                              />
                            }
                          >
                            <Route
                              path="exp-calculation"
                              element={<ExpCalculation />}
                            />
                          </Route>
                          <Route
                            element={<RequirePermission permissions={[]} />}
                          >
                            <Route path="jobs" element={<Jobs />} />
                          </Route>
                          <Route
                            element={
                              <RequirePermission
                                permissions={["viewDatabaseLogs"]}
                              />
                            }
                          >
                            <Route
                              path="database"
                              element={<DatabaseChanges />}
                            />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                  <Route path="*" element={<FallbackPage />} />
                </Routes>
              </div>
            </ConfirmDialogProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
