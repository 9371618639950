import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const permissionsAdapter = createEntityAdapter();

const initialState = permissionsAdapter.getInitialState();

export const permissionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPermissions: builder.query({
      query: () => ({
        url: "/permissions",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedPermissions = responseData.map((permission) => {
          permission.id = permission._id;
          return permission;
        });

        return permissionsAdapter.setAll(initialState, loadedPermissions);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Permission", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Permission", id })),
          ];
        } else return [{ type: "Permission", id: "LIST" }];
      },
    }),
  }),
});

export const { useGetPermissionsQuery } = permissionsApiSlice;

export const selectPermissionsResult =
  permissionsApiSlice.endpoints.getPermissions.select();

const selectPermissionsData = createSelector(
  selectPermissionsResult,
  (permissionsResult) => permissionsResult.data
);

export const { selectAll: selectAllPermissions } =
  permissionsAdapter.getSelectors(
    (state) => selectPermissionsData(state) ?? initialState
  );
