import { Box } from "@mui/material";
import Header from "components/Header";
import RecruitDataGrid from "features/cops/RecruitDataGrid";
import { useEffect } from "react";

const Recruits = () => {
  useEffect(() => {
    document.title = `Anwärter | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="ANWÄRTER" subtitle="Polizisten in Ausbildung" />
      <RecruitDataGrid />
    </Box>
  );
};

export default Recruits;
