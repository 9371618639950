import { Box } from "@mui/material";

const FallbackPage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
    >
      Hier gibts nichts zu sehen
    </Box>
  );
};
export default FallbackPage;
