import { Autocomplete, Box, TextField, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomCard from "components/CustomCard";
import CustomGridPagination from "components/CustomGridPagination";
import { eventTypes } from "util/copEnums";
import dayjs from "dayjs";
import { memo, useEffect, useState } from "react";
import { dataGridSx } from "styles/dataGridSx";
import { dateTimeColumnType } from "util/dateTimeColumnType";

const renderOrganizedBy = (params) => (
  <Autocomplete
    multiple
    fullWidth
    limitTags={3}
    noOptionsText="Keine Einträge"
    disableCloseOnSelect
    forcePopupIcon={false}
    disableClearable
    readOnly
    value={params.value}
    options={params.value}
    getOptionLabel={(option) => option.user?.name}
    isOptionEqualToValue={(option, value) => option._id === value._id}
    ChipProps={{
      size: "small",
    }}
    renderInput={(props) => (
      <TextField
        {...props}
        sx={{
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
          ".MuiInputBase-input": {
            display: "none",
          },
        }}
      />
    )}
  />
);

const columns = [
  {
    field: "title",
    headerName: "Titel",
    flex: 1,
    maxWidth: 150,
  },
  {
    field: "type",
    headerName: "Art",
    type: "singleSelect",
    valueOptions: eventTypes,
    flex: 1,
    maxWidth: 100,
  },
  {
    field: "start",
    headerName: "Start",
    ...dateTimeColumnType,
    flex: 1,
    maxWidth: 120,
  },
  {
    field: "end",
    headerName: "Ende",
    ...dateTimeColumnType,
    flex: 1,
    maxWidth: 120,
  },
  {
    field: "organizedBy",
    headerName: "Organisiert von",
    renderCell: renderOrganizedBy,
    flex: 1,
  },
  {
    field: "processedAt",
    headerName: "Verrechnet am",
    ...dateTimeColumnType,
    valueGetter: ({ row }) => dayjs(row.participation?.processedAt),
    flex: 1,
    maxWidth: 120,
  },
  {
    field: "processedBy",
    headerName: "Verrechnet von",
    valueGetter: ({ row }) => row.participation?.processedBy?.name,
    flex: 1,
    maxWidth: 150,
  },
];

const CopEvents = ({ eventsQuery }) => {
  const theme = useTheme();

  const { data: events, isSuccess, isLoading } = eventsQuery;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setRows(events.ids.map((id) => events.entities[id]));
    }
  }, [events, isSuccess]);

  return (
    <CustomCard title="ABSOLVIERTE VERANSTALTUNGEN">
      <Box height="350px" width="100%" sx={dataGridSx(theme)}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "processedAt", sort: "desc" }],
            },
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          density="compact"
          disableRowSelectionOnClick
          columns={columns}
          rows={isSuccess ? rows : []}
          loading={isLoading}
          slots={{ pagination: CustomGridPagination }}
          pageSizeOptions={[5, 10, 25]}
          sx={{
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
          }}
        />
      </Box>
    </CustomCard>
  );
};
export default memo(CopEvents);
