import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const playtimesAdapter = createEntityAdapter();

const initialState = playtimesAdapter.getInitialState();

export const playtimesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPlaytimes: builder.query({
      query: (params) => {
        const query = {
          url: "/playtimes",
        };

        if (params) query.params = { ...params };

        return query;
      },
      transformResponse: (responseData) => {
        const loadedPlaytimes = responseData.map((playtime) => {
          playtime.id = playtime._id;
          return playtime;
        });

        return playtimesAdapter.setAll(initialState, loadedPlaytimes);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Playtime", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Playtime", id })),
          ];
        } else return [{ type: "Playtime", id: "LIST" }];
      },
    }),
  }),
});

export const { useGetPlaytimesQuery } = playtimesApiSlice;

export const selectPlaytimesResult =
  playtimesApiSlice.endpoints.getPlaytimes.select();

const selectPlaytimesData = createSelector(
  selectPlaytimesResult,
  (playtimesResult) => playtimesResult.data
);

export const { selectAll: selectAllPlaytimes, selectIds: selectPlaytimeIds } =
  playtimesAdapter.getSelectors(
    (state) => selectPlaytimesData(state) ?? initialState
  );
