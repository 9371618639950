import { Box, Chip, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridPagination from "components/CustomGridPagination";
import { memo } from "react";
import { dataGridSx } from "styles/dataGridSx";
import { dateTimeColumnType } from "util/dateTimeColumnType";

const PollVoteDataGrid = ({ votes }) => {
  const theme = useTheme();

  const renderVote = ({ value }) => (
    <Chip
      label={value}
      size="small"
      color={
        {
          Ja: "success",
          Nein: "error",
          Enthaltung: "default",
        }[value] ?? "default"
      }
    />
  );

  const columns = [
    {
      field: "cop",
      headerName: "Name",
      valueGetter: ({ value }) => value?.user?.name,
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "vote",
      headerName: "Stimme",
      renderCell: renderVote,
      flex: 1,
    },
    {
      field: "comment",
      headerName: "Kommentar",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Geändert am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
  ];

  return (
    <Box
      height="100%"
      sx={{
        ...dataGridSx(theme),
        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
      }}
    >
      <DataGrid
        columns={columns}
        rows={votes || []}
        rowSelection={false}
        density="compact"
        getRowId={(row) => row._id}
        initialState={{
          sorting: {
            sortModel: [{ field: "cop", sort: "asc" }],
          },
        }}
        slots={{
          pagination: CustomGridPagination,
        }}
      />
    </Box>
  );
};
export default memo(PollVoteDataGrid);
