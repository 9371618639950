import { Box, Chip, useTheme } from "@mui/material";
import {
  useDeleteOperationMutation,
  useGetOperationsQuery,
  useUpdateOperationMutation,
} from "./operationsApiSlice";
import { useGetCopsQuery } from "features/cops/copsApiSlice";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "contexts/Snackbar.context";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import { Link } from "react-router-dom";
import { dateColumnType, dateTimeColumnType } from "util/dateTimeColumnType";
import { serverPeriods } from "util/copEnums";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { dataGridSx } from "styles/dataGridSx";
import CopsAutocomplete from "components/CopsAutocomplete";
import CustomGridPagination from "components/CustomGridPagination";
import Textarea from "components/Textarea";
import { calcGridHeightSubtraction } from "util/calculations";

const OperationDataGrid = () => {
  const theme = useTheme();

  const {
    data: operations,
    isSuccess: getOperationsIsSuccess,
    isLoading: getOperationsIsLoading,
  } = useGetOperationsQuery("operationsList", {
    refetchOnMountOrArgChange: true,
  });

  const { data: cops, isSuccess: getCopsIsSuccess } = useGetCopsQuery(
    "copsList",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [updateOperation] = useUpdateOperationMutation();
  const [deleteOperation] = useDeleteOperationMutation();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const showSnackbar = useSnackbar();

  useEffect(() => {
    if (getOperationsIsSuccess) {
      setRows(
        operations?.ids.map((id) => ({
          id,
          cop: operations.entities[id].cop,
          involvedCops: operations.entities[id].involvedCops.map(
            (cop) => cop._id
          ),
          date: operations.entities[id].date,
          period: operations.entities[id].period,
          report: operations.entities[id].report,
          updatedBy: operations.entities[id].updatedBy,
          updatedAt: operations.entities[id].updatedAt,
          createdAt: operations.entities[id].createdAt,
        }))
      );
    }
  }, [operations, getOperationsIsSuccess]);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const showConfirmDialog = useConfirmDialog();

  const handleDelete = useCallback(async (id) => {
    try {
      await deleteOperation(id).unwrap();
      showSnackbar("Einsatz gelöscht");
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = (id) => () => {
    showConfirmDialog({
      title: "Einsatz löschen?",
      message: "Soll der Einsatz gelöscht werden?",
      onConfirm: () => handleDelete(id),
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    if (newRow.isNew) {
    } else {
      await updateOperation(newRow).unwrap();
      showSnackbar("Einsatz gespeichert");
    }

    return newRow;
  };

  const handleProcessRowUpdateError = (error) => {
    showSnackbar(error.data?.message || "Fehler", "error");
  };

  const renderCopName = ({ value, row }) => (
    <Link
      to={`/cops/${row.cop?._id}`}
      style={{ color: theme.palette.info.main, textDecoration: "none" }}
    >
      {value}
    </Link>
  );

  const renderInvolvedCops = (params) => {
    return getCopsIsSuccess ? (
      <CopsAutocomplete
        cops={cops}
        autocompleteProps={{
          readOnly: true,
          forcePopupIcon: false,
          value: params.value,
          limitTags: 3,
          renderTags: (value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={cops.entities[option].user?.name}
                clickable
                component={Link}
                to={`/cops/${cops.entities[option]._id}`}
              />
            )),
        }}
        inputProps={{
          sx: {
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
          },
        }}
      />
    ) : (
      <></>
    );
  };

  const InvolvedCopsEditInput = (params) => {
    const { id, field } = params;

    const apiRef = useGridApiContext();

    const handleChange = (event, newValue) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return getCopsIsSuccess ? (
      <CopsAutocomplete
        cops={cops}
        autocompleteProps={{
          readOnly: false,
          value: params.value,
          limitTags: 3,
          onChange: handleChange,
        }}
        inputProps={{
          sx: {
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
          },
        }}
      />
    ) : (
      <></>
    );
  };

  const renderInvolvedCopsEdit = (params) => (
    <InvolvedCopsEditInput {...params} />
  );

  const columns = [
    {
      field: "cop",
      headerName: "Eingetragen von",
      valueGetter: ({ value }) => value?.user?.name,
      renderCell: renderCopName,
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "involvedCops",
      headerName: "Beteiligte Polizisten",
      renderCell: renderInvolvedCops,
      renderEditCell: renderInvolvedCopsEdit,
      flex: 1,
      editable: true,
    },
    {
      field: "date",
      headerName: "Datum",
      ...dateColumnType,
      flex: 1,
      maxWidth: 110,
      editable: true,
    },
    {
      field: "period",
      headerName: "Server Periode",
      flex: 1,
      maxWidth: 150,
      type: "singleSelect",
      valueOptions: serverPeriods,
      editable: true,
    },
    {
      field: "report",
      headerName: "Bericht",
      renderEditCell: (params) => <Textarea {...params} />,
      flex: 1,
      editable: true,
    },
    {
      field: "updatedAt",
      headerName: "Geändert am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "updatedBy",
      headerName: "Geändert von",
      valueGetter: ({ value }) => value?.name,
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "actions",
      headerName: "Aktionen",
      type: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Speichern"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Bearbeiten"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Löschen"
            onClick={handleDeleteClick(id)}
            color="error"
          />,
        ];
      },
    },
  ];

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        columns={columns}
        rows={getOperationsIsSuccess ? rows : []}
        loading={getOperationsIsLoading}
        editMode="row"
        disableRowSelectionOnClick
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        getRowHeight={() => "auto"}
        sx={{
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
        slots={{
          pagination: CustomGridPagination,
        }}
      />
    </Box>
  );
};
export default OperationDataGrid;
