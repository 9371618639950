import { apiSlice } from "../../app/api/apiSlice";

import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const copsAdapter = createEntityAdapter();

const initialState = copsAdapter.getInitialState();

export const copsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCops: builder.query({
      query: () => ({
        url: "/cops",
      }),
      transformResponse: (responseData) => {
        const loadedCops = responseData.map((cop) => {
          cop.id = cop._id;
          return cop;
        });

        return copsAdapter.setAll(initialState, loadedCops);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Cop", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Cop", id })),
          ];
        } else return [{ type: "Cop", id: "LIST" }];
      },
    }),
    addCop: builder.mutation({
      query: (cop) => ({
        url: "/cops",
        method: "POST",
        body: { ...cop },
      }),
      invalidatesTags: [{ type: "Cop", id: "LIST" }],
    }),
    updateCop: builder.mutation({
      query: (copData) => ({
        url: "/cops",
        method: "PATCH",
        body: { ...copData },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Cop", id: arg.id }],
    }),
    createCop: builder.mutation({
      query: (copData) => ({
        url: "/cops/create",
        method: "POST",
        body: { ...copData },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Cop", id: arg.id }],
    }),
    updateCareer: builder.mutation({
      query: (data) => ({
        url: "/cops/update-career",
        method: "PATCH",
        body: { ...data },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Cop", id: arg.id }],
    }),
    addCopModule: builder.mutation({
      query: (data) => ({
        url: "/cops/modules",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Cop", id: arg.id }],
    }),
    removeCopModule: builder.mutation({
      query: (data) => ({
        url: "/cops/modules",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Cop", id: arg.id }],
    }),
  }),
});

export const {
  useGetCopsQuery,
  useAddCopMutation,
  useUpdateCopMutation,
  useAddCopModuleMutation,
  useRemoveCopModuleMutation,
  useCreateCopMutation,
  useUpdateCareerMutation,
} = copsApiSlice;

export const selectCopsResult = copsApiSlice.endpoints.getCops.select();

const selectCopsData = createSelector(
  selectCopsResult,
  (copsResult) => copsResult.data
);

export const {
  selectAll: selectAllCops,
  selectById: selectCopById,
  selectIds: selectCopIds,
} = copsAdapter.getSelectors((state) => selectCopsData(state) ?? initialState);
