import { Box } from "@mui/material";
import Header from "components/Header";
import RoleDataGrid from "features/roles/RoleDataGrid";
import { useEffect } from "react";

const RoleList = () => {
  useEffect(() => {
    document.title = `Rollen | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header
        title="ROLLEN"
        subtitle="Zuordnung von Berechtigungen zu Benutzerrollen"
      />
      <RoleDataGrid />
    </Box>
  );
};
export default RoleList;
