import {
  Box,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CopMainData from "./CopMainData";
import { memo, useCallback, useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import { hasPermission } from "util/permissionHelper";
import CopCareerChart from "./CopCareerChart";
import CopLearningModules from "./CopLearningModules";
import CopRatings from "./CopRatings";
import CopInfos from "./CopInfos";
import CopEvents from "./CopEvents";
import CopVacations from "./CopVacations";
import { useGetCopsQuery } from "./copsApiSlice";
import { useParams } from "react-router-dom";
import CopActivityChart from "./CopActivityChart";
import { useGetCopEventsQuery } from "features/events/eventsApiSlice";
import { useGetCopRatingsQuery } from "features/ratings/ratingsApiSlice";
import { useGetCopVacationsQuery } from "features/vacations/vacactionsApiSlice";

const scrollTo = (id) => {
  const element = document.getElementById(id);

  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const CopData = () => {
  const [canEditCop, setCanEditCop] = useState(false);
  const [tab, setTab] = useState("main");
  const theme = useTheme();
  const xlBreakpoint = useMediaQuery("(min-width: 1536px)");

  const { user: currentUser, roles } = useAuth();

  const { id } = useParams();

  const { cop } = useGetCopsQuery("copsList", {
    selectFromResult: ({ data }) => ({
      cop: data?.entities[id],
    }),
  });

  const eventsQuery = useGetCopEventsQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const ratingsQuery = useGetCopRatingsQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const vacationsQuery = useGetCopVacationsQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const sections = document.querySelectorAll("section");

    // TODO: Fix
    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const top = section.offsetTop;
        const height = section.clientHeight;

        if (window.scrollY >= top - height / 3) {
          setTab(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    if (cop.user?._id === currentUser._id) {
      setCanEditCop(hasPermission({ roles, permissions: [] }));
    } else {
      setCanEditCop(
        hasPermission({
          roles,
          permissions:
            cop.status === "Gekündigt"
              ? ["reappointCops"]
              : cop.rank === "CA"
              ? ["editRecruits", "editCops"]
              : ["editCops"],
        })
      );
    }
    // eslint-disable-next-line
  }, [cop, roles]);

  const handleChange = useCallback((_, value) => {
    setTab(value);
  }, []);

  if (!cop) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: theme.palette.background.default,
          zIndex: 999,
          mb: 1,
        }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label="Allgemeines"
            value="main"
            onClick={() => scrollTo("main")}
          />
          <Tab
            label="Aktivität"
            value="activity"
            onClick={() => scrollTo("activity")}
          />
          <Tab
            label="Karriere"
            value="career"
            onClick={() => scrollTo("career")}
          />
          <Tab
            label="Urlaub"
            value="vacation"
            onClick={() => scrollTo("vacation")}
          />
          <Tab
            label="Absolvierte Module"
            value="modules"
            onClick={() => scrollTo("modules")}
          />
          <Tab
            label="Absolvierte Veranstaltungen"
            value="events"
            onClick={() => scrollTo("events")}
          />
          <Tab
            label="Notizen / Bestrafungen"
            value="infos"
            onClick={() => scrollTo("infos")}
          />
          <Tab
            label="Bewertungen"
            value="ratings"
            onClick={() => scrollTo("ratings")}
          />
        </Tabs>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={3}>
          <section
            id="main"
            style={{
              scrollMarginTop: "20rem",
              height: "100%",
            }}
          >
            <CopMainData cop={cop} canEditCop={canEditCop} />
          </section>
        </Grid>

        <Grid item xs={12} xl={9}>
          <Box
            display="grid"
            gap={2}
            gridTemplateColumns="repeat(12, 1fr)"
            gridTemplateRows="1fr auto"
            height="100%"
          >
            <Box gridColumn="span 12">
              <section
                id="activity"
                style={{
                  scrollMarginTop: "3rem",
                  height: "100%",
                }}
              >
                <CopActivityChart playtimes={cop.playtimes} />
              </section>
            </Box>

            <Box gridColumn={xlBreakpoint ? "span 5" : "span 12"}>
              <section id="career" style={{ scrollMarginTop: "3rem" }}>
                <CopCareerChart copId={cop.id} career={cop.career} />
              </section>
            </Box>
            <Box gridColumn={xlBreakpoint ? "span 7" : "span 12"}>
              <section id="vacation" style={{ scrollMarginTop: "3rem" }}>
                <CopVacations vacationsQuery={vacationsQuery} />
              </section>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} xl={4}>
          <section id="modules" style={{ scrollMarginTop: "3rem" }}>
            <CopLearningModules
              copId={cop.id}
              learningModules={cop.learningModules}
              canEditCop={canEditCop}
            />
          </section>
        </Grid>

        <Grid item xs={12} xl={8}>
          <section id="events" style={{ scrollMarginTop: "3rem" }}>
            <CopEvents eventsQuery={eventsQuery} />
          </section>
        </Grid>

        <Grid item xs={12}>
          <section id="infos" style={{ scrollMarginTop: "3rem" }}>
            <CopInfos copId={cop.id} canEditCop={canEditCop} />
          </section>
        </Grid>

        <Grid item xs={12}>
          <section id="ratings" style={{ scrollMarginTop: "3rem" }}>
            <CopRatings
              ratingsQuery={ratingsQuery}
              copId={cop._id}
              canEditCop={canEditCop}
            />
          </section>
        </Grid>
      </Grid>
    </Box>
  );
};
export default memo(CopData);
