import { Box, Tab, Tabs } from "@mui/material";
import Header from "components/Header";
import CopRatio from "features/copRatioViolations/CopRatio";
import CopStatistics from "features/copStatistics/CopStatistics";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { hasPermission } from "util/permissionHelper";

const Cockpit = () => {
  const [tab, setTab] = useState("copRatio");
  const { roles } = useAuth();

  useEffect(() => {
    document.title = `Personalrat | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="PERSONALRAT" subtitle="Allgemeines Monitoring" />
      <Box
        mb={2}
        sx={{ borderBottom: 1, borderColor: "divider" }}
        id="staff-cockpit-tabs"
      >
        <Tabs value={tab} onChange={(_, value) => setTab(value)}>
          {hasPermission({
            roles,
            permissions: ["readCopRatioViolations"],
          }) && <Tab label="Cop Quote" value="copRatio" />}

          {hasPermission({ roles, permissions: ["readUpcomingUpranks"] }) && (
            <Tab label="Bevorstehende Beförderungen" value="upranks" />
          )}

          {hasPermission({
            roles,
            permissions: ["sendMessages", "editCops"],
          }) && <Tab label="Sammelfunktionen" value="massMaintain" />}

          {hasPermission({ roles, permissions: ["readCopStatistics"] }) && (
            <Tab label="Statistiken" value="statistics" />
          )}
        </Tabs>
      </Box>
      {tab === "copRatio" && <CopRatio />}
      {tab === "upranks" && <>Beförderungen 🚧</>}
      {tab === "massMaintain" && <>Sammelfunktionen 🚧</>}
      {tab === "statistics" && <CopStatistics />}
    </Box>
  );
};
export default Cockpit;
