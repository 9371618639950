import { apiSlice } from "app/api/apiSlice";

export const activePlayersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActivePlayers: builder.query({
      query: () => ({
        url: "/active-players",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
    }),
  }),
});

export const { useGetActivePlayersQuery } = activePlayersApiSlice;
