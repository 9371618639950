import { useGetVacationsQuery } from "./vacactionsApiSlice";
import TextBadge from "components/TextBadge";

const NewVacationsBadge = ({ children, ...props }) => {
  const { data: vacations, isSuccess } = useGetVacationsQuery("vacationsList", {
    refetchOnMountOrArgChange: false,
  });

  return (
    <TextBadge
      color="error"
      badgeContent={
        isSuccess
          ? vacations.ids.filter(
              (id) => vacations.entities[id].status === "Neu"
            ).length
          : 0
      }
      {...props}
    >
      {children}
    </TextBadge>
  );
};
export default NewVacationsBadge;
