import { Box } from "@mui/material";
import Header from "components/Header";
import NewsDataGrid from "features/news/NewsDataGrid";
import { useEffect } from "react";

const NewsList = () => {
  useEffect(() => {
    document.title = `News | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="NEWS" subtitle="Hinzufügen / Bearbeiten von Neuigkeiten" />
      <NewsDataGrid />
    </Box>
  );
};
export default NewsList;
