import { Box } from "@mui/material";
import Header from "components/Header";
import ApplicationDataGrid from "features/applications/ApplicationDataGrid";
import { useEffect } from "react";

const Applications = () => {
  useEffect(() => {
    document.title = `Bewerbungen | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="BEWERBUNGEN" subtitle="Eingegangene Bewerbungen" />
      <ApplicationDataGrid />
    </Box>
  );
};

export default Applications;
