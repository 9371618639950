import { Box, Tab, Tabs, Tooltip, Typography, useTheme } from "@mui/material";
import Chat from "components/Chat";
import { useState } from "react";
import {
  useDeleteMessageMutation,
  useDeleteRecruiterCommentMutation,
  usePostMessageMutation,
  usePostRecruiterCommentMutation,
} from "./applicationsApiSlice";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CustomCard from "components/CustomCard";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box mt="24px">{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => ({
  id: `tab-${index}`,
  "aria-controls": `tabpanel-${index}`,
});

const ApplicationCommunication = ({ application }) => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const [postComment] = usePostRecruiterCommentMutation();
  const [deleteComment] = useDeleteRecruiterCommentMutation();
  const [postMessage] = usePostMessageMutation();
  const [deleteMessage] = useDeleteMessageMutation();

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <CustomCard>
      <Box display="flex" alignItems="center">
        <Tabs
          value={value}
          onChange={handleTabChange}
          TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
          }}
          sx={{
            mt: "-12px",
            ml: "-16px",
            "& .MuiTabs-indicator": {
              display: "flex",
              justifyContent: "center",
              backgroundColor: "transparent",
            },
            "& .MuiTabs-indicatorSpan": {
              maxWidth: 70,
              width: "100%",
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          <Tab
            label={
              <Typography variant="subtitle1" letterSpacing={1}>
                Kommentare
              </Typography>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Typography variant="subtitle1" letterSpacing={1}>
                Nachrichten
              </Typography>
            }
            {...a11yProps(1)}
          />
        </Tabs>

        <Tooltip
          arrow
          title={
            <Typography textAlign="center" variant="subtitle1">
              Nachrichten können vom Bewerber gesehen werden, Kommentare nicht
            </Typography>
          }
        >
          <HelpOutlineOutlinedIcon
            color="info"
            sx={{
              ml: "16px",
            }}
          />
        </Tooltip>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Chat
          placeholder="Kommentar"
          noMessagesText="Noch keine Kommentare vorhanden"
          messages={application.recruiterComments}
          onSubmit={async (comment) => {
            await postComment({ id: application.id, message: comment });
          }}
          onDelete={async (commentId) => {
            await deleteComment({ id: application.id, commentId: commentId });
          }}
          windowMinHeight={200}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Chat
          placeholder="Nachricht an Bewerber"
          messages={application.messages}
          onSubmit={async (message) => {
            await postMessage({ id: application.id, message: message });
          }}
          onDelete={async (messageId) => {
            await deleteMessage({ id: application.id, messageId: messageId });
          }}
          windowMinHeight={200}
          textInputDisabled={application.isClosed}
          deleteMessageDisabled={application.isClosed}
        />
      </CustomTabPanel>
    </CustomCard>
  );
};
export default ApplicationCommunication;
