import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  TextField,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { ratingFunctions } from "util/copEnums";
import dayjs from "dayjs";
import { memo, useEffect, useMemo, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const CopRatingsChart = ({ ratings }) => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 1024px");

  const [rawData, setRawData] = useState([]);
  const [hiddenIds, setHiddenIds] = useState([]);
  const [functionFilter, setFunctionFilter] = useState([]);
  const [initialized, setInitialized] = useState(false);

  const data = useMemo(
    () => rawData.filter((item) => !hiddenIds.includes(item.id)) || [],
    [hiddenIds, rawData]
  );

  useEffect(() => {
    if (ratings.length > 0 && !initialized) {
      setFunctionFilter(
        ratingFunctions.filter(
          (ratingFunction) =>
            ratings.filter((rating) => rating.rated.function === ratingFunction)
              .length > 0
        )
      );
      setInitialized(true);
    }
    // eslint-disable-next-line
  }, [ratings]);

  useEffect(() => {
    const roleplayData = {
      id: "roleplay",
      label: "Roleplay",
      color: theme.palette.secondary.main,
      data: [],
    };

    const performanceData = {
      id: "performance",
      label: "Leistung unter Stress",
      color: theme.palette.primary.main,
      data: [],
    };

    const tacticsData = {
      id: "tactics",
      label: "Taktisches Verständnis",
      color: theme.palette.tertiary.main,
      data: [],
    };

    const radioData = {
      id: "radio",
      label: "Funkdiziplin",
      color: theme.palette.quaternary.main,
      data: [],
    };

    const knowledgeData = {
      id: "knowledge",
      label: "Fachwissen",
      color: theme.palette.quinary.main,
      data: [],
    };

    // Bewertungsdurchschnitte pro Tag berechnen
    const uniqueDates = [...new Set(ratings.map((rating) => rating.date))].sort(
      (a, b) => new Date(a) - new Date(b)
    );

    uniqueDates.forEach((date) => {
      const ratingsSums = ratings.reduce(
        (sums, rating) => {
          if (
            rating.date === date &&
            functionFilter.includes(rating.rated.function)
          ) {
            if (rating.ratings?.roleplay > 0) {
              sums.roleplay.sum += rating.ratings.roleplay;
              sums.roleplay.counter += 1;
            }

            if (rating.ratings?.performance > 0) {
              sums.performance.sum += rating.ratings.performance;
              sums.performance.counter += 1;
            }

            if (rating.ratings?.tactics > 0) {
              sums.tactics.sum += rating.ratings.tactics;
              sums.tactics.counter += 1;
            }

            if (rating.ratings?.radio > 0) {
              sums.radio.sum += rating.ratings.radio;
              sums.radio.counter += 1;
            }

            if (rating.ratings?.knowledge > 0) {
              sums.knowledge.sum += rating.ratings.knowledge;
              sums.knowledge.counter += 1;
            }
          }

          return sums;
        },
        {
          roleplay: {
            sum: 0,
            counter: 0,
          },
          performance: {
            sum: 0,
            counter: 0,
          },
          tactics: {
            sum: 0,
            counter: 0,
          },
          radio: {
            sum: 0,
            counter: 0,
          },
          knowledge: {
            sum: 0,
            counter: 0,
          },
        }
      );

      if (ratingsSums.roleplay.counter > 0) {
        roleplayData.data.push({
          x: new Date(date),
          y:
            Math.round(
              (ratingsSums.roleplay.sum / ratingsSums.roleplay.counter) * 100
            ) / 100,
        });
      }

      if (ratingsSums.performance.counter > 0) {
        performanceData.data.push({
          x: new Date(date),
          y:
            Math.round(
              (ratingsSums.performance.sum / ratingsSums.performance.counter) *
                100
            ) / 100,
        });
      }

      if (ratingsSums.tactics.counter > 0) {
        tacticsData.data.push({
          x: new Date(date),
          y:
            Math.round(
              (ratingsSums.tactics.sum / ratingsSums.tactics.counter) * 100
            ) / 100,
        });
      }

      if (ratingsSums.radio.counter > 0) {
        radioData.data.push({
          x: new Date(date),
          y:
            Math.round(
              (ratingsSums.radio.sum / ratingsSums.radio.counter) * 100
            ) / 100,
        });
      }

      if (ratingsSums.knowledge.counter > 0) {
        knowledgeData.data.push({
          x: new Date(date),
          y:
            Math.round(
              (ratingsSums.knowledge.sum / ratingsSums.knowledge.counter) * 100
            ) / 100,
        });
      }
    });

    setRawData(
      [roleplayData, performanceData, tacticsData, radioData, knowledgeData] ||
        []
    );
  }, [ratings, theme.palette, functionFilter]);

  return (
    <Box>
      <Box maxWidth="800px" mt="16px" ml="60px">
        <Autocomplete
          value={functionFilter}
          options={ratingFunctions}
          multiple
          getOptionDisabled={(option) =>
            ratings.filter((rating) => rating.rated.function === option)
              .length === 0
          }
          disableCloseOnSelect
          disableClearable
          noOptionsText="Keine Optionen"
          onChange={(_, value) => {
            if (
              ratings.filter((rating) => value.includes(rating.rated.function))
                .length === 0
            ) {
              return;
            }
            setFunctionFilter(value);
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(props) => (
            <TextField {...props} size="small" label="An Funktion" />
          )}
          ChipProps={{
            disabled: functionFilter?.length < 2,
          }}
        />
      </Box>

      <Box
        height="400px"
        overflow="visible"
        sx={{
          svg: {
            overflow: "visible",
          },
        }}
      >
        {data.length > 0 ? (
          <ResponsiveLine
            data={data}
            colors={data.map((series) => series.color)}
            theme={{
              grid: {
                line: {
                  stroke: alpha(theme.palette.text.secondary, 0.15),
                },
              },
              axis: {
                legend: {
                  text: {
                    fill: theme.palette.text.secondary,
                  },
                },
                ticks: {
                  text: {
                    fill: theme.palette.text.secondary,
                  },
                  line: {
                    stroke: alpha(theme.palette.text.secondary, 0.15),
                  },
                },
              },
              tooltip: {
                container: {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.background.default,
                },
              },
              legends: {
                text: {
                  fill: theme.palette.text.secondary,
                },
                hidden: { text: { textDecoration: "line-through" } },
              },
            }}
            margin={{ top: 20, right: 30, bottom: 80, left: 60 }}
            xScale={{
              type: "time",
              precision: "day",
              max: "auto",
              min: "auto",
            }}
            xFormat={(value) => dayjs(value).format("DD.MM.YY")}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            yFormat={(value) => value}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 10,
              tickValues: isNonMobile ? 8 : 5,
              tickPadding: 10,
              tickRotation: 0,
              legend: "",
              legendOffset: 0,
              legendPosition: "middle",
              truncateTickAt: 0,
              format: (value) => dayjs(value).format("DD.MM.YY"),
            }}
            axisLeft={{
              tickSize: 10,
              tickValues: [1, 2, 3, 4, 5],
              tickPadding: 10,
              tickRotation: 0,
              legend: "Bewertung",
              legendOffset: -40,
              legendPosition: "middle",
              truncateTickAt: 0,
              format: (value) => value,
            }}
            pointSize={10}
            pointColor={theme.palette.background.paper}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            enableCrosshair={false}
            useMesh={true}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                data: rawData.map((item) => ({
                  color: hiddenIds.includes(item.id)
                    ? alpha(item.color, 0.1)
                    : item.color,
                  id: item.id,
                  label: item.label,
                })),
                onClick: (datum) => {
                  if (hiddenIds.includes(datum.id)) {
                    setHiddenIds((state) =>
                      state.filter((item) => item !== datum.id)
                    );
                  } else {
                    if (hiddenIds.length >= 4) return;

                    setHiddenIds((state) => [...state, datum.id]);
                  }
                },
                justify: false,
                translateY: 65,
                itemsSpacing: 30,
                itemDirection: "left-to-right",
                itemWidth: 120,
                itemHeight: 20,
                itemOpacity: 1,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            curve="catmullRom"
          />
        ) : (
          <></>
        )}
        <Divider />
      </Box>
    </Box>
  );
};
export default memo(CopRatingsChart);
