import { Box } from "@mui/material";
import Header from "components/Header";
import UserDataGrid from "features/users/UserDataGrid";
import { useEffect } from "react";

const UserList = () => {
  useEffect(() => {
    document.title = `Benutzer | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="BENUTZER" subtitle="Auflistung registrierter Benutzer" />
      <UserDataGrid />
    </Box>
  );
};

export default UserList;
