import { Box, Chip, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "contexts/Snackbar.context";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
} from "@mui/x-data-grid";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import { Link } from "react-router-dom";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { dataGridSx } from "styles/dataGridSx";
import CustomGridPagination from "components/CustomGridPagination";
import {
  useDeleteVacationMutation,
  useGetVacationsQuery,
  useUpdateVacationMutation,
} from "./vacactionsApiSlice";
import { getVacationStatusColor } from "util/muiColorGetter";
import { calcGridHeightSubtraction } from "util/calculations";

const VacationDataGrid = () => {
  const theme = useTheme();

  const {
    data: vacations,
    isSuccess: getVacationsIsSuccess,
    isLoading: getVacationsIsLoading,
  } = useGetVacationsQuery("vacationsList", {
    refetchOnMountOrArgChange: true,
  });

  const [updateVacation] = useUpdateVacationMutation();
  const [deleteVacation] = useDeleteVacationMutation();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const showSnackbar = useSnackbar();

  useEffect(() => {
    if (getVacationsIsSuccess) {
      setRows(
        vacations?.ids.map((id) => ({
          id,
          cop: vacations.entities[id].cop,
          from: vacations.entities[id].from,
          until: vacations.entities[id].until,
          status: vacations.entities[id].status,
          reason: vacations.entities[id].reason,
          comment: vacations.entities[id].comment,
          updatedBy: vacations.entities[id].updatedBy,
          updatedAt: vacations.entities[id].updatedAt,
          createdAt: vacations.entities[id].createdAt,
        }))
      );
    }
  }, [vacations, getVacationsIsSuccess]);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const showConfirmDialog = useConfirmDialog();

  const handleDelete = useCallback(async (id) => {
    try {
      await deleteVacation(id).unwrap();
      showSnackbar("Urlaub gelöscht");
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = (id) => () => {
    showConfirmDialog({
      title: "Urlaub löschen?",
      message: "Soll der Urlaub gelöscht werden?",
      onConfirm: () => handleDelete(id),
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    if (newRow.isNew) {
    } else {
      await updateVacation(newRow).unwrap();
      showSnackbar("Urlaub gespeichert");
    }

    return newRow;
  };

  const handleProcessRowUpdateError = (error) => {
    showSnackbar(error.data?.message || "Fehler", "error");
  };

  const renderCopName = ({ value, row }) => (
    <Link
      to={`/cops/${row?.cop?._id}`}
      style={{ color: theme.palette.info.main, textDecoration: "none" }}
    >
      {value}
    </Link>
  );

  const renderStatus = ({ value }) => (
    <Chip label={value} color={getVacationStatusColor(value)} />
  );

  const columns = [
    {
      field: "cop",
      headerName: "Beantragt von",
      valueGetter: ({ value }) => value.user?.name,
      renderCell: renderCopName,
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "from",
      headerName: "Von",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 130,
      editable: true,
    },
    {
      field: "until",
      headerName: "Bis",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 130,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 150,
      renderCell: renderStatus,
      type: "singleSelect",
      valueOptions: ["Neu", "Angenommen", "Abgelehnt"],
      editable: true,
    },
    {
      field: "reason",
      headerName: "Grund",
      flex: 1,
    },
    {
      field: "comment",
      headerName: "Kommentar",
      flex: 1,
      maxWidth: 400,
      editable: true,
    },
    {
      field: "updatedAt",
      headerName: "Geändert am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "updatedBy",
      headerName: "Geändert von",
      valueGetter: ({ value }) => value?.name,
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "actions",
      headerName: "Aktionen",
      type: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Speichern"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Bearbeiten"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Löschen"
            onClick={handleDeleteClick(id)}
            color="error"
          />,
        ];
      },
    },
  ];

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        columns={columns}
        rows={getVacationsIsSuccess ? rows : []}
        loading={getVacationsIsLoading}
        editMode="row"
        disableRowSelectionOnClick
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        sx={{
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
        slots={{
          pagination: CustomGridPagination,
        }}
      />
    </Box>
  );
};
export default VacationDataGrid;
