import { Box } from "@mui/material";
import CustomCard from "components/CustomCard";
import Header from "components/Header";
import dayjs from "dayjs";
import OperationForm from "features/operations/OperationForm";
import { useEffect } from "react";

const AddOperation = () => {
  useEffect(() => {
    document.title = `Einsatz eintragen | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box>
      <Header title="EINSATZ EINTRAGEN" subtitle="Einsatzbericht abgeben" />
      <Box maxWidth="700px">
        <CustomCard>
          <OperationForm
            initialValues={{
              involvedCops: [],
              date: dayjs(),
              period: "",
              report: "",
              requestRatings: true,
            }}
          />
        </CustomCard>
      </Box>
    </Box>
  );
};
export default AddOperation;
