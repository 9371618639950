import { useGetApplicationFileQuery } from "./applicationsApiSlice";
import { Box, Chip, CircularProgress } from "@mui/material";
import { useMemo } from "react";

const ApplicationFileView = ({ id }) => {
  const { data, isSuccess, isError, error } = useGetApplicationFileQuery(id);

  const file = useMemo(
    () =>
      isSuccess
        ? {
            data: data.file?.data,
          }
        : null,
    [data, isSuccess]
  );

  const blob = useMemo(() => {
    if (!isSuccess) return null;

    return new Blob([new Uint8Array(file.data, 0, file.data.length)], {
      type: "application/pdf",
    });
  }, [file, isSuccess]);

  if (isError)
    return (
      <Chip
        label={error.data?.message || "Fehler beim lesen der Datei"}
        color="error"
      />
    );

  if (!blob && !isError) return <CircularProgress />;

  return (
    <Box display="flex">
      <iframe
        id="pdf-iframe"
        title="pdf-view"
        src={`${window.URL.createObjectURL(blob)}#zoom=100`}
        width="100%"
        height="1180px"
        style={{
          border: "none",
        }}
      />
    </Box>
  );
};
export default ApplicationFileView;
