import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const infosAdapter = createEntityAdapter();

const initialState = infosAdapter.getInitialState();

export const infosApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInfos: builder.query({
      query: () => ({
        url: "/infos",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedInfos = responseData.map((info) => {
          info.id = info._id;
          return info;
        });

        return infosAdapter.setAll(initialState, loadedInfos);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Info", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Info", id })),
          ];
        } else return [{ type: "Info", id: "LIST" }];
      },
    }),
    addInfo: builder.mutation({
      query: (info) => ({
        url: "/infos",
        method: "POST",
        body: { ...info },
      }),
      invalidatesTags: [{ type: "Info", id: "LIST" }],
    }),
    updateInfo: builder.mutation({
      query: (info) => ({
        url: "/infos",
        method: "PATCH",
        body: { ...info },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Info", id: arg.id }],
    }),
    deleteInfo: builder.mutation({
      query: (id) => ({
        url: "/infos",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Info", id: arg.id }],
    }),
  }),
});

export const {
  useGetInfosQuery,
  useAddInfoMutation,
  useUpdateInfoMutation,
  useDeleteInfoMutation,
} = infosApiSlice;

export const selectInfosResult = infosApiSlice.endpoints.getInfos.select();

const selectInfosData = createSelector(
  selectInfosResult,
  (infosResult) => infosResult.data
);

export const { selectAll: selectAllInfos, selectIds: selectInfosIds } =
  infosAdapter.getSelectors((state) => selectInfosData(state) ?? initialState);
