import { CircularProgress } from "@mui/material";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useSnackbar } from "contexts/Snackbar.context";
import { useAuthenticateDiscordMutation } from "features/discord/discordApiSlice";

const DiscordCallback = () => {
  const navigate = useNavigate();
  const effectRan = useRef(false);
  const showSnackbar = useSnackbar();

  const [authenticateDiscord, { isLoading, isError, error }] =
    useAuthenticateDiscordMutation();

  useEffect(() => {
    (async () => {
      try {
        if (effectRan.current) return;
        effectRan.current = true;

        const args = new URLSearchParams(window.location.search);
        await authenticateDiscord(Object.fromEntries(args))
          .unwrap()
          .then(() => showSnackbar("Discord Account verknüpft"));
      } catch (err) {
        console.log(err);
      } finally {
        navigate("/", { replace: true });
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isError && error?.data?.message)
      showSnackbar(error?.data?.message, "error");
    // eslint-disable-next-line
  }, [error, isError, isLoading]);

  return isLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100%"
    >
      <CircularProgress color="info" size={80} />
    </Box>
  ) : (
    <></>
  );
};
export default DiscordCallback;
