import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "app/api/apiSlice";

const applicationsAdapter = createEntityAdapter({});

const initialState = applicationsAdapter.getInitialState();

export const applicationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getApplications: builder.query({
      query: (params) => {
        const query = {
          url: "/applications",
        };

        if (params) query.params = { ...params };

        return query;
      },
      transformResponse: (responseData) => {
        const loadedApplications = responseData.map((application) => {
          application.id = application._id;
          return application;
        });

        return applicationsAdapter.setAll(initialState, loadedApplications);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Application", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Application", id })),
          ];
        } else return [{ type: "Application", id: "LIST" }];
      },
    }),
    getUserApplications: builder.query({
      query: () => ({
        url: "/applications/user",
      }),
      transformResponse: (responseData) => {
        const loadedApplications = responseData.map((application) => {
          application.id = application._id;
          return application;
        });

        return applicationsAdapter.setAll(initialState, loadedApplications);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Application", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Application", id })),
          ];
        } else return [{ type: "Application", id: "LIST" }];
      },
    }),
    getApplicationFile: builder.query({
      query: (id) => ({
        url: `/applications/${id}/file`,
      }),
    }),
    getAvailableNumbers: builder.query({
      query: () => ({
        url: "/applications/available-numbers",
      }),
    }),
    addNewApplication: builder.mutation({
      query: (formData) => ({
        url: "/applications",
        method: "POST",
        body: formData,
        formData: true,
      }),

      invalidatesTags: [{ type: "Application", id: "LIST" }],
    }),
    updateApplication: builder.mutation({
      query: (applicationData) => ({
        url: "/applications",
        method: "PATCH",
        body: { ...applicationData },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Application", id: arg.id },
      ],
    }),
    withdrawApplication: builder.mutation({
      query: (id) => ({
        url: "/applications/withdraw",
        method: "PATCH",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Application", id: arg.id },
      ],
    }),
    postRecruiterComment: builder.mutation({
      query: ({ id, message }) => ({
        url: `/applications/${id}/comments`,
        method: "POST",
        body: {
          message,
        },
      }),

      invalidatesTags: [{ type: "Application", id: "LIST" }],
    }),
    deleteRecruiterComment: builder.mutation({
      query: ({ id, commentId }) => ({
        url: `/applications/${id}/comments`,
        method: "DELETE",
        body: {
          commentId,
        },
      }),
      invalidatesTags: [{ type: "Application", id: "LIST" }],
    }),
    postMessage: builder.mutation({
      query: ({ id, message }) => ({
        url: `/applications/${id}/messages`,
        method: "POST",
        body: {
          message,
        },
      }),

      invalidatesTags: [{ type: "Application", id: "LIST" }],
    }),
    deleteMessage: builder.mutation({
      query: ({ id, messageId }) => ({
        url: `/applications/${id}/messages`,
        method: "DELETE",
        body: {
          messageId,
        },
      }),
      invalidatesTags: [{ type: "Application", id: "LIST" }],
    }),
  }),
});

export const {
  useAddNewApplicationMutation,
  useGetApplicationsQuery,
  useGetUserApplicationsQuery,
  useGetApplicationFileQuery,
  useGetAvailableNumbersQuery,
  useUpdateApplicationMutation,
  usePostRecruiterCommentMutation,
  useDeleteRecruiterCommentMutation,
  usePostMessageMutation,
  useDeleteMessageMutation,
  useWithdrawApplicationMutation,
} = applicationsApiSlice;

export const selectApplicationsResult =
  applicationsApiSlice.endpoints.getApplications.select();

export const selectUserApplicationsResult =
  applicationsApiSlice.endpoints.getUserApplications.select();

const selectApplicationsData = createSelector(
  selectApplicationsResult,
  (applicationResult) => applicationResult.data
);

const selectUserApplicationsData = createSelector(
  selectUserApplicationsResult,
  (userApplicationResult) => userApplicationResult.data
);

export const {
  selectAll: selectAllApplications,
  selectById: selectApplicationById,
  selectIds: selectApplicationsIds,
} = applicationsAdapter.getSelectors(
  (state) => selectApplicationsData(state) ?? initialState
);

export const {
  selectAll: selectUserApplications,
  selectById: selectUserApplicationById,
  selectIds: selectUserApplicationsIds,
} = applicationsAdapter.getSelectors(
  (state) => selectUserApplicationsData(state) ?? initialState
);
