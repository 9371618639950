import { Badge } from "@mui/material";
import dayjs from "dayjs";
import { useGetPollsQuery } from "./pollsApiSlice";
import useAuth from "hooks/useAuth";

const PollsBadge = ({ children, ...props }) => {
  const { data: polls, isSuccess } = useGetPollsQuery("pollsList", {
    refetchOnMountOrArgChange: false,
  });

  const { user } = useAuth();

  return (
    <Badge
      color="error"
      badgeContent={
        isSuccess
          ? polls.ids.filter((id) => {
              const poll = polls.entities[id];

              return (
                dayjs(poll.start).isBefore(dayjs()) &&
                dayjs(poll.end).isAfter(dayjs()) &&
                !poll.votes?.some((vote) => vote.cop?._id === user.copData?._id)
              );
            }).length
          : 0
      }
      {...props}
    >
      {children}
    </Badge>
  );
};
export default PollsBadge;
