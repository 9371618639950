import { createContext, useState, useMemo } from "react";
import {
  createTheme,
  getContrastRatio,
  responsiveFontSizes,
} from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { amber, blue, pink } from "@mui/material/colors";
import { deDE as datePickersDeDE } from "@mui/x-date-pickers";
import { deDE as dataGridDeDE } from "@mui/x-data-grid";
import { deDE } from "@mui/material/locale";

const rankColors = {
  recruits: "#169bf2",
  middle: "#166bc1",
  upper: "#cfcfcf",
  higher: "#deb145",
};

const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        // DARK MODE
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#1F2A40",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
      }
    : {
        // LIGHT MODE
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#f2f0f0",
          500: "#141b2d",
          600: "#434957",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
      }),
});

// MUI Theme
export const themeSettings = (mode) => {
  const colors = tokens(mode);

  return {
    palette: {
      mode: mode,
      primary: {
        main: colors.blueAccent[500],
      },
      secondary: {
        main: colors.greenAccent[500],
      },
      tertiary: {
        main: blue[500],
      },
      quaternary: {
        main: pink[500],
      },
      quinary: {
        main: amber[500],
      },
      text: {
        primary: colors.grey[100],
        secondary: colors.grey[400],
      },
      cop: {
        main: "#004c98",
        light: "#004c98",
        dark: "#004c98",
        contrastText:
          getContrastRatio("#004c98", "#fff") > 4.5 ? "#fff" : "#111",
      },
      civ: {
        main: "#66007f",
        light: "#66007f",
        dark: "#66007f",
        contrastText:
          getContrastRatio("#66007f", "#fff") > 4.5 ? "#fff" : "#111",
      },
      higherRank: {
        main: rankColors.higher,
        light: rankColors.higher,
        dark: rankColors.higher,
        contrastText:
          getContrastRatio(rankColors.higher, "#fff") > 4.5 ? "#fff" : "#111",
      },
      upperRank: {
        main: rankColors.upper,
        light: rankColors.upper,
        dark: rankColors.upper,
        contrastText:
          getContrastRatio(rankColors.upper, "#fff") > 4.5 ? "#fff" : "#111",
      },
      middleRank: {
        main: rankColors.middle,
        light: rankColors.middle,
        dark: rankColors.middle,
        contrastText:
          getContrastRatio(rankColors.middle, "#fff") > 4.5 ? "#fff" : "#111",
      },
      recruit: {
        main: rankColors.recruits,
        light: rankColors.recruits,
        dark: rankColors.recruits,
        contrastText:
          getContrastRatio(rankColors.middle, "#fff") > 4.5 ? "#fff" : "#111",
      },
      ...(mode === "dark"
        ? {
            background: {
              default: colors.primary[500],
              paper: colors.primary[400],
            },
          }
        : {
            background: {
              default: "#fcfcfc",
              paper: colors.primary[400],
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
    },
    shape: {
      borderRadius: 3,
    },
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const storedColorMode = localStorage.getItem("colorMode");
  const [mode, setMode] = useState(
    storedColorMode || (prefersDarkMode ? "dark" : "light")
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => {
          const newColorMode = prev === "light" ? "dark" : "light";
          localStorage.setItem("colorMode", newColorMode);
          return newColorMode;
        }),
    }),
    []
  );

  const theme = useMemo(
    () =>
      responsiveFontSizes(
        createTheme(themeSettings(mode), deDE, dataGridDeDE, datePickersDeDE)
      ),
    [mode]
  );

  return [theme, colorMode];
};
