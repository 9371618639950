import { Box } from "@mui/material";
import Header from "components/Header";
import OperationDataGrid from "features/operations/OperationDataGrid";
import { useEffect } from "react";

const Operations = () => {
  useEffect(() => {
    document.title = `Einsätze | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="EINSÄTZE" subtitle="Einsatzberichte" />
      <OperationDataGrid />
    </Box>
  );
};

export default Operations;
