import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popper,
  Typography,
  alpha,
  keyframes,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import {
  useGetNotificationsQuery,
  useSetNotificationReadMutation,
} from "./notificationsApiSlice";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const NotificationText = ({ title, message, readAt, author, createdAt }) => (
  <Box display="flex" width="100%" alignItems="center">
    <ListItemText
      primary={
        <Box fontWeight="bold" color="primary.main">
          {title}
        </Box>
      }
      primaryTypographyProps={{
        component: "span",
      }}
      secondary={
        <Box>
          <Typography component="span" color="text.primary">
            {message}
          </Typography>
          <Box display="flex">
            <Typography
              component="span"
              color="text.secondary"
              variant="subtitle2"
              display="flex"
            >
              <Box>{dayjs(createdAt).fromNow()}</Box>
              {!!author && <Box ml={1}>~{author}</Box>}
            </Typography>
          </Box>
        </Box>
      }
      secondaryTypographyProps={{
        component: "span",
      }}
      sx={{
        maxWidth: "350px",
      }}
    />
    <Box
      sx={{
        height: "10px",
        width: "10px",
        borderRadius: "50%",
        backgroundColor: readAt ? "transparent" : "primary.main",
        ml: 1,
      }}
    />
  </Box>
);

const Notification = ({ notification }) => {
  const theme = useTheme();
  const [setNotificationRead] = useSetNotificationReadMutation();

  return (
    <Box>
      {notification.url ? (
        <Link
          style={{
            color: theme.palette.text.primary,
            textDecoration: "none",
            width: "100%",
          }}
          to={notification.url}
          target={notification.url.charAt(0) === "/" ? "_self" : "_blank"}
        >
          <ListItemButton
            onClick={() => {
              if (!notification.readAt) {
                setNotificationRead([notification._id]);
              }
            }}
          >
            <NotificationText
              {...notification}
              title={
                <Box display="flex">
                  <Typography component="span" fontWeight="inherit">
                    {notification.title}
                  </Typography>
                  <LinkOutlinedIcon color="info" sx={{ ml: 1 }} />
                </Box>
              }
            />
          </ListItemButton>
        </Link>
      ) : (
        <ListItem>
          <NotificationText {...notification} title={notification.title} />
        </ListItem>
      )}
    </Box>
  );
};

const bellshake = keyframes`0% { transform: rotate(0); }
                            1.5% { transform: rotate(8deg); }
                            3% { transform: rotate(-8deg); }
                            4.5% { transform: rotate(6deg); }
                            6% { transform: rotate(-6deg); }
                            7.5% { transform: rotate(3deg); }
                            8.5% { transform: rotate(-3deg); }
                            9.2% { transform: rotate(1deg); }
                            10% { transform: rotate(0); }
                            100% { transform: rotate(0); }`;

const NotificationBell = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 1200px)");
  const { data, isSuccess } = useGetNotificationsQuery("notificationsList", {
    refetchOnMountOrArgChange: true,
    pollingInterval: 300000, // 5 Minuten
  });
  const [setNotificationRead] = useSetNotificationReadMutation();

  const [notifications, setNotifications] = useState([]);
  const [numberUnreadNotifications, setNumberUnreadNotifications] = useState(0);
  const [anchor, setAnchor] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const open = Boolean(anchor);

  useEffect(() => {
    if (isSuccess) {
      setNotifications(
        data.ids
          .map((id) => data.entities[id])
          .filter((notification) => showAll || !notification.readAt)
      );

      setNumberUnreadNotifications(
        data.ids
          .map((id) => data.entities[id])
          .filter((notification) => !notification.readAt).length
      );
    }
  }, [data, isSuccess, showAll]);

  const setAllNotificationsRead = useCallback(async () => {
    await setNotificationRead(
      notifications
        .filter((notification) => !notification.readAt)
        .map((notification) => notification._id)
    );
  }, [notifications, setNotificationRead]);

  const Component = useCallback(
    ({ children }) =>
      isNonMobile ? (
        <Popper
          open={open}
          anchorEl={anchor}
          placement="bottom-end"
          sx={{
            position: "relative",
            zIndex: 2000,
          }}
        >
          <ClickAwayListener
            onClickAway={() => {
              setAnchor(null);
              setAllNotificationsRead();
              setShowAll(false);
            }}
          >
            <Card sx={{ minWidth: "432px" }}>
              <CardContent>{children}</CardContent>
            </Card>
          </ClickAwayListener>
        </Popper>
      ) : (
        <Drawer
          open={open}
          anchor="right"
          onClose={() => {
            setAnchor(null);
            setShowAll(false);
          }}
        >
          <Box p={2} sx={{ maxWidth: "70vw" }}>
            {children}
          </Box>
        </Drawer>
      ),
    // eslint-disable-next-line
    [anchor, isNonMobile, open]
  );

  return (
    <>
      <IconButton
        onClick={(event) =>
          setAnchor((value) => {
            if (!!value) {
              setAllNotificationsRead();
              setShowAll(false);
            }

            return !!value ? null : event.currentTarget;
          })
        }
      >
        <Badge
          badgeContent={numberUnreadNotifications}
          color="error"
          overlap="circular"
          variant="dot"
        >
          {numberUnreadNotifications > 0 ? (
            <NotificationsActiveOutlinedIcon
              sx={{
                animation: `${bellshake} 5s infinite cubic-bezier(.36,.07,.19,.97) both`,
              }}
            />
          ) : (
            <NotificationsNoneOutlinedIcon />
          )}
        </Badge>
      </IconButton>

      <Component>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            fontWeight="bold"
            variant="h6"
            sx={{ userSelect: "none" }}
          >
            {`Benachrichtigungen (${notifications.length})`}
          </Typography>
          <Button
            disabled={numberUnreadNotifications === 0}
            onClick={setAllNotificationsRead}
          >
            Alle gelesen
          </Button>
        </Box>
        <Box mt={1}>
          <Chip
            label={
              <Typography color={showAll ? "text.primary" : "primary.main"}>
                Ungelesen
              </Typography>
            }
            onClick={() => setShowAll(false)}
            sx={{
              mr: 1,
              border: "none",
              backgroundColor: showAll
                ? "transparent"
                : alpha(theme.palette.primary.main, 0.2),
              ":hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.3),
              },
            }}
            variant={showAll ? "outlined" : "filled"}
            color={showAll ? "default" : "primary"}
          />
          <Chip
            label={
              <Typography color={!showAll ? "text.primary" : "primary.main"}>
                Alle
              </Typography>
            }
            onClick={() => setShowAll(true)}
            variant={!showAll ? "outlined" : "filled"}
            color={!showAll ? "default" : "primary"}
            sx={{
              border: "none",
              backgroundColor: !showAll
                ? "transparent"
                : alpha(theme.palette.primary.main, 0.2),
              ":hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.3),
              },
            }}
          />
        </Box>
        {notifications.length > 0 ? (
          <Box
            mt={1}
            maxHeight={isNonMobile && "50vh"}
            overflow={isNonMobile && "auto"}
          >
            <List disablePadding>
              {notifications.map((notification) => (
                <Notification
                  key={notification._id}
                  notification={notification}
                />
              ))}
            </List>
          </Box>
        ) : (
          <Box mt={2} ml={2}>
            Keine Benachrichtigungen
          </Box>
        )}
      </Component>
    </>
  );
};
export default NotificationBell;
