import { useTheme } from "@mui/material";
import PieChartBase from "components/PieChartBase";
import { memo, useEffect, useState } from "react";

const getDefaultVoteValue = (vote) =>
  ({
    Ja: 1,
    Nein: 2,
    Enthaltung: 3,
  }[vote] ?? 0);

const PollPieChart = ({ votes, ...props }) => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const voteNumbers = votes?.reduce((voteNumbers, vote) => {
      if (voteNumbers[vote.vote]) {
        voteNumbers[vote.vote] += 1;
      } else {
        voteNumbers[vote.vote] = 1;
      }

      return voteNumbers;
    }, {});

    if (!voteNumbers) return;

    const data = [];
    const colors = [];

    for (const [id, value] of Object.entries(voteNumbers)) {
      data.push({ id, value });
    }

    data.sort((a, b) => getDefaultVoteValue(a.id) - getDefaultVoteValue(b.id));

    if (voteNumbers.Ja > 0) {
      colors.push(theme.palette.success.main);
    }
    if (voteNumbers.Nein > 0) {
      colors.push(theme.palette.error.main);
    }
    if (voteNumbers.Enthaltung > 0) {
      colors.push(theme.palette.grey[300]);
    }

    setData(data);
    setColors(
      Object.keys(voteNumbers).every((vote) =>
        ["Ja", "Nein", "Enthaltung"].includes(vote)
      )
        ? colors
        : { scheme: "nivo" }
    );
    // eslint-disable-next-line
  }, [votes, theme.palette]);

  return <PieChartBase data={data} colors={colors} {...props} />;
};
export default memo(PollPieChart);
