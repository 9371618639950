import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const submittedQuestionsAdapter = createEntityAdapter();

const initialState = submittedQuestionsAdapter.getInitialState();

export const submittedQuestionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubmittedQuestions: builder.query({
      query: () => ({
        url: "/questions",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedSubmittedQuestions = responseData.map(
          (submittedQuestion) => {
            submittedQuestion.id = submittedQuestion._id;
            return submittedQuestion;
          }
        );

        return submittedQuestionsAdapter.setAll(
          initialState,
          loadedSubmittedQuestions
        );
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "SubmittedQuestion", id: "LIST" },
            ...result.ids.map((id) => ({ type: "SubmittedQuestion", id })),
          ];
        } else return [{ type: "SubmittedQuestion", id: "LIST" }];
      },
    }),
    addSubmittedQuestion: builder.mutation({
      query: (submittedQuestion) => ({
        url: "/questions",
        method: "POST",
        body: { ...submittedQuestion },
      }),
      invalidatesTags: [{ type: "SubmittedQuestion", id: "LIST" }],
    }),
    deleteSubmittedQuestion: builder.mutation({
      query: (id) => ({
        url: "/questions",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "SubmittedQuestion", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetSubmittedQuestionsQuery,
  useAddSubmittedQuestionMutation,
  useDeleteSubmittedQuestionMutation,
} = submittedQuestionsApiSlice;

export const selectSubmittedQuestionsResult =
  submittedQuestionsApiSlice.endpoints.getSubmittedQuestions.select();

const selectSubmittedQuestionsData = createSelector(
  selectSubmittedQuestionsResult,
  (submittedQuestionsResult) => submittedQuestionsResult.data
);

export const {
  selectAll: selectAllSubmittedQuestions,
  selectIds: selectSubmittedQuestionsIds,
} = submittedQuestionsAdapter.getSelectors(
  (state) => selectSubmittedQuestionsData(state) ?? initialState
);
