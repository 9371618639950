import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useSnackbar } from "contexts/Snackbar.context";
import {
  usePostMessageMutation,
  useUpdateApplicationMutation,
} from "./applicationsApiSlice";
import { applicationTexts } from "util/applicationTexts";
import useAuth from "hooks/useAuth";
import CustomCard from "components/CustomCard";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import { useAddCopMutation, useGetCopsQuery } from "features/cops/copsApiSlice";

const ApplicationActions = ({ application }) => {
  const [process, setProcess] = useState("");
  const [interviewDate, setInterviewDate] = useState(null);
  const [hiringDate, setHirirngDate] = useState(null);
  const [rejectionReason, setRejectionReason] = useState("");
  const [newName, setNewName] = useState("");
  const [number, setNumber] = useState("");

  const { user } = useAuth();

  const showSnackbar = useSnackbar();

  const [
    updateApplication,
    {
      isSuccess: updateApplicationIsSuccess,
      isLoading: updateApplicationIsLoading,
    },
  ] = useUpdateApplicationMutation();

  const [postMessage, { isSuccess: postMessageIsSuccess }] =
    usePostMessageMutation();

  const [
    addCop,
    {
      isSuccess: addCopIsSuccess,
      isLoading: addCopIsLoading,
      isError: addCopIsError,
      error: addCopError,
    },
  ] = useAddCopMutation();

  const { data: cops, isSuccess: getCopsIsSuccess } = useGetCopsQuery(
    "copsList",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const sendInvitation = async () => {
    await updateApplication({
      id: application._id,
      status: "Bewerbungsgespräch",
    });

    await postMessage({
      id: application._id,
      message: applicationTexts.invitation(
        application.name,
        interviewDate,
        user.name
      ),
    });

    setInterviewDate(null);
  };

  const sendAcceptance = async () => {
    await updateApplication({
      id: application._id,
      status: "Angenommen",
    });

    await postMessage({
      id: application._id,
      message: applicationTexts.acceptance(
        application.name,
        hiringDate,
        user.name
      ),
    });

    setHirirngDate(null);
  };

  const sendRejection = async () => {
    await updateApplication({
      id: application._id,
      status: "Abgelehnt",
      isClosed: true,
    });

    await postMessage({
      id: application._id,
      message: applicationTexts.rejection(
        application.name,
        rejectionReason,
        user.name
      ),
    });

    setRejectionReason(null);
  };

  const resetApplication = async () => {
    setProcess("Zurücksetzen");
    await updateApplication({
      id: application._id,
      status: "Neu",
      isClosed: false,
    });
  };

  const hire = async () => {
    if (
      getCopsIsSuccess &&
      cops.ids
        .map((id) => cops.entities[id])
        .some((cop) => cop.user._id === application.user._id)
    ) {
      showSnackbar("Der Benutzer ist bereits Polizist", "error");
      return;
    }

    await updateApplication({
      id: application._id,
      isClosed: true,
    });

    await addCop({
      userId: application.user._id,
      number: number,
      status:
        application.applicationType === "Hauptfraktion"
          ? "Normal"
          : "Entschuldigt",
      isInvisible: false,
      functions:
        application.applicationType === "Hauptfraktion"
          ? []
          : ["Zweitfraktion"],
      name: newName,
    });

    setNewName("");
    setNumber("");
  };

  useEffect(() => {
    if (addCopIsSuccess && updateApplicationIsSuccess)
      showSnackbar("Bewerber eingestellt");
    // eslint-disable-next-line
  }, [addCopIsSuccess, updateApplicationIsSuccess]);

  useEffect(() => {
    if (addCopIsError)
      showSnackbar(addCopError.data?.message || "Fehler", "error");
    // eslint-disable-next-line
  }, [addCopIsError, addCopError]);

  useEffect(() => {
    if (process && updateApplicationIsSuccess && postMessageIsSuccess) {
      switch (process) {
        case "Einladen":
          showSnackbar("Einladung gesendet");
          break;
        case "Annehmen":
          showSnackbar("Bewerbung angenommen");
          break;
        case "Ablehnen":
          showSnackbar("Bewerbung abgelehnt");
          break;
        case "Zurücksetzen":
          showSnackbar("Bewerbung zurückgesetzt");
          break;
        default:
          break;
      }
      setProcess("");
    }
    // eslint-disable-next-line
  }, [updateApplicationIsSuccess, postMessageIsSuccess]);

  return application.isClosed ? (
    <Box>
      <Button
        variant="contained"
        color="warning"
        onClick={resetApplication}
        disabled={application.status === "Angenommen"}
      >
        Bewerbung zurücksetzen
      </Button>
    </Box>
  ) : (
    <Box maxWidth="1000px">
      <Grid container columns={3} rowSpacing={2} columnSpacing={0}>
        <Grid item xs={3} md={1}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Button
              color="info"
              variant={process === "Einladen" ? "contained" : "outlined"}
              disabled={
                application.isClosed ||
                application.status === "Bewerbungsgespräch" ||
                application.status === "Angenommen"
              }
              onClick={() => setProcess("Einladen")}
              sx={{
                width: "180px",
              }}
            >
              Einladen
            </Button>

            {process === "Einladen" && (
              <Box mt="8px" width="100%">
                <CustomCard>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <DateTimePicker
                      label="Terminvorschlag für Bewerbungsgespräch"
                      value={dayjs(interviewDate)}
                      onChange={(value) => setInterviewDate(value)}
                      slotProps={{
                        actionBar: {
                          actions: ["cancel", "clear", "accept"],
                        },
                        textField: {
                          fullWidth: true,
                          error: null,
                        },
                      }}
                    />
                    <Button
                      onClick={sendInvitation}
                      variant="contained"
                      disabled={!!!interviewDate}
                      endIcon={<SendOutlinedIcon />}
                      sx={{
                        mt: "16px",
                      }}
                    >
                      Absenden
                    </Button>
                  </Box>
                </CustomCard>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={3} md={1}>
          {application.status === "Angenommen" ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Button
                color="primary"
                variant={process === "Einstellen" ? "contained" : "outlined"}
                disabled={application.status !== "Angenommen"}
                onClick={() => setProcess("Einstellen")}
                sx={{
                  width: "180px",
                }}
              >
                Einstellen
              </Button>

              {process === "Einstellen" && (
                <Box mt="8px" width="100%">
                  <CustomCard>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <TextField
                        label="Neuer Name"
                        fullWidth
                        value={newName}
                        onChange={(event) => setNewName(event.target.value)}
                        inputProps={{
                          maxLength: 30,
                        }}
                      />

                      <TextField
                        label="Dienstnummer"
                        fullWidth
                        select
                        value={number}
                        onChange={(event) => setNumber(event.target.value)}
                        sx={{
                          mt: "16px",
                        }}
                      >
                        {application.applicationType === "Hauptfraktion"
                          ? [...Array(89).keys()].map((number) => (
                              <MenuItem
                                key={number + 11}
                                value={number + 11}
                                disabled={
                                  getCopsIsSuccess &&
                                  cops.ids
                                    .map((id) => cops.entities[id].number)
                                    .includes(number + 11)
                                }
                              >
                                {number + 11}
                              </MenuItem>
                            ))
                          : [...Array(20).keys()].map((number) => (
                              <MenuItem
                                key={number + 100}
                                value={number + 100}
                                disabled={
                                  getCopsIsSuccess &&
                                  cops.ids
                                    .map((id) => cops.entities[id].number)
                                    .includes(number + 100)
                                }
                              >
                                {number + 100}
                              </MenuItem>
                            ))}
                      </TextField>
                      <Button
                        onClick={hire}
                        disabled={
                          !!!number ||
                          number < 1 ||
                          number > 199 ||
                          addCopIsLoading ||
                          updateApplicationIsLoading
                        }
                        variant="contained"
                        endIcon={
                          addCopIsLoading || updateApplicationIsLoading ? (
                            <CircularProgress size="1em" />
                          ) : (
                            <PersonAddOutlinedIcon />
                          )
                        }
                        sx={{
                          mt: "16px",
                        }}
                      >
                        Einstellen
                      </Button>
                    </Box>
                  </CustomCard>
                </Box>
              )}
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Button
                color="success"
                variant={process === "Annehmen" ? "contained" : "outlined"}
                disabled={
                  application.isClosed || application.status === "Angenommen"
                }
                onClick={() => setProcess("Annehmen")}
                sx={{
                  width: "180px",
                }}
              >
                Annehmen
              </Button>

              {process === "Annehmen" && (
                <Box mt="8px" width="100%">
                  <CustomCard>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <DateTimePicker
                        label="Terminvorschlag für Einstellung"
                        value={dayjs(hiringDate)}
                        onChange={(value) => setHirirngDate(value)}
                        slotProps={{
                          actionBar: {
                            actions: ["cancel", "clear", "accept"],
                          },
                          textField: {
                            fullWidth: true,
                            error: null,
                          },
                        }}
                      />
                      <Button
                        onClick={sendAcceptance}
                        variant="contained"
                        disabled={!!!hiringDate}
                        endIcon={<SendOutlinedIcon />}
                        sx={{
                          mt: "16px",
                        }}
                      >
                        Absenden
                      </Button>
                    </Box>
                  </CustomCard>
                </Box>
              )}
            </Box>
          )}
        </Grid>
        <Grid item xs={3} md={1}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Button
              color="error"
              variant={process === "Ablehnen" ? "contained" : "outlined"}
              disabled={application.isClosed}
              onClick={() => setProcess("Ablehnen")}
              sx={{
                width: "180px",
              }}
            >
              Ablehnen
            </Button>
            {process === "Ablehnen" && (
              <Box mt="8px" width="100%">
                <CustomCard>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <TextField
                      label="Grund für die Ablehnung"
                      fullWidth
                      multiline
                      value={rejectionReason}
                      onChange={(event) =>
                        setRejectionReason(event.target.value)
                      }
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                    <Button
                      onClick={sendRejection}
                      variant="contained"
                      disabled={!!!rejectionReason}
                      endIcon={<SendOutlinedIcon />}
                      sx={{
                        mt: "16px",
                      }}
                    >
                      Absenden
                    </Button>
                  </Box>
                </CustomCard>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ApplicationActions;
