import { Typography, Box, useTheme } from "@mui/material";

const Header = ({ title, subtitle, sx = {} }) => {
  const theme = useTheme();

  return (
    <Box pb="24px" sx={sx} maxWidth="100%" id="header">
      <Typography variant="h4" fontWeight="bold" sx={{ p: "0 0 5px 0" }}>
        {title}
      </Typography>
      <Typography variant="subtitle" color={theme.palette.text.secondary}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
