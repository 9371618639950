import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const copRatioViolationsAdapter = createEntityAdapter();

const initialState = copRatioViolationsAdapter.getInitialState();

export const copRatioViolationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCopRatioViolations: builder.query({
      query: (params) => ({
        url: "/cop-ratio-violations",
        params,
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedCopRatioViolations = responseData.map(
          (copRatioViolation) => {
            copRatioViolation.id = copRatioViolation._id;
            return copRatioViolation;
          }
        );

        return copRatioViolationsAdapter.setAll(
          initialState,
          loadedCopRatioViolations
        );
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "CopRatioViolation", id: "LIST" },
            ...result.ids.map((id) => ({ type: "CopRatioViolation", id })),
          ];
        } else return [{ type: "CopRatioViolation", id: "LIST" }];
      },
    }),
    getCopRatioStatus: builder.query({
      query: () => ({
        url: "/cop-ratio-violations/status",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
    }),
    setCopRatioViolationDone: builder.mutation({
      query: ({ id, approved, comment }) => ({
        url: "/cop-ratio-violations/set-done",
        method: "PATCH",
        body: { id, approved, comment },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CopRatioViolation", id: arg.id },
      ],
    }),
    setCopRatioViolationUndone: builder.mutation({
      query: (id) => ({
        url: "/cop-ratio-violations/set-undone",
        method: "PATCH",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CopRatioViolation", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetCopRatioViolationsQuery,
  useGetCopRatioStatusQuery,
  useSetCopRatioViolationDoneMutation,
  useSetCopRatioViolationUndoneMutation,
} = copRatioViolationsApiSlice;

export const selectCopRatioViolationsResult =
  copRatioViolationsApiSlice.endpoints.getCopRatioViolations.select();

const selectCopRatioViolationsData = createSelector(
  selectCopRatioViolationsResult,
  (copRatioViolationsResult) => copRatioViolationsResult.data
);

export const {
  selectAll: selectAllCopRatioViolations,
  selectIds: selectCopRatioViolationIds,
} = copRatioViolationsAdapter.getSelectors(
  (state) => selectCopRatioViolationsData(state) ?? initialState
);
