import { Box, CircularProgress, Grid, IconButton } from "@mui/material";
import CopRatioStatus from "./CopRatioStatus";
import ActiveCopAsCivPlayers from "features/activePlayers/ActiveCopAsCivPlayers";
import CopRatioViolationsDataGrid from "./CopRatioViolationsDataGrid";
import CopsOnlineList from "features/panthorApi/CopsOnlineList";
import CustomCard from "components/CustomCard";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useGetOnlineCopsQuery } from "features/panthorApi/panthorApiSlice";

const CopsOnlineRefreshButton = () => {
  const { isLoading, isFetching, refetch } = useGetOnlineCopsQuery(
    "onlineCopsList",
    {}
  );

  return (
    <IconButton
      disabled={isLoading || isFetching}
      onClick={async () => await refetch()}
      color="primary"
      sx={{
        position: "relative",
        zIndex: 10,
        mb: "-100px",
        ml: "155px",
        mt: "9px",
        float: "left",
      }}
    >
      {isLoading || isFetching ? (
        <CircularProgress size="1em" />
      ) : (
        <RefreshIcon />
      )}
    </IconButton>
  );
};

const CopRatio = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={3}>
        <Box mb={1.2}>
          <CopRatioStatus />
        </Box>
        <ActiveCopAsCivPlayers />
        <Box mt={2}>
          <>
            <CopsOnlineRefreshButton />
            <CustomCard title="POLIZISTEN ONLINE">
              <CopsOnlineList />
            </CustomCard>
          </>
        </Box>
      </Grid>
      <Grid item xs={12} xl={9}>
        <CopRatioViolationsDataGrid />
      </Grid>
    </Grid>
  );
};
export default CopRatio;
