import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import TrafficOutlinedIcon from "@mui/icons-material/TrafficOutlined";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import LooksTwoOutlinedIcon from "@mui/icons-material/LooksTwoOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserSecret,
  faPersonMilitaryPointing,
  faCrosshairs,
} from "@fortawesome/free-solid-svg-icons";

export const getApplicationStatusColor = (status) =>
  ({
    "In Bearbeitung": "info",
    Angenommen: "success",
    Abgelehnt: "error",
    Zurückgezogen: "error",
  }[status] ?? "warning");

export const getCopStatusColor = (status) =>
  ({
    Aktiv: "success",
    Entschuldigt: "info",
    Normal: "default",
    Inaktiv: "warning",
    "Unter Beobachtung": "warning",
    Suspendiert: "error",
    Gekündigt: "error",
  }[status] ?? "default");

export const getCopFunctionColor = (copFunction) =>
  ({
    Polizeileitung: "error",
    Zweitfraktion: "info",
    Rekrutierer: "success",
    Ausbilder: "success",
    Fortbilder: "success",
    Polizeiführung: "warning",
    Personalrat: "primary",
  }[copFunction] ?? "default");

export const getCopFunctionIcon = (copFunction) =>
  ({
    Rekrutierer: <PersonAddOutlinedIcon />,
    Ausbilder: <SchoolOutlinedIcon />,
    Fortbilder: <LocalLibraryOutlinedIcon />,
    Personalrat: <GavelOutlinedIcon fontSize="small" />,
    Verkehrsüberwachung: <TrafficOutlinedIcon />,
    Kriminalpolizei: <FontAwesomeIcon fontSize="16px" icon={faUserSecret} />,
    Einsatzkommando: <FontAwesomeIcon fontSize="16px" icon={faCrosshairs} />,
    Zoll: <FontAwesomeIcon fontSize="16px" icon={faPersonMilitaryPointing} />,
    Zweitfraktion: <LooksTwoOutlinedIcon />,
    Polizeileitung: <LocalPoliceOutlinedIcon />,
    Polizeiführung: <StarOutlineOutlinedIcon />,
  }[copFunction] ?? null);

export const getVacationStatusColor = (status) =>
  ({
    Neu: "info",
    Angenommen: "success",
    Abgelehnt: "error",
  }[status] ?? "default");
