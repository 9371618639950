import { Badge } from "@mui/material";
import { useGetSubmittedQuestionsQuery } from "./questionsApiSlice";

const SubmitQuestionsBadge = ({ children, ...props }) => {
  const { data: questions, isSuccess } = useGetSubmittedQuestionsQuery(
    "questionsList",
    {
      refetchOnMountOrArgChange: false,
    }
  );

  return (
    <Badge
      color="error"
      badgeContent={isSuccess ? questions.ids.length : 0}
      {...props}
    >
      {children}
    </Badge>
  );
};
export default SubmitQuestionsBadge;
