import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import {
  useBanPlayerMutation,
  useGetBansQuery,
  useGetOnlinePlayersQuery,
} from "./rconApiSlice";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "contexts/Snackbar.context";

const PlayerBanDialog = ({ open, onClose, id, beguid, name }) => {
  const [banPlayer] = useBanPlayerMutation();
  const { refetch: refetchPlayerList } = useGetOnlinePlayersQuery(
    undefined,
    {}
  );
  const { refetch: refetchBanList } = useGetBansQuery(undefined, {});
  const showSnackbar = useSnackbar();
  const [localBeguid, setLocalBeGuid] = useState("");
  const [duration, setDuration] = useState("0");
  const [reason, setReason] = useState("");

  useEffect(() => {
    setLocalBeGuid(beguid);
  }, [beguid, open]);

  const onBanPlayer = useCallback(() => {
    banPlayer({
      params: {
        player: id,
        guid: localBeguid,
      },
      body: {
        time: duration,
        reason: reason,
      },
    })
      .unwrap()
      .then(() => {
        showSnackbar(`Spieler ${name || localBeguid} gebannt`);
        refetchPlayerList();
        refetchBanList();
        onClose();
      })
      .catch((error) => {
        showSnackbar(
          error.data?.message || "Fehler beim Kicken des Spielers",
          "error"
        );
      });
  }, [
    banPlayer,
    showSnackbar,
    onClose,
    name,
    id,
    localBeguid,
    refetchBanList,
    refetchPlayerList,
    duration,
    reason,
  ]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Spieler {name} bannen</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={0}>
          <Grid item xs={12} sm={2}>
            <TextField
              defaultValue={id}
              label="ID"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField
              value={localBeguid}
              label="BattleEye GUID"
              fullWidth
              onChange={(event) => setLocalBeGuid(event.target.value)}
              InputProps={{
                readOnly: !!beguid,
              }}
              error={!localBeguid}
              helperText={!localBeguid && "Pflichtfeld"}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              value={duration}
              label="Dauer (Minuten); 0 = perm"
              fullWidth
              onChange={(event) => setDuration(event.target.value)}
              error={!duration && duration !== 0}
              helperText={!duration && duration !== 0 && "Pflichtfeld"}
            />
          </Grid>
          <Grid item xs={12} lg={8}>
            <TextField
              value={reason}
              label="Grund"
              fullWidth
              onChange={(event) => setReason(event.target.value)}
              inputProps={{
                maxLength: 200,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          startIcon={<BlockOutlinedIcon />}
          color="error"
          onClick={onBanPlayer}
          disabled={(!duration && duration !== 0) || !localBeguid}
        >
          Bannen
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PlayerBanDialog;
