import useAuth from "hooks/useAuth";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { hasPermission } from "util/permissionHelper";

const RequirePermission = ({ permissions }) => {
  const { user } = useAuth();
  const location = useLocation();

  return hasPermission({ roles: user.roles, permissions }) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};
export default RequirePermission;
