import { useNavigate, useParams } from "react-router-dom";
import { useGetCopsQuery } from "./copsApiSlice";
import User from "features/users/User";
import { Box, Button, CircularProgress, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import CopData from "features/cops/CopData";
import useAuth from "hooks/useAuth";
import { hasPermission } from "util/permissionHelper";

const Cop = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { roles } = useAuth();

  const { id } = useParams();

  const { cop } = useGetCopsQuery("copsList", {
    selectFromResult: ({ data }) => ({
      cop: data?.entities[id],
    }),
  });

  useEffect(() => {
    if (cop?.user?.name) {
      document.title = `${cop?.user?.name} | Polizei ${process.env.REACT_APP_MAP}`;
    }
  }, [cop?.user?.name]);

  useEffect(() => {
    if (cop) {
      if (
        (cop?.rank !== "CA" &&
          !hasPermission({ roles, permissions: ["editCops", "seeCopData"] })) ||
        (cop.rank === "CA" &&
          !hasPermission({
            roles,
            permissions: ["editRecruits", "seeRecruitData"],
          }))
      ) {
        navigate("/", { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [cop, roles]);

  const [currentTab, setCurrentTab] = useState("cop");

  if (!cop)
    return (
      <Box m="16px">
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      <Box display="flex" mb={1} maxWidth="400px">
        <Button
          variant={currentTab === "cop" ? "contained" : "outlined"}
          onClick={() => setCurrentTab("cop")}
          fullWidth
          sx={{
            mr: theme.spacing(2),
          }}
        >
          Personalakte
        </Button>
        <Button
          fullWidth
          disabled={
            !hasPermission({ roles, permissions: ["getUsersList"] }) ||
            !!!cop?.user
          }
          variant={currentTab === "user" ? "contained" : "outlined"}
          onClick={() => setCurrentTab("user")}
        >
          Benutzer
        </Button>
      </Box>
      <div hidden={currentTab !== "cop"}>
        <CopData />
      </div>
      <div hidden={currentTab !== "user"}>
        <User id={cop?.user?._id} />
      </div>
    </Box>
  );
};
export default Cop;
