import { Badge } from "@mui/material";
import dayjs from "dayjs";
import { useGetEventsQuery } from "features/events/eventsApiSlice";

const EventsBadge = ({ children, ...props }) => {
  const { data: events, isSuccess: getEventsIsSuccess } = useGetEventsQuery(
    "eventsList",
    {
      refetchOnMountOrArgChange: false,
    }
  );

  return (
    <Badge
      color="error"
      badgeContent={
        getEventsIsSuccess
          ? events.ids.filter((id) =>
              dayjs(events.entities[id].start).isAfter(dayjs())
            ).length
          : 0
      }
      {...props}
    >
      {children}
    </Badge>
  );
};
export default EventsBadge;
