import CustomCard from "components/CustomCard";
import { useGetActivePlayersQuery } from "./activePlayersApiSlice";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import FaceIcon from "@mui/icons-material/Face";
import CopRankBadge from "components/CopRankBadge";
import { ranks } from "util/copEnums";
import RefreshIcon from "@mui/icons-material/Refresh";

const RefreshButton = () => {
  const { isLoading, isFetching, refetch } = useGetActivePlayersQuery(
    "activePlayers",
    {}
  );

  return (
    <IconButton
      disabled={isLoading || isFetching}
      onClick={async () => await refetch()}
      color="primary"
      sx={{
        position: "relative",
        zIndex: 10,
        mb: "-100px",
        ml: "240px",
        mt: "10px",
        float: "left",
      }}
    >
      {isLoading || isFetching ? (
        <CircularProgress size="1em" />
      ) : (
        <RefreshIcon />
      )}
    </IconButton>
  );
};

const ActiveCopAsCivPlayers = () => {
  const theme = useTheme();

  const { data: activePlayers, isSuccess } = useGetActivePlayersQuery(
    "activePlayers",
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 60000, // 1 Minute
      selectFromResult: ({ data, isSuccess }) => ({
        data: data
          ?.filter((activePlayer) => activePlayer.side === "civ")
          .sort(
            (a, b) =>
              (ranks[b.cop?.rank]?.value || 0) -
              (ranks[a.cop?.rank]?.value || 0)
          ),
        isSuccess,
      }),
    }
  );

  return (
    <>
      <RefreshButton />
      <CustomCard title="POLIZISTEN ONLINE ALS ZIVILIST">
        {isSuccess && activePlayers.length > 0 ? (
          <List disablePadding>
            {activePlayers.map((activePlayer, index) => (
              <Link
                key={index}
                to={`/cops/${activePlayer.cop._id}`}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "none",
                }}
              >
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar>
                      <FaceIcon fontSize="large" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        display="flex"
                        alignItems="center"
                        component="span"
                      >
                        <CopRankBadge
                          rank={activePlayer.cop.rank}
                          hideTitle
                          height="20px"
                        />
                        <Box ml={1}>{activePlayer.cop.user.name}</Box>
                      </Typography>
                    }
                    secondary={`Spielt als ${activePlayer.name} seit ${dayjs(
                      activePlayer.createdAt
                    ).format("HH:mm")} Uhr`}
                  />
                </ListItemButton>
              </Link>
            ))}
          </List>
        ) : (
          <Typography>
            Es sind keine Polizisten als Zivilist auf dem Server
          </Typography>
        )}
      </CustomCard>
    </>
  );
};
export default ActiveCopAsCivPlayers;
