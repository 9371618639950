import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";

const rconConsoleLogAdapter = createEntityAdapter();

const initialState = rconConsoleLogAdapter.getInitialState();

export const rconApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConsoleLogs: builder.query({
      query: () => ({
        url: "/rcon/console",
      }),
      transformResponse: (responseData) => {
        const data = responseData.map((consoleLog) => {
          consoleLog.id = consoleLog._id;
          return consoleLog;
        });

        return rconConsoleLogAdapter.setAll(initialState, data);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "RConConsoleLog", id: "LIST" },
            ...result.ids.map((id) => ({ type: "RConConsoleLog", id })),
          ];
        } else return [{ type: "RConConsoleLog", id: "LIST" }];
      },
    }),
    getOnlinePlayers: builder.query({
      query: () => ({
        url: "/rcon/players",
      }),
    }),
    getBans: builder.query({
      query: () => ({
        url: "/rcon/bans",
      }),
    }),
    reloadBans: builder.mutation({
      query: () => ({
        url: "/rcon/reload-bans",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "RConConsoleLog", id: "LIST" },
      ],
    }),
    banPlayer: builder.mutation({
      query: ({ params, body }) => ({
        method: "POST",
        url: "/rcon/ban",
        params,
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "RConConsoleLog", id: "LIST" },
      ],
    }),
    unbanPlayer: builder.mutation({
      query: (params) => ({
        url: "/rcon/unban",
        params,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "RConConsoleLog", id: "LIST" },
      ],
    }),
    kickPlayer: builder.mutation({
      query: (params) => ({
        url: "/rcon/kick",
        params,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "RConConsoleLog", id: "LIST" },
      ],
    }),
    sendMessage: builder.mutation({
      query: ({ params, body }) => ({
        method: "POST",
        url: "/rcon/say",
        params,
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "RConConsoleLog", id: "LIST" },
      ],
    }),
    restartMission: builder.mutation({
      query: () => ({
        url: "/rcon/restart-mission",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "RConConsoleLog", id: "LIST" },
      ],
    }),
    restartServer: builder.mutation({
      query: () => ({
        url: "/rcon/restart-server",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "RConConsoleLog", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetConsoleLogsQuery,
  useGetOnlinePlayersQuery,
  useGetBansQuery,
  useReloadBansMutation,
  useBanPlayerMutation,
  useUnbanPlayerMutation,
  useKickPlayerMutation,
  useSendMessageMutation,
  useRestartMissionMutation,
  useRestartServerMutation,
} = rconApiSlice;
