import { styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const RotatedExpandIcon = styled((props) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(360deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
export default RotatedExpandIcon;
