import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetCopsQuery } from "./copsApiSlice";
import { Box, Checkbox, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { dataGridSx } from "styles/dataGridSx";
import { hasPermission } from "util/permissionHelper";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";
import CopRankBadge from "components/CopRankBadge";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import CopFunctions from "./CopFunctions";
import CustomGridPagination from "components/CustomGridPagination";

const renderRank = (params) => <CopRankBadge rank={params.value} />;

const rankComparator = (a, b) => {
  if (a === "CA") return -1;

  const aValue = Number(a.substring(1));
  const bValue = Number(b.substring(1));

  return aValue - bValue;
};

const renderIsInvisible = (params) => (
  <Checkbox defaultChecked={params.value} disableRipple />
);

const renderFunctions = (params) => (
  <CopFunctions functions={params.value} readOnly hideOutlines hideLabel />
);

const DismissedCopDataGrid = () => {
  const theme = useTheme();
  const { roles: userRoles } = useAuth();
  const navigate = useNavigate();

  const { data: cops, isSuccess, isLoading } = useGetCopsQuery("copsList", {});

  const [rows, setRows] = useState([]);
  const [copEditPermissions, setCopEditPermissions] = useState(false);

  useEffect(() => {
    setCopEditPermissions(
      hasPermission({
        roles: userRoles,
        permissions: ["reappointCops", "editCops"],
      })
    );
  }, [userRoles]);

  useEffect(() => {
    if (isSuccess) {
      const newRows = cops?.ids
        .map((id) => ({
          id,
          user: cops.entities[id].user?.name,
          rank: cops.entities[id].rank,
          status: cops.entities[id].status,
          latestDismissal: cops.entities[id].latestDismissal,
          functions: cops.entities[id].functions,
          isInvisible: cops.entities[id].isInvisible,
        }))
        .filter((cop) => cop.status === "Gekündigt");

      setRows(newRows);
    }
  }, [isSuccess, cops]);

  const columns = useMemo(
    () =>
      copEditPermissions
        ? [
            {
              field: "rank",
              headerName: "Rang",
              flex: 1,
              maxWidth: 300,
              renderCell: renderRank,
              sortComparator: rankComparator,
              sortingOrder: ["desc", "asc"],
            },
            {
              field: "user",
              headerName: "Name",
              flex: 1,
              maxWidth: 150,
            },
            {
              field: "latestDismissal",
              ...dateTimeColumnType,
              headerName: "Gekündigt am",
              flex: 1,
              maxWidth: 150,
            },
            {
              field: "functions",
              headerName: "Funktionen",
              flex: 1,
              renderCell: renderFunctions,
            },
            {
              field: "isInvisible",
              headerName: "Unsichtbar",
              type: "boolean",
              renderCell: renderIsInvisible,
            },
          ]
        : [
            {
              field: "rank",
              headerName: "Rang",
              flex: 1,
              maxWidth: 300,
              renderCell: renderRank,
              sortComparator: rankComparator,
              sortingOrder: ["desc", "asc"],
            },
            {
              field: "user",
              headerName: "Name",
              flex: 1,
              maxWidth: 150,
            },
            {
              field: "latestDismissal",
              ...dateTimeColumnType,
              headerName: "Gekündigt am",
              maxWidth: 200,
            },
            {
              field: "functions",
              headerName: "Funktionen",
              flex: 1,
              renderCell: renderFunctions,
            },
          ],
    [copEditPermissions]
  );

  const handleRowClick = useCallback(
    (params) => {
      if (copEditPermissions) navigate(`/cops/${params.row.id}`);
    },
    // eslint-disable-next-line
    [copEditPermissions]
  );

  return (
    <Box
      height="30vh"
      sx={{
        ...dataGridSx(theme),

        "& .row-style--editable": {
          ":hover": {
            cursor: "pointer !important",
          },
        },
        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
      }}
    >
      <DataGrid
        columns={columns}
        rows={isSuccess ? rows : []}
        loading={isLoading}
        rowSelection={false}
        {...(!!copEditPermissions && {
          onRowClick: handleRowClick,
        })}
        getRowClassName={(params) =>
          copEditPermissions && "row-style--editable"
        }
        pageSizeOptions={[5, 10, 25]}
        initialState={{
          sorting: {
            sortModel: [{ field: "latestDismissal", sort: "desc" }],
          },
          pagination: {
            paginationModel: { pageSize: 5 },
          },
        }}
        slots={{
          pagination: CustomGridPagination,
        }}
      />
    </Box>
  );
};
export default DismissedCopDataGrid;
