import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { dataGridSx } from "styles/dataGridSx";
import { dateColumnType, dateTimeColumnType } from "util/dateTimeColumnType";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarQuickFilter,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  useDeleteRatingMutation,
  useUpdateRatingMutation,
} from "./ratingsApiSlice";
import { useSnackbar } from "contexts/Snackbar.context";
import { ratingFunctions, serverPeriods } from "util/copEnums";
import { Link } from "react-router-dom";
import CustomGridPagination from "components/CustomGridPagination";
import Textarea from "components/Textarea";
import { calcGridHeightSubtraction } from "util/calculations";
import ColoredRating from "components/ColoredRating";

const unclickableFields = ["ratedCop", "evaluatorCop", "actions"];

const RatingCommentDialog = ({ open, onClose, comment }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Anmerkung zur Bewertung</DialogTitle>
    <DialogContent>
      <Typography>{comment || "Keine Anmerkung vorhanden"}</Typography>
    </DialogContent>
  </Dialog>
);

const CustomGridToolbar = () => (
  <GridToolbarQuickFilter sx={{ maxWidth: "200px" }} />
);

const RatingEditInput = (params) => {
  const { id, field } = params;

  const apiRef = useGridApiContext();

  const handleChange = (_, newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <ColoredRating value={params.value} onChange={handleChange} />;
};

const renderRating = ({ value }) => <ColoredRating value={value} readOnly />;

const renderRatingInput = (params) => <RatingEditInput {...params} />;

const RatingDataGrid = ({ ratings, isLoading, readOnly, perspective }) => {
  const theme = useTheme();
  const showSnackbar = useSnackbar();
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [currentComment, setCurrentComment] = useState("");

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [deleteRating] = useDeleteRatingMutation();
  const [updateRating] = useUpdateRatingMutation();

  useEffect(() => {
    setRows(ratings || []);
  }, [ratings]);

  const handleRowModesModelChange = useCallback((newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  }, []);

  const handleRowEditStop = useCallback((params, event) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.enterKeyDown
    ) {
      event.defaultMuiPrevented = true;
    }
  }, []);

  const handleEditClick = useCallback(
    (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const showConfirmDialog = useConfirmDialog();

  const handleDeleteLearningModule = useCallback(async (id) => {
    try {
      await deleteRating(id).unwrap();
      showSnackbar("Bewertung gelöscht");
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = useCallback(
    (id) => () => {
      showConfirmDialog({
        title: "Bewertung löschen?",
        message: "Soll die Bewertung gelöscht werden?",
        onConfirm: () => handleDeleteLearningModule(id),
      });
    },
    // eslint-disable-next-line
    [handleDeleteLearningModule]
  );

  const handleCancelClick = useCallback(
    (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    },
    [rows, rowModesModel]
  );

  const processRowUpdate = useCallback(async (newRow) => {
    if (newRow.isNew) {
      newRow.isNew = false;
      showSnackbar("Bewertung angelegt");
    } else {
      await updateRating(newRow).unwrap();
      showSnackbar("Bewertung gespeichert");
    }

    return newRow;
    // eslint-disable-next-line
  }, []);

  const handleProcessRowUpdateError = useCallback((error) => {
    showSnackbar(error.data?.message || "Fehler", "error");
    // eslint-disable-next-line
  }, []);

  const renderRatedName = useCallback(
    ({ value, row }) =>
      readOnly ? (
        value
      ) : (
        <Link
          to={`/cops/${row.rated?.cop?._id}`}
          style={{ color: theme.palette.info.main, textDecoration: "none" }}
        >
          {value}
        </Link>
      ),
    [theme, readOnly]
  );

  const renderEvaluatorName = useCallback(
    ({ value, row }) =>
      readOnly ? (
        value
      ) : (
        <Link
          to={`/cops/${row.evaluator?.cop?._id}`}
          style={{ color: theme.palette.info.main, textDecoration: "none" }}
        >
          {value}
        </Link>
      ),
    [theme, readOnly]
  );

  const columns = useMemo(() => {
    const columns =
      perspective === "userReceived"
        ? [
            {
              field: "ratedFunction",
              headerName: "An Funktion",
              valueGetter: ({ row }) => row.rated?.function,
              type: "singleSelect",
              valueOptions: ratingFunctions,
              flex: 1,
              maxWidth: 200,
              editable: false,
            },
            {
              field: "evaluatorFunction",
              headerName: "Von Funktion",
              valueGetter: ({ row }) => row.evaluator?.function,
              type: "singleSelect",
              valueOptions: ratingFunctions,
              flex: 1,
              maxWidth: 200,
              editable: false,
            },
            {
              field: "date",
              headerName: "Datum",
              ...dateColumnType,
              flex: 1,
              maxWidth: 110,
              editable: false,
            },
            {
              field: "period",
              headerName: "Server Periode",
              flex: 1,
              maxWidth: 150,
              type: "singleSelect",
              valueOptions: serverPeriods,
              editable: false,
            },
            {
              field: "ratingsRoleplay",
              type: "number",
              headerName: "Roleplay",
              headerAlign: "left",
              align: "left",
              valueGetter: ({ row }) => row.ratings?.roleplay,
              renderCell: renderRating,
              flex: 1,
              maxWidth: 150,
              editable: false,
            },
            {
              field: "ratingsPerformance",
              type: "number",
              headerName: "Leistung unter Stress",
              headerAlign: "left",
              align: "left",
              valueGetter: ({ row }) => row.ratings?.performance,
              renderCell: renderRating,
              flex: 1,
              maxWidth: 150,
              editable: false,
            },
            {
              field: "ratingsTactics",
              type: "number",
              headerName: "Taktisches Verständnis",
              headerAlign: "left",
              align: "left",
              valueGetter: ({ row }) => row.ratings?.tactics,
              renderCell: renderRating,
              flex: 1,
              maxWidth: 150,
              editable: false,
            },
            {
              field: "ratingsRadio",
              type: "number",
              headerName: "Funkdisziplin",
              headerAlign: "left",
              align: "left",
              valueGetter: ({ row }) => row.ratings?.radio,
              renderCell: renderRating,
              flex: 1,
              maxWidth: 150,
              editable: false,
            },
            {
              field: "ratingsKnowledge",
              type: "number",
              headerName: "Fachwissen",
              headerAlign: "left",
              align: "left",
              valueGetter: ({ row }) => row.ratings?.knowledge,
              renderCell: renderRating,
              flex: 1,
              maxWidth: 150,
              editable: false,
            },
            {
              field: "comment",
              headerName: "Anmerkung",
              flex: 1,
              editable: false,
            },
            {
              field: "updatedAt",
              headerName: "Geändert am",
              ...dateTimeColumnType,
              flex: 1,
              maxWidth: 120,
            },
            {
              field: "createdAt",
              headerName: "Erstellt am",
              ...dateTimeColumnType,
              flex: 1,
              maxWidth: 120,
            },
          ]
        : [
            {
              field: "ratedCop",
              headerName: "An",
              valueGetter: ({ row }) => row.rated?.cop?.user?.name,
              renderCell: renderRatedName,
              flex: 1,
            },
            {
              field: "ratedFunction",
              headerName: "An Funktion",
              valueGetter: ({ row }) => row.rated?.function,
              valueSetter: ({ row, value }) => ({
                ...row,
                rated: { ...row.rated, function: value },
              }),
              type: "singleSelect",
              valueOptions: ratingFunctions,
              flex: 1,
              editable: !readOnly || perspective === "userSent",
            },
            {
              field: "evaluatorCop",
              headerName: "Von",
              valueGetter: ({ row }) => row.evaluator?.cop?.user?.name,
              renderCell: renderEvaluatorName,
              flex: 1,
            },
            {
              field: "evaluatorFunction",
              headerName: "Von Funktion",
              valueGetter: ({ row }) => row.evaluator?.function,
              valueSetter: ({ row, value }) => ({
                ...row,
                evaluator: { ...row.evaluator, function: value },
              }),
              type: "singleSelect",
              valueOptions: ratingFunctions,
              flex: 1,
              editable: !readOnly || perspective === "userSent",
            },
            {
              field: "date",
              headerName: "Datum",
              ...dateColumnType,
              flex: 1,
              maxWidth: 110,
              editable: !readOnly || perspective === "userSent",
            },
            {
              field: "period",
              headerName: "Server Periode",
              flex: 1,
              maxWidth: 150,
              type: "singleSelect",
              valueOptions: serverPeriods,
              editable: !readOnly || perspective === "userSent",
            },
            {
              field: "ratingsRoleplay",
              type: "number",
              headerName: "Roleplay",
              headerAlign: "left",
              align: "left",
              valueGetter: ({ row }) => row.ratings?.roleplay,
              valueSetter: ({ row, value }) => ({
                ...row,
                ratings: { ...row.ratings, roleplay: value },
              }),
              renderCell: renderRating,
              renderEditCell: renderRatingInput,
              flex: 1,
              maxWidth: 150,
              editable: !readOnly || perspective === "userSent",
            },
            {
              field: "ratingsPerformance",
              type: "number",
              headerName: "Leistung unter Stress",
              headerAlign: "left",
              align: "left",
              valueGetter: ({ row }) => row.ratings?.performance,
              valueSetter: ({ row, value }) => ({
                ...row,
                ratings: { ...row.ratings, performance: value },
              }),
              renderCell: renderRating,
              renderEditCell: renderRatingInput,
              flex: 1,
              maxWidth: 150,
              editable: !readOnly || perspective === "userSent",
            },
            {
              field: "ratingsTactics",
              type: "number",
              headerName: "Taktisches Verständnis",
              headerAlign: "left",
              align: "left",
              valueGetter: ({ row }) => row.ratings?.tactics,
              valueSetter: ({ row, value }) => ({
                ...row,
                ratings: { ...row.ratings, tactics: value },
              }),
              renderCell: renderRating,
              renderEditCell: renderRatingInput,
              flex: 1,
              maxWidth: 150,
              editable: !readOnly || perspective === "userSent",
            },
            {
              field: "ratingsRadio",
              type: "number",
              headerName: "Funkdisziplin",
              headerAlign: "left",
              align: "left",
              valueGetter: ({ row }) => row.ratings?.radio,
              valueSetter: ({ row, value }) => ({
                ...row,
                ratings: { ...row.ratings, radio: value },
              }),
              renderCell: renderRating,
              renderEditCell: renderRatingInput,
              flex: 1,
              maxWidth: 150,
              editable: !readOnly || perspective === "userSent",
            },
            {
              field: "ratingsKnowledge",
              type: "number",
              headerName: "Fachwissen",
              headerAlign: "left",
              align: "left",
              valueGetter: ({ row }) => row.ratings?.knowledge,
              valueSetter: ({ row, value }) => ({
                ...row,
                ratings: { ...row.ratings, knowledge: value },
              }),
              renderCell: renderRating,
              renderEditCell: renderRatingInput,
              flex: 1,
              maxWidth: 150,
              editable: !readOnly || perspective === "userSent",
            },
            {
              field: "comment",
              headerName: "Anmerkung",
              flex: 1,
              editable: !readOnly || perspective === "userSent",
              renderEditCell: (params) => <Textarea {...params} />,
            },
            {
              field: "updatedAt",
              headerName: "Geändert am",
              ...dateTimeColumnType,
              flex: 1,
              maxWidth: 120,
            },
            {
              field: "updatedBy",
              headerName: "Geändert von",
              valueGetter: ({ value }) => value?.name,
              flex: 1,
            },
            {
              field: "createdAt",
              headerName: "Erstellt am",
              ...dateTimeColumnType,
              flex: 1,
              maxWidth: 120,
            },
          ];

    if (
      (!readOnly || perspective === "userSent") &&
      perspective !== "userReceived"
    ) {
      columns.push({
        field: "actions",
        headerName: "Aktionen",
        type: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Speichern"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          const actions = [];

          if (perspective !== "userReceived") {
            actions.push(
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Bearbeiten"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />
            );
          }

          if (perspective !== "userReceived" && perspective !== "userSent") {
            actions.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Löschen"
                onClick={handleDeleteClick(id)}
                color="error"
              />
            );
          }

          return actions;
        },
      });
    }
    return columns;
  }, [
    handleCancelClick,
    handleDeleteClick,
    handleEditClick,
    handleSaveClick,
    perspective,
    readOnly,
    renderEvaluatorName,
    renderRatedName,
    rowModesModel,
  ]);

  const handleCellClick = useCallback(({ field, row }) => {
    if (unclickableFields.includes(field)) return;

    setCurrentComment(row.comment);
    setCommentDialogOpen(true);
  }, []);

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .cell-clickable": {
          ":hover": {
            cursor: "pointer !important",
          },
        },
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows?.length > 0 ? rows : []}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        editMode="row"
        disableRowSelectionOnClick
        onCellClick={handleCellClick}
        slots={{
          pagination: CustomGridPagination,
          toolbar: CustomGridToolbar,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        getCellClassName={({ field }) =>
          unclickableFields.includes(field) ? "" : "cell-clickable"
        }
        sx={{
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
      />
      <RatingCommentDialog
        open={commentDialogOpen}
        onClose={() => setCommentDialogOpen(false)}
        comment={currentComment}
      />
    </Box>
  );
};
export default memo(RatingDataGrid);
