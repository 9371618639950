import { Box } from "@mui/material";
import Header from "components/Header";
import LearningModulesDataGrid from "features/learningModules/LearningModulesDataGrid";
import { useEffect } from "react";

const LearningModuleList = () => {
  useEffect(() => {
    document.title = `Module | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="MODULE" subtitle="Lerninhalte" />
      <LearningModulesDataGrid />
    </Box>
  );
};
export default LearningModuleList;
