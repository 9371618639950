import { Box, CircularProgress, Typography } from "@mui/material";
import { memo } from "react";

const CircularProgressWithBackground = (props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        position: "relative",
      }}
    >
      <CircularProgress
        {...props}
        value={100}
        sx={{
          color: "action.selected",
        }}
      />
      <CircularProgress
        {...props}
        sx={{
          position: "absolute",
          left: 0,
        }}
      />
      {props.label && (
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            sx={{ userSelect: "none" }}
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default memo(CircularProgressWithBackground);
