import CustomCard from "components/CustomCard";
import { useGetBansQuery, useUnbanPlayerMutation } from "./rconApiSlice";
import { useCallback, useMemo, useState } from "react";
import { Box, Button, CircularProgress, useTheme } from "@mui/material";
import { dataGridSx } from "styles/dataGridSx";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useSnackbar } from "contexts/Snackbar.context";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import PlayerBanDialog from "./PlayerBanDialog";
import CustomGridPagination from "components/CustomGridPagination";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

const GridToolbar = () => {
  const [openBanDialog, setOpenBanDialog] = useState(false);

  return (
    <GridToolbarContainer>
      <Button
        startIcon={<AddOutlinedIcon />}
        onClick={() => setOpenBanDialog(true)}
      >
        Offline Ban
      </Button>
      <PlayerBanDialog
        open={openBanDialog}
        onClose={() => setOpenBanDialog(false)}
      />
    </GridToolbarContainer>
  );
};

const RConBanList = () => {
  const {
    data: bans,
    isLoading,
    isSuccess,
    refetch,
  } = useGetBansQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [unbanPlayer, { isLoading: unbanPlayerIsLoading }] =
    useUnbanPlayerMutation();
  const theme = useTheme();
  const showSnackbar = useSnackbar();

  const onDeleteBan = useCallback(
    (id) => {
      unbanPlayer({ banId: id })
        .unwrap()
        .then(() => {
          refetch();
          showSnackbar(`Bann gelöscht`);
        })
        .catch((error) => {
          refetch();
          showSnackbar(
            error.data?.message || "Fehler beim Löschen des Banns",
            "error"
          );
        });
    },
    [unbanPlayer, showSnackbar, refetch]
  );

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "#",
        width: 50,
      },
      {
        field: "type",
        headerName: "Typ",
        flex: 1,
        maxWidth: 100,
      },
      {
        field: "resource",
        headerName: "Ressource",
        flex: 1,
        maxWidth: 300,
      },
      {
        field: "timeLeft",
        headerName: "Rest Dauer",
        valueFormatter: ({ value }) => `${value}${value === "perm" ? "" : "m"}`,
      },
      {
        field: "reason",
        headerName: "Grund",
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        getActions: ({ row }) => [
          <GridActionsCellItem
            icon={
              unbanPlayerIsLoading ? (
                <CircularProgress size="1em" color="error" />
              ) : (
                <DeleteIcon color="error" />
              )
            }
            label="Löschen"
            onClick={() => onDeleteBan(row.id)}
            disabled={unbanPlayerIsLoading}
          />,
        ],
      },
    ],
    [unbanPlayerIsLoading, onDeleteBan]
  );

  return (
    <CustomCard title="BANNLISTE" expandable defaultExpanded>
      <Box sx={dataGridSx(theme)} height="400px">
        <DataGrid
          rows={isSuccess ? bans : []}
          loading={isLoading}
          columns={columns}
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
            pagination: CustomGridPagination,
          }}
        />
      </Box>
    </CustomCard>
  );
};
export default RConBanList;
