import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";
import CustomCard from "components/CustomCard";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import { useSnackbar } from "contexts/Snackbar.context";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CancelIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import { dataGridSx } from "styles/dataGridSx";
import { useGetLearningModulesQuery } from "features/learningModules/learningModulesApiSlice";
import {
  useAddCopModuleMutation,
  useRemoveCopModuleMutation,
} from "./copsApiSlice";
import CustomGridPagination from "components/CustomGridPagination";

const EditShortcutInput = ({
  learningModuleList,
  learningModules,
  getLearningModulesIsSuccess,
  ...params
}) => {
  const { id, field } = params;

  const apiRef = useGridApiContext();

  const handleChange = (event) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
  };

  if (!getLearningModulesIsSuccess) {
    return <CircularProgress size="1.5em" />;
  }

  return (
    <TextField
      select
      fullWidth
      value={params.value}
      onChange={handleChange}
      sx={{
        ".MuiOutlinedInput-notchedOutline": { border: "none" },
      }}
    >
      {learningModuleList.ids.map((id) => (
        <MenuItem
          key={learningModuleList.entities[id]._id}
          value={learningModuleList.entities[id].shortcut}
          disabled={learningModules?.some(
            (learningModule) =>
              learningModule._id === learningModuleList.entities[id]._id
          )}
        >
          {learningModuleList.entities[id].shortcut}
        </MenuItem>
      ))}
    </TextField>
  );
};

const CopLearningModules = ({ copId, learningModules, canEditCop }) => {
  const theme = useTheme();
  const showSnackbar = useSnackbar();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const { data: learningModuleList, isSuccess: getLearningModulesIsSuccess } =
    useGetLearningModulesQuery("learningModulesList", {
      refetchOnMountOrArgChange: true,
    });

  const [addModule] = useAddCopModuleMutation();
  const [removeModule] = useRemoveCopModuleMutation();

  useEffect(() => {
    if (learningModules) {
      setRows(
        learningModules?.map((learningModule) => ({
          id: learningModule._id,
          ...learningModule,
        }))
      );
    }
  }, [learningModules]);

  const EditToolbar = useCallback(() => {
    const handleAddModule = async () => {
      const id = `$${Date.now()}`;
      setRows((oldRows) => [
        {
          id,
          shortcut: "",
          name: "",
          completedAt: "",
          enteredBy: "",
          isNew: true,
        },
        ...oldRows,
      ]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "shortcut" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          variant="text"
          onClick={handleAddModule}
          startIcon={<AddOutlinedIcon />}
        >
          Modul hinzufügen
        </Button>
      </GridToolbarContainer>
    );
  }, []);

  const showConfirmDialog = useConfirmDialog();

  const handleDeleteModule = useCallback(async (moduleId) => {
    try {
      setRows((oldRows) => oldRows.filter((row) => row.id !== moduleId));
      await removeModule({ id: copId, moduleId: moduleId }).unwrap();
      showSnackbar("Modul entfernt");
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = useCallback(
    (id) => () => {
      showConfirmDialog({
        title: "Modul löschen?",
        message:
          "Soll das das Modul aus den absolvierten Modulen entfernt werden?",
        onConfirm: () => handleDeleteModule(id),
      });
    },
    // eslint-disable-next-line
    [handleDeleteModule]
  );

  const handleCancelClick = useCallback(
    (id) => () => {
      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    },
    [rows]
  );

  const handleSaveClick = useCallback(
    (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const processRowUpdate = useCallback(
    async (newRow) => {
      if (newRow.isNew) {
        const moduleId = learningModuleList.ids.find(
          (id) => learningModuleList.entities[id].shortcut === newRow.shortcut
        );

        if (!moduleId) return;

        await addModule({ id: copId, moduleId }).unwrap();

        newRow.isNew = false;
        showSnackbar("Modul hinzugefügt");
      }

      return newRow;
    },
    // eslint-disable-next-line
    [copId, learningModuleList]
  );

  const handleProcessRowUpdateError = useCallback((error) => {
    console.log(error);
    showSnackbar(error.data?.message || "Fehler", "error");
    // eslint-disable-next-line
  }, []);

  const renderShortcutEdit = useCallback(
    (params) => (
      <EditShortcutInput
        {...params}
        learningModuleList={learningModuleList}
        learningModules={learningModules}
        getLearningModulesIsSuccess={getLearningModulesIsSuccess}
      />
    ),
    [learningModuleList, learningModules, getLearningModulesIsSuccess]
  );

  const columns = useMemo(() => {
    const columns = [
      {
        field: "shortcut",
        headerName: "Abkürzung",
        renderEditCell: renderShortcutEdit,
        maxWidth: 120,
        editable: true,
        flex: 1,
      },
      {
        field: "name",
        headerName: "Bezeichnung",
        flex: 1,
      },
      {
        field: "completedAt",
        ...dateTimeColumnType,
        headerName: "Absolviert am",
        maxWidth: 150,
        flex: 1,
      },
      {
        field: "enteredBy",
        headerName: "Eingetragen von",
        valueGetter: (params) => params.value?.name,
        maxWidth: 150,
        flex: 1,
      },
    ];

    if (canEditCop)
      columns.push({
        field: "actions",
        headerName: "Aktionen",
        type: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Speichern"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Löschen"
              onClick={handleDeleteClick(id)}
              color="error"
            />,
          ];
        },
      });

    return columns;
  }, [
    renderShortcutEdit,
    canEditCop,
    handleCancelClick,
    handleDeleteClick,
    handleSaveClick,
    rowModesModel,
  ]);

  return (
    <CustomCard title="ABSOLVIERTE MODULE">
      <Box height="350px" width="100%" sx={dataGridSx(theme)}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "shortcut", sort: "asc" }],
            },
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          density="compact"
          columns={columns}
          rows={rows || []}
          loading={!!!rows}
          editMode="row"
          slots={
            canEditCop
              ? { toolbar: EditToolbar, pagination: CustomGridPagination }
              : { pagination: CustomGridPagination }
          }
          pageSizeOptions={[5, 10, 25]}
          rowModesModel={rowModesModel}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          sx={{
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
          }}
        />
      </Box>
    </CustomCard>
  );
};
export default memo(CopLearningModules);
