import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

const ChangedItem = ({ item }) => (
  <ListItem>
    <ListItemText primary={item.name} />
    <Tooltip
      arrow
      title={
        <Typography>
          {item.oldCount < item.newCount
            ? "Gegenstände hinzufügt"
            : "Gegenstände entfernt"}
        </Typography>
      }
    >
      <Chip
        sx={{
          justifyContent: "flex-start",
          userSelect: "none",
        }}
        size="small"
        icon={
          item.oldCount < item.newCount ? (
            <AddCircleOutlineOutlinedIcon />
          ) : (
            <RemoveCircleOutlineOutlinedIcon />
          )
        }
        color={item.oldCount < item.newCount ? "success" : "error"}
        label={
          <Box display="flex" alignItems="center">
            <Typography>{item.oldCount}</Typography>
            <ArrowRightAltOutlinedIcon />
            <Typography>{item.newCount}</Typography>
          </Box>
        }
      />
    </Tooltip>
  </ListItem>
);

const ArmoryChangeDialog = ({ open, onClose, change }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{`Änderungen vom ${dayjs(change?.createdAt).format(
        "DD.MM.YYYY HH:mm"
      )}`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} columns={2}>
          <Grid item xs={2} md={1}>
            <List
              subheader={
                <ListSubheader sx={{ backgroundColor: "transparent" }}>
                  {`Ausrüstung - ${change?.gearChanges?.length} Änderungen`}
                </ListSubheader>
              }
            >
              <Box maxHeight="300px" overflow="auto">
                {change?.gearChanges?.map((item, index) => (
                  <Box key={item._id}>
                    <ChangedItem item={item} />
                    {index < change?.gearChanges?.length - 1 && (
                      <Divider component="li" sx={{ m: "0 8px" }} />
                    )}
                  </Box>
                ))}
              </Box>
            </List>
          </Grid>
          <Grid item xs={2} md={1}>
            <List
              subheader={
                <ListSubheader sx={{ backgroundColor: "transparent" }}>
                  {`Virtuelle Gegenstände (T-Inventar) - ${change?.inventoryChanges?.length} Änderungen`}
                </ListSubheader>
              }
            >
              <Box maxHeight="300px" overflow="auto">
                {change?.inventoryChanges?.map((item, index) => (
                  <Box key={item._id}>
                    <ChangedItem item={item} />
                    {index < change?.inventoryChanges?.length - 1 && (
                      <Divider component="li" sx={{ m: "0 8px" }} />
                    )}
                  </Box>
                ))}
              </Box>
            </List>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default ArmoryChangeDialog;
