import { Box, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import InitNameFormDialog from "widgets/InitNameFormDialog";

const Layout = () => {
  const theme = useTheme();

  return (
    <Box display="flex" width="100%">
      <Sidebar />

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Navbar />
        <Box
          display="flex"
          flexDirection="column"
          ml={theme.spacing(2)}
          mr={theme.spacing(2)}
          pb={theme.spacing(2)}
          height="100%"
        >
          <Outlet />
        </Box>
      </Box>
      <InitNameFormDialog />
    </Box>
  );
};

export default Layout;
