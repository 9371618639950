import { useCallback, useEffect, useState } from "react";
import { useGetUserApplicationsQuery } from "./applicationsApiSlice";
import dayjs from "dayjs";
import { Box, Chip, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { dataGridSx } from "styles/dataGridSx";
import { getApplicationStatusColor } from "util/muiColorGetter";
import CustomGridPagination from "components/CustomGridPagination";

const renderStatus = (params) => (
  <Chip
    label={params.value}
    size="small"
    color={getApplicationStatusColor(params.value)}
  />
);

const columns = [
  {
    field: "status",
    headerName: "Status",
    renderCell: renderStatus,
    flex: 0.6,
  },
  {
    field: "createdAt",
    headerName: "Erstellt am",
    valueGetter: (params) => dayjs(params.value).format("DD.MM.YYYY HH:mm"),
    flex: 0.7,
  },
  {
    field: "applicationType",
    headerName: "Bewerbungstyp",
    flex: 0.7,
  },
  {
    field: "recruitmentPhase",
    headerName: "Einstellungsphase",
    flex: 1,
  },
];

const MyApplicationsDataGrid = () => {
  const theme = useTheme();
  const {
    data: applications,
    isSuccess,
    isLoading,
  } = useGetUserApplicationsQuery("userApplicationsList", {
    refetchOnMountOrArgChange: true,
  });

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (isSuccess)
      setRows(
        applications?.ids.map((id) => ({
          id: id,
          user: applications.entities[id].user.name,
          recruitmentPhase: applications.entities[id].recruitmentPhase?.name,
          applicationType: applications.entities[id].applicationType,
          status: applications.entities[id].status,
          createdAt: applications.entities[id].createdAt,
        }))
      );
  }, [isSuccess, applications]);

  const navigate = useNavigate();

  const handleRowClick = useCallback((params) => {
    navigate(`/profile/my-applications/${params.row.id}`);
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        height: "350px",
        ...dataGridSx(theme),
        ".MuiDataGrid-row": {
          ":hover": {
            cursor: "pointer !important",
          },
        },
        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-row": {
          borderBottom: "solid",

          borderColor: "primary.main",
          borderWidth: "1px",
        },
      }}
    >
      <DataGrid
        density="compact"
        columns={columns}
        rows={isSuccess ? rows : []}
        loading={isLoading}
        onRowClick={handleRowClick}
        rowSelection={false}
        pageSizeOptions={[5, 10, 25]}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10 },
          },
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        slots={{
          pagination: CustomGridPagination,
        }}
      />
    </Box>
  );
};
export default MyApplicationsDataGrid;
