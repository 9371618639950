import { Chip, Typography } from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InfoChip = ({ label }) => {
  return (
    <Chip
      component="div"
      icon={<InfoOutlinedIcon color="info" />}
      label={
        <Typography
          sx={{
            whiteSpace: "normal",
          }}
        >
          {label}
        </Typography>
      }
      sx={{
        height: "100%",
        p: "0.5em 0",
      }}
    />
  );
};

export default InfoChip;
