import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useGetOnlinePlayersQuery,
  useReloadBansMutation,
  useRestartMissionMutation,
  useRestartServerMutation,
  useSendMessageMutation,
} from "./rconApiSlice";
import { useSnackbar } from "contexts/Snackbar.context";
import { useEffect, useState } from "react";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

const MessageBox = () => {
  const theme = useTheme();
  const showSnackbar = useSnackbar();

  const { data: players, isSuccess } = useGetOnlinePlayersQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [sendMessage, { isLoading }] = useSendMessageMutation();

  const [recipients, setRecipients] = useState([
    { id: "-1", name: "Alle Spieler" },
  ]);
  const [selectedRecipient, setSelectedRecipient] = useState("-1");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setRecipients([
        { id: "-1", name: "Alle Spieler" },
        ...players.map((player) => ({ id: player.id, name: player.name })),
      ]);

      setSelectedRecipient("-1");
    }
  }, [isSuccess, players]);

  const StartAdornment = () => (
    <Box display="flex" alignItems="center" height="100%">
      <Typography>An</Typography>
      <TextField
        select
        value={selectedRecipient}
        onChange={(event) => setSelectedRecipient(event.target.value)}
        sx={{
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
      >
        {recipients.map((recipient) => (
          <MenuItem key={recipient.id} value={recipient.id}>
            <Typography fontStyle="italic">{recipient.name}</Typography>
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );

  return (
    <TextField
      fullWidth
      value={message}
      onChange={(event) => setMessage(event.target.value)}
      placeholder="Nachricht"
      InputProps={{
        sx: {
          borderRadius: theme.shape.borderRadius,
        },
        startAdornment: (
          <InputAdornment position="start">
            {" "}
            <StartAdornment />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="medium"
              color="primary"
              onClick={() => {
                sendMessage({
                  params: {
                    player: selectedRecipient,
                  },
                  body: {
                    message,
                  },
                })
                  .unwrap()
                  .then(() => {
                    showSnackbar("Nachricht gesendet");
                    setMessage("");
                  })
                  .catch((error) => {
                    showSnackbar(
                      error.data?.message || "Fehler beim Senden der Nachricht",
                      "error"
                    );
                  });
              }}
              disabled={!message || isLoading}
            >
              <SendOutlinedIcon fontSize="inherit" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{
        maxLength: 200,
      }}
    />
  );
};

const RConFunctions = () => {
  const [reloadBans, { isLoading: reloadBansIsLoading }] =
    useReloadBansMutation();
  const [restartMission, { isLoading: restartMissionIsLoading }] =
    useRestartMissionMutation();
  const [restartServer, { isLoading: restartServerIsLoading }] =
    useRestartServerMutation();

  const showSnackbar = useSnackbar();

  return (
    <Grid container spacing={2} columns={16} alignItems="center">
      <Grid item xs={12} md={10}>
        <MessageBox />
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          startIcon={reloadBansIsLoading && <CircularProgress size="1em" />}
          disabled={reloadBansIsLoading}
          fullWidth
          variant="contained"
          onClick={() => {
            reloadBans()
              .unwrap()
              .then(() => {
                showSnackbar("Bannliste von bans.txt erneuert");
              })
              .catch((error) => {
                showSnackbar(error.data?.message || "Fehler", "error");
              });
          }}
        >
          Bannliste neuladen
        </Button>
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          startIcon={restartMissionIsLoading && <CircularProgress size="1em" />}
          disabled={restartMissionIsLoading}
          color="error"
          fullWidth
          variant="contained"
          onClick={() => {
            restartMission()
              .unwrap()
              .then(() => {
                showSnackbar("Mission wird neugestartet");
              })
              .catch((error) => {
                showSnackbar(error.data?.message || "Fehler", "error");
              });
          }}
        >
          Mission neustarten
        </Button>
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          startIcon={restartServerIsLoading && <CircularProgress size="1em" />}
          disabled={restartServerIsLoading}
          color="error"
          fullWidth
          variant="contained"
          onClick={() => {
            restartServer()
              .unwrap()
              .then(() => {
                showSnackbar("Server wird neugestartet");
              })
              .catch((error) => {
                showSnackbar(error.data?.message || "Fehler", "error");
              });
          }}
        >
          Server neustarten
        </Button>
      </Grid>
    </Grid>
  );
};
export default RConFunctions;
