import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useAddLearningModuleMutation,
  useDeleteLearningModuleMutation,
  useGetLearningModulesQuery,
  useUpdateLearningModuleMutation,
} from "./learningModulesApiSlice";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "contexts/Snackbar.context";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { dataGridSx } from "styles/dataGridSx";
import { ranks } from "util/copEnums";
import CopRankBadge from "components/CopRankBadge";
import dayjs from "dayjs";
import { useGetCopsQuery } from "features/cops/copsApiSlice";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import CustomGridPagination from "components/CustomGridPagination";
import { Link } from "react-router-dom";
import { calcGridHeightSubtraction } from "util/calculations";

const LearningModulesDataGrid = () => {
  const theme = useTheme();

  const { data: cops, isSuccess: getCopsIsSuccess } = useGetCopsQuery(
    "copsList",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: learningModules,
    isSuccess: getLearningModulesIsSuccess,
    isLoading: getLearningModulesIsLoading,
  } = useGetLearningModulesQuery("learningModulesList", {
    refetchOnMountOrArgChange: true,
  });

  const [addLearningModule] = useAddLearningModuleMutation();
  const [updateLearningModule] = useUpdateLearningModuleMutation();
  const [deleteLearningModule] = useDeleteLearningModuleMutation();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const showSnackbar = useSnackbar();

  useEffect(() => {
    if (getLearningModulesIsSuccess) {
      setRows(
        learningModules?.ids.map((id) => ({
          id,
          shortcut: learningModules.entities[id].shortcut,
          name: learningModules.entities[id].name,
          requiredForRank: learningModules.entities[id].requiredForRank,
          graduates: learningModules.entities[id].graduates,
        }))
      );
    }
  }, [learningModules, getLearningModulesIsSuccess]);

  const EditToolbar = () => {
    const handleAddLearningModule = async () => {
      const id = `$${Date.now()}`;
      setRows((oldRows) => [
        {
          id,
          shortcut: "",
          name: "",
          requiredForRank: "",
          graduates: [],
          isNew: true,
        },
        ...oldRows,
      ]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "shortcut" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          variant="text"
          onClick={handleAddLearningModule}
          startIcon={<AddOutlinedIcon />}
        >
          Modul hinzufügen
        </Button>
      </GridToolbarContainer>
    );
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const showConfirmDialog = useConfirmDialog();

  const handleDeleteLearningModule = useCallback(async (id) => {
    try {
      await deleteLearningModule(id).unwrap();
      showSnackbar("Modul gelöscht");
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = (id) => () => {
    showConfirmDialog({
      title: "Modul löschen?",
      message: "Soll das Modul gelöscht werden?",
      onConfirm: () => handleDeleteLearningModule(id),
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    if (newRow.isNew) {
      await addLearningModule(newRow).unwrap();
      newRow.isNew = false;
      showSnackbar("Modul angelegt");
    } else {
      await updateLearningModule(newRow).unwrap();
      showSnackbar("Modul gespeichert");
    }

    return newRow;
  };

  const handleProcessRowUpdateError = (error) => {
    showSnackbar(error.data?.message || "Fehler", "error");
  };

  const renderRank = (params) =>
    params.value && <CopRankBadge rank={params.value} />;

  const EditRankInput = (params) => {
    const { id, field } = params;

    const apiRef = useGridApiContext();

    const handleChange = (event) => {
      apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    };

    return (
      <TextField
        select
        value={params.value}
        fullWidth
        onChange={handleChange}
        sx={{
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
      >
        {Object.keys(ranks)
          .filter((rank) => rank !== "AD")
          .map((rank) => (
            <MenuItem key={rank} value={rank}>
              <CopRankBadge rank={rank} />
            </MenuItem>
          ))}
      </TextField>
    );
  };

  const renderEditRank = (params) => <EditRankInput {...params} />;

  const cops2graduates = ({ cops, graduates }) =>
    cops.map((cop) => {
      const graduate = graduates.find(
        (graduate) => graduate.cop?._id === cop._id
      );

      return {
        cop: cop,
        completedAt: graduate?.completedAt,
        enteredBy: graduate?.enteredBy,
      };
    });

  const GraduatesAutoComplete = (params) => (
    <Autocomplete
      fullWidth
      multiple
      limitTags={10}
      freeSolo
      noOptionsText="Keine Absolventen"
      disableCloseOnSelect
      readOnly={params.readOnly}
      value={params.value}
      onChange={params.onChange}
      forcePopupIcon={!params.readOnly}
      options={
        getCopsIsSuccess
          ? cops2graduates({
              cops: cops.ids.map((id) => cops.entities[id]),
              graduates: params.value,
            })
          : []
      }
      getOptionLabel={(option) => option?.cop?.user?.name}
      isOptionEqualToValue={(option, value) =>
        option.cop?._id === value.cop?._id
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: "8px" }}
            checked={selected}
          />
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
            mr="16px"
          >
            <Box display="flex" alignItems="center">
              <CopRankBadge rank={option.cop?.rank} hideTitle height="25px" />
              <Box ml="8px">{option.cop?.user?.name}</Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Tooltip arrow title={<Typography>Absolviert am</Typography>}>
                <Box
                  display={!!option.completedAt ? "flex" : "none"}
                  alignItems="center"
                >
                  <TodayOutlinedIcon sx={{ mr: "4px" }} color="primary" />

                  {dayjs(option.completedAt).format("DD.MM.YYYY HH:mm")}
                </Box>
              </Tooltip>

              <Tooltip
                sx={{ ml: "16px" }}
                arrow
                title={<Typography>Eingetragen von</Typography>}
              >
                <Box
                  display={!!option.enteredBy?.name ? "flex" : "none"}
                  alignItems="center"
                >
                  <SchoolOutlinedIcon sx={{ mr: "4px" }} color="primary" />

                  {option.enteredBy?.name}
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={option.cop?.user?.name}
            disabled={!cops?.ids.some((id) => id === option.cop?._id)}
            clickable
            component={Link}
            to={`/cops/${option.cop?._id}`}
          />
        ))
      }
      renderInput={(props) => (
        <TextField
          {...props}
          sx={{
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
          }}
        />
      )}
    />
  );

  const GraduatesEditInputCell = (params) => {
    const { id, field } = params;

    const apiRef = useGridApiContext();

    const handleChange = (event, newValue) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <GraduatesAutoComplete {...params} onChange={handleChange} />;
  };

  const renderGraduatesEdit = (params) => {
    return <GraduatesEditInputCell {...params} />;
  };

  const renderGraduates = (params) => {
    return <GraduatesAutoComplete {...params} readOnly />;
  };

  const columns = [
    {
      field: "shortcut",
      type: "string",
      headerName: "Kennzeichen",
      editable: true,
      maxWidth: 100,
      flex: 1,
    },
    {
      field: "name",
      type: "string",
      headerName: "Bezeichnung",
      editable: true,
      maxWidth: 200,
      flex: 1,
    },
    {
      field: "graduates",
      headerName: "Absolviert von",
      renderCell: renderGraduates,
      renderEditCell: renderGraduatesEdit,
      editable: true,
      flex: 1,
    },
    {
      field: "requiredForRank",
      headerName: "Voraussetzung für Rang",
      renderCell: renderRank,
      renderEditCell: renderEditRank,
      editable: true,
      maxWidth: 350,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Aktionen",
      type: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Speichern"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Bearbeiten"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Löschen"
            onClick={handleDeleteClick(id)}
            color="error"
          />,
        ];
      },
    },
  ];

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        rows={getLearningModulesIsSuccess ? rows : []}
        loading={getLearningModulesIsLoading}
        editMode="row"
        disableRowSelectionOnClick
        slots={{ toolbar: EditToolbar, pagination: CustomGridPagination }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        getRowHeight={() => "auto"}
        sx={{
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
      />
    </Box>
  );
};
export default LearningModulesDataGrid;
