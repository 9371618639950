import { Rating } from "@mui/material";

const getRatingColor = (rating) =>
  ({
    1: "error.main",
    2: "error.light",
    3: "",
    4: "success.main",
    5: "success.light",
  }[rating] ?? "");

const ColoredRating = (props) => {
  return (
    <Rating
      {...props}
      sx={{
        color: getRatingColor(props.value || props.defaultValue || 0),
        ...props.sx,
      }}
    />
  );
};
export default ColoredRating;
