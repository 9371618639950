import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const faqsAdapter = createEntityAdapter();

const initialState = faqsAdapter.getInitialState();

export const faqsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFAQs: builder.query({
      query: () => ({
        url: "/faq",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedFAQs = responseData.map((faq) => {
          faq.id = faq._id;
          return faq;
        });

        return faqsAdapter.setAll(initialState, loadedFAQs);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "FAQ", id: "LIST" },
            ...result.ids.map((id) => ({ type: "FAQ", id })),
          ];
        } else return [{ type: "FAQ", id: "LIST" }];
      },
    }),
    addFAQ: builder.mutation({
      query: (faq) => ({
        url: "/faq",
        method: "POST",
        body: { ...faq },
      }),
      invalidatesTags: [{ type: "FAQ", id: "LIST" }],
    }),
    updateFAQ: builder.mutation({
      query: (faq) => ({
        url: "/faq",
        method: "PATCH",
        body: { ...faq },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "FAQ", id: arg.id }],
    }),
    deleteFAQ: builder.mutation({
      query: (id) => ({
        url: "/faq",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "FAQ", id: arg.id }],
    }),
  }),
});

export const {
  useGetFAQsQuery,
  useAddFAQMutation,
  useUpdateFAQMutation,
  useDeleteFAQMutation,
} = faqsApiSlice;

export const selectFAQsResult = faqsApiSlice.endpoints.getFAQs.select();

const selectFAQsData = createSelector(
  selectFAQsResult,
  (faqsResult) => faqsResult.data
);

export const { selectAll: selectAllFAQs, selectIds: selectFAQsIds } =
  faqsAdapter.getSelectors((state) => selectFAQsData(state) ?? initialState);
