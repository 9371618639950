import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { useGetUsersQuery } from "features/users/usersApiSlice";
import { Formik } from "formik";
import * as yup from "yup";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useGetRolesQuery } from "features/roles/rolesApiSlice";
import { ranks, status } from "util/copEnums";
import CopRankBadge from "components/CopRankBadge";
import { getCopStatusColor } from "util/muiColorGetter";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CopFunctions from "./CopFunctions";
import { useCreateCopMutation } from "./copsApiSlice";
import { useSnackbar } from "contexts/Snackbar.context";

const initialValues = {
  createNewUser: true,
  user: null,
  steamid: "",
  name: "",
  roles: [],
  number: "",
  rank: "",
  status: "",
  isInvisible: false,
  functions: [],
};

const copSchema = yup.object().shape({
  createNewUser: yup.boolean(),
  user: yup.string().when("createNewUser", {
    is: (value) => !value,
    then: (schema) => schema.required("Pflichtfeld"),
    otherwise: (schema) => schema.nullable(true),
  }),
  steamid: yup.string().when("createNewUser", {
    is: (value) => value,
    then: (schema) => schema.required("Pflichtfeld"),
    otherwise: (schema) => schema,
  }),
  name: yup.string().when("createNewUser", {
    is: (value) => value,
    then: (schema) => schema.required("Pflichtfeld"),
    otherwise: (schema) => schema,
  }),
  roles: yup.array().of(yup.string()),
  number: yup.number(),
  rank: yup.string(),
  status: yup.string(),
  isInvisible: yup.boolean(),
  functions: yup.array().of(yup.string()),
});

const AddCopDialog = ({ open, onClose }) => {
  const showSnackbar = useSnackbar();
  const { data: users, isSuccess } = useGetUsersQuery("usersList", {
    refetchOnMountOrArgChange: true,
  });

  const { data: roles, isSuccess: getRolesIsSuccess } =
    useGetRolesQuery("rolesList");

  const [createCop, { isLoading: createCopIsLoading }] = useCreateCopMutation();

  const onSubmit = async (values, { resetForm }) => {
    await createCop(values)
      .unwrap()
      .then(() => {
        showSnackbar("Polizist angelegt");
        resetForm();
      })
      .catch((error) => {
        showSnackbar(error.data?.message || "Fehler", "error");
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Polizist hinzufügen</DialogTitle>
      <DialogContent>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={copSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="createNewUser"
                        checked={values.createNewUser}
                        onChange={(_, checked) =>
                          setFieldValue("createNewUser", checked)
                        }
                      />
                    }
                    label="Neuen Benutzer anlegen"
                    sx={{
                      color: "text.secondary",
                    }}
                  />
                </Grid>
                {values.createNewUser ? (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Steam ID"
                      name="steamid"
                      value={values.steamid}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.steamid && !!errors.steamid}
                      helperText={touched.steamid && errors.steamid}
                    />
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                      sx={{ mt: 2, mb: 2 }}
                    />
                    <Autocomplete
                      id="roles"
                      fullWidth
                      disableCloseOnSelect
                      multiple
                      limitTags={5}
                      value={values.roles}
                      onChange={(_, value) => {
                        setFieldValue("roles", value);
                      }}
                      onBlur={handleBlur}
                      noOptionsText="Keine Optionen"
                      getOptionLabel={(option) =>
                        getRolesIsSuccess
                          ? roles.entities[option]?.name || ""
                          : ""
                      }
                      options={getRolesIsSuccess ? roles.ids : []}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          label="Rollen"
                          error={touched.roles && !!errors.roles}
                          helperText={touched.roles && errors.roles}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Autocomplete
                      fullWidth
                      options={isSuccess ? users.ids : []}
                      noOptionsText="Keine Benutzer"
                      id="user"
                      value={values.user}
                      onChange={(_, value) => setFieldValue("user", value)}
                      onBlur={handleBlur}
                      getOptionLabel={(option) =>
                        isSuccess ? users.entities[option]?.name || "" : ""
                      }
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          label="Benutzer"
                          error={touched.user && !!errors.user}
                          helperText={touched.user && errors.user}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    value={values.number}
                    name="number"
                    label="Dienstnummer"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.number && !!errors.number}
                    helperText={touched.number && errors.number}
                  >
                    {[...Array(120).keys()].map((number) => (
                      <MenuItem key={number + 1} value={number + 1}>
                        {number + 1}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    value={values.status}
                    label="Status"
                    name="status"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.status && !!errors.status}
                    helperText={touched.status && errors.status}
                  >
                    {status.map((status) => (
                      <MenuItem key={status} value={status}>
                        <Chip
                          label={status}
                          color={getCopStatusColor(status)}
                        />
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    value={values.rank}
                    name="rank"
                    label="Dienstgrad"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.rank && !!errors.rank}
                    helperText={touched.rank && errors.rank}
                  >
                    {Object.keys(ranks)
                      .filter((rank) => rank !== "AD")
                      .map((rank) => (
                        <MenuItem key={rank} value={rank}>
                          <CopRankBadge rank={rank} />
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <CopFunctions
                    functions={values.functions}
                    onChange={(_, value) => setFieldValue("functions", value)}
                    autocompleteProps={{
                      id: "functions",
                      onBlur: handleBlur,
                    }}
                    inputProps={{
                      error: touched.functions && !!errors.functions,
                      helperText: touched.functions && errors.functions,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="warning"
                        checked={values.isInvisible}
                        checkedIcon={<VisibilityOffIcon />}
                        onChange={(_, checked) =>
                          setFieldValue("isInvisible", checked)
                        }
                      />
                    }
                    label="Unsichtbar"
                    name="isInvisible"
                    sx={{
                      color: "text.secondary",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    startIcon={<AddOutlinedIcon />}
                    variant="contained"
                    type="submit"
                    fullWidth
                    disabled={createCopIsLoading}
                  >
                    Anlegen
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
export default AddCopDialog;
