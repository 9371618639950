import { Box } from "@mui/material";
import { ranks } from "util/copEnums";

const CopRankBadge = ({ rank, hideTitle, height = "35px" }) => {
  return (
    <Box display="flex" alignItems="center">
      <img
        alt="rank-badge"
        src={ranks[rank]?.badge}
        height={height}
        style={{
          marginRight: !hideTitle && "8px",
        }}
      />

      {!hideTitle && `${rank} - ${ranks[rank].description}`}
    </Box>
  );
};
export default CopRankBadge;
