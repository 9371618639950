import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { DateTimePicker } from "@mui/x-date-pickers";
import CustomGridPagination from "components/CustomGridPagination";
import dayjs from "dayjs";
import {
  useGetDocumentChangesQuery,
  useGetModelNamesQuery,
} from "features/documentChanges/documentChangesApiSlice";
import { memo, useCallback, useEffect, useState } from "react";
import { dataGridSx } from "styles/dataGridSx";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DocumentChangeDialog from "./DocumentChangeDialog";
import { calcGridHeightSubtraction } from "util/calculations";

const renderOperationType = ({ value }) => (
  <Chip
    label={value}
    color={
      {
        Anlage: "info",
        Änderung: "warning",
        Löschung: "error",
      }[value] ?? "default"
    }
    icon={
      {
        Anlage: <AddCircleOutlineOutlinedIcon />,
        Änderung: <EditIcon />,
        Löschung: <DeleteOutlinedIcon />,
      }[value] ?? <></>
    }
  />
);

const columns = [
  {
    field: "modelName",
    headerName: "Entität",
    flex: 1,
    maxWidth: 180,
  },
  {
    field: "user",
    headerName: "Benutzer",
    valueGetter: ({ value }) => value?.name,
    flex: 1,
    maxWidth: 100,
  },
  {
    field: "operationType",
    headerName: "Art der Operation",
    type: "singleSelect",
    valueOptions: ["Anlage", "Änderung", "Löschung"],
    renderCell: renderOperationType,
    flex: 1,
    maxWidth: 150,
  },
  {
    field: "operation",
    headerName: "DB Operation",
    flex: 1,
    maxWidth: 100,
  },
  {
    field: "createdAt",
    headerName: "Zeitpunkt",
    ...dateTimeColumnType,
    flex: 1,
    maxWidth: 120,
  },
  {
    field: "documentId",
    headerName: "Dokumenten ID",
    flex: 1,
    maxWidth: 200,
  },
  {
    field: "changedFields",
    headerName: "Geänderte Felder",
    valueGetter: ({ value }) => (value ? Object.keys(value).join(", ") : ""),
    flex: 1,
  },
];

const DocumentChangesDataGrid = () => {
  const theme = useTheme();
  const [model, setModel] = useState("");
  const [from, setFrom] = useState(dayjs().subtract(1, "week").startOf("day"));
  const [until, setUntil] = useState(dayjs());
  const [rows, setRows] = useState([]);
  const [change, setChange] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    data: changes,
    isSuccess,
    isLoading,
  } = useGetDocumentChangesQuery(
    {
      from: from?.valueOf() || undefined,
      until: until?.valueOf() || undefined,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: models,
    isSuccess: getModelNamesIsSuccess,
    isLoading: getModelNamesIsLoading,
  } = useGetModelNamesQuery(
    {
      from: from?.valueOf() || undefined,
      until: until?.valueOf() || undefined,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    document.title = `Datenbank | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setRows(
        changes.ids.reduce((filtered, id) => {
          const change = changes.entities[id];
          if (!model || change.modelName === model) {
            filtered.push(change);
          }

          return filtered;
        }, [])
      );
    }
  }, [isSuccess, changes, model]);

  const CustomGridToolbar = useCallback(
    () => (
      <GridToolbarContainer sx={{ mb: 1, p: 0 }}>
        <DateTimePicker
          label="Von"
          value={from}
          onAccept={(value) => setFrom(value)}
          slotProps={{
            actionBar: {
              actions: ["cancel", "clear", "accept"],
            },
          }}
        />
        <DateTimePicker
          label="Bis"
          value={until}
          onAccept={(value) => setUntil(value)}
          slotProps={{
            actionBar: {
              actions: ["cancel", "clear", "accept"],
            },
          }}
        />
      </GridToolbarContainer>
    ),
    [from, until]
  );

  const handleRowClick = useCallback(({ row }) => {
    setChange(row);
    setDialogOpen(true);
  }, []);

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return !getModelNamesIsLoading && getModelNamesIsSuccess ? (
    <Box display="flex" height="100%">
      <Box
        sx={{
          minWidth: "10%",
          height: "100%",
          overflow: "auto",
          backgroundColor: "background.paper",
          borderRadius: theme.shape.borderRadius / 3,
        }}
      >
        <List
          subheader={
            <>
              <ListSubheader
                sx={{
                  borderTopLeftRadius: theme.shape.borderRadius,
                  borderTopRightRadius: theme.shape.borderRadius,
                  fontSize: theme.typography.body1.fontSize,
                }}
              >
                Entitäten
              </ListSubheader>
              <Divider variant="fullWidth" />
            </>
          }
          sx={{
            backgroundColor: "background.paper",
            borderRadius: theme.shape.borderRadius / 3,
          }}
        >
          <ListItem key={"empty"} disablePadding>
            <ListItemButton
              sx={{
                color: model === "" ? "primary.main" : "text.primary",
              }}
              selected={model === ""}
              onClick={() => setModel("")}
            >
              <ListItemText primary="Alle" />
            </ListItemButton>
          </ListItem>
          {models?.modelNames?.map((modelName) => (
            <ListItem key={modelName} disablePadding>
              <ListItemButton
                sx={{
                  color: modelName === model ? "primary.main" : "text.primary",
                }}
                selected={modelName === model}
                onClick={() => setModel(modelName)}
              >
                <ListItemText primary={modelName} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box
        ml={2}
        width="100%"
        sx={{
          ...dataGridSx(theme),
          ".MuiDataGrid-row": {
            ":hover": {
              cursor: "pointer !important",
            },
          },
          ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          height: `calc(100vh - ${subtractGridHeight}px)`,
        }}
      >
        <DataGrid
          columns={columns}
          rows={isSuccess ? rows : []}
          loading={isLoading}
          rowSelection={false}
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          slots={{
            pagination: CustomGridPagination,
            toolbar: CustomGridToolbar,
          }}
        />
      </Box>
      <DocumentChangeDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        change={change}
      />
    </Box>
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <CircularProgress color="primary" size={80} />
    </Box>
  );
};
export default memo(DocumentChangesDataGrid);
