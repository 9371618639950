import { useSnackbar } from "contexts/Snackbar.context";
import dayjs from "dayjs";
import { useGetCopsQuery } from "features/cops/copsApiSlice";
import { Formik } from "formik";
import * as yup from "yup";
import { useAddControlcenterMutation } from "features/controlcenters/controlcentersApiSlice";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import CopsAutocomplete from "components/CopsAutocomplete";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { serverPeriods } from "util/copEnums";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useAddRequestedRatingMutation } from "features/requestedRatings/requestedRatingsApiSlice";
import { memo } from "react";

yup.addMethod(yup.object, "dayjs", function method(message) {
  return this.test("dayjs", message, function validate(value) {
    if (!value) {
      return true;
    }

    if (dayjs.isDayjs(value) && !!value.isValid) return value.isValid();

    return false;
  });
});

const ControlcenterForm = ({ initialValues, postSubmit }) => {
  const showSnackbar = useSnackbar();

  const { data: cops } = useGetCopsQuery("copsList", {
    refetchOnMountOrArgChange: true,
  });

  const [addControlcenter, { isLoading: addControlcenterIsLoading }] =
    useAddControlcenterMutation();
  const [addRequestedRating, { isLoading: addRequestedRatingIsLoading }] =
    useAddRequestedRatingMutation();

  const controlcenterSchema = yup.object().shape({
    involvedCops: yup.array().min(1, "Pflichtfeld").of(yup.string()),
    date: yup
      .object()
      .nullable()
      .required("Pflichtfeld")
      .dayjs("Ungültiges Datum"),
    period: yup.string().required("Pflichtfeld"),
    from: yup
      .object()
      .nullable()
      .required("Pflichtfeld")
      .dayjs("Ungültiges Datum"),
    until: yup
      .object()
      .nullable()
      .required("Pflichtfeld")
      .dayjs("Ungültiges Datum"),
    comment: yup.string(),
  });

  const onSubmit = async (values, { resetForm }) => {
    await addControlcenter(values)
      .unwrap()
      .then(async () => {
        showSnackbar("Leitstelle eingetragen");

        if (values.requestRatings) {
          await addRequestedRating({
            requestedFrom: values.involvedCops || [],
            forFunction: "Leitstelle",
            date: values.date,
            period: values.period,
            reason: `Leitstelle ${dayjs(values.date).format("DD.MM.YYYY")}, ${
              values.period
            }`,
          });
        }

        resetForm();
        if (postSubmit) postSubmit();
      })
      .catch((error) =>
        showSnackbar(error?.data?.message || "Fehler", "error")
      );
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={controlcenterSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={2} columns={2}>
            <Grid item xs={2}>
              <CopsAutocomplete
                cops={cops}
                autocompleteProps={{
                  id: "involvedCops",
                  limitTags: 5,
                  value: values.involvedCops,
                  onChange: (_, value) => setFieldValue("involvedCops", value),
                  onBlur: handleBlur,
                }}
                inputProps={{
                  error: touched.involvedCops && !!errors.involvedCops,
                  helperText: touched.involvedCops && errors.involvedCops,
                  label: "Beteiligte Polizisten",
                }}
              />
            </Grid>
            <Grid item xs={2} sm={1}>
              <DatePicker
                value={dayjs(values.date)}
                name="date"
                label="Datum"
                onChange={(value) => setFieldValue("date", value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    onBlur: handleBlur,
                    error: !!touched.date && !!errors.date,
                    helperText: touched.date && errors.date,
                  },
                }}
              />
            </Grid>
            <Grid item xs={2} sm={1}>
              <TextField
                select
                fullWidth
                label="Server Periode"
                name="period"
                value={values.period}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.period && !!errors.period}
                helperText={touched.period && errors.period}
              >
                {serverPeriods.map((period) => (
                  <MenuItem key={period} value={period}>
                    {period}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2} sm={1}>
              <TimePicker
                value={dayjs(values.from)}
                name="from"
                label="Von"
                onChange={(value) => setFieldValue("from", value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    onBlur: handleBlur,
                    error: !!touched.from && !!errors.from,
                    helperText: touched.from && errors.from,
                  },
                }}
              />
            </Grid>
            <Grid item xs={2} sm={1}>
              <TimePicker
                value={dayjs(values.until)}
                name="until"
                label="Bis"
                onChange={(value) => setFieldValue("until", value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    onBlur: handleBlur,
                    error: !!touched.until && !!errors.until,
                    helperText: touched.until && errors.until,
                  },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                multiline
                label="Anmerkung"
                name="comment"
                value={values.comment}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.comment && !!errors.comment}
                helperText={touched.comment && errors.comment}
                inputProps={{
                  maxLength: 500,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.requestRatings}
                    name="requestRatings"
                    onChange={handleChange}
                  />
                }
                label="Bewertungen anfordern"
                sx={{
                  color: "text.secondary",
                }}
              />
              <Button
                fullWidth
                endIcon={<SendOutlinedIcon />}
                variant="contained"
                type="submit"
                disabled={
                  addControlcenterIsLoading || addRequestedRatingIsLoading
                }
              >
                Absenden
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};
export default memo(ControlcenterForm);
