import { Box, Grid } from "@mui/material";
import Header from "components/Header";
import RConBanList from "features/rcon/RConBanList";
import RConFunctions from "features/rcon/RConFunctions";
import RConLog from "features/rcon/RConLog";
import RConPlayerList from "features/rcon/RConPlayerList";
import { useEffect } from "react";

const RCon = () => {
  useEffect(() => {
    document.title = `RCon | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box>
      <Header title="RCON" subtitle="Einsatztrainings Server" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RConLog />
        </Grid>
        <Grid item xs={12}>
          <RConFunctions />
        </Grid>
        <Grid item xs={12} xl={6}>
          <RConPlayerList />
        </Grid>
        <Grid item xs={12} xl={6}>
          <RConBanList />
        </Grid>
      </Grid>
    </Box>
  );
};
export default RCon;
