import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    user: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken, user } = action.payload;
      state.token = accessToken;
      state.user = user;
    },
    logout: (state, action) => {
      state.token = null;
      state.user = null;
    },
    setName: (state, action) => {
      state.user.name = action.payload;
    },
    setDiscord: (state, action) => {
      state.user.discord = action.payload;
    },
    setNotificationSettings: (state, action) => {
      state.user.settings.notifications = action.payload;
    },
  },
});

export const {
  setCredentials,
  logout,
  setName,
  setDiscord,
  setNotificationSettings,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.token;

export const selectCurrentUser = (state) => state.auth.user;
