import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
} from "@mui/material";
import { LinkBubbleMenu, RichTextEditor, TableBubbleMenu } from "mui-tiptap";
import { memo, useEffect, useRef, useState } from "react";
import useExtensions from "hooks/useExtensions";
import EditorMenuControls from "components/EditorMenuControls";
import SaveIcon from "@mui/icons-material/Save";
import { useAddFAQMutation, useUpdateFAQMutation } from "./faqApiSlice";
import { useSnackbar } from "contexts/Snackbar.context";
import { useDeleteSubmittedQuestionMutation } from "./questionsApiSlice";

const FAQForm = ({ open, onClose, initialValues, questionId }) => {
  const showSnackbar = useSnackbar();
  const { id } = initialValues;
  const [title, setTitle] = useState(initialValues.title);
  const [errors, setErrors] = useState({});
  const contentRef = useRef(null);
  const extensions = useExtensions({ placeholder: "" });

  const [addFAQ] = useAddFAQMutation();
  const [updateFAQ] = useUpdateFAQMutation();
  const [deleteSubmittedQuestion] = useDeleteSubmittedQuestionMutation();

  useEffect(() => {
    setTitle(initialValues.title);
  }, [initialValues]);

  const handleSave = async () => {
    if (!title) {
      setErrors({ title: "Pflichtfeld" });
      return;
    }

    if (!!id) {
      await updateFAQ({
        id,
        title,
        content: contentRef.current?.editor?.getJSON(),
      })
        .unwrap()
        .then(() => {
          showSnackbar("FAQ geändert");
          setTitle("");

          onClose();
        })
        .catch((error) => {
          showSnackbar(error.data?.message || "Fehler", "error");
        });
    } else {
      await addFAQ({
        title,
        content: contentRef.current?.editor?.getJSON(),
      })
        .unwrap()
        .then(() => {
          showSnackbar("FAQ hinzugefügt");

          if (questionId) {
            deleteSubmittedQuestion(questionId);
          }

          onClose();
        })
        .catch((error) => {
          showSnackbar(error.data?.message || "Fehler", "error");
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        sx: {
          overflow: "visible",
        },
      }}
    >
      <DialogTitle>{!!id ? "FAQ bearbeiten" : "FAQ hinzufügen"}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: "8px" }}>
          <TextField
            fullWidth
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            label="Frage"
            error={!title && !!errors.title}
            helperText={!title && errors.title}
          />
          <Box
            mt={2}
            maxHeight="800px"
            sx={{
              ".MuiTiptap-MenuBar-root": {
                backgroundColor: "transparent",
              },
            }}
          >
            <InputLabel>Antwort</InputLabel>
            <RichTextEditor
              ref={contentRef}
              extensions={extensions}
              content={initialValues.content}
              renderControls={() => <EditorMenuControls />}
            >
              {() => (
                <>
                  <LinkBubbleMenu
                    disablePortal
                    labels={{
                      viewLinkEditButtonLabel: "Link bearbeiten",
                      viewLinkRemoveButtonLabel: "Link entfernen",
                      editLinkAddTitle: "Link hinzufügen",
                      editLinkEditTitle: "Link ändern",
                      editLinkCancelButtonLabel: "Abbrechen",
                      editLinkTextInputLabel: "Text",
                      editLinkHrefInputLabel: "URL",
                      editLinkSaveButtonLabel: "Speichern",
                    }}
                  />
                  <TableBubbleMenu
                    disablePortal
                    labels={{
                      insertColumnBefore: "Spalte davor einfügen",
                      insertColumnAfter: "Spalte danach einfügen",
                      deleteColumn: "Spalte löschen",
                      deleteRow: "Zeile löschen",
                      deleteTable: "Tabelle löschen",
                      insertRowAbove: "Zeile darüber einfügen",
                      insertRowBelow: "Zeile darunter einfügen",
                      mergeCells: "Zellen zusammenfügen",
                      splitCell: "Zellen aufteilen",
                      toggleHeaderCell: "Kopfzelle umschalten",
                      toggleHeaderColumn: "Kopfspalte umschalten",
                      toggleHeaderRow: "Kopfzeile umschalten",
                    }}
                  />
                </>
              )}
            </RichTextEditor>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ mr: "16px" }}>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSave}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default memo(FAQForm);
