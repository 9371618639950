import { useSnackbar } from "contexts/Snackbar.context";
import dayjs from "dayjs";
import { Formik } from "formik";
import * as yup from "yup";
import { useAddVacationMutation } from "./vacactionsApiSlice";
import { Button, Grid, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

yup.addMethod(yup.object, "dayjs", function method(message) {
  return this.test("dayjs", message, function validate(value) {
    if (!value) {
      return true;
    }

    if (dayjs.isDayjs(value) && !!value.isValid) return value.isValid();

    return false;
  });
});

const VactationForm = ({ initialValues, postSubmit }) => {
  const showSnackbar = useSnackbar();

  const [addVacation, { isLoading: addVacationIsLoading }] =
    useAddVacationMutation();

  const vacationSchema = yup.object().shape({
    from: yup
      .object()
      .nullable()
      .required("Pflichtfeld")
      .dayjs("Ungültiges Datum"),
    until: yup
      .object()
      .nullable()
      .required("Pflichtfeld")
      .dayjs("Ungültiges Datum"),
    reason: yup.string().required("Pflichtfeld"),
  });

  const onSubmit = async (values, { resetForm }) => {
    await addVacation(values)
      .unwrap()
      .then(async () => {
        showSnackbar("Urlaubsantrag eingereicht");

        resetForm();
        if (postSubmit) postSubmit();
      })
      .catch((error) =>
        showSnackbar(error?.data?.message || "Fehler", "error")
      );
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={vacationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={2} columns={2}>
            <Grid item xs={2} sm={1}>
              <DateTimePicker
                value={dayjs(values.from)}
                name="from"
                label="Von"
                onChange={(value) => setFieldValue("from", value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    onBlur: handleBlur,
                    error: !!touched.from && !!errors.from,
                    helperText: touched.from && errors.from,
                  },
                }}
              />
            </Grid>
            <Grid item xs={2} sm={1}>
              <DateTimePicker
                value={dayjs(values.until)}
                name="until"
                label="Bis"
                onChange={(value) => setFieldValue("until", value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    onBlur: handleBlur,
                    error: !!touched.until && !!errors.until,
                    helperText: touched.until && errors.until,
                  },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                multiline
                label="Grund"
                name="reason"
                value={values.reason}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.reason && !!errors.reason}
                helperText={touched.reason && errors.reason}
                inputProps={{
                  maxLength: 500,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                endIcon={<SendOutlinedIcon />}
                variant="contained"
                type="submit"
                disabled={addVacationIsLoading}
              >
                Absenden
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};
export default VactationForm;
