import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useCallback, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { DateTimeField } from "@mui/x-date-pickers";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSetCopRatioViolationDoneMutation } from "./copRatioViolationsApiSlice";
import { useSnackbar } from "contexts/Snackbar.context";

dayjs.extend(duration);

const CopRatioViolationDoneDialog = ({ open, onClose, violation }) => {
  const showSnackbar = useSnackbar();

  const [approved, setApproved] = useState(false);
  const [comment, setComment] = useState("");

  const [setViolationDone, { isLoading }] =
    useSetCopRatioViolationDoneMutation();

  const handleSetDone = useCallback(async () => {
    setViolationDone({ id: violation.id, approved, comment })
      .unwrap()
      .then(() => {
        setApproved(false);
        setComment("");
        onClose();
        showSnackbar("Vertoß erledigt");
      })
      .catch((error) => {
        showSnackbar(error.data?.message || "Fehler", "error");
      });
  }, [
    violation.id,
    approved,
    comment,
    setViolationDone,
    showSnackbar,
    onClose,
  ]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Vertstoß erledigen</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={0}>
          <Grid item xs={12} lg={3}>
            <TextField
              fullWidth
              value={violation.cop?.user?.name}
              label="Name"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <DateTimeField
              fullWidth
              value={dayjs(violation.createdAt)}
              readOnly
              label="Bemerkt am"
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              fullWidth
              value={dayjs
                .duration(
                  dayjs(violation.playtimeEnd).diff(
                    dayjs(violation.playtimeStart)
                  )
                )
                .format("HH[h] mm[m]")}
              label="Dauer der Spielzeit"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              fullWidth
              value={dayjs
                .duration(violation.violationDuration)
                .format("HH[h] mm[m]")}
              label="Dauer des Verstoßes"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={approved}
                  onChange={(_, checked) => setApproved(checked)}
                  color="success"
                  checkedIcon={<CheckCircleIcon />}
                  icon={<CancelIcon />}
                />
              }
              label={`Verstoß ist ${approved ? "" : "nicht"} genehmigt`}
              sx={{
                userSelect: "none",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              label="Kommentar"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          color="success"
          variant="contained"
          startIcon={
            isLoading ? (
              <CircularProgress size="1em" color="inherit" />
            ) : (
              <DoneIcon />
            )
          }
          onClick={handleSetDone}
          disabled={isLoading}
        >
          Erledigen
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CopRatioViolationDoneDialog;
