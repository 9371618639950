import { Box } from "@mui/material";
import CustomCard from "components/CustomCard";
import Header from "components/Header";
import dayjs from "dayjs";
import ControlcenterForm from "features/controlcenters/ControlcenterForm";
import { useEffect } from "react";

const AddControlcenter = () => {
  useEffect(() => {
    document.title = `Leitstelle eintragen | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box>
      <Header
        title="LEITSTELLE EINTRAGEN"
        subtitle="Absolvierte Leitstelle eintragen"
      />
      <Box maxWidth="700px">
        <CustomCard>
          <ControlcenterForm
            initialValues={{
              involvedCops: [],
              date: dayjs(),
              from: null,
              until: null,
              period: "",
              comment: "",
              requestRatings: true,
            }}
          />
        </CustomCard>
      </Box>
    </Box>
  );
};
export default AddControlcenter;
