import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const learningModulesAdapter = createEntityAdapter();

const initialState = learningModulesAdapter.getInitialState();

export const learningModulesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLearningModules: builder.query({
      query: () => ({
        url: "/learning-modules",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedLearningModules = responseData.map((learningModule) => {
          learningModule.id = learningModule._id;
          return learningModule;
        });

        return learningModulesAdapter.setAll(
          initialState,
          loadedLearningModules
        );
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "LearningModule", id: "LIST" },
            ...result.ids.map((id) => ({ type: "LearningModule", id })),
          ];
        } else return [{ type: "LearningModule", id: "LIST" }];
      },
    }),
    addLearningModule: builder.mutation({
      query: (learningModule) => ({
        url: "/learning-modules",
        method: "POST",
        body: { ...learningModule },
      }),
      invalidatesTags: [{ type: "LearningModule", id: "LIST" }],
    }),
    updateLearningModule: builder.mutation({
      query: (learningModule) => ({
        url: "/learning-modules",
        method: "PATCH",
        body: { ...learningModule },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "LearningModule", id: arg.id },
      ],
    }),
    deleteLearningModule: builder.mutation({
      query: (id) => ({
        url: "/learning-modules",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "LearningModule", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetLearningModulesQuery,
  useAddLearningModuleMutation,
  useUpdateLearningModuleMutation,
  useDeleteLearningModuleMutation,
} = learningModulesApiSlice;

export const selectLearningModulesResult =
  learningModulesApiSlice.endpoints.getLearningModules.select();

const selectLearningModulesData = createSelector(
  selectLearningModulesResult,
  (learningModulesResult) => learningModulesResult.data
);

export const {
  selectAll: selectAllLearningModules,
  selectIds: selectLearningModulesIds,
} = learningModulesAdapter.getSelectors(
  (state) => selectLearningModulesData(state) ?? initialState
);
