import { Badge, styled } from "@mui/material";

const TextBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: 10,
    top: 15,
  },
}));

export default TextBadge;
