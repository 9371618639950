import { Box } from "@mui/material";
import CustomCard from "components/CustomCard";
import Header from "components/Header";
import VacactionForm from "features/vacations/VacactionForm";
import { useEffect } from "react";

const RequestVacation = () => {
  useEffect(() => {
    document.title = `Urlaub beantragen | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box>
      <Header
        title="URLAUB BEANTRAGEN"
        subtitle="Antrag auf Befreiung von der Aktivitätspflicht"
      />
      <Box maxWidth="700px">
        <CustomCard>
          <VacactionForm
            initialValues={{
              from: null,
              until: null,
              reason: "",
            }}
          />
        </CustomCard>
      </Box>
    </Box>
  );
};
export default RequestVacation;
