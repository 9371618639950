import { Box, CircularProgress, useTheme } from "@mui/material";
import { ranks } from "util/copEnums";
import { useGetCopsQuery } from "features/cops/copsApiSlice";
import { useEffect, useState } from "react";
import PieChartBase from "./PieChartBase";

const CopsPieChart = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [colors, setColors] = useState([]);

  const { data: cops, isSuccess, isLoading } = useGetCopsQuery("copsList", {});

  useEffect(() => {
    if (isSuccess) {
      const numberHigherRanks = cops.ids
        .map((id) => cops.entities[id])
        .filter(
          (cop) =>
            cop.status !== "Gekündigt" && ranks[cop.rank].group === "higher"
        ).length;

      const numberUpperRanks = cops.ids
        .map((id) => cops.entities[id])
        .filter(
          (cop) =>
            cop.status !== "Gekündigt" && ranks[cop.rank].group === "upper"
        ).length;

      const numberMiddleRanks = cops.ids
        .map((id) => cops.entities[id])
        .filter(
          (cop) =>
            cop.status !== "Gekündigt" && ranks[cop.rank].group === "middle"
        ).length;

      const numberRecruits = cops.ids
        .map((id) => cops.entities[id])
        .filter(
          (cop) =>
            cop.status !== "Gekündigt" && ranks[cop.rank].group === "recruit"
        ).length;

      const data = [];
      const colors = [];

      if (numberHigherRanks > 0) {
        data.push({
          id: "Höherer Dienst",
          value: numberHigherRanks,
          color: theme.palette.higherRank.main,
        });

        colors.push(theme.palette.higherRank.main);
      }

      if (numberUpperRanks > 0) {
        data.push({
          id: "Gehobener Dienst",
          value: numberUpperRanks,
          color: theme.palette.upperRank.main,
        });

        colors.push(theme.palette.upperRank.main);
      }

      if (numberMiddleRanks > 0) {
        data.push({
          id: "Mittlerer Dienst",
          value: numberMiddleRanks,
          color: theme.palette.middleRank.main,
        });

        colors.push(theme.palette.middleRank.main);
      }

      if (numberRecruits > 0) {
        data.push({
          id: "Anwärter",
          value: numberRecruits,
          color: theme.palette.recruit.main,
        });

        colors.push(theme.palette.recruit.main);
      }

      setData(data);
      setColors(colors);
    }
    // eslint-disable-next-line
  }, [cops, isSuccess]);

  if (isLoading)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="90%"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box
      display="flex"
      height="100%"
      sx={{
        backgroundImage: "url(/assets/img/polizei.png)",
        backgroundSize: "120px",
        backgroundPositionX: "center",
        backgroundPositionY: "112px",
        backgroundRepeat: "no-repeat",
      }}
    >
      <PieChartBase data={data} colors={colors} />
    </Box>
  );
};
export default CopsPieChart;
