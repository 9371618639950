import { Box } from "@mui/material";
import Header from "components/Header";
import VacationDataGrid from "features/vacations/VacationDataGrid";
import { useEffect } from "react";

const Vacations = () => {
  useEffect(() => {
    document.title = `Urlaubsanträge | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header
        title="URLAUBSANTRÄGE"
        subtitle="Anträge auf Befreiung von der Aktivitätspflicht"
      />
      <VacationDataGrid />
    </Box>
  );
};

export default Vacations;
