import { Avatar, Box, Chip, Collapse, IconButton } from "@mui/material";
import { useState } from "react";
import { faSteam, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MadeByBadge = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box
      display="flex"
      alignItems="center"
      mt={1}
      sx={{
        opacity: 0.5,
      }}
    >
      <Chip
        avatar={
          <Avatar
            src="https://avatars.steamstatic.com/706bb386323b8a3ade28a118b5f3a68bb2a3cda2_medium.jpg"
            alt="orbit-avatar"
          />
        }
        label="Made by ORBIT · Jim Gordon"
        onClick={() => setExpanded((oldValue) => !oldValue)}
        clickable
        size="small"
      />

      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit={false}
        orientation="horizontal"
      >
        <Box display="flex" ml={0.5} alignItems="center">
          <IconButton
            size="small"
            disableRipple
            disableTouchRipple
            href="https://forum.panthor.de/user/31-jim-gordon/"
            target="_blank"
            sx={{
              fontSize: 20,
              color: "text.primary",
              "&.MuiButtonBase-root:hover": {
                color: "primary.main",
              },
            }}
          >
            <FontAwesomeIcon icon={faGlobe} />
          </IconButton>

          <IconButton
            size="small"
            disableRipple
            disableTouchRipple
            href="https://steamcommunity.com/id/orbitv1/"
            target="_blank"
            sx={{
              fontSize: 20,
              color: "text.primary",
              "&.MuiButtonBase-root:hover": {
                color: "primary.main",
              },
            }}
          >
            <FontAwesomeIcon icon={faSteam} />
          </IconButton>

          <IconButton
            size="small"
            disableRipple
            disableTouchRipple
            href="https://github.com/ORBITXX/"
            target="_blank"
            sx={{
              fontSize: 20,
              color: "text.primary",
              "&.MuiButtonBase-root:hover": {
                color: "primary.main",
              },
            }}
          >
            <FontAwesomeIcon icon={faGithub} />
          </IconButton>
        </Box>
      </Collapse>
    </Box>
  );
};
export default MadeByBadge;
