import { useParams } from "react-router-dom";
import { useGetApplicationsQuery } from "./applicationsApiSlice";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "components/Header";
import dayjs from "dayjs";
import CustomCard from "components/CustomCard";
import ApplicationFileView from "./ApplicationFileView";
import ApplicationCommunication from "./ApplicationCommunication";
import ApplicationActions from "./ApplicationActions";
import RequirePermissionForRender from "features/auth/RequirePermissionForRender";
import ApplicationHeader from "./ApplicationHeader";
import { useEffect } from "react";

const Application = () => {
  const { id } = useParams();
  const theme = useTheme();

  const { application } = useGetApplicationsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      application: data?.entities[id],
    }),
  });

  useEffect(() => {
    if (application)
      document.title = `Bewerbung ${application.user?.name} | Polizei ${process.env.REACT_APP_MAP}`;
  }, [application]);

  if (!application)
    return (
      <Box m="16px">
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      <Header
        title={`BEWERBUNG VON ${application.user?.name?.toUpperCase()}`}
        subtitle={`Vom ${dayjs(application.createdAt).format(
          "DD.MM.YYYY HH:mm"
        )}`}
      />

      <Grid container columns={2} spacing={theme.spacing(2)}>
        <Grid item xs={2}>
          <ApplicationHeader
            application={application}
            user={application.user}
          />
        </Grid>
        <Grid item xs={2}>
          <CustomCard title="ANMERKUNG">
            <Typography fontStyle="italic">{application.comment}</Typography>
          </CustomCard>
        </Grid>
        <Grid item xs={2}>
          <RequirePermissionForRender permissions={["editApplications"]}>
            <ApplicationActions application={application} />
          </RequirePermissionForRender>
        </Grid>
        <Grid item xs={2} xl={1}>
          <ApplicationCommunication application={application} />
        </Grid>
        <Grid item xs={2} xl={1}>
          <CustomCard title="BEWERBUNG" expandable>
            <ApplicationFileView id={application.id} />
          </CustomCard>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Application;
