import { Box, Button, Chip, useTheme } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "contexts/Snackbar.context";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { dataGridSx } from "styles/dataGridSx";
import { useGetCopsQuery } from "features/cops/copsApiSlice";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import CustomGridPagination from "components/CustomGridPagination";
import {
  useAddInfoMutation,
  useDeleteInfoMutation,
  useGetInfosQuery,
  useUpdateInfoMutation,
} from "../infos/infosApiSlice";
import CopsAutocomplete from "components/CopsAutocomplete";
import Textarea from "components/Textarea";
import { Link } from "react-router-dom";

const CopInfoDataGrid = ({ copId, infoType, canEditCop, rankFilter = [] }) => {
  const theme = useTheme();

  const { data: cops, isSuccess: getCopsIsSuccess } = useGetCopsQuery(
    "copsList",
    {
      refetchOnMountOrArgChange: false,
    }
  );

  const {
    data: infos,
    isSuccess: getInfosIsSuccess,
    isLoading: getInfosIsLoading,
  } = useGetInfosQuery("infosList", {
    refetchOnMountOrArgChange: false,
  });

  const [addInfo] = useAddInfoMutation();
  const [updateInfo] = useUpdateInfoMutation();
  const [deleteInfo] = useDeleteInfoMutation();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const showSnackbar = useSnackbar();

  useEffect(() => {
    if (getInfosIsSuccess) {
      setRows(
        infos.ids.reduce((filtered, id) => {
          const info = infos.entities[id];

          if (
            info.type === infoType &&
            info.affectedCops.some((cop) => cop._id === copId)
          ) {
            const addedInfo = {
              id,
              affectedCops:
                rankFilter.length > 0
                  ? info.affectedCops
                      .filter((cop) => rankFilter.includes(cop.rank))
                      .map((cop) => cop._id)
                  : info.affectedCops.map((cop) => cop._id),
              type: info.type,
              text: info.text,
              updatedAt: info.updatedAt,
              updatedBy: info.updatedBy,
              createdAt: info.createdAt,
              createdBy: info.createdBy,
            };

            if (addedInfo.affectedCops?.length > 0) filtered.push(addedInfo);
          }

          return filtered;
        }, [])
      );
    }
    // eslint-disable-next-line
  }, [infos, getInfosIsSuccess]);

  const EditToolbar = useCallback(() => {
    const handleAdd = async () => {
      const id = `$${Date.now()}`;
      setRows((oldRows) => [
        ...oldRows,
        {
          id,
          affectedCops: [copId],
          type: infoType,
          text: "",
          updatedAt: null,
          updatedBy: "",
          createdAt: null,
          createdBy: "",
          isNew: true,
        },
      ]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "text" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          variant="text"
          onClick={handleAdd}
          startIcon={<AddOutlinedIcon />}
        >
          {infoType === "note" ? "Notiz" : "Sanktion"} hinzufügen
        </Button>
      </GridToolbarContainer>
    );
  }, [infoType, copId]);

  const handleRowModesModelChange = useCallback((newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  }, []);

  const handleRowEditStop = useCallback((params, event) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.enterKeyDown
    ) {
      event.defaultMuiPrevented = true;
    }
  }, []);

  const handleEditClick = useCallback(
    (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const showConfirmDialog = useConfirmDialog();

  const handleDelete = useCallback(async (id) => {
    try {
      await deleteInfo(id).unwrap();
      showSnackbar(`${infoType === "note" ? "Notiz" : "Sanktion"} gelöscht`);
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = useCallback(
    (id) => () => {
      showConfirmDialog({
        title: `${infoType === "note" ? "Notiz" : "Sanktion"} löschen?`,
        message: `Soll die ${
          infoType === "note" ? "Notiz" : "Sanktion"
        } gelöscht werden`,
        onConfirm: () => handleDelete(id),
      });
    },
    // eslint-disable-next-line
    [handleDelete, infoType]
  );

  const handleCancelClick = useCallback(
    (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    },
    [rowModesModel, rows]
  );

  const processRowUpdate = useCallback(
    async (newRow) => {
      if (newRow.isNew) {
        await addInfo(newRow).unwrap();
        newRow.isNew = false;
        showSnackbar(`${infoType === "note" ? "Notiz" : "Sanktion"} angelegt`);
      } else {
        await updateInfo(newRow).unwrap();
        showSnackbar(
          `${infoType === "note" ? "Notiz" : "Sanktion"} gespeichert`
        );
      }

      return newRow;
    },
    // eslint-disable-next-line
    [infoType]
  );

  const handleProcessRowUpdateError = useCallback((error) => {
    showSnackbar(error.data?.message || "Fehler", "error");
    // eslint-disable-next-line
  }, []);

  const renderCops = useCallback(
    (params) =>
      getCopsIsSuccess ? (
        <CopsAutocomplete
          cops={cops}
          filterCop={
            rankFilter.length > 0 && ((cop) => rankFilter.includes(cop.rank))
          }
          autocompleteProps={{
            readOnly: true,
            forcePopupIcon: false,
            value: params.value,
            limitTags: 3,
            renderTags: (value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  label={cops.entities[option].user?.name}
                  clickable
                  component={Link}
                  to={`/cops/${cops.entities[option]._id}`}
                />
              )),
          }}
          inputProps={{
            sx: {
              ".MuiOutlinedInput-notchedOutline": { border: "none" },
            },
          }}
        />
      ) : (
        <></>
      ),
    [cops, getCopsIsSuccess, rankFilter]
  );

  const columns = useMemo(() => {
    const columns = [
      {
        field: "affectedCops",
        headerName: "Betroffene Polizisten",
        renderCell: renderCops,
        flex: 1,
      },
      {
        field: "text",
        headerName: "Sachverhalt",
        renderEditCell: (params) => <Textarea {...params} />,
        editable: canEditCop,
        flex: 2,
      },
      {
        field: "updatedAt",
        headerName: "Geändert am",
        ...dateTimeColumnType,
        flex: 1,
        maxWidth: 120,
      },
      {
        field: "updatedBy",
        headerName: "Geändert von",
        valueGetter: ({ value }) => value?.name,
        flex: 1,
        maxWidth: 200,
      },
      {
        field: "createdAt",
        headerName: "Erstellt am",
        ...dateTimeColumnType,
        flex: 1,
        maxWidth: 120,
      },
      {
        field: "createdBy",
        headerName: "Erstellt von",
        valueGetter: ({ value }) => value?.name,
        flex: 1,
        maxWidth: 200,
      },
    ];

    if (canEditCop) {
      columns.push({
        field: "actions",
        headerName: "Aktionen",
        type: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Speichern"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Bearbeiten"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Löschen"
              onClick={handleDeleteClick(id)}
              color="error"
            />,
          ];
        },
      });
    }

    return columns;
  }, [
    canEditCop,
    handleCancelClick,
    handleDeleteClick,
    handleEditClick,
    handleSaveClick,
    renderCops,
    rowModesModel,
  ]);

  return (
    <Box height="100%" sx={dataGridSx(theme)}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        columns={columns}
        rows={getInfosIsSuccess ? rows : []}
        loading={getInfosIsLoading}
        editMode="row"
        disableRowSelectionOnClick
        slots={{
          toolbar: canEditCop && EditToolbar,
          pagination: CustomGridPagination,
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        getRowHeight={() => "auto"}
        autoPageSize
        sx={{
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
      />
    </Box>
  );
};
export default memo(CopInfoDataGrid);
