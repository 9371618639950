export const dataGridSx = (theme) => ({
  "& .MuiDataGrid-root": {
    border: "none",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "none",
  },

  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "primary.main",
    borderBottom: "none",
    color: "primary.contrastText",
    "& .MuiButtonBase-root": {
      color: "primary.contrastText",
    },
  },
  "& .MuiDataGrid-virtualScroller": {
    backgroundColor: "background.paper",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "none",
    backgroundColor: "primary.main",
    color: "primary.contrastText",
    "& .MuiTablePagination-root": {
      color: "primary.contrastText",
      "& .MuiSvgIcon-root": {
        color: "primary.contrastText",
      },
    },
    "& .MuiButtonBase-root": {
      color: "primary.contrastText",
    },
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
});
