import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { Formik } from "formik";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import * as yup from "yup";
import dayjs from "dayjs";
import { useGetCopsQuery } from "features/cops/copsApiSlice";
import { DateTimePicker } from "@mui/x-date-pickers";
import CopFunctions from "features/cops/CopFunctions";
import CopsAutocomplete from "components/CopsAutocomplete";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  useAddPollMutation,
  useAddVoteMutation,
  useDeletePollMutation,
  useUpdatePollMutation,
  useUpdateVoteMutation,
} from "./pollsApiSlice";
import { useSnackbar } from "contexts/Snackbar.context";
import HowToVoteOutlinedIcon from "@mui/icons-material/HowToVoteOutlined";
import { hasPermission } from "util/permissionHelper";
import useAuth from "hooks/useAuth";
import PollPieChart from "./PollPieChart";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PollVoteDataGrid from "./PollVoteDataGrid";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";

const hasPermissionForFunction = ({ affectedFunction, roles }) => {
  switch (affectedFunction) {
    case "Zweitfraktion":
      return hasPermission({
        roles,
        permissions: ["postPollSecondaryFaction", "postPollAll"],
      });

    case "Verkehrsüberwachung":
      return hasPermission({
        roles,
        permissions: ["postPollVerkÜ", "postPollAll"],
      });

    case "Zoll":
      return hasPermission({
        roles,
        permissions: ["postPollZoll", "postPollAll"],
      });

    case "Kriminalpolizei":
      return hasPermission({
        roles,
        permissions: ["postPollKripo", "postPollAll"],
      });

    case "Einsatzkommando":
      return hasPermission({
        roles,
        permissions: ["postPollSEK", "postPollAll"],
      });

    case "Rekrutierer":
      return hasPermission({
        roles,
        permissions: ["postPollRekrutierer", "postPollAll"],
      });

    case "Ausbilder":
      return hasPermission({
        roles,
        permissions: ["postPollAusbilder", "postPollAll"],
      });

    case "Fortbilder":
      return hasPermission({
        roles,
        permissions: ["postPollFortbilder", "postPollAll"],
      });

    case "Personalrat":
      return hasPermission({
        roles,
        permissions: ["postPollPersonalrat", "postPollAll"],
      });

    case "Polizeiführung":
      return hasPermission({
        roles,
        permissions: ["postPollFührung", "postPollAll"],
      });

    case "Polizeileitung":
      return hasPermission({
        roles,
        permissions: ["postPollLeitung", "postPollAll"],
      });

    default:
      return false;
  }
};

const hasPermissionForFunctions = ({ affectedFunctions, roles }) => {
  if (affectedFunctions?.length > 0) {
    return affectedFunctions?.every((affectedFunction) => {
      return hasPermissionForFunction({ affectedFunction, roles });
    });
  } else {
    return hasPermission({
      roles,
      permissions: ["postPollAll"],
    });
  }
};

yup.addMethod(yup.object, "dayjs", function method(message) {
  return this.test("dayjs", message, function validate(value) {
    if (!value) {
      return true;
    }

    if (dayjs.isDayjs(value) && !!value.isValid) return value.isValid();

    return false;
  });
});

const RotatedExpandIcon = styled((props) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(360deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const PollDialog = ({ open, onClose, initialValues }) => {
  const { id } = initialValues;
  const [readOnly, setReadOnly] = useState(false);
  const [seeVotes, setSeeVotes] = useState(false);
  const { roles, user } = useAuth();
  const theme = useTheme();

  const { data: cops } = useGetCopsQuery("copsList", {
    refetchOnMountOrArgChange: false,
  });

  const showSnackbar = useSnackbar();
  const [addPoll] = useAddPollMutation();
  const [updatePoll] = useUpdatePollMutation();
  const [deletePoll] = useDeletePollMutation();
  const [addVote, { isLoading: addVoteIsLoading }] = useAddVoteMutation();
  const [updateVote, { isLoading: updateVoteIsLoading }] =
    useUpdateVoteMutation();

  const [pollIsActive, setPollIsActive] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!!id) {
      setReadOnly(
        !hasPermissionForFunctions({
          affectedFunctions: initialValues.affectedFunctions,
          roles,
        })
      );
    }

    setSeeVotes(
      hasPermissionForFunctions({
        affectedFunctions: initialValues.affectedFunctions,
        roles,
      }) ||
        initialValues.organizedBy?.some(
          (organizer) => organizer === user.copData?._id
        ) ||
        hasPermission({ roles, permissions: ["seePollVoters"] })
    );

    setPollIsActive(
      initialValues?.start?.isBefore(dayjs()) &&
        initialValues?.end?.isAfter(dayjs())
    );
    // eslint-disable-next-line
  }, [initialValues, id]);

  const pollSchema = yup.object().shape({
    title: yup.string().required("Pflichtfeld"),
    description: yup.string(),
    start: yup
      .object()
      .nullable()
      .required("Pflichtfeld")
      .dayjs("Ungültiges Datum"),
    end: yup
      .object()
      .nullable()
      .required("Pflichtfeld")
      .dayjs("Ungültiges Datum"),
    affectedFunctions: yup
      .array()
      .when("_", (_, schema) =>
        hasPermission({
          roles,
          permissions: ["postPollAll"],
        })
          ? schema
          : schema.min(1, "Pflichtfeld")
      )
      .of(yup.string()),
    organizedBy: yup.array().min(1, "Pflichtfeld").of(yup.string()),
    commentRequired: yup.boolean(),
    anonymous: yup.boolean(),
    voteOptions: yup
      .array()
      .min(2, "Pflichtfeld (min. 2 Optionen)")
      .of(yup.string()),
  });

  // Pflichtfeld comment
  const voteSchema = yup.object().shape({
    vote: yup.string().required("Pflichtfeld"),
    comment: yup
      .string()
      .when("_", (_, schema) =>
        initialValues.commentRequired ? schema.required("Pflichtfeld") : schema
      ),
  });

  const onSubmit = async (values, { resetForm }) => {
    if (!!id) {
      await updatePoll(values)
        .unwrap()
        .then(() => {
          showSnackbar("Abstimmung geändert");
          resetForm();
          onClose();
        })
        .catch((error) => {
          showSnackbar(error?.data?.message || "Fehler", "error");
          onClose();
        });
    } else {
      await addPoll(values)
        .unwrap()
        .then(() => {
          showSnackbar("Abstimmung erstellt");
          resetForm();
          onClose();
        })
        .catch((error) => {
          showSnackbar(error?.data?.message || "Fehler", "error");
          onClose();
        });
    }
  };

  const onDelete = async () => {
    await deletePoll(id)
      .unwrap()
      .then(() => {
        showSnackbar("Abstimmung gelöscht");
        onClose();
      })
      .catch((error) => {
        showSnackbar(error?.data?.message || "Fehler", "error");
        onClose();
      });
  };

  const onVoteSubmit = async (values) => {
    if (initialValues.userVote?.vote) {
      await updateVote({ id, comment: values.comment, vote: values.vote })
        .unwrap()
        .then(() => {
          showSnackbar("Stimme geändert");
          onClose();
        })
        .catch((error) => {
          showSnackbar(error?.data?.message || "Fehler", "error");
          onClose();
        });
    } else {
      await addVote({ id, comment: values.comment, vote: values.vote })
        .unwrap()
        .then(() => {
          showSnackbar("Stimme gesendet");
          onClose();
        })
        .catch((error) => {
          showSnackbar(error?.data?.message || "Fehler", "error");
          onClose();
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (expanded) setExpanded(false);
        onClose();
      }}
      maxWidth={!!id ? "lg" : "sm"}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            {!!id
              ? readOnly
                ? "Abstimmung anzeigen"
                : "Abstimmung bearbeiten"
              : "Neue Abstimmung"}
            {!!id && !pollIsActive && (
              <Chip
                color="warning"
                label="Beendet"
                icon={<InfoOutlinedIcon />}
                size="small"
                sx={{
                  ml: "16px",
                }}
              />
            )}
          </Box>
          {!!id && !readOnly && (
            <Button
              onClick={() => onDelete(id)}
              color="error"
              startIcon={<DeleteOutlinedIcon />}
              variant="contained"
            >
              Löschen
            </Button>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} columns={2} sx={{ mt: 0 }}>
          <Grid item xs={2} xl={!!id ? 1 : 2}>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={pollSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                setFieldValue,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Titel"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.title}
                        name="title"
                        error={!readOnly && !!touched.title && !!errors.title}
                        helperText={!readOnly && touched.title && errors.title}
                        InputProps={{
                          readOnly: readOnly,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        label="Beschreibung"
                        name="description"
                        value={values.description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          !readOnly &&
                          !!touched.description &&
                          !!errors.description
                        }
                        helperText={
                          !readOnly && touched.description && errors.description
                        }
                        InputProps={{
                          readOnly: readOnly,
                        }}
                        inputProps={{
                          maxLength: 200,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} xl={6}>
                      <DateTimePicker
                        value={dayjs(values.start)}
                        name="start"
                        label="Start"
                        onChange={(value) => setFieldValue("start", value)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            onBlur: handleBlur,
                            error:
                              !readOnly && !!touched.start && !!errors.start,
                            helperText:
                              !readOnly && touched.start && errors.start,
                          },
                        }}
                        readOnly={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} xl={6}>
                      <DateTimePicker
                        value={dayjs(values.end)}
                        name="end"
                        label="Ende"
                        onChange={(value) => setFieldValue("end", value)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            onBlur: handleBlur,
                            error: !readOnly && !!touched.end && !!errors.end,
                            helperText: !readOnly && touched.end && errors.end,
                          },
                        }}
                        readOnly={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CopFunctions
                        functions={values.affectedFunctions}
                        onChange={(_, value) => {
                          setFieldValue("affectedFunctions", value);
                        }}
                        hideInputOnReadOnly={false}
                        inputProps={{
                          limitTags: 3,
                          name: "affectedFunctions",
                          label: "Für Funktion",
                          onBlur: handleBlur,
                          error:
                            !readOnly &&
                            touched.affectedFunctions &&
                            !!errors.affectedFunctions,
                          helperText:
                            !readOnly &&
                            touched.affectedFunctions &&
                            errors.affectedFunctions,
                        }}
                        readOnly={readOnly}
                        autocompleteProps={{
                          getOptionDisabled: (option) =>
                            !hasPermissionForFunction({
                              affectedFunction: option,
                              roles,
                            }),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CopsAutocomplete
                        cops={cops}
                        autocompleteProps={{
                          id: "organizedBy",
                          limitTags: 3,
                          readOnly: readOnly,
                          forcePopupIcon: !readOnly,
                          value: values.organizedBy,
                          onChange: (_, value) =>
                            setFieldValue("organizedBy", value),
                          onBlur: handleBlur,
                        }}
                        inputProps={{
                          error:
                            !readOnly &&
                            touched.organizedBy &&
                            !!errors.organizedBy,
                          helperText:
                            !readOnly &&
                            touched.organizedBy &&
                            errors.organizedBy,
                          label: "Organisatoren",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        multiple
                        freeSolo
                        value={values.voteOptions}
                        onChange={(_, value) =>
                          setFieldValue("voteOptions", value)
                        }
                        options={[]}
                        onBlur={handleBlur}
                        readOnly={readOnly}
                        forcePopupIcon={!readOnly}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            label="Optionen"
                            error={
                              !readOnly &&
                              touched.voteOptions &&
                              !!errors.voteOptions
                            }
                            helperText={
                              !readOnly &&
                              touched.voteOptions &&
                              errors.voteOptions
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} xl={7}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.commentRequired}
                            name="commentRequired"
                            onChange={handleChange}
                            disabled={readOnly}
                          />
                        }
                        label="Kommentar bei Stimmabgabe verpflichtend"
                      />
                    </Grid>
                    <Grid item xs={12} xl={5}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.anonymous}
                            name="anonymous"
                            onChange={handleChange}
                            disabled={readOnly || !!id}
                          />
                        }
                        label="Anonyme Abstimmung"
                      />
                    </Grid>
                    {!readOnly && (
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          type="submit"
                          startIcon={
                            !!id ? <SaveOutlinedIcon /> : <AddOutlinedIcon />
                          }
                        >
                          {!!id ? "Speichern" : "Erstellen"}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>

          {!!id && (
            <Grid item xs={2} xl={1}>
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="space-between"
              >
                <Box>
                  <Formik
                    onSubmit={onVoteSubmit}
                    initialValues={initialValues.userVote}
                    validationSchema={voteSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <Grid container spacing={2}>
                          <Grid item xs={12} xl={3}>
                            <TextField
                              select
                              value={values.vote}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.vote && !!errors.vote}
                              helperText={touched.vote && errors.vote}
                              label="Stimme"
                              name="vote"
                              fullWidth
                              InputProps={{
                                disabled: !pollIsActive,
                              }}
                            >
                              {initialValues?.voteOptions?.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} xl>
                            <TextField
                              fullWidth
                              type="text"
                              label="Kommentar"
                              name="comment"
                              value={values.comment}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.comment && !!errors.comment}
                              helperText={touched.comment && errors.comment}
                              inputProps={{
                                maxLength: 200,
                              }}
                              InputProps={{
                                disabled: !pollIsActive,
                              }}
                            />
                          </Grid>
                          {pollIsActive && (
                            <Grid item xs={12} xl={4}>
                              <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                startIcon={<HowToVoteOutlinedIcon />}
                                disabled={
                                  addVoteIsLoading || updateVoteIsLoading
                                }
                                sx={{ mt: "8px" }}
                              >
                                {initialValues.userVote?.vote
                                  ? "Stimme ändern"
                                  : "Abstimmen"}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </form>
                    )}
                  </Formik>
                  <Box mt={2} height={seeVotes ? "330px" : "300px"}>
                    {initialValues.votes?.length > 0 ? (
                      <PollPieChart votes={initialValues.votes} />
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        height="100%"
                        justifyContent="center"
                        alignItems="center"
                        color={theme.palette.action.disabled}
                      >
                        <PendingOutlinedIcon
                          sx={{
                            fontSize: "40px",
                          }}
                        />
                        <Typography variant="h6">
                          Noch Keine Stimmen vorhanden
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>

                {seeVotes && (
                  <Box>
                    <Button
                      onClick={() => setExpanded((value) => !value)}
                      variant="contained"
                      disabled={initialValues.votes?.length === 0}
                    >
                      <Box mr="8px">Stimmen</Box>
                      <RotatedExpandIcon expand={expanded} />
                    </Button>
                    <Collapse
                      in={expanded}
                      timeout="auto"
                      unmountOnExit={false}
                    >
                      <Box mt={1} height="300px">
                        <PollVoteDataGrid votes={initialValues.votes} />
                      </Box>
                    </Collapse>
                  </Box>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default memo(PollDialog);
