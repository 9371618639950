import { useEffect, useRef, useState } from "react";
import { useGetNewsQuery } from "./newsApiSlice";
import dayjs from "dayjs";
import { Box, Chip, IconButton, Typography } from "@mui/material";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Carousel from "react-material-ui-carousel";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const News = ({ news }) => {
  return !!news?.url ? (
    <Link
      to={news?.url}
      target={news?.url.charAt(0) === "/" ? "_self" : "_blank"}
    >
      <Chip
        icon={<LinkOutlinedIcon color="info" />}
        label={
          <Typography sx={{ userSelect: "none" }}>{news?.message}</Typography>
        }
        clickable
        sx={{
          ml: 1,
        }}
      />
    </Link>
  ) : (
    <Chip
      label={
        <Typography sx={{ userSelect: "none" }}>{news?.message}</Typography>
      }
      sx={{
        ml: 1,
      }}
    />
  );
};

const NewsFeatBar = () => {
  const { data: news, isSuccess: getNewsIsSuccess } = useGetNewsQuery(
    "newsList",
    {
      refetchOnMountOrArgChange: false,
    }
  );

  const [activeNews, setActiveNews] = useState([]);
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (getNewsIsSuccess) {
      setActiveNews(
        news.ids?.reduce((filtered, id) => {
          const newsEntity = news.entities[id];

          if (
            dayjs().isBetween(dayjs(newsEntity.from), dayjs(newsEntity.until))
          ) {
            filtered.push(newsEntity);
          }
          return filtered;
        }, [])
      );
    }
  }, [news, getNewsIsSuccess]);

  useEffect(() => {
    timeoutRef.current = setTimeout(
      () => setIndex((index) => (index + 1) % activeNews.length),
      10000
    );

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [index, activeNews]);

  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box display="flex" alignItems="center" mr={1}>
        <KeyboardDoubleArrowRightIcon color="primary" sx={{ mr: 1 }} />
        <Typography
          fontStyle="italic"
          sx={{
            userSelect: "none",
          }}
        >
          News
        </Typography>
        <CampaignOutlinedIcon sx={{ ml: 1 }} />
      </Box>
      {activeNews.length > 0 ? (
        <Box display="flex" alignItems="center" width="100%">
          <IconButton
            size="small"
            disabled={index === 0}
            onClick={() => setIndex((index) => (index - 1) % activeNews.length)}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            size="small"
            disabled={activeNews.length === 1}
            onClick={() => setIndex((index) => (index + 1) % activeNews.length)}
          >
            <NavigateNextIcon />
          </IconButton>

          <Carousel
            index={index}
            onChange={(now) => setIndex(now)}
            autoPlay={false}
            indicators={false}
            swipe={false}
            animation="slide"
            duration={500}
            cycleNavigation
            navButtonsAlwaysInvisible
            sx={{
              display: "flex",
              width: "100%",
              maxWidth: "500px",
              "--mask-image-content":
                "linear-gradient(to right, transparent, black 8px, black calc(100% - 8px), transparent)",
              "--mask-size-content": "calc(100% - 0) 100%",
              overflow: "visible",

              padding: "0 8px",
              maskImage: "var(--mask-image-content)",
              maskSize: "100%",
              maskPosition: "0 0, 100% 0",
              maskRepeat: "no-repeat",
            }}
          >
            {activeNews.map((news, i) => (
              <News key={i} news={news} />
            ))}
          </Carousel>
        </Box>
      ) : (
        <Chip
          label={<Typography>Derzeit gibt es keine Neuigkeiten</Typography>}
        />
      )}
    </Box>
  );
};
export default NewsFeatBar;
