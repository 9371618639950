import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { faSteam } from "@fortawesome/free-brands-svg-icons";

const SteamProfileLinkButton = ({ profileurl }) => {
  const theme = useTheme();

  return (
    <Tooltip
      arrow
      title={<Typography variant="subtitle1">Steam Profil</Typography>}
    >
      <Link
        style={{
          textDecoration: "none",
          marginLeft: "8px",
        }}
        to={profileurl}
        target="_blank"
      >
        <FontAwesomeIcon
          style={{
            color: theme.palette.info.main,
          }}
          size="xl"
          icon={faSteam}
        />
      </Link>
    </Tooltip>
  );
};
export default SteamProfileLinkButton;
