import { Box, Button, useTheme } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  useAddLinkMutation,
  useDeleteLinkMutation,
  useGetLinksQuery,
  useUpdateLinkMutation,
} from "./linksApiSlice";
import { dataGridSx } from "styles/dataGridSx";
import { useCallback, useEffect, useState } from "react";
import AddLinkOutlinedIcon from "@mui/icons-material/AddLinkOutlined";
import { useSnackbar } from "contexts/Snackbar.context";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useConfirmDialog } from "contexts/ConfirmDialog.context";
import CustomGridPagination from "components/CustomGridPagination";
import { Link } from "react-router-dom";
import { calcGridHeightSubtraction } from "util/calculations";

const LinksDataGrid = () => {
  const theme = useTheme();
  const {
    data: links,
    isSuccess: getLinksIsSuccess,
    isLoading: getLinksIsLoading,
  } = useGetLinksQuery("linksList", {
    refetchOnMountOrArgChange: true,
  });

  const [addLink] = useAddLinkMutation();
  const [updateLink] = useUpdateLinkMutation();
  const [deleteLink] = useDeleteLinkMutation();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const showSnackbar = useSnackbar();

  useEffect(() => {
    if (getLinksIsSuccess)
      setRows(
        links?.ids.map((id) => ({
          id,
          category: links.entities[id].category,
          text: links.entities[id].text,
          url: links.entities[id].url,
        }))
      );
  }, [links, getLinksIsSuccess]);

  const EditToolbar = () => {
    const handleAddLink = async () => {
      const id = `$${Date.now()}`;
      setRows((oldRows) => [
        { id, category: "", text: "", url: "", isNew: true },
        ...oldRows,
      ]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "category" },
      }));
    };

    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <Button
          variant="text"
          onClick={handleAddLink}
          startIcon={<AddLinkOutlinedIcon />}
        >
          Link hinzufügen
        </Button>
      </GridToolbarContainer>
    );
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const showConfirmDialog = useConfirmDialog();

  const handleDeleteLink = useCallback(async (id) => {
    try {
      await deleteLink(id).unwrap();
      showSnackbar("Link gelöscht");
    } catch (error) {
      showSnackbar(error.data?.message || "Fehler", "error");
    }

    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = (id) => () => {
    showConfirmDialog({
      title: "Link löschen?",
      message: "Soll der Link gelöscht werden?",
      onConfirm: () => handleDeleteLink(id),
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    if (newRow.isNew) {
      await addLink(newRow).unwrap();
      newRow.isNew = false;
      showSnackbar("Link angelegt");
    } else {
      await updateLink(newRow).unwrap();
      showSnackbar("Link gespeichert");
    }

    return newRow;
  };

  const handleProcessRowUpdateError = (error) => {
    showSnackbar(error.data?.message, "error");
  };

  const renderUrl = ({ value }) => (
    <Link
      to={value || "#"}
      target="_blank"
      style={{ color: theme.palette.info.main, textDecoration: "none" }}
    >
      {value}
    </Link>
  );

  const columns = [
    {
      field: "category",
      type: "singleSelect",
      valueOptions: [
        "Formulare",
        "Regeln",
        "Dienstanweisungen",
        "Konzepte",
        "Informationen",
        "Sonstiges",
      ],
      headerName: "Kategorie",
      editable: true,
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "text",
      headerName: "Beschreibung",
      editable: true,
      flex: 1,
    },
    {
      field: "url",
      headerName: "URL",
      renderCell: renderUrl,
      flex: 2,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Aktionen",
      type: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Speichern"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Bearbeiten"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Löschen"
            onClick={handleDeleteClick(id)}
            color="error"
          />,
        ];
      },
    },
  ];

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        loading={getLinksIsLoading}
        editMode="row"
        rows={getLinksIsSuccess ? rows : []}
        slots={{ toolbar: EditToolbar, pagination: CustomGridPagination }}
        initialState={{
          sorting: {
            sortModel: [{ field: "category", sort: "asc" }],
          },
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
    </Box>
  );
};
export default LinksDataGrid;
