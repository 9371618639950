import {
  Avatar,
  Box,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useContext } from "react";
import { ColorModeContext } from "theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSendLogoutMutation } from "features/auth/authApiSlice";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import RequirePermissionForRender from "features/auth/RequirePermissionForRender";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import NewsFeatBar from "features/news/NewsFeatBar";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import SlotChecker from "components/SlotChecker";
import useAuth from "hooks/useAuth";
import NotificationBell from "features/notifications.js/NotificationBell";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { hasPermission } from "util/permissionHelper";
import Settings from "components/Settings";

const Navbar = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const isNonMobile = useMediaQuery("(min-width: 1000px");

  const [sendLogout, { isLoading }] = useSendLogoutMutation();
  const navigate = useNavigate();
  const location = useLocation();

  const { steamUser, roles } = useAuth();

  const handleLogout = async () => {
    await sendLogout();
    navigate("/login");
  };

  return isNonMobile ? (
    <Box
      id="navbar"
      display="flex"
      justifyContent={"space-between"}
      p={theme.spacing(2)}
      alignItems="center"
    >
      <Box display="flex" width="100%">
        <NewsFeatBar />
      </Box>

      {/* ICONS */}
      <Box display="flex" ml="16px" alignItems="center">
        <Tooltip
          arrow
          title={
            <Typography>
              Wechseln zu{" "}
              {theme.palette.mode === "dark" ? "Lightmode" : "Darkmode"}
            </Typography>
          }
        >
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Tooltip>

        <RequirePermissionForRender permissions={["copBasic"]}>
          <SlotChecker />

          <Tooltip arrow title={<Typography>Nützliche Links</Typography>}>
            <IconButton
              onClick={() => navigate("/useful-links")}
              color={
                location.pathname === "/useful-links" ? "primary" : "default"
              }
            >
              <LinkOutlinedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title={<Typography>FAQ</Typography>}>
            <IconButton
              onClick={() => navigate("/faq")}
              color={location.pathname === "/faq" ? "primary" : "default"}
            >
              <HelpOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </RequirePermissionForRender>

        <NotificationBell />

        <Settings />

        <Tooltip arrow title={<Typography>Profil</Typography>}>
          <IconButton
            onClick={() => navigate("/profile")}
            sx={{
              borderRadius: "50%",
              m: "0 8px",
              p: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              src={steamUser?.avatarmedium}
              sx={{ width: "36.5px", height: "36.5px" }}
              alt="steam-avatar"
            />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title={<Typography>Ausloggen</Typography>}>
          <IconButton disabled={isLoading} onClick={handleLogout}>
            {isLoading ? (
              <CircularProgress color="error" size="1em" />
            ) : (
              <LogoutIcon color="error" />
            )}
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  ) : (
    <Box mb={2}>
      <SpeedDial
        ariaLabel="speed-dial"
        direction="up"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={
          <Avatar
            src={steamUser?.avatarmedium}
            sx={{ width: "100%", height: "100%" }}
            alt="steam-avatar"
          />
        }
      >
        <SpeedDialAction
          icon={
            <PersonOutlineOutlinedIcon
              sx={{
                color:
                  location.pathname === "/profile"
                    ? "primary.main"
                    : "text.primary",
              }}
            />
          }
          onClick={() => navigate("/profile")}
        />

        <SpeedDialAction
          FabProps={{
            component: "div",
          }}
          icon={<Settings />}
        />

        <SpeedDialAction
          FabProps={{
            component: "div",
          }}
          icon={<NotificationBell />}
        />

        {hasPermission({ roles, permissions: ["copBasic"] }) && (
          <SpeedDialAction
            FabProps={{
              component: "div",
            }}
            icon={<SlotChecker />}
          />
        )}

        <SpeedDialAction
          onClick={colorMode.toggleColorMode}
          icon={
            theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon
                sx={{
                  color: "text.primary",
                }}
              />
            ) : (
              <LightModeOutlinedIcon
                sx={{
                  color: "text.primary",
                }}
              />
            )
          }
        />

        {hasPermission({ roles, permissions: ["copBasic"] }) && (
          <SpeedDialAction
            onClick={() => navigate("/faq")}
            icon={
              <HelpOutlineOutlinedIcon
                sx={{
                  color:
                    location.pathname === "/faq"
                      ? "primary.main"
                      : "text.primary",
                }}
              />
            }
          />
        )}

        {hasPermission({ roles, permissions: ["copBasic"] }) && (
          <SpeedDialAction
            onClick={() => navigate("/useful-links")}
            icon={
              <LinkOutlinedIcon
                sx={{
                  color:
                    location.pathname === "/useful-links"
                      ? "primary.main"
                      : "text.primary",
                }}
              />
            }
          />
        )}

        <SpeedDialAction
          icon={
            isLoading ? (
              <CircularProgress color="error" size="1em" />
            ) : (
              <LogoutIcon color="error" />
            )
          }
          onClick={handleLogout}
        />
      </SpeedDial>
    </Box>
  );
};

export default Navbar;
