import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { DateTimeField } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import CopRatioViolationDetailChart from "./CopRatioViolationDetailChart";

dayjs.extend(duration);

const CopRatioViolationDetailDialog = ({ open, onClose, violation }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Cop Quoten Verstoß</DialogTitle>
      <DialogContent sx={{ overflowX: "hidden", overflowY: "auto" }}>
        <Grid container spacing={2} mt={0}>
          <Grid item xs={12} lg={3}>
            <TextField
              fullWidth
              value={violation.cop?.user?.name}
              label="Name"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <DateTimeField
              fullWidth
              value={dayjs(violation.createdAt)}
              readOnly
              label="Bemerkt am"
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              fullWidth
              value={dayjs
                .duration(
                  dayjs(violation.playtimeEnd).diff(
                    dayjs(violation.playtimeStart)
                  )
                )
                .format("HH[h] mm[m]")}
              label="Dauer der Spielzeit"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              fullWidth
              value={dayjs
                .duration(violation.violationDuration)
                .format("HH[h] mm[m]")}
              label="Dauer des Verstoßes"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CopRatioViolationDetailChart violations={violation.violations} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default CopRatioViolationDetailDialog;
