import { apiSlice } from "app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const pollsAdapter = createEntityAdapter();

const initialState = pollsAdapter.getInitialState();

export const pollsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPolls: builder.query({
      query: () => ({
        url: "/polls",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      transformResponse: (responseData) => {
        const loadedPolls = responseData.map((poll) => {
          poll.id = poll._id;
          return poll;
        });

        return pollsAdapter.setAll(initialState, loadedPolls);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Poll", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Poll", id })),
          ];
        } else return [{ type: "Poll", id: "LIST" }];
      },
    }),
    addPoll: builder.mutation({
      query: (poll) => ({
        url: "/polls",
        method: "POST",
        body: { ...poll },
      }),
      invalidatesTags: [{ type: "Poll", id: "LIST" }],
    }),
    updatePoll: builder.mutation({
      query: (poll) => ({
        url: "/polls",
        method: "PATCH",
        body: { ...poll },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Poll", id: arg.id }],
    }),
    deletePoll: builder.mutation({
      query: (id) => ({
        url: "/polls",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Poll", id: arg.id }],
    }),
    addVote: builder.mutation({
      query: (vote) => ({
        url: "/polls/vote",
        method: "POST",
        body: { ...vote },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Poll", id: arg.id }],
    }),
    updateVote: builder.mutation({
      query: (vote) => ({
        url: "/polls/vote",
        method: "PATCH",
        body: { ...vote },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Poll", id: arg.id }],
    }),
  }),
});

export const {
  useGetPollsQuery,
  useAddPollMutation,
  useUpdatePollMutation,
  useDeletePollMutation,
  useAddVoteMutation,
  useUpdateVoteMutation,
} = pollsApiSlice;

export const selectPollsResult = pollsApiSlice.endpoints.getPolls.select();

const selectPollsData = createSelector(
  selectPollsResult,
  (pollsResult) => pollsResult.data
);

export const { selectAll: selectAllPolls, selectIds: selectPollsIds } =
  pollsAdapter.getSelectors((state) => selectPollsData(state) ?? initialState);
