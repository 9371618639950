import { Navigate, Outlet } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useRefreshMutation } from "./authApiSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { useSnackbar } from "contexts/Snackbar.context";

import React from "react";
import useAuth from "hooks/useAuth";

const PersistLogin = () => {
  const showSnackbar = useSnackbar();
  const { token } = useAuth();
  const effectRan = useRef(false);
  const persist = true; // for possible feature in future: user can decide wheter he wants to stay logged in

  const [trueSuccess, setTrueSuccess] = useState(false);

  const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] =
    useRefreshMutation();

  useEffect(() => {
    if (effectRan.current === true || process.env.NODE_ENV !== "development") {
      const verifyRefreshToken = async () => {
        try {
          await refresh();
          setTrueSuccess(true);
        } catch (err) {
          console.log(err);
        }
      };

      if (!token && persist) verifyRefreshToken();
    }

    return () => (effectRan.current = true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isError && error?.data?.message)
      showSnackbar(error?.data?.message, "error");
    // eslint-disable-next-line
  }, [error, isError, isLoading]);

  let content;
  if (!persist) {
    // persist: no
    content = <Outlet />;
  } else if (isLoading) {
    //persist: yes, token: no
    content = (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100%"
      >
        <CircularProgress color="info" size={80} />
      </Box>
    );
  } else if (isError) {
    //persist: yes, token: no
    content = <Navigate to="/login" />;
  } else if (isSuccess && trueSuccess) {
    //persist: yes, token: yes
    content = <Outlet />;
  } else if (token && isUninitialized) {
    //persist: yes, token: yes
    content = <Outlet />;
  }

  return content;
};

export default PersistLogin;
