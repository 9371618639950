import { Box } from "@mui/material";
import Header from "components/Header";
import PlaytimeDataGrid from "features/playtimes/PlaytimeDataGrid";
import { useEffect } from "react";

const PlaytimeList = () => {
  useEffect(() => {
    document.title = `Spielzeiten | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header
        title="SPIELZEITEN"
        subtitle="Auflistung der Spielzeit Protokolle"
      />
      <PlaytimeDataGrid />
    </Box>
  );
};
export default PlaytimeList;
