import { Box } from "@mui/material";
import CustomCard from "components/CustomCard";
import Header from "components/Header";
import dayjs from "dayjs";
import RatingForm from "features/ratings/RatingForm";
import { useEffect } from "react";

const AddRating = () => {
  useEffect(() => {
    document.title = `Bewertung verfassen | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box>
      <Header
        title="BEWERTUNG VERFASSEN"
        subtitle="Leistung eines Polizisten bewerten"
      />
      <Box maxWidth="1000px">
        <CustomCard>
          <RatingForm
            initialValues={{
              evaluator: { function: "" },
              rated: { cop: "", function: "" },
              date: dayjs(),
              period: "",
              ratings: {
                roleplay: 0,
                performance: 0,
                tactics: 0,
                radio: 0,
                knowledge: 0,
              },
              comment: "",
            }}
          />
        </CustomCard>
      </Box>
    </Box>
  );
};
export default AddRating;
