import { Box, Chip, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomCard from "components/CustomCard";
import CustomGridPagination from "components/CustomGridPagination";
import { memo, useEffect, useState } from "react";
import { dataGridSx } from "styles/dataGridSx";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import { getVacationStatusColor } from "util/muiColorGetter";

const renderStatus = ({ value }) => (
  <Chip label={value} color={getVacationStatusColor(value)} size="small" />
);

const columns = [
  {
    field: "from",
    headerName: "Von",
    ...dateTimeColumnType,
    flex: 1,
    maxWidth: 130,
  },
  {
    field: "until",
    headerName: "Bis",
    ...dateTimeColumnType,
    flex: 1,
    maxWidth: 130,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    maxWidth: 120,
    renderCell: renderStatus,
    type: "singleSelect",
    valueOptions: ["Neu", "Angenommen", "Abgelehnt"],
  },
  {
    field: "reason",
    headerName: "Grund",
    flex: 1,
    maxWidth: 150,
  },
  {
    field: "comment",
    headerName: "Kommentar",
    flex: 1,
  },
  {
    field: "updatedAt",
    headerName: "Geändert am",
    ...dateTimeColumnType,
    flex: 1,
    maxWidth: 120,
  },
  {
    field: "updatedBy",
    headerName: "Geändert von",
    valueGetter: ({ value }) => value?.name,
    flex: 1,
    maxWidth: 150,
  },
];

const CopVacations = ({ vacationsQuery }) => {
  const theme = useTheme();

  const { data: vacations, isSuccess, isLoading } = vacationsQuery;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setRows(vacations.ids.map((id) => vacations.entities[id]));
    }
  }, [isSuccess, vacations]);

  return (
    <CustomCard title="URLAUB">
      <Box height="350px" width="100%" sx={dataGridSx(theme)}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "until", sort: "desc" }],
            },
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          density="compact"
          disableRowSelectionOnClick
          columns={columns}
          rows={isSuccess ? rows : []}
          loading={isLoading}
          slots={{ pagination: CustomGridPagination }}
          pageSizeOptions={[5, 10, 25]}
          sx={{
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
          }}
        />
      </Box>
    </CustomCard>
  );
};
export default memo(CopVacations);
