import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import { ranks } from "util/copEnums";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { dataGridSx } from "styles/dataGridSx";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { useUpdateCareerMutation } from "./copsApiSlice";
import { useSnackbar } from "contexts/Snackbar.context";
import dayjs from "dayjs";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const CopCareerDialog = ({ open, onClose, career, copId }) => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [updateCareer] = useUpdateCareerMutation();

  const showSnackbar = useSnackbar();

  useEffect(() => {
    if (career) setRows([...career]);
  }, [career]);

  const handleSave = useCallback(async () => {
    await updateCareer({ id: copId, career: rows })
      .unwrap()
      .then(() => {
        onClose();
        showSnackbar("Karriere Informationen geändert");
      })
      .catch((error) => {
        showSnackbar(error?.data?.message || "Fehler", "error");
      });
    // eslint-disable-next-line
  }, [copId, rows]);

  const columns = useMemo(
    () => [
      {
        field: "date",
        headerName: "Zeitpunkt",
        flex: 1,
        ...dateTimeColumnType,
        editable: true,
        maxWidth: 160,
      },
      {
        field: "newRank",
        headerName: "Neuer Rang",
        editable: true,
        flex: 1,
        type: "singleSelect",
        valueOptions: ["", ...Object.keys(ranks)],
      },
      {
        field: "actions",
        headerName: "Aktionen",
        type: "actions",
        getActions: ({ id }) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Löschen"
            onClick={() =>
              setRows((oldRows) => oldRows.filter((row) => row._id !== id))
            }
            color="error"
          />,
        ],
      },
    ],
    []
  );

  const processRowUpdate = useCallback((newRow) => {
    setRows((oldRows) =>
      oldRows.map((oldRow) => (oldRow._id === newRow._id ? newRow : oldRow))
    );

    return newRow;
  }, []);

  const handleProcessRowUpdateError = useCallback((error) => {
    showSnackbar(error.data?.message || "Fehler", "error");
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Karriere Informationen bearbeiten</DialogTitle>
      <DialogContent>
        <Button
          startIcon={<AddOutlinedIcon />}
          onClick={() =>
            setRows((oldRows) => [
              {
                newRank: "",
                date: dayjs().startOf("day"),
                _id: `$${Date.now()}`,
              },
              ...oldRows,
            ])
          }
        >
          Zeile hinzufügen
        </Button>
        <Box
          height="700px"
          sx={{
            ...dataGridSx(theme),
            ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
        >
          <DataGrid
            rows={rows || []}
            getRowId={(row) => row?._id}
            columns={columns}
            editMode="row"
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            initialState={{
              sorting: {
                sortModel: [{ field: "date", sort: "desc" }],
              },
            }}
          />
        </Box>
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
          startIcon={<SaveIcon />}
          onClick={handleSave}
        >
          Speichern
        </Button>
      </DialogContent>
    </Dialog>
  );
};
export default memo(CopCareerDialog);
