import { Box } from "@mui/material";
import Header from "components/Header";
import ExpCalculationDataGrid from "features/expCalculations/ExpCalculationDataGrid";
import { useEffect } from "react";

const ExpCalculation = () => {
  useEffect(() => {
    document.title = `EP Kalkulation | Polizei ${process.env.REACT_APP_MAP}`;
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header
        title="EP KALKULATION"
        subtitle="Protokolle der berechneten Erfahrungspunkte"
      />
      <ExpCalculationDataGrid />
    </Box>
  );
};
export default ExpCalculation;
