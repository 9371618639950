import { Box, Button, useTheme } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { dataGridSx } from "styles/dataGridSx";
import { hasPermission } from "util/permissionHelper";
import { useGetPollsQuery } from "./pollsApiSlice";
import { useEffect, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CopsAutocomplete from "components/CopsAutocomplete";
import { dateTimeColumnType } from "util/dateTimeColumnType";
import CustomGridPagination from "components/CustomGridPagination";
import PollDialog from "./PollDialog";
import useAuth from "hooks/useAuth";
import dayjs from "dayjs";
import CopFunctions from "features/cops/CopFunctions";
import PollPieChart from "./PollPieChart";
import { calcGridHeightSubtraction } from "util/calculations";

const PollDataGrid = () => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [pollData, setPollData] = useState({});
  const { user, roles } = useAuth();

  const {
    data: polls,
    isSuccess,
    isLoading,
  } = useGetPollsQuery("pollsList", { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (isSuccess) {
      setRows(polls.ids.map((id) => polls.entities[id]));
    }
  }, [polls, isSuccess]);

  useEffect(() => {
    if (!dialogOpen) setPollData({});
  }, [dialogOpen]);

  const EditToolbar = () => {
    const handleAdd = async () => {
      setPollData({
        title: "",
        description: "",
        start: null,
        end: null,
        affectedFunctions: [],
        organizedBy: [user.copData?._id],
        commentRequired: false,
        anonymous: false,
        userVote: {
          vote: "",
          comment: "",
        },
        voteOptions: ["Ja", "Nein", "Enthaltung"],
      });
      setDialogOpen(true);
    };

    return (
      <GridToolbarContainer sx={{ pr: 0 }}>
        <GridToolbarQuickFilter />
        <Button
          variant="text"
          onClick={handleAdd}
          startIcon={<AddOutlinedIcon />}
        >
          Neue Abstimmung
        </Button>
      </GridToolbarContainer>
    );
  };

  const renderCops = (params) => (
    <CopsAutocomplete
      cops={{
        ids: params.value.map((organizer) => organizer._id),
        entities: params.value.reduce(
          (entities, organizer) => ({
            ...entities,
            [organizer._id]: organizer,
          }),
          {}
        ),
      }}
      autocompleteProps={{
        readOnly: true,
        forcePopupIcon: false,
        value: params.value.map((cop) => cop._id),
        limitTags: 2,
      }}
      inputProps={{
        sx: {
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
        },
      }}
    />
  );
  const renderFunctions = (params) => (
    <CopFunctions
      functions={params.value}
      readOnly
      hideOutlines
      hideLabel
      inputProps={{ limitTags: 3 }}
    />
  );

  const renderVotes = (params) => (
    <Box display="flex" width="100%" height="50px">
      <PollPieChart
        votes={params.value}
        legends={[]}
        margin={{ top: 8, right: 0, bottom: 8, left: 0 }}
        enableArcLabels={false}
        cornerRadius={0}
      />
    </Box>
  );

  const columns = [
    {
      field: "title",
      headerName: "Titel",
      flex: 1,
      maxWidth: 300,
    },
    {
      field: "start",
      headerName: "Start",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "end",
      headerName: "Ende",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "isOpen",
      headerName: "Geöffnet",
      type: "boolean",
      valueGetter: ({ row }) =>
        dayjs(row.start).isBefore(dayjs()) && dayjs(row.end).isAfter(dayjs()),
      flex: 1,
      maxWidth: 100,
    },
    {
      field: "affectedFunctions",
      headerName: "Für Funktion",
      renderCell: renderFunctions,
      flex: 1,
    },
    {
      field: "votes",
      headerName: "Ergebnis",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      renderCell: renderVotes,
      display: "flex",
    },
    {
      field: "organizedBy",
      headerName: "Organisatoren",
      renderCell: renderCops,
      flex: 1,
      maxWidth: 350,
    },
    {
      field: "updatedAt",
      headerName: "Geändert am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "updatedBy",
      headerName: "Geändert von",
      valueGetter: ({ value }) => value?.name,
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      ...dateTimeColumnType,
      flex: 1,
      maxWidth: 120,
    },
    {
      field: "createdBy",
      headerName: "Erstellt von",
      valueGetter: ({ value }) => value?.name,
      flex: 1,
      maxWidth: 200,
    },
  ];

  const handleRowClick = ({ row }) => {
    const userVote = row.votes?.find(
      (vote) => vote.cop?._id && vote.cop?._id === user.copData?._id
    );

    setPollData({
      ...row,
      start: dayjs(row.start),
      end: dayjs(row.end),
      organizedBy: row.organizedBy.map((cop) => cop._id),
      userVote: userVote
        ? { vote: userVote.vote, comment: userVote.comment }
        : { vote: "", comment: "" },
    });
    setDialogOpen(true);
  };

  const [subtractGridHeight, setSubtractGridHeight] = useState(0);

  useEffect(() => {
    setSubtractGridHeight(calcGridHeightSubtraction());
  }, []);

  return (
    <Box
      sx={{
        ...dataGridSx(theme),
        ".MuiDataGrid-row": {
          ":hover": {
            cursor: "pointer !important",
          },
        },
        ".MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        ".MuiDataGrid-virtualScroller": {
          overflow: "visible",
        },
        ".cell-votes": {
          overflow: "visible !important",
        },
        "& .row-done": {
          color:
            theme.palette.mode === "dark"
              ? theme.palette.action.disabled
              : theme.palette.action.active,
        },
        height: `calc(100vh - ${subtractGridHeight}px)`,
      }}
    >
      <DataGrid
        columns={columns}
        rows={isSuccess ? rows : []}
        loading={isLoading}
        onRowClick={handleRowClick}
        rowSelection={false}
        getCellClassName={(params) =>
          params.field === "votes" ? "cell-votes" : ""
        }
        getRowClassName={({ row }) => {
          const isActive =
            dayjs(row.start).isBefore(dayjs()) &&
            dayjs(row.end).isAfter(dayjs());
          const didVote = row.votes?.some(
            (vote) => vote.cop?._id === user.copData?._id
          );

          return !isActive || didVote ? "row-done" : "";
        }}
        getRowHeight={() => "auto"}
        initialState={{
          sorting: {
            sortModel: [{ field: "isOpen", sort: "desc" }],
          },
        }}
        slots={{
          pagination: CustomGridPagination,
          toolbar:
            hasPermission({
              roles,
              permissions: [
                "postPollSecondaryFaction",
                "postPollVerkÜ",
                "postPollZoll",
                "postPollKripo",
                "postPollSEK",
                "postPollFortbilder",
                "postPollRekrutierer",
                "postPollAusbilder",
                "postPollPersonalrat",
                "postPollFührung",
                "postPollLeitung",
                "postPollAll",
              ],
            }) && EditToolbar,
        }}
      />
      <PollDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        initialValues={pollData}
      />
    </Box>
  );
};
export default PollDataGrid;
